import {
  AssessmentTemplateComplianceRequirement,
  CompetencyAssessmentType,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import Checkbox from "@components/checkbox";
import ComboboxGeneric, {
  ComboboxGenericOption,
} from "@components/combobox/generic-combobox";
import Input from "@components/input/input";
import { assertNonNull } from "@helpers/helpers";

import { AssessmentTemplateExtraSettings } from "../edit-compliance-program";

const competencyAssessmentOptions = [
  {
    value: CompetencyAssessmentType.CriteriaCombined,
    label: "Assess competency as a whole",
  },
  {
    value: CompetencyAssessmentType.IndividualCriteria,
    label: "Assess each competency individually",
  },
];

const scaleSizeOptions = [
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
];
const scaleStartsAtOptions = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
];
enum NextRoleAssessmentConfig {
  NO = "NO",
  ONLY_MANAGER = "ONLY_MANAGER",
  ONLY_SUBJECT = "ONLY_SUBJECT",
  BOTH = "BOTH",
}
const rateNextRoleOptions = [
  {
    value: NextRoleAssessmentConfig.NO,
    label: "No",
  },
  {
    value: NextRoleAssessmentConfig.ONLY_MANAGER,
    label: "Only manager",
  },
  {
    value: NextRoleAssessmentConfig.ONLY_SUBJECT,
    label: "Only employee",
  },
  {
    value: NextRoleAssessmentConfig.BOTH,
    label: "Both",
  },
];
const nextRoleAssessmentConfigToProgramSettings = (
  opt: ComboboxGenericOption<NextRoleAssessmentConfig>
) => {
  if (opt.value === NextRoleAssessmentConfig.ONLY_MANAGER) {
    return {
      roleBasedAssessmentsSelfAssessNextRole: false,
      roleBasedAssessmentsAssessNextRole: true,
    };
  }
  if (opt.value === NextRoleAssessmentConfig.ONLY_SUBJECT) {
    return {
      roleBasedAssessmentsSelfAssessNextRole: true,
      roleBasedAssessmentsAssessNextRole: false,
    };
  }
  if (opt.value === NextRoleAssessmentConfig.BOTH) {
    return {
      roleBasedAssessmentsSelfAssessNextRole: true,
      roleBasedAssessmentsAssessNextRole: true,
    };
  }
  return {
    roleBasedAssessmentsSelfAssessNextRole: false,
    roleBasedAssessmentsAssessNextRole: false,
  };
};
const programSettingsToNextRoleAssessmentConfig = (
  programSettings: AssessmentTemplateComplianceRequirement
) => {
  if (
    programSettings.roleBasedAssessmentsAssessNextRole &&
    programSettings.roleBasedAssessmentsSelfAssessNextRole
  ) {
    return NextRoleAssessmentConfig.BOTH;
  }
  if (programSettings.roleBasedAssessmentsAssessNextRole) {
    return NextRoleAssessmentConfig.ONLY_MANAGER;
  }
  if (programSettings.roleBasedAssessmentsSelfAssessNextRole) {
    return NextRoleAssessmentConfig.ONLY_SUBJECT;
  }
  return NextRoleAssessmentConfig.NO;
};

const IncludeRoleBasedForm = ({
  selectedPerformanceAssessmentGroup,
  onChangeAssessmentTemplateSettings,
  disabled,
}: {
  selectedPerformanceAssessmentGroup?: AssessmentTemplateComplianceRequirement;
  onChangeAssessmentTemplateSettings: (
    assessmentTemplateSettings: AssessmentTemplateExtraSettings
  ) => void;
  disabled?: boolean;
}) => {
  const label = useLabel();
  const roleBasedEnabled =
    !!selectedPerformanceAssessmentGroup?.enableRoleBasedAssessments;
  return (
    <>
      <div className="w-fit">
        <Checkbox
          tooltip={
            selectedPerformanceAssessmentGroup
              ? undefined
              : `Requires performance ${label("review")}`
          }
          disabled={!selectedPerformanceAssessmentGroup || disabled}
          checked={roleBasedEnabled}
          onChange={(checked) =>
            onChangeAssessmentTemplateSettings({
              enableRoleBasedAssessments: checked,
            })
          }
          label={`Include Role-based ${label("review", {
            pluralize: true,
            capitalize: true,
          })}`}
        />
      </div>
      {roleBasedEnabled && (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Assess employee's next role, if available
            </div>
            <div className="max-w-sm">
              <ComboboxGeneric
                options={rateNextRoleOptions}
                disabled={disabled}
                onChangeValue={(opt) =>
                  onChangeAssessmentTemplateSettings(
                    nextRoleAssessmentConfigToProgramSettings(opt)
                  )
                }
                value={assertNonNull(
                  rateNextRoleOptions.find(
                    ({ value }) =>
                      value ===
                      programSettingsToNextRoleAssessmentConfig(
                        selectedPerformanceAssessmentGroup
                      )
                  )
                )}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Competency assessment configuration
            </div>
            <div className="max-w-sm">
              <ComboboxGeneric
                options={competencyAssessmentOptions}
                disabled={disabled}
                onChangeValue={(opt) =>
                  onChangeAssessmentTemplateSettings({
                    competencyAssessmentType: opt.value,
                  })
                }
                value={assertNonNull(
                  competencyAssessmentOptions.find(
                    ({ value }) =>
                      value ===
                      selectedPerformanceAssessmentGroup.competencyAssessmentType
                  )
                )}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Next role competency assessment configuration
            </div>
            <div className="max-w-sm">
              <ComboboxGeneric
                options={competencyAssessmentOptions}
                disabled={
                  disabled ||
                  !(
                    selectedPerformanceAssessmentGroup.roleBasedAssessmentsAssessNextRole ||
                    selectedPerformanceAssessmentGroup.roleBasedAssessmentsSelfAssessNextRole
                  )
                }
                onChangeValue={(opt) =>
                  onChangeAssessmentTemplateSettings({
                    nextRoleCompetencyAssessmentType: opt.value,
                  })
                }
                value={assertNonNull(
                  competencyAssessmentOptions.find(
                    ({ value }) =>
                      value ===
                      selectedPerformanceAssessmentGroup.nextRoleCompetencyAssessmentType
                  )
                )}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Scale size
            </div>
            <div className="max-w-sm">
              <ComboboxGeneric
                options={scaleSizeOptions}
                disabled={disabled}
                onChangeValue={(opt) =>
                  onChangeAssessmentTemplateSettings({
                    roleBasedAssessmentScale: opt.value,
                  })
                }
                value={assertNonNull(
                  scaleSizeOptions.find(
                    ({ value }) =>
                      value ===
                      selectedPerformanceAssessmentGroup.roleBasedAssessmentScale
                  )
                )}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Starts at
            </div>
            <div className="max-w-sm">
              <ComboboxGeneric
                options={scaleStartsAtOptions}
                disabled={disabled}
                onChangeValue={(opt) =>
                  onChangeAssessmentTemplateSettings({
                    roleBasedAssessmentScaleStartsAt: opt.value,
                  })
                }
                value={assertNonNull(
                  scaleStartsAtOptions.find(
                    ({ value }) =>
                      value ===
                      selectedPerformanceAssessmentGroup.roleBasedAssessmentScaleStartsAt
                  )
                )}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2 max-w-sm">
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Range labels
            </div>
            <div className="flex flex-col gap-2">
              {assertNonNull(
                selectedPerformanceAssessmentGroup.roleBasedAssessmentScaleLabels
              ).map((label, index) => {
                return (
                  <div key={index} className="flex items-center gap-2">
                    <div className="font-medium w-12">
                      {index +
                        assertNonNull(
                          selectedPerformanceAssessmentGroup.roleBasedAssessmentScaleStartsAt
                        )}
                    </div>
                    <div className="flex-1">
                      <Input
                        disabled={disabled}
                        placeholder="Label (optional)"
                        value={label ?? ""}
                        onChange={(e) => {
                          const newLabels = [
                            ...assertNonNull(
                              selectedPerformanceAssessmentGroup.roleBasedAssessmentScaleLabels
                            ),
                          ];
                          newLabels[index] = e.target.value;
                          onChangeAssessmentTemplateSettings({
                            roleBasedAssessmentScaleLabels: newLabels,
                          });
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default IncludeRoleBasedForm;
