import { gql } from "@apollo/client";

import GoalAlignmentDialogGoalFragment from "./goal-alignment-dialog-goal-fragment";

export default gql`
  ${GoalAlignmentDialogGoalFragment}
  query getGoalSearchResultsToAlignQuery($search: String!, $after: String) {
    artifacts(
      search: $search
      orderBy: "objective_alignment"
      first: 10
      after: $after
      artifactType: goal
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          ...GoalAlignmentDialogGoalFragment
        }
      }
    }
  }
`;
