import { useMutation } from "@apollo/client";
import { compact } from "lodash";
import { useMemo } from "react";
import { useCallback } from "react";
import {
  ComplianceProgramListItemFragment,
  ComplianceProgramState,
  DeleteProgramMutation,
  DeleteProgramMutationVariables,
  ExportComplianceProgramToCsvMutationMutation,
  ExportComplianceProgramToCsvMutationMutationVariables,
  GetComplianceProgramQuery,
  SaveComplianceProgramMutation,
  SaveComplianceProgramMutationVariables,
} from "types/graphql-schema";

import exportComplianceProgramToCsvMutation from "@apps/programs/graphql/export-compliance-program-to-csv-mutation";
import {
  currentOrganizationVar,
  currentUserVar,
  isAdminVar,
  successNotificationVar,
} from "@cache/cache";
import Dropdown, { DropdownOptionType } from "@components/dropdown/dropdown";
import { useLink } from "@components/link/link";
import { useNotificationError } from "@components/use-error/use-error";
import useConfirm from "@helpers/hooks/use-confirm";

import createOrUpdateComplianceProgramMutation from "../graphql/create-or-update-compliance-program-mutation";
import deleteComplianceProgramMutation from "../graphql/delete-compliance-program-mutation";
import getComplianceProgramQuery from "../graphql/get-compliance-program-admin-query";
import getComplianceProgramsQuery from "../graphql/get-compliance-programs-query";

const ComplianceProgramActionDropdown = ({
  complianceProgram,
}: {
  complianceProgram:
    | NonNullable<GetComplianceProgramQuery["complianceProgram"]>
    | ComplianceProgramListItemFragment;
}) => {
  const link = useLink();
  const isAdmin = isAdminVar();
  const currentUser = currentUserVar();
  const organization = currentOrganizationVar();
  const { onError } = useNotificationError();
  const complianceProgramId = complianceProgram.id;

  const { ConfirmationDialog: ConfirmDeleteDialog, confirm: confirmDelete } =
    useConfirm(
      "Are you sure?",
      "Are you sure you want to delete this program? All assessment data will be deleted."
    );

  const [saveComplianceProgram, { loading: isPublishingProgram }] = useMutation<
    SaveComplianceProgramMutation,
    SaveComplianceProgramMutationVariables
  >(createOrUpdateComplianceProgramMutation);

  const [exportToCSV, { loading: isExportingProgram }] = useMutation<
    ExportComplianceProgramToCsvMutationMutation,
    ExportComplianceProgramToCsvMutationMutationVariables
  >(exportComplianceProgramToCsvMutation);

  const [deleteProgram, { loading: isDeletingProgram }] = useMutation<
    DeleteProgramMutation,
    DeleteProgramMutationVariables
  >(deleteComplianceProgramMutation);

  const publishProgram = useCallback(() => {
    saveComplianceProgram({
      variables: {
        complianceProgramId,
        state: ComplianceProgramState.Published,
        organizationId: organization.id,
      },
      onError,
      refetchQueries: [getComplianceProgramQuery],
      onCompleted: () =>
        successNotificationVar({
          title: "Program published",
        }),
    });
  }, [complianceProgramId, onError, organization, saveComplianceProgram]);

  const exportProgram = useCallback(() => {
    exportToCSV({ variables: { complianceProgramId }, onError });
    successNotificationVar({
      title: `CSV export will be emailed to ${currentUser.email}`,
    });
  }, [exportToCSV, complianceProgramId, onError, currentUser]);

  const handleDeleteProgram = useCallback(async () => {
    const confirm = await confirmDelete();
    if (!confirm) return;
    deleteProgram({
      variables: {
        complianceProgramId,
      },
      onError,
      refetchQueries: [getComplianceProgramsQuery],
      onCompleted: () => {
        successNotificationVar({ title: "Program deleted" });
        link.redirect(`/programs`);
      },
    });
  }, [confirmDelete, deleteProgram, complianceProgramId, onError, link]);

  const handlePauseProgram = useCallback(async () => {
    saveComplianceProgram({
      variables: {
        complianceProgramId,
        state: ComplianceProgramState.Paused,
      },
      onError,
      refetchQueries: [getComplianceProgramQuery],
      onCompleted: () =>
        successNotificationVar({
          title: "Program paused",
        }),
    });
  }, [saveComplianceProgram, complianceProgramId, onError]);

  const dropdownOptions: DropdownOptionType[] = useMemo(() => {
    return compact([
      {
        label: "Clone program",
        onClick: () =>
          link.redirect(`/programs/${complianceProgram.id}/duplicate`),
        disabled:
          isPublishingProgram || isDeletingProgram || isExportingProgram,
      },
      { type: "divider" },
      complianceProgram.canUpdate?.permission && {
        label: "Edit program",
        onClick: () => link.redirect(`/programs/${complianceProgram.id}/edit`),
        disabled:
          isPublishingProgram || isDeletingProgram || isExportingProgram,
      },
      complianceProgram.canDelete?.permission && {
        label: "Delete program",
        onClick: handleDeleteProgram,
        disabled:
          isPublishingProgram || isDeletingProgram || isExportingProgram,
      },
      complianceProgram.canUpdate?.permission && { type: "divider" },
      complianceProgram.canUpdate?.permission &&
        complianceProgram.state === ComplianceProgramState.Published && {
          label: "Pause program",
          onClick: handlePauseProgram,
          disabled:
            isPublishingProgram || isDeletingProgram || isExportingProgram,
        },
      complianceProgram.canUpdate?.permission &&
        complianceProgram.state !== ComplianceProgramState.Published && {
          label:
            complianceProgram.state === ComplianceProgramState.Draft
              ? "Publish"
              : "Resume",
          onClick: publishProgram,
          disabled: isPublishingProgram,
        },
      complianceProgram.state === ComplianceProgramState.Published && {
        type: "divider",
      },
      complianceProgram.state === ComplianceProgramState.Published && {
        label: "Export to CSV",
        onClick: exportProgram,
        disabled: isExportingProgram,
      },
    ]);
  }, [
    complianceProgram.canDelete?.permission,
    complianceProgram.canUpdate?.permission,
    complianceProgram.id,
    complianceProgram.state,
    exportProgram,
    handleDeleteProgram,
    handlePauseProgram,
    isDeletingProgram,
    isExportingProgram,
    isPublishingProgram,
    link,
    publishProgram,
  ]);

  if (!isAdmin) {
    return null;
  }

  if (dropdownOptions.length === 0) return null;

  return (
    <>
      <Dropdown options={dropdownOptions} />
      <ConfirmDeleteDialog />
    </>
  );
};
export default ComplianceProgramActionDropdown;
