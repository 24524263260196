import { useQuery } from "@apollo/client";
import { ChangeEvent, useCallback, useState } from "react";
import {
  AccountToOrganizationMembershipStatus,
  GetOrganizationMembersQueryQuery,
  GetOrganizationMembersQueryQueryVariables,
  OrgSettingsOrganizationFragmentFragment,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import cache from "@cache/cache";
import Button from "@components/button/button";
import Heading from "@components/heading/heading";
import Input from "@components/input/input";
import Loading from "@components/loading/loading";
import {
  Table,
  TableBody,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
  TableSortDir,
} from "@components/table/table";
import useDebounce from "@components/use-debounce/use-debounce";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
  UserComboboxTeamOption,
  UserComboboxUserOption,
} from "@components/user-combobox/user-combobox-list";
import { graphqlNone } from "@helpers/constants";
import { assertEdgesNonNull } from "@helpers/helpers";

import InviteDialog from "../../invite-dialog/invite-dialog";
import getOrganizationMembersQuery from "../graphql/get-organization-members-query";
import MemberItem from "./member-item";

enum MemberColumns {
  Name = "name",
  StartDate = "startDate",
  EmployeeID = "employeeId",
  Position = "position",
  Role = "role",
  JobLevel = "jobLevel",
}

type TableSort = { column: MemberColumns; direction: TableSortDir };

const getOrderBy = (sort: TableSort) => {
  const direction = `${sort.direction === "desc" ? "-" : ""}`;
  switch (sort.column) {
    case MemberColumns.Name:
      return `${direction}user__first_name`;
    case MemberColumns.StartDate:
      return `${direction}start_date`;
    case MemberColumns.EmployeeID:
      return `${direction}employee_id`;
    case MemberColumns.Position:
      return `${direction}position`;
    case MemberColumns.Role:
      return `${direction}role`;
    case MemberColumns.JobLevel:
      return `${direction}job_level`;
  }
};

const Members = ({
  organization,
}: {
  organization: OrgSettingsOrganizationFragmentFragment;
}) => {
  const label = useLabel();
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);
  const [showAdminsOnly, setShowAdminsOnly] = useState(false);
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const [showActiveMembersOnly, setShowActiveMembersOnly] = useState(true);
  const [sort, setSort] = useState<TableSort>({
    column: MemberColumns.Name,
    direction: "asc",
  });
  const [managerFilter, setManagerFilter] = useState<UserComboboxOption | null>(
    null
  );
  const [team, setTeam] = useState<UserComboboxOption | null>(null);
  const emptyTeamOption: UserComboboxTeamOption = {
    id: graphqlNone,
    type: UserComboboxOptionType.TEAM,
    title: `No ${label("team", { pluralize: 0 })}`,
  };
  const emptyManagerOption: UserComboboxUserOption = {
    id: graphqlNone,
    type: UserComboboxOptionType.USER,
    name: `No managers`,
  };
  const {
    options: teamOptions,
    query: teamQuery,
    setQuery: setTeamQuery,
  } = useUserComboboxQuery({
    types: [UserComboboxOptionType.TEAM],
    emptyOption: emptyTeamOption,
  });
  const {
    options: managerOptions,
    query: managerQuery,
    setQuery: setManagerQuery,
  } = useUserComboboxQuery({
    types: [UserComboboxOptionType.USER],
    emptyOption: emptyManagerOption,
  });

  const { data, loading, fetchMore, variables } = useQuery<
    GetOrganizationMembersQueryQuery,
    GetOrganizationMembersQueryQueryVariables
  >(getOrganizationMembersQuery, {
    variables: {
      search: debouncedSearch,
      after: null,
      organizationId: organization.id,
      managerId: managerFilter?.id,
      orderBy: getOrderBy(sort),
      showAdminsOnly: showAdminsOnly ? true : undefined,
      membershipStatus: showActiveMembersOnly
        ? AccountToOrganizationMembershipStatus.Active
        : undefined,
      teamId: team?.id,
    },
    onError: onNotificationErrorHandler(),
  });
  const members = data ? assertEdgesNonNull(data.organization?.members) : [];

  const handleChangeShowAdminsOnly = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setShowAdminsOnly(e.target.checked);
    },
    []
  );

  const handleChangeShowActiveMembersOnly = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setShowActiveMembersOnly(e.target.checked);
    },
    []
  );

  const handleClickMore = () => {
    const after = data?.organization?.members.pageInfo.endCursor;
    fetchMore({
      variables: { after, merge: true },
    });
  };

  const handleRemoveUser = (member: { id: number }) => {
    cache.updateQuery(
      {
        query: getOrganizationMembersQuery,
        variables,
      },
      (data) => {
        const newEdges = data.organization.members.edges.filter(
          (edge: any) => edge?.node.id !== member.id
        );
        const newData = {
          ...data,
          organization: {
            ...data.organization,
            totalCount: data.organization.totalCount - 1,
            members: {
              ...data.organization.members,
              edges: newEdges,
            },
          },
        };
        return newData;
      }
    );
  };

  const handleSort = useCallback(
    (column: MemberColumns) => {
      const direction =
        sort.column === column && sort.direction === "asc" ? "desc" : "asc";
      setSort({ column, direction });
    },
    [sort]
  );

  return (
    <div className="pb-12" aria-label="Org settings members">
      {showInviteDialog && (
        <InviteDialog
          organization={organization}
          onClose={() => {
            setShowInviteDialog(false);
          }}
        />
      )}
      <div className="flex justify-between items-center">
        <Heading
          small
          title={`Members${
            data?.organization?.members.totalCount
              ? ` (${data.organization.members.totalCount})`
              : ""
          }`}
        />
        <div>
          <Button
            theme="primary"
            text="Invite"
            onClick={() => {
              setShowInviteDialog(true);
            }}
          />
        </div>
      </div>
      <div className="mt-4 flex items-center gap-8">
        <div className="flex items-center gap-4 flex-none">
          <div className="w-42 flex-none">
            <Input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search..."
            />
          </div>
          <div className="w-42 flex-none">
            <UserCombobox
              query={teamQuery}
              value={team}
              options={teamOptions}
              clearable={team !== null}
              onChangeQuery={setTeamQuery}
              onChangeValue={setTeam}
              onClearValue={() => setTeam(null)}
              placeholder={`Filter by ${label("team")}...`}
            />
          </div>
          <div className="w-44 flex-none">
            <UserCombobox
              query={managerQuery}
              value={managerFilter}
              options={managerOptions}
              clearable={managerFilter !== null}
              onChangeQuery={setManagerQuery}
              onChangeValue={setManagerFilter}
              onClearValue={() => setManagerFilter(null)}
              placeholder={`Filter by manager...`}
            />
          </div>
        </div>
        <label className="flex gap-1 items-center text-xs text-gray-600">
          <input
            type="checkbox"
            defaultChecked={showAdminsOnly}
            onChange={handleChangeShowAdminsOnly}
          />
          Show only admins
        </label>
        <label className="flex gap-1 items-center text-xs text-gray-600">
          <input
            type="checkbox"
            defaultChecked={showActiveMembersOnly}
            onChange={handleChangeShowActiveMembersOnly}
          />
          Show only active members
        </label>
      </div>

      {loading && !data && (
        <div className="flex justify-center mt-8">
          <Loading mini size="5" />
        </div>
      )}
      {data && (
        <TableContainer scroll className="mt-6">
          <Table className="w-full">
            <TableHeadRow>
              <TableHeadCell
                cellClassName="min-w-[300px]"
                sorted={
                  sort.column === MemberColumns.Name ? sort.direction : null
                }
                onClick={() => handleSort(MemberColumns.Name)}
              >
                Name
              </TableHeadCell>
              <TableHeadCell cellClassName="min-w-[200px]">
                Manager
              </TableHeadCell>
              <TableHeadCell cellClassName="min-w-[200px]">
                {label("team", { pluralize: true, capitalize: true })}
              </TableHeadCell>
              <TableHeadCell
                cellClassName="min-w-[120px]"
                sorted={
                  sort.column === MemberColumns.EmployeeID
                    ? sort.direction
                    : null
                }
                onClick={() => handleSort(MemberColumns.EmployeeID)}
              >
                Employee ID
              </TableHeadCell>
              <TableHeadCell
                cellClassName="min-w-[120px]"
                sorted={
                  sort.column === MemberColumns.Position ? sort.direction : null
                }
                onClick={() => handleSort(MemberColumns.Position)}
              >
                Position
              </TableHeadCell>
              <TableHeadCell
                cellClassName="min-w-[120px]"
                sorted={
                  sort.column === MemberColumns.JobLevel ? sort.direction : null
                }
                onClick={() => handleSort(MemberColumns.JobLevel)}
              >
                Job level
              </TableHeadCell>
              <TableHeadCell
                cellClassName="min-w-[150px]"
                sorted={
                  sort.column === MemberColumns.StartDate
                    ? sort.direction
                    : null
                }
                onClick={() => handleSort(MemberColumns.StartDate)}
              >
                Start Date
              </TableHeadCell>
              <TableHeadCell
                cellClassName="min-w-[200px]"
                sorted={
                  sort.column === MemberColumns.Role ? sort.direction : null
                }
                onClick={() => handleSort(MemberColumns.Role)}
              >
                Role
              </TableHeadCell>
            </TableHeadRow>
            <TableBody>
              {data &&
                members.map((member) => (
                  <MemberItem
                    member={member}
                    organization={organization}
                    key={member.id}
                    onRemoveUser={handleRemoveUser}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {data?.organization?.members.pageInfo.hasNextPage && (
        <div className="flex items-center border-t py-2">
          <button
            className="text-gray-500 text-sm mr-4 hover:underline"
            onClick={handleClickMore}
            disabled={loading}
          >
            Load more members...
          </button>
          {loading && <Loading size="5" mini />}
        </div>
      )}
    </div>
  );
};

export default Members;
