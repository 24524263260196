import { useCallback } from "react";

import { classNames } from "@helpers/css";

type Props = {
  choices?: ReadonlyArray<number | null> | null;
  options: ReadonlyArray<string | null>;
  optionDescriptions: ReadonlyArray<string | null>;
  disabled?: boolean;
  onClickAnswer?: (choices: number[]) => void;
};
const AssessmentQuestionMultiSelectOptions: React.FC<Props> = ({
  disabled,
  choices,
  options,
  optionDescriptions,
  onClickAnswer,
}) => {
  const handleClickAnswer = useCallback(
    (index: number) => {
      if (onClickAnswer) {
        if (choices?.includes(index)) {
          onClickAnswer(
            choices
              .flatMap((f) => (f !== null ? [f] : []))
              .filter((choice) => choice !== index)
          );
        } else {
          onClickAnswer(
            choices
              ? choices.flatMap((f) => (f !== null ? [f] : [])).concat(index)
              : [index]
          );
        }
      }
    },
    [choices, onClickAnswer]
  );
  return (
    <div className="flex flex-col gap-3">
      {options.map((value, index) => (
        <div className="flex items-start gap-4" key={value}>
          <input
            className="mt-1"
            type="checkbox"
            disabled={disabled}
            checked={choices?.includes(index)}
            onChange={() => handleClickAnswer(index)}
          />
          <div
            role="button"
            className={classNames(disabled && "cursor-default")}
            onClick={() => handleClickAnswer(index)}
          >
            <div>{value}</div>
            {optionDescriptions[index] && (
              <div className="italic text-gray-500 whitespace-pre-line">
                {optionDescriptions[index]}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AssessmentQuestionMultiSelectOptions;
