import { useQuery } from "@apollo/client";
import { compact } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  GetDashboardSelectedUserQueryQuery,
  GetDashboardSelectedUserQueryQueryVariables,
  PricingTier,
} from "types/graphql-schema";

import AskAI from "@apps/ask-ai/ask-ai";
import WorkHistorySidebar from "@apps/work-history/components/work-history-sidebar";
import { currentOrganizationVar, currentUserVar } from "@cache/cache";
import AppError from "@components/error/error";
import Layout from "@components/layout/layout";
import { ToggleButtonGroupType } from "@components/toggle-button-group/toggle-button-group";
import useDocumentTitle from "@components/use-document-title/use-document-title";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
} from "@components/user-combobox/user-combobox-list";

import ActionItems from "./components/action-items";
import Feedbacks from "./components/feedbacks";
import Goals from "./components/goals";
import Insights from "./components/insights";
import DashboardPeopleTable from "./components/people-table";
import Recognitions from "./components/recognitions";
import DashboardUserSelector from "./dashboard-user-selector";
import getDashboardSelectedUserQuery from "./graphql/get-dashboard-selected-user-query";

enum DashboardSidebarTabEnum {
  items = "Items",
  askAI = "AskAI",
  workHistory = "Work History",
}

const Dashboard = () => {
  const [askAIChatSessionId, setAskAIChatSessionId] = useState<null | number>(
    null
  );
  const currentOrganization = currentOrganizationVar();
  const currentUser = currentUserVar();
  const history = useHistory();
  const { userId } = useParams<{ userId: string | undefined }>();
  const selectedUserId = userId ? parseInt(userId) : currentUser.id;
  const [sidebarActiveTab, setSidebarActiveTab] = useState(
    DashboardSidebarTabEnum.items
  );

  const { data, loading: loadingQuery } = useQuery<
    GetDashboardSelectedUserQueryQuery,
    GetDashboardSelectedUserQueryQueryVariables
  >(getDashboardSelectedUserQuery, {
    variables: { userId: selectedUserId },
    onError: onNotificationErrorHandler(),
  });
  const selectedUser = data?.user;
  const {
    loading: loadingOptions,
    query,
    setQuery,
    options,
  } = useUserComboboxQuery({
    selected: selectedUser
      ? { type: UserComboboxOptionType.USER, ...selectedUser }
      : undefined,
  });

  useDocumentTitle(selectedUser ? selectedUser.name : "");

  const handleChangeAssignee = (assignee: UserComboboxOption) => {
    history.push(`/dashboard/user/${assignee.id}`);
  };

  const handleClearAssignee = () => {
    history.push(`/dashboard/user/${currentUser.id}`);
  };

  const handleChangeSidebarTab = (
    option: ToggleButtonGroupType<DashboardSidebarTabEnum>
  ) => {
    if (option.value) setSidebarActiveTab(option.value);
  };

  useEffect(() => {
    setAskAIChatSessionId(null);
  }, [selectedUserId]);

  return (
    <Layout>
      <Layout.Header
        sidebarExpandedUiPreferenceKey="dashboardSidebarExpanded"
        title={
          <UserCombobox
            disabled={loadingOptions || loadingQuery}
            aria-label="Dashboard user dropdown"
            width="full"
            query={query}
            value={
              selectedUser
                ? { type: UserComboboxOptionType.USER, ...selectedUser }
                : null
            }
            options={options}
            clearable={!!selectedUser && currentUser.id !== selectedUser.id}
            onChangeValue={handleChangeAssignee}
            onChangeQuery={setQuery}
            onClearValue={handleClearAssignee}
          >
            {({
              clearable,
              onClearValue,
              value,
              onClickButton,
              setReferenceElement,
            }) =>
              value?.type === UserComboboxOptionType.USER ? (
                <DashboardUserSelector
                  clearable={clearable}
                  onClearValue={onClearValue}
                  value={value}
                  onClickButton={onClickButton}
                  setReferenceElement={setReferenceElement}
                />
              ) : null
            }
          </UserCombobox>
        }
      />
      <Layout.Container loading={loadingQuery}>
        {selectedUser ? (
          <>
            <Layout.Main sidebarExpandedUiPreferenceKey="dashboardSidebarExpanded">
              {selectedUser.id === currentUser.id && (
                <Insights selectedUser={selectedUser} />
              )}
              {currentOrganization.featureFlags.homepageMyConnections && (
                <DashboardPeopleTable selectedUser={selectedUser} />
              )}
            </Layout.Main>
            <Layout.Sidebar<DashboardSidebarTabEnum>
              sidebarExpandedUiPreferenceKey="dashboardSidebarExpanded"
              fullHeight={DashboardSidebarTabEnum.askAI === sidebarActiveTab}
              tabs={compact([
                {
                  title: DashboardSidebarTabEnum.items,
                  value: DashboardSidebarTabEnum.items,
                  active: DashboardSidebarTabEnum.items === sidebarActiveTab,
                },
                currentOrganization.pricingTier === PricingTier.Ultimate && {
                  title: DashboardSidebarTabEnum.askAI,
                  value: DashboardSidebarTabEnum.askAI,
                  active: DashboardSidebarTabEnum.askAI === sidebarActiveTab,
                },
                currentOrganization.featureFlags.workHistory &&
                  currentOrganization.pricingTier === PricingTier.Ultimate && {
                    title: DashboardSidebarTabEnum.workHistory,
                    value: DashboardSidebarTabEnum.workHistory,
                    active:
                      DashboardSidebarTabEnum.workHistory === sidebarActiveTab,
                  },
              ])}
              onChangeTab={handleChangeSidebarTab}
            >
              {sidebarActiveTab === DashboardSidebarTabEnum.items &&
                currentOrganization.featureFlags.actionItems && (
                  <ActionItems selectedUser={selectedUser} />
                )}
              {sidebarActiveTab === DashboardSidebarTabEnum.items &&
                currentOrganization.featureFlags.goals && (
                  <Goals selectedUser={selectedUser} />
                )}
              {sidebarActiveTab === DashboardSidebarTabEnum.items &&
                currentOrganization.featureFlags.recognitions && (
                  <Recognitions selectedUser={selectedUser} />
                )}
              {sidebarActiveTab === DashboardSidebarTabEnum.items &&
                currentOrganization.featureFlags.feedbacks && (
                  <Feedbacks selectedUser={selectedUser} />
                )}
              {sidebarActiveTab === DashboardSidebarTabEnum.askAI && (
                <AskAI
                  chatSessionId={askAIChatSessionId}
                  onChangeChatSessionId={setAskAIChatSessionId}
                />
              )}
              {sidebarActiveTab === DashboardSidebarTabEnum.workHistory && (
                <WorkHistorySidebar
                  userId={selectedUser.id}
                  startDate={moment().subtract(7, "days").format("YYYY-MM-DD")}
                  endDate={moment().format("YYYY-MM-DD")}
                />
              )}
            </Layout.Sidebar>
          </>
        ) : (
          <div className="p-6 flex-1">
            <AppError title="User cannot be found" />
          </div>
        )}
      </Layout.Container>
    </Layout>
  );
};

export default Dashboard;
