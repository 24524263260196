import { useQuery } from "@apollo/client";
import { useCallback, useMemo } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import {
  GetRecognitionBoardQueryQuery,
  GetRecognitionBoardQueryQueryVariables,
  RecognitionArtifactNode,
} from "types/graphql-schema";
import { DateRangeEnum } from "types/topicflow";

import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import GraphqlError from "@components/error/graphql-error";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import getRecognitionBoardQuery from "./graphql/get-recognition-board-query";
import RecognitionBoardItem from "./recognition-item";

const limit = 25;

export enum RecognitionBoardExtraDateRangeEnum {
  all = "all",
  custom = "custom",
}
export type RecognitionBoardDateRangeType =
  | RecognitionBoardExtraDateRangeEnum
  | DateRangeEnum;

const RecognitionBoardList = ({
  coreValueId,
  recipientId,
  receivedByMembersOfTeamId,
  createdDateRange,
  createdBetweenDates,
  onShowCreationDialog,
}: {
  coreValueId?: GetRecognitionBoardQueryQueryVariables["coreValueId"];
  recipientId: GetRecognitionBoardQueryQueryVariables["recipientId"];
  receivedByMembersOfTeamId: GetRecognitionBoardQueryQueryVariables["receivedByMembersOfTeamId"];
  createdDateRange: RecognitionBoardDateRangeType;
  createdBetweenDates: string[];
  onShowCreationDialog: () => void;
}) => {
  const label = useLabel();
  const currentOrganization = currentOrganizationVar();

  const createdBetweenDatesVariable =
    createdDateRange === RecognitionBoardExtraDateRangeEnum.all
      ? null
      : createdBetweenDates;
  const { loading, error, data, fetchMore } = useQuery<
    GetRecognitionBoardQueryQuery,
    GetRecognitionBoardQueryQueryVariables
  >(getRecognitionBoardQuery, {
    variables: {
      organizationId: currentOrganization.id,
      coreValueId,
      recipientId,
      receivedByMembersOfTeamId,
      createdBetweenDates: createdBetweenDatesVariable,
      limit: 25,
    },
    onError: onNotificationErrorHandler(),
  });

  const artifactData = useMemo(
    () => (data ? assertNonNull(data?.artifacts) : null),
    [data]
  );
  const artifacts = useMemo(
    () => (data?.artifacts ? assertEdgesNonNull(data.artifacts) : []),
    [data]
  ) as RecognitionArtifactNode[];

  const handleClickLoadMore = useCallback(() => {
    if (!artifactData) {
      return;
    }
    fetchMore({
      variables: {
        isFetchingStats: false,
        limit,
        after: artifactData.pageInfo.endCursor,
        merge: true,
      },
    });
  }, [artifactData, fetchMore]);

  // Render
  return (
    <div className="flex-1">
      {error ? (
        <div className="mb-8">
          <GraphqlError
            error={error}
            whatDidNotWork="Recognition board could not be loaded."
          />
        </div>
      ) : (
        <div>
          {!data && loading ? (
            <div className="mb-12">
              <Loading>
                Loading{" "}
                {label("recognition", {
                  pluralize: true,
                  capitalize: false,
                })}
                ...
              </Loading>
            </div>
          ) : (
            <div>
              {artifacts.length > 0 && artifactData ? (
                <div>
                  <ResponsiveMasonry
                    columnsCountBreakPoints={{
                      400: 1,
                      1000: 2,
                      1300: 3,
                      1500: 4,
                      1800: 5,
                    }}
                  >
                    <Masonry gutter="16px">
                      {artifacts.map((node) => (
                        <RecognitionBoardItem
                          recognition={node}
                          key={node.id}
                        />
                      ))}
                    </Masonry>
                  </ResponsiveMasonry>

                  {artifactData.pageInfo.hasNextPage && (
                    <div className="flex items-center justify-center mt-4">
                      <div className="flex gap-4 items-center">
                        <Button onClick={handleClickLoadMore}>Load more</Button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex flex-col gap-8 items-center">
                  <div className="flex justify-center text-gray-400 mt-8 text-6xl">
                    🌟
                  </div>
                  <Button
                    theme={buttonTheme.primary}
                    onClick={onShowCreationDialog}
                    text={`Give your first ${label("recognition")}!`}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RecognitionBoardList;
