import {
  ComplianceProgramParticipantStatus,
  GetComplianceProgramProgressQuery,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import Layout from "@components/layout/layout";
import ManagedTable from "@components/table/managed-table";

import {
  complianceProgramReportingTableColNameClassName,
  complianceProgramReportingTableColsClassName,
} from "./compliance-program-progress";
import {
  ComplianceProgramReportingEntityTableCol,
  ComplianceProgramReportingEntityTableParticipantStatusWithNominations,
} from "./compliance-program-progress-entity-table";
import ProgramProgressPill from "./program-progress-pill";

const ComplianceProgramProgressUserTable = ({
  participantStatuses,
  cols,
  title,
  complianceProgram,
  usersLabel,
}: {
  participantStatuses: ComplianceProgramReportingEntityTableParticipantStatusWithNominations[];
  cols: ComplianceProgramReportingEntityTableCol[];
  title: string;
  usersLabel: string;
  complianceProgram: NonNullable<
    GetComplianceProgramProgressQuery["complianceProgram"]
  >;
}) => {
  const label = useLabel();
  return (
    <Layout.MainSubSection title={title}>
      {participantStatuses.length === 0 ? (
        <div className="text-gray-500 text-sm">
          No {usersLabel} participating to this {label("program")}.
        </div>
      ) : (
        <ManagedTable
          scroll
          sortingEnabled
          sortDir="asc"
          sortKey="name"
          data={participantStatuses}
          className="overscroll-x-contain"
          aria-label="Compliance program progress user table"
          columns={[
            {
              headerClassName: complianceProgramReportingTableColNameClassName,
              cellClassName: complianceProgramReportingTableColNameClassName,
              header: `Name`,
              render: (
                data: ComplianceProgramReportingEntityTableParticipantStatusWithNominations
              ) => {
                return data.user.name;
              },
              sortKey: "name",
              value: (
                data: ComplianceProgramReportingEntityTableParticipantStatusWithNominations
              ) => {
                return data.user.name;
              },
            },
            ...cols.map((col) => ({
              headerClassName: complianceProgramReportingTableColsClassName,
              cellClassName: complianceProgramReportingTableColsClassName,
              header: col.label,
              sortKey: col.keyName,
              value: (
                data: ComplianceProgramReportingEntityTableParticipantStatusWithNominations
              ) =>
                data[col.keyName] ===
                ComplianceProgramParticipantStatus.Complete
                  ? 4
                  : data[col.keyName] ===
                    ComplianceProgramParticipantStatus.InProgress
                  ? 3
                  : data[col.keyName] ===
                    ComplianceProgramParticipantStatus.NotStarted
                  ? 2
                  : 1,
              render: (
                data: ComplianceProgramReportingEntityTableParticipantStatusWithNominations
              ) => {
                return (
                  <ProgramProgressPill
                    col={col}
                    participantStatus={data}
                    status={data[col.keyName]}
                    complianceProgram={complianceProgram}
                  />
                );
              },
            })),
            {
              header: ``,
              render: () => "",
            },
          ]}
        />
      )}
    </Layout.MainSubSection>
  );
};

export default ComplianceProgramProgressUserTable;
