import { useQuery } from "@apollo/client";
import moment from "moment";
import { useState } from "react";
import {
  GetAssessmentDeliveriesReceivedQuery,
  GetAssessmentDeliveriesReceivedQueryVariables,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar, currentUserVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Button from "@components/button/button";
import Layout from "@components/layout/layout";
import AppLink from "@components/link/link";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
  UserComboboxUserOption,
} from "@components/user-combobox/user-combobox-list";
import { assertEdgesNonNull } from "@helpers/helpers";

import getAssessmentDeliveriesReceived from "../graphql/get-assessment-deliveries-received";

const AssessmentsHomepageDeliveries = () => {
  const label = useLabel();
  const currentUser = currentUserVar();
  const currentOrganization = currentOrganizationVar();
  const [userFilter, setUserFilter] = useState<UserComboboxUserOption | null>({
    id: currentUser.id,
    name: currentUser.name,
    type: UserComboboxOptionType.USER,
  });

  const { options, query, setQuery } = useUserComboboxQuery({
    types: [UserComboboxOptionType.USER],
  });

  const { data, loading, fetchMore } = useQuery<
    GetAssessmentDeliveriesReceivedQuery,
    GetAssessmentDeliveriesReceivedQueryVariables
  >(getAssessmentDeliveriesReceived, {
    notifyOnNetworkStatusChange: true,
    variables: {
      organizationId: currentOrganization.id,
      targetId: userFilter?.id,
      after: null,
      orderBy: "submitted_datetime",
    },
    onError: onNotificationErrorHandler(),
  });
  const deliveries = data?.assessmentDeliveries
    ? assertEdgesNonNull(data?.assessmentDeliveries)
    : [];

  const handleClickLoadMore = () => {
    if (data?.assessmentDeliveries?.pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          after: data?.assessmentDeliveries?.pageInfo?.endCursor,
          merge: true,
        },
      });
    }
  };

  const handleChangeUserFilter = (option: UserComboboxOption) => {
    if (option.type === UserComboboxOptionType.USER) {
      setUserFilter(option);
      setQuery("");
    }
  };

  return (
    <Layout.MainSection>
      <div className="flex items-center gap-3 mb-4">
        <div className="text-sm font-medium text-gray-700">
          Show reviews for
        </div>
        <div className="w-64">
          <UserCombobox
            value={userFilter}
            options={options}
            query={query}
            onChangeQuery={setQuery}
            onChangeValue={handleChangeUserFilter}
            clearable={userFilter !== null}
            onClearValue={() => setUserFilter(null)}
            placeholder={`Filter by person...`}
          />
        </div>
      </div>
      <Layout.MainSubSection
        title={`Past ${label("review", {
          capitalize: true,
          pluralize: true,
        })}`}
        loading={!data && loading}
        emptyPlaceholder={
          deliveries.length === 0 &&
          `No past ${label("review", {
            capitalize: true,
            pluralize: true,
          })}`
        }
      >
        <Layout.MainSubSectionList>
          {deliveries.map((delivery) => (
            <Layout.MainSubSectionListItem key={delivery.id}>
              <div className="flex items-center justify-between text-sm gap-4">
                <div className="flex items-center gap-3">
                  <Avatar user={delivery.target} size="5" />
                  <div className="flex flex-col gap-0.5">
                    <div className="font-medium">{delivery.target?.name}</div>
                    {delivery.complianceProgram && (
                      <AppLink
                        to={`/assessments/assessment/delivery/${delivery.id}`}
                        className="text-gray-500 text-xs font-medium hover:underline"
                      >
                        {delivery.complianceProgram?.title}
                      </AppLink>
                    )}
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <div className="text-gray-400">
                    {delivery.deliveryDatetime
                      ? `Delivered on ${moment(
                          delivery.deliveryDatetime
                        ).format("MMM Do, YYYY")}`
                      : delivery.lastSubmissionDatetime
                      ? `Submitted on ${moment(
                          delivery.lastSubmissionDatetime
                        ).format("MMM Do, YYYY")}`
                      : ""}
                  </div>
                  <Button
                    text="View"
                    to={`/assessments/assessment/delivery/${delivery.id}`}
                  />
                </div>
              </div>
            </Layout.MainSubSectionListItem>
          ))}
          {data?.assessmentDeliveries?.pageInfo.hasNextPage && (
            <Layout.MainSubSectionListItem className="flex justify-center">
              <Button text="Load more" onClick={handleClickLoadMore} />
            </Layout.MainSubSectionListItem>
          )}
        </Layout.MainSubSectionList>
      </Layout.MainSubSection>
    </Layout.MainSection>
  );
};

export default AssessmentsHomepageDeliveries;
