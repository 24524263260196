import { PlayIcon, XIcon } from "@heroicons/react/solid";
import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { NodeViewWrapper } from "@tiptap/react";
import { padStart } from "lodash";
import { ChangeEvent, useState } from "react";

import useVisibilityControlledInterval from "@helpers/hooks/use-interval";

const TimerComponent = ({
  node,
  updateAttributes,
}: {
  node: any;
  updateAttributes: (attributes: any) => void;
}) => {
  const [now, setNow] = useState(Date.now());
  const targetTime = Number(node.attrs.targetTime);
  const secondsRemaining = Math.floor((targetTime - now) / 1000);

  const handleStartTimer = () => {
    const minutes = Number(node.attrs.minutes);
    if (minutes > 0 && minutes < 100) {
      updateAttributes({
        targetTime: Date.now() + minutes * 60 * 1000,
      });
    }
  };
  const handleResetTimer = () => {
    updateAttributes({
      targetTime: null,
    });
  };

  const handleChangeMinutes = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= 2 && value.length >= 0) {
      updateAttributes({ minutes: Number(e.target.value) || "" });
    }
  };

  useVisibilityControlledInterval(() => {
    setNow(Date.now());
  }, 500);

  const totalMinutes = Math.floor(secondsRemaining / 60);
  const totalSeconds = Math.floor(secondsRemaining % 60);
  return (
    <NodeViewWrapper className="flex">
      <div
        className="flex items-center text-sm relative"
        contentEditable={false}
      >
        {node.attrs.targetTime ? (
          <div className="border border-r-0 rounded-l py-1 px-1.5 font-mono">
            {secondsRemaining >= 0
              ? `${padStart(String(totalMinutes), 2, "0")}:${padStart(
                  String(totalSeconds),
                  2,
                  "0"
                )}`
              : "00:00"}
            /{node.attrs.minutes}
            <span className="text-xs">mins</span>
          </div>
        ) : (
          <>
            <input
              size={2}
              type="text"
              className="w-16 rounded-l bg-white hover:bg-gray-100 focus:outline-0 focus:ring-0 text-right border border-r-0 py-1 pl-1 pr-10 font-mono"
              value={node.attrs.minutes}
              onChange={handleChangeMinutes}
            />
            <div className="absolute left-6 font-medium border-t border-b p-1 pointer-events-none font-mono">
              <span className="text-xs">mins</span>
            </div>
          </>
        )}
        {node.attrs.targetTime ? (
          <button
            className="border rounded-r bg-gray-200 hover:bg-gray-300 text-gray-500 p-1"
            onClick={handleResetTimer}
          >
            <XIcon className="w-5 h-5" />
          </button>
        ) : (
          <button
            className="border rounded-r bg-gray-200 hover:bg-gray-300 text-gray-500 p-1"
            onClick={handleStartTimer}
          >
            <PlayIcon className="w-5 h-5" />
          </button>
        )}
      </div>
    </NodeViewWrapper>
  );
};

const Timer = Node.create({
  name: "timer",
  group: "block",

  addAttributes() {
    // Return an object with attribute configuration
    return {
      minutes: {
        default: 5,
      },
      targetTime: {
        default: null,
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(TimerComponent);
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(HTMLAttributes)];
  },

  addCommands() {
    return {
      addTimer:
        (attrs: any) =>
        ({ chain }: { chain: any }) => {
          return chain().insertContent({ type: "timer", attrs }).run();
        },
    };
  },
});

export default Timer;
