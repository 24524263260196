import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/outline";

const ToggleChevronIcon = ({ expanded }: { expanded: boolean }) => (
  <div className="text-gray-400 group-hover:text-gray-700 flex items-center py-0.5">
    {expanded ? (
      <ChevronDownIcon className="h-3 w-3" />
    ) : (
      <ChevronRightIcon className="h-3 w-3" />
    )}
  </div>
);

export default ToggleChevronIcon;
