import { useQuery } from "@apollo/client";
import { Popover } from "@headlessui/react";
import { PlusIcon, XIcon } from "@heroicons/react/outline";
import { uniqBy } from "lodash";
import { useState } from "react";
import {
  GetOrganizationCareerTracksQuery,
  GetOrganizationCareerTracksQueryVariables,
} from "types/graphql-schema";

import getOrganizationCareerTracksQuery from "@apps/org-settings/graphql/get-organization-career-tracks-query";
import { currentOrganizationVar } from "@cache/cache";
import ComboboxGeneric, {
  ComboboxGenericOption,
} from "@components/combobox/generic-combobox";
import { useNotificationError } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

export type CareerTrackPickerTrack = {
  id: number;
  title: string;
};

const CareerTrackPickerDropdown = ({
  careerTracks,
  onChange,
}: {
  careerTracks: CareerTrackPickerTrack[];
  onChange: (value: CareerTrackPickerTrack) => void;
}) => {
  const currentOrganization = currentOrganizationVar();
  const [options, setOptions] = useState<
    ComboboxGenericOption<CareerTrackPickerTrack>[]
  >([]);
  const careerTrackIds = careerTracks.map(({ id }) => id);
  const [canLoadData, setCanLoadData] = useState(false);
  const { onError } = useNotificationError();

  const { loading } = useQuery<
    GetOrganizationCareerTracksQuery,
    GetOrganizationCareerTracksQueryVariables
  >(getOrganizationCareerTracksQuery, {
    variables: {
      organizationId: currentOrganization.id,
    },
    skip: !canLoadData,
    onError,
    onCompleted: (data) => {
      const tracks = assertEdgesNonNull(data.careerTracks);
      setOptions(
        uniqBy(tracks, "id").map((track) => ({
          label: track.title,
          value: track,
        }))
      );
    },
  });

  const filteredOptions = options.filter(
    (option) => !option.value.id || !careerTrackIds.includes(option.value.id)
  );

  const handleChangeCareerTrack = (
    option: ComboboxGenericOption<CareerTrackPickerTrack>
  ) => {
    onChange(option.value);
  };

  const handlePreloadData = () => {
    setCanLoadData(true);
  };

  return (
    <ComboboxGeneric
      aria-label="Career Track picker dropdown"
      loading={loading}
      value={null}
      options={filteredOptions}
      onChangeValue={handleChangeCareerTrack}
      onClickButton={handlePreloadData}
    >
      {({ setReferenceElement }) => (
        <div className={"flex justify-between bg-white rounded-md"}>
          <Popover.Button
            className="flex items-center text-sm gap-1 border rounded-lg py-1 px-2"
            onClick={handlePreloadData}
            ref={setReferenceElement}
          >
            <PlusIcon className="h-4 w-4" />
            Pick a Career Track
          </Popover.Button>
        </div>
      )}
    </ComboboxGeneric>
  );
};

const CareerTrackPicker = ({
  careerTracks,
  onRemoveCareerTrack,
  onAddCareerTrack,
  canChange,
}: {
  careerTracks: CareerTrackPickerTrack[];
  onRemoveCareerTrack: (recipient: CareerTrackPickerTrack) => void;
  onAddCareerTrack: (option: CareerTrackPickerTrack) => void;
  canChange: boolean;
}) => {
  return (
    <div className="flex-1 flex gap-2 flex-wrap">
      {careerTracks.map((track) => (
        <div
          className="border rounded-lg px-2 py-1 text-sm flex items-center gap-1"
          key={track.id}
        >
          {track.title}
          {canChange && (
            <button
              className="ml-0.5 rounded-full p-0.5 border bg-gray-50 text-gray-600 hover:text-white hover:bg-blue-600 hover:border-blue-600"
              onClick={() => onRemoveCareerTrack(track)}
            >
              <XIcon className="h-3 w-3" />
            </button>
          )}
        </div>
      ))}
      {canChange && (
        <CareerTrackPickerDropdown
          careerTracks={careerTracks}
          onChange={onAddCareerTrack}
        />
      )}
    </div>
  );
};

export default CareerTrackPicker;
