import { LinkIcon } from "@heroicons/react/outline";
import { orderBy } from "lodash";
import moment from "moment";
import { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  GoalOverviewGoalFragmentFragment,
  GoalState,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import ArtifactDropdownMenu from "@apps/artifact/artifact-dropdown-menu";
import ArtifactDueDate from "@apps/artifact/components/artifact-due-date";
import ArtifactIcon from "@apps/artifact/components/artifact-icon";
import GoalParticipants from "@apps/artifact/components/goal-participants";
import GoalProgress from "@apps/goal-progress/goal-progress";
import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache, {
  GoalOverviewGoalDisplayEnum,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import Avatar from "@components/avatar/avatar";
import DraftLabel from "@components/draft-label/draft-label";
import GoalIcon from "@components/goal-icon/goal-icon";
import GoalVisibilityIcon from "@components/goal-visibility-icon/goal-visibility-icon";
import KeyResultIcon from "@components/key-result-icon/key-result-icon";
import AppLink from "@components/link/link";
import ProgressBar from "@components/progress-bar/progress-bar";
import Table, {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
  TableSortDir,
} from "@components/table/table";
import StartCurrentTargetTooltip from "@components/tooltip/start-current-target-tooltip";
import AppTooltip from "@components/tooltip/tooltip";
import {
  assertEdgesNonNull,
  getProgressFromStartCurrentTargetValues,
  getProgressLabel,
  getUrl,
  toWithBackground,
} from "@helpers/helpers";

enum GoalSortKeyEnum {
  title = "title",
  progress = "progress",
  lastUpdate = "last_update",
  dueDate = "due_date",
}

const sortGoals = (
  objectives: GoalOverviewGoalFragmentFragment[],
  sortDir: TableSortDir,
  sortKey: GoalSortKeyEnum
) =>
  orderBy(
    objectives,
    (objective) => {
      if (sortKey === GoalSortKeyEnum.title)
        return [objective.title?.toLowerCase()];
      if (sortKey === GoalSortKeyEnum.dueDate) return [objective.dueDate];
      if (sortKey === GoalSortKeyEnum.progress) return [objective.progress];
      if (sortKey === GoalSortKeyEnum.lastUpdate)
        return [objective.activities.edges?.[0]?.node?.created];
    },
    [sortDir]
  );

const GoalOverviewGoalTable = ({
  goals,
}: {
  goals: GoalOverviewGoalFragmentFragment[];
}) => {
  const { uiPreferenceCache } = useUiPreferenceCache();
  const [sortKey, setSortKey] = useState<GoalSortKeyEnum>(
    GoalSortKeyEnum.title
  );
  const [sortDir, setSortDir] = useState<TableSortDir>("asc");
  const location = useLocation<TFLocationState>();
  const label = useLabel();

  const handleClickSorting = (newSortKey: GoalSortKeyEnum) => () => {
    if (newSortKey !== sortKey) {
      setSortKey(newSortKey);
    } else {
      setSortDir(sortDir === "asc" ? "desc" : "asc");
    }
  };

  const sortedGoals = sortGoals(goals, sortDir, sortKey);
  return (
    <TableContainer className="shadow border-0">
      <Table>
        <TableHeadRow>
          <TableHeadCell
            sorted={sortKey === GoalSortKeyEnum.title ? sortDir : undefined}
            onClick={handleClickSorting(GoalSortKeyEnum.title)}
          >
            Title
          </TableHeadCell>
          <TableHeadCell
            sorted={sortKey === GoalSortKeyEnum.progress ? sortDir : undefined}
            onClick={handleClickSorting(GoalSortKeyEnum.progress)}
            width="32"
          >
            Progress
          </TableHeadCell>
          <TableHeadCell
            sorted={
              sortKey === GoalSortKeyEnum.lastUpdate ? sortDir : undefined
            }
            onClick={handleClickSorting(GoalSortKeyEnum.lastUpdate)}
            width="48"
          >
            Last update
          </TableHeadCell>
          <TableHeadCell
            sorted={sortKey === GoalSortKeyEnum.dueDate ? sortDir : undefined}
            onClick={handleClickSorting(GoalSortKeyEnum.dueDate)}
            width="32"
          >
            Due Date
          </TableHeadCell>
          <TableHeadCell width="36">Owners</TableHeadCell>
        </TableHeadRow>
        <TableBody>
          {sortedGoals.map((goal) => (
            <Fragment key={goal.id}>
              <TableBodyRow>
                <TableBodyCell>
                  <div className="flex items-center gap-2">
                    <ArtifactIcon artifact={goal} size="5" />
                    <AppLink
                      className="text-sm text-slate-700 hover:underline"
                      to={toWithBackground({
                        pathname: getUrl({
                          artifactType: goal.artifactType,
                          artifactId: goal.id,
                        }),
                        location,
                      })}
                    >
                      {goal.title}
                    </AppLink>
                    {goal.state === GoalState.Draft && (
                      <DraftLabel className="ml-1" />
                    )}{" "}
                    <GoalVisibilityIcon
                      showOnlyIfPrivate
                      goalVisibility={goal.goalVisibility}
                      className="ml-1 inline-block"
                      size="4"
                    />
                  </div>
                </TableBodyCell>
                <TableBodyCell>
                  <div>
                    <GoalProgress artifact={goal} withPopover={true} />
                  </div>
                </TableBodyCell>
                <TableBodyCell>
                  <div className="hidden sm:flex items-center text-sm tracking-tight text-gray-400">
                    {goal.activities.edges?.[0]?.node?.created
                      ? moment(goal.activities.edges[0].node.created).format(
                          "lll"
                        )
                      : "None"}
                  </div>
                </TableBodyCell>
                <TableBodyCell>
                  <div className="text-sm">
                    <ArtifactDueDate
                      artifact={goal}
                      labelName="due date"
                      canEdit={goal.canUpdate.permission}
                    />
                  </div>
                </TableBodyCell>
                <TableBodyCell>
                  <div className="flex justify-between items-center">
                    <GoalParticipants artifact={goal} />
                    <ArtifactDropdownMenu
                      artifact={goal}
                      size="5"
                      portal={true}
                      className="hover:bg-black/5"
                    />
                  </div>
                </TableBodyCell>
              </TableBodyRow>
              {uiPreferenceCache.goalOverviewGoalDisplay ===
                GoalOverviewGoalDisplayEnum.details &&
                (goal.keyResults.edges.length > 0 ||
                  (goal.childGoals?.edges.length ?? 0) > 0) && (
                  <TableBodyRow className="border-t-0">
                    <TableBodyCell className="pt-1 pb-4" colSpan={5}>
                      <div className="border rounded divide-y">
                        {assertEdgesNonNull(goal.keyResults).map(
                          (keyResult) => (
                            <div
                              key={keyResult.id}
                              className="px-3 py-1.5 flex items-center justify-between gap-2"
                            >
                              <div className="flex items-center gap-2 [overflow-wrap:anywhere]">
                                <KeyResultIcon className="h-5 w-5 mt-px shrink-0" />
                                {keyResult.title}
                              </div>
                              <div className="flex items-center gap-3">
                                {keyResult.kpi?.id && (
                                  <AppTooltip
                                    text={`<div>Connected to KPI: ${
                                      keyResult.kpi.title
                                    }.</div><div>The value of this ${label(
                                      "key result"
                                    )} will be updated when the KPI's value is updated.</div>`}
                                  >
                                    <a
                                      className="rounded p-0.5 hover:bg-gray-100"
                                      aria-label="KR connected to KPI icon"
                                      href={`/kpis/${keyResult.kpi.id}`}
                                    >
                                      <LinkIcon className="h-4 w-4 text-gray-500" />
                                    </a>
                                  </AppTooltip>
                                )}
                                <StartCurrentTargetTooltip
                                  goalOrKeyresult={keyResult}
                                >
                                  <ProgressBar
                                    label={getProgressLabel(keyResult, {
                                      shortVersion: true,
                                    })}
                                    progress={getProgressFromStartCurrentTargetValues(
                                      keyResult
                                    )}
                                  />
                                </StartCurrentTargetTooltip>
                                <Avatar
                                  user={keyResult.assignee}
                                  tooltipPrefix="Assigned to "
                                  size="5"
                                />
                              </div>
                            </div>
                          )
                        )}
                        {assertEdgesNonNull(goal.childGoals).map(
                          (childGoal) => (
                            <div
                              key={childGoal.id}
                              className="px-3 py-1.5 flex items-center justify-between gap-2"
                            >
                              <div className="flex items-center gap-2">
                                <GoalIcon
                                  scope={childGoal.scope}
                                  state={childGoal.state}
                                  size={5}
                                />
                                <AppLink
                                  className="text-sm text-slate-700 hover:underline"
                                  to={toWithBackground({
                                    pathname: getUrl({
                                      artifactType: childGoal.artifactType,
                                      artifactId: childGoal.id,
                                    }),
                                    location,
                                  })}
                                >
                                  {childGoal.title}
                                </AppLink>
                                {childGoal.state === GoalState.Draft && (
                                  <div>
                                    <DraftLabel />
                                  </div>
                                )}
                                <GoalVisibilityIcon
                                  showOnlyIfPrivate
                                  goalVisibility={childGoal.goalVisibility}
                                  className="ml-1 inline-block"
                                  size="4"
                                />
                              </div>
                              <div className="flex items-center gap-3">
                                <ArtifactDueDate
                                  artifact={childGoal}
                                  labelName="due date"
                                  canEdit={childGoal.canUpdate.permission}
                                />
                                <StartCurrentTargetTooltip
                                  goalOrKeyresult={childGoal}
                                >
                                  <ProgressBar
                                    label={getProgressLabel(childGoal, {
                                      shortVersion: true,
                                    })}
                                    progress={getProgressFromStartCurrentTargetValues(
                                      childGoal
                                    )}
                                  />
                                </StartCurrentTargetTooltip>
                                <GoalParticipants artifact={childGoal} />
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </TableBodyCell>
                  </TableBodyRow>
                )}
            </Fragment>
          ))}
          {sortedGoals.length === 0 && (
            <TableBodyRow>
              <TableBodyCell colSpan={5}>
                No {label("goal", { pluralize: true })}
              </TableBodyCell>
            </TableBodyRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GoalOverviewGoalTable;
