import { FormEvent, useState } from "react";

import Button, { ButtonSize, buttonTheme } from "@components/button/button";
import AppPopover from "@components/popover/app-popover";
import { meetingEditType } from "@helpers/constants";

export const meetingDialogInputClassName =
  "hover:bg-gray-100 rounded text-sm px-2 py-1.5 focus:outline-0 focus:ring-0";

const MeetingRecurringPopover = ({
  onSubmit,
  disabled,
  showAllEventsOption,
}: {
  onSubmit: ({ editType }: { editType: any }) => void;
  disabled: boolean;
  showAllEventsOption: boolean;
}) => {
  const [editType, setEditType] = useState(meetingEditType.thisEventAndFuture);

  const handleSubmitForm = (e: FormEvent) => {
    e.preventDefault();
    onSubmit({ editType });
  };

  return (
    <AppPopover
      options={{
        "aria-label": "Meeting dialog recurring changes popover",
      }}
      content={({ close }) => (
        <div className="p-4 flex flex-col gap-4">
          <div className="font-medium">Edit recurring event</div>
          <div className="flex flex-col gap-2 text-sm">
            <label className="flex items-center gap-2">
              <input
                type="radio"
                name="recurring-event-changes"
                value={meetingEditType.singleEvent}
                checked={editType === meetingEditType.singleEvent}
                onChange={() => setEditType(meetingEditType.singleEvent)}
              />{" "}
              This meeting
            </label>
            <label className="flex items-center gap-2">
              <input
                type="radio"
                name="recurring-event-changes"
                value={meetingEditType.thisEventAndFuture}
                checked={editType === meetingEditType.thisEventAndFuture}
                onChange={() => setEditType(meetingEditType.thisEventAndFuture)}
              />{" "}
              This and future meetings
            </label>

            {showAllEventsOption && (
              <label className="flex items-center gap-2">
                <input
                  type="radio"
                  name="recurring-event-changes"
                  value={meetingEditType.allEvents}
                  checked={editType === meetingEditType.allEvents}
                  onChange={() => setEditType(meetingEditType.allEvents)}
                />{" "}
                All meetings
              </label>
            )}
          </div>
          <div className="flex gap-2">
            <Button
              size={ButtonSize.large}
              text="Cancel"
              theme={buttonTheme.default}
              className="w-1/2"
              onClick={() => close()}
            />
            <Button
              size={ButtonSize.large}
              text="Apply"
              type="submit"
              theme={buttonTheme.primary}
              className="w-1/2"
              onClick={(e) => {
                handleSubmitForm(e);
                close();
              }}
            />
          </div>
        </div>
      )}
    >
      <AppPopover.Button
        as={Button}
        theme={buttonTheme.primary}
        className="w-full"
        disabled={disabled}
      >
        Save changes
      </AppPopover.Button>
    </AppPopover>
  );
};

export default MeetingRecurringPopover;
