import { compact, isInteger, range, uniqBy } from "lodash";
import { useMemo } from "react";

import useLabel from "@apps/use-label/use-label";
import Avatar from "@components/avatar/avatar";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";
import { assertEdgesNonNull } from "@helpers/helpers";

import RoleBasedAssessmentQuestionChoice from "./role-based-assessment-question-choice";
import RoleBasedAssessmentQuestionLayout from "./role-based-assessment-question-layout";
import {
  AssessmentAnswer,
  AssessmentAnswerWithResponder,
  AssessmentQuestionRole,
  AssessmentQuestionWithWeight,
  CompetencyCriteriaAssessmentQuestion,
} from "./types";

const getAnswersForQuestion = (
  answers: AssessmentAnswerWithResponder[],
  questionId: number,
  disabled?: boolean
): AssessmentAnswerWithResponder[] => {
  const filteredAnswers = answers.filter(
    (answer) => answer.questionId === questionId
  );
  if (!disabled && filteredAnswers.length > 1) {
    throw new Error(
      "Editable assessment form found more than one answer for a question!"
    );
  }
  if (!disabled && filteredAnswers.length === 0) {
    return [
      {
        questionId,
        textAnswer: null,
        comment: null,
      },
    ];
  }
  return filteredAnswers;
};

const RoleBasedAssessmentQuestionGroup = ({
  role,
  questions,
  allAnswers,
  onUpdateAnswer,
  formDisabled,
  showError,
  isCurrentRole,
}: {
  role: AssessmentQuestionRole;
  questions: AssessmentQuestionWithWeight[];
  allAnswers: AssessmentAnswerWithResponder[];
  onUpdateAnswer?: (answer: AssessmentAnswer) => void;
  formDisabled?: boolean;
  showError?: boolean;
  isCurrentRole?: boolean;
}) => {
  const label = useLabel();

  const combinedCriteria = questions.every(
    ({ question }) =>
      question.__typename === "CompetencyAssessmentQuestionNode" ||
      question.__typename === "ResponsibilityAssessmentQuestionNode"
  );
  const separateCriteria = questions.every(
    ({ question }) =>
      question.__typename === "CompetencyCriteriaAssessmentQuestionNode" ||
      question.__typename === "ResponsibilityAssessmentQuestionNode"
  );
  if (!combinedCriteria && !separateCriteria) {
    throw new Error(
      "Role based question group must have either combined or separate criteria"
    );
  }

  const hasResponders = useMemo((): boolean => {
    if (questions.length === 0) {
      return false;
    }
    const answers = getAnswersForQuestion(
      allAnswers,
      questions[0].id,
      formDisabled
    );
    return answers.length > 0 && !!answers[0].responder;
  }, [allAnswers, formDisabled, questions]);

  const roleQuestionContent = useMemo(() => {
    if (combinedCriteria) {
      const roleQuestions = questions
        .map(({ question }) => question)
        .filter(
          (question) =>
            question.__typename === "CompetencyAssessmentQuestionNode"
        );

      return roleQuestions.map((question) => {
        const { startValue, endValue, labels } = question;
        const criteria = assertEdgesNonNull(question.competency?.criteria);
        const answers = getAnswersForQuestion(
          allAnswers,
          question.id,
          formDisabled
        );

        return (
          <RoleBasedAssessmentQuestionLayout key={question.id}>
            <RoleBasedAssessmentQuestionLayout.Header
              title={question.title}
              startValue={roleQuestions[0].startValue}
              endValue={roleQuestions[0].endValue}
              labels={roleQuestions[0].labels}
              hasResponders={hasResponders}
            />

            <RoleBasedAssessmentQuestionLayout.Row
              showError={
                showError &&
                !isInteger(answers[0]?.integerAnswer) &&
                question.isResponseMandatory
              }
            >
              <RoleBasedAssessmentQuestionLayout.RowContent borderRight>
                {criteria.map((criteriaItem) => {
                  return (
                    <div key={criteriaItem.id}>
                      <TextareaWysiwyg
                        editable={false}
                        value={criteriaItem.content}
                      />
                    </div>
                  );
                })}
              </RoleBasedAssessmentQuestionLayout.RowContent>

              <RoleBasedAssessmentQuestionLayout.RowChoices>
                {answers.map((answer) => {
                  const integerAnswer = answer.integerAnswer;
                  const responder = answer.responder;
                  return (
                    <RoleBasedAssessmentQuestionLayout.Choices
                      key={`${answer.questionId}-${responder?.id}`}
                    >
                      {responder && (
                        <div className="shrink-0">
                          <Avatar user={responder} size="5" />
                        </div>
                      )}
                      <div className="flex-1 flex items-center justify-between">
                        {range(startValue, endValue + 1).map((value, index) => (
                          <RoleBasedAssessmentQuestionChoice
                            key={index}
                            tooltip={labels[index] ? labels[index] : undefined}
                            value={value}
                            disabled={formDisabled}
                            integerAnswer={integerAnswer}
                            onUpdateAnswer={(value) => {
                              if (!onUpdateAnswer) {
                                return;
                              }
                              onUpdateAnswer({
                                ...answer,
                                questionId: question.id,
                                integerAnswer: value,
                                textAnswer: null,
                                comment: answer.comment ?? null,
                              });
                            }}
                          />
                        ))}
                      </div>
                    </RoleBasedAssessmentQuestionLayout.Choices>
                  );
                })}
              </RoleBasedAssessmentQuestionLayout.RowChoices>
            </RoleBasedAssessmentQuestionLayout.Row>
          </RoleBasedAssessmentQuestionLayout>
        );
      });
    } else if (separateCriteria) {
      const roleQuestions = questions
        .map(({ question }) => question)
        .filter(
          (question) =>
            question.__typename === "CompetencyCriteriaAssessmentQuestionNode"
        );
      const { competencies, roleQuestionsByCompetencyId } =
        roleQuestions.reduce(
          (acc, question) => {
            const competency = question.competencyCriteria.competency;
            const competencyId = competency.id;
            const existing =
              acc.roleQuestionsByCompetencyId[competencyId] ?? [];
            return {
              roleQuestionsByCompetencyId: {
                ...acc.roleQuestionsByCompetencyId,
                [competencyId]: [...existing, question],
              },
              competencies: uniqBy(
                compact([...acc.competencies, competency]),
                "id"
              ),
            };
          },
          { competencies: [], roleQuestionsByCompetencyId: {} } as {
            competencies: {
              id: number;
              title: string;
              description: string;
            }[];
            roleQuestionsByCompetencyId: {
              [competencyId: number]: CompetencyCriteriaAssessmentQuestion[];
            };
          }
        );

      return competencies.map((competency) => {
        const roleQuestions = roleQuestionsByCompetencyId[competency.id];
        if (!roleQuestions || roleQuestions.length === 0) {
          return null;
        }
        return (
          <RoleBasedAssessmentQuestionLayout key={competency.id}>
            <RoleBasedAssessmentQuestionLayout.Header
              title={competency.title}
              startValue={roleQuestions[0].startValue}
              endValue={roleQuestions[0].endValue}
              labels={roleQuestions[0].labels}
              hasResponders={hasResponders}
            />
            {roleQuestions.map((question) => {
              const { startValue, endValue, labels } = question;
              const criteria = question.competencyCriteria.content;
              const answers = getAnswersForQuestion(
                allAnswers,
                question.id,
                formDisabled
              );

              return (
                <RoleBasedAssessmentQuestionLayout.Row
                  showError={
                    showError &&
                    !isInteger(answers[0]?.integerAnswer) &&
                    question.isResponseMandatory
                  }
                  key={question.id}
                >
                  <RoleBasedAssessmentQuestionLayout.RowContent>
                    <TextareaWysiwyg editable={false} value={criteria} />
                  </RoleBasedAssessmentQuestionLayout.RowContent>

                  <RoleBasedAssessmentQuestionLayout.RowChoices>
                    {answers.map((answer) => {
                      const integerAnswer = answer.integerAnswer;
                      const responder = answer.responder;
                      return (
                        <RoleBasedAssessmentQuestionLayout.Choices
                          key={`${answer.questionId}-${responder?.id}`}
                        >
                          {responder && (
                            <div className="shrink-0">
                              <Avatar user={responder} size="5" />
                            </div>
                          )}
                          <div className="flex-1 flex items-center justify-between">
                            {range(startValue, endValue + 1).map(
                              (value, index) => (
                                <RoleBasedAssessmentQuestionChoice
                                  key={index}
                                  tooltip={
                                    labels[index] ? labels[index] : undefined
                                  }
                                  value={value}
                                  disabled={formDisabled}
                                  integerAnswer={integerAnswer}
                                  onUpdateAnswer={(value) => {
                                    if (!onUpdateAnswer) {
                                      return;
                                    }
                                    onUpdateAnswer({
                                      ...answer,
                                      questionId: question.id,
                                      integerAnswer: value,
                                      textAnswer: null,
                                      comment: answer.comment ?? null,
                                    });
                                  }}
                                />
                              )
                            )}
                          </div>
                        </RoleBasedAssessmentQuestionLayout.Choices>
                      );
                    })}
                  </RoleBasedAssessmentQuestionLayout.RowChoices>
                </RoleBasedAssessmentQuestionLayout.Row>
              );
            })}
          </RoleBasedAssessmentQuestionLayout>
        );
      });
    }
    return null;
  }, [
    combinedCriteria,
    separateCriteria,
    questions,
    allAnswers,
    formDisabled,
    showError,
    hasResponders,
    onUpdateAnswer,
  ]);

  const responsiblityQuestions = useMemo(
    () =>
      questions
        .map(({ question }) => question)
        .filter(
          (question) =>
            question.__typename === "ResponsibilityAssessmentQuestionNode"
        ),
    [questions]
  );

  return (
    <>
      <div className="font-medium mb-4">
        {`${isCurrentRole ? "Current Role " : "Next Level "} ${label("review", {
          capitalize: true,
        })}: ${role.title} (Level ${role.level})`}
      </div>
      <div className="text-sm flex flex-col gap-4">
        {roleQuestionContent}

        {responsiblityQuestions.length > 0 && (
          <RoleBasedAssessmentQuestionLayout>
            <RoleBasedAssessmentQuestionLayout.Header
              title="Responsibilities"
              startValue={responsiblityQuestions[0].startValue}
              endValue={responsiblityQuestions[0].endValue}
              labels={responsiblityQuestions[0].labels}
              hasResponders={hasResponders}
            />
            {responsiblityQuestions.map((question) => {
              const { startValue, endValue, labels } = question;
              const responsibility = question.responsibility?.content;
              const answers = getAnswersForQuestion(
                allAnswers,
                question.id,
                formDisabled
              );

              return (
                <RoleBasedAssessmentQuestionLayout.Row
                  showError={
                    showError &&
                    !isInteger(answers[0]?.integerAnswer) &&
                    question.isResponseMandatory
                  }
                  key={question.id}
                >
                  <RoleBasedAssessmentQuestionLayout.RowContent>
                    <TextareaWysiwyg editable={false} value={responsibility} />
                  </RoleBasedAssessmentQuestionLayout.RowContent>

                  <RoleBasedAssessmentQuestionLayout.RowChoices>
                    {answers.map((answer) => {
                      const integerAnswer = answer.integerAnswer;
                      const responder = answer.responder;
                      return (
                        <RoleBasedAssessmentQuestionLayout.Choices
                          key={`${answer.questionId}-${responder?.id}`}
                        >
                          {responder && (
                            <div className="shrink-0">
                              <Avatar user={responder} size="5" />
                            </div>
                          )}
                          <div className="flex-1 flex items-center justify-between">
                            {range(startValue, endValue + 1).map(
                              (value, index) => (
                                <RoleBasedAssessmentQuestionChoice
                                  key={index}
                                  tooltip={
                                    labels[index] ? labels[index] : undefined
                                  }
                                  value={value}
                                  disabled={formDisabled}
                                  integerAnswer={integerAnswer}
                                  onUpdateAnswer={(value) => {
                                    if (!onUpdateAnswer) {
                                      return;
                                    }
                                    onUpdateAnswer({
                                      ...answer,
                                      questionId: question.id,
                                      integerAnswer: value,
                                      textAnswer: null,
                                      comment: answer.comment ?? null,
                                    });
                                  }}
                                />
                              )
                            )}
                          </div>
                        </RoleBasedAssessmentQuestionLayout.Choices>
                      );
                    })}
                  </RoleBasedAssessmentQuestionLayout.RowChoices>
                </RoleBasedAssessmentQuestionLayout.Row>
              );
            })}
          </RoleBasedAssessmentQuestionLayout>
        )}
      </div>
    </>
  );
};

export default RoleBasedAssessmentQuestionGroup;
