import { useMemo } from "react";

import IntegrationLogo, {
  IntegrationLogoType,
} from "@components/integration-logo/integration-logo";
import { pluralize } from "@helpers/string";

enum WorkHistoryExternalObjectType {
  GITHUB_PULL_REQUEST = "github_pull_request",
  JIRA_ISSUE = "jira_issue",
  HUBSPOT_DEAL = "hubspot_deal",
  SALESFORCE_ACTIVITY = "salesforce_activity",
  LINEAR_ISSUE = "linear_issue",
  LINEAR_PROJECT = "linear_project",
}

export const WorkHistoryExternalObjectTitles: {
  [key: string]: string;
} = {
  [WorkHistoryExternalObjectType.GITHUB_PULL_REQUEST]: "Github",
  [WorkHistoryExternalObjectType.JIRA_ISSUE]: "JIRA",
  [WorkHistoryExternalObjectType.HUBSPOT_DEAL]: "Hubspot",
  [WorkHistoryExternalObjectType.SALESFORCE_ACTIVITY]: "Salesforce",
  [WorkHistoryExternalObjectType.LINEAR_ISSUE]: "Linear",
  [WorkHistoryExternalObjectType.LINEAR_PROJECT]: "Linear",
};

type BasicExternalEvent = {
  id: string;
  title: string;
  url: string;
};

type GithubPullRequestEventObject = BasicExternalEvent & {
  type: WorkHistoryExternalObjectType.GITHUB_PULL_REQUEST;
};
type JiraIssueEventObject = BasicExternalEvent & {
  type: WorkHistoryExternalObjectType.JIRA_ISSUE;
};
type HubspotDealEventObject = BasicExternalEvent & {
  type: WorkHistoryExternalObjectType.HUBSPOT_DEAL;
  status: "Open" | "Closed";
  stage: string;
  won: boolean;
  amount: number;
  engagements: { id: string; type: "email" | "call" }[];
};
type LinearIssueEventObject = BasicExternalEvent & {
  type: WorkHistoryExternalObjectType.LINEAR_ISSUE;
};
type LinearProjectEventObject = BasicExternalEvent & {
  type: WorkHistoryExternalObjectType.LINEAR_PROJECT;
  is_lead: boolean;
  is_member: boolean;
};

export type WorkHistoryExternalEventObject =
  | GithubPullRequestEventObject
  | JiraIssueEventObject
  | HubspotDealEventObject
  | LinearIssueEventObject
  | LinearProjectEventObject;

const externalObjectIcons: {
  [key in WorkHistoryExternalObjectType]: IntegrationLogoType;
} = {
  [WorkHistoryExternalObjectType.GITHUB_PULL_REQUEST]:
    IntegrationLogoType.Github,
  [WorkHistoryExternalObjectType.JIRA_ISSUE]: IntegrationLogoType.Jira,
  [WorkHistoryExternalObjectType.HUBSPOT_DEAL]: IntegrationLogoType.Hubspot,
  [WorkHistoryExternalObjectType.SALESFORCE_ACTIVITY]:
    IntegrationLogoType.Salesforce,
  [WorkHistoryExternalObjectType.LINEAR_ISSUE]: IntegrationLogoType.Linear,
  [WorkHistoryExternalObjectType.LINEAR_PROJECT]: IntegrationLogoType.Linear,
};

const ExternalEventObject = ({
  eventObject,
}: {
  eventObject: WorkHistoryExternalEventObject;
}) => {
  const iconType = externalObjectIcons[eventObject.type];

  const content = useMemo(() => {
    const link = (
      <a
        className="hover:underline font-medium"
        target="_blank"
        rel="noreferrer"
        href={eventObject.url}
      >
        {eventObject.title}
      </a>
    );
    if (eventObject.type === WorkHistoryExternalObjectType.HUBSPOT_DEAL) {
      const callCount = eventObject.engagements.filter(
        (engagement) => engagement.type === "call"
      ).length;
      const emailCount = eventObject.engagements.filter(
        (engagement) => engagement.type === "email"
      ).length;
      return (
        <>
          {link}
          <div className="text-xs text-gray-500 flex items-center gap-2">
            <div>{eventObject.stage}</div>
            {callCount > 0 && (
              <div className="italic">
                {`${callCount} ${pluralize("call", callCount)}`}
              </div>
            )}
            {emailCount > 0 && (
              <div className="italic">
                {`${emailCount} ${pluralize("email", emailCount)}`}
              </div>
            )}
          </div>
        </>
      );
    } else if (
      eventObject.type === WorkHistoryExternalObjectType.LINEAR_ISSUE
    ) {
      return (
        <>
          <span>Issue: </span>
          {link}
        </>
      );
    } else if (
      eventObject.type === WorkHistoryExternalObjectType.LINEAR_PROJECT
    ) {
      return (
        <>
          <span>Project ({eventObject.is_lead ? "lead" : "member"}): </span>
          {link}
        </>
      );
    } else {
      return link;
    }
  }, [eventObject]);

  return (
    <div className="flex items-center gap-2">
      <div>
        <IntegrationLogo type={iconType} />
      </div>
      <div>{content}</div>
    </div>
  );
};

export default ExternalEventObject;
