import { JSONContent } from "@tiptap/core";
import { uniqueId } from "lodash";
import {
  FinalizePermissions,
  NotesRequirement,
  TopicTemplateFragmentFragment,
} from "types/graphql-schema";

export type EditTemplateTopicType = {
  id: number | string;
  isMandatory: boolean;
  notesRequirement: NotesRequirement;
  title: string;
  description: string;
  discussionNotes: string | JSONContent;
  defaultSubjectNotes: string | JSONContent;
  defaultFacilitatorNotes: string | JSONContent;
  includesIndividualNotes: boolean;
  includesSharedNotes: boolean;
};

export const getNewTopic = (): EditTemplateTopicType => {
  const id = uniqueId("topic-");
  const newTopic = {
    id,
    isMandatory: false,
    notesRequirement: NotesRequirement.NotRequired,
    title: "",
    description: "",
    discussionNotes: "",
    defaultSubjectNotes: "",
    defaultFacilitatorNotes: "",
    includesIndividualNotes: false,
    includesSharedNotes: true,
  };
  return newTopic;
};

export const emptyTemplate: Partial<TopicTemplateFragmentFragment> = {
  publicTemplate: true,
  title: "",
  oneononeTemplate: true,
  finalizePermissions: FinalizePermissions.FacilitatorsAndAdmins,
  description: "",
  categoriesList: [],
  managerInstructions: "",
  reportInstructions: "",
  allowAdminVisibility: true,
  allowManagementTreeVisibility: true,
  allowOrgVisibility: false,
};

export const removeIdFromTopics = (topics: any) =>
  topics.map((node: any) => ({
    id: `topic-${node.id}`,
    title: node.title,
    description: node.description,
    isMandatory: node.isMandatory,
    discussionNotes: node.discussionNotes,
    defaultSubjectNotes: node.defaultSubjectNotes,
    defaultFacilitatorNotes: node.defaultFacilitatorNotes,
    includesIndividualNotes: node.includesIndividualNotes,
  })) || [];

export const isSameObject = (obj1: unknown, obj2: unknown) =>
  JSON.stringify(obj1) === JSON.stringify(obj2);

export const removeTopicsWithoutTitles = (topics: any[]) =>
  topics.filter((topic) => {
    return topic.title.length > 0;
  });
