import { gql } from "@apollo/client";

export default gql`
  fragment ComplianceProgramMissingAssessment on MissingAssessmentNode {
    responder {
      id
      name
      avatar
    }
    target {
      id
      name
      avatar
    }
    assessmentTemplate {
      id
      assessmentType
    }
  }
  fragment ComplianceProgramReportParticipantStatusUser on UserNode {
    id
    name
    teams {
      edges {
        node {
          id
          title
        }
      }
    }
  }
  fragment ComplianceProgramReportParticipantStatus on ParticipantStatusNode {
    oneononeStatus
    performanceAssessmentStatus
    performanceSelfAssessmentStatus
    managerAssessmentStatus
    managerSelfAssessmentStatus
    peerAssessmentStatus
    peerSelfAssessmentStatus
    meeting {
      id
      title
      meetingGroupId
    }
    user {
      ...ComplianceProgramReportParticipantStatusUser
      email
      avatar
      managers(first: 1) {
        edges {
          node {
            id
            name
            avatar
          }
        }
      }
    }
  }

  query getComplianceProgramProgress($complianceProgramId: Int!) {
    assessmentsOpenForNominations(complianceProgramId: $complianceProgramId) {
      edges {
        node {
          id
          targetUser {
            ...ComplianceProgramReportParticipantStatusUser
          }
          nominations {
            totalCount
          }
        }
      }
    }
    complianceProgram(pk: $complianceProgramId) {
      id
      title
      created
      updated
      dueDate
      startDate
      periodStartDate
      periodEndDate
      ongoing
      startDateOffset
      startDateDueDateOffset
      startDatePeriodStartOffset
      startDatePeriodEndOffset
      state
      recurrence
      canUpdate {
        permission
        reason
      }
      canDelete {
        permission
        reason
      }
      appliesTo
      appliesToTeams {
        edges {
          node {
            id
            title
          }
        }
      }
      appliesToManagers {
        edges {
          node {
            id
            name
            avatar
          }
        }
      }
      appliesToUsers {
        edges {
          node {
            id
            name
            avatar
          }
        }
      }
      excludedUsers {
        edges {
          node {
            id
            name
            avatar
          }
        }
      }
      excludedHireDateAfter
      requiredTopicTemplates {
        edges {
          node {
            id
            title
          }
        }
      }
      performanceAssessmentTemplate {
        id
        assessmentType
        hasSelfAssessment
        isOnlySelfAssessment
      }
      managerAssessmentTemplate {
        id
        assessmentType
        hasSelfAssessment
        isOnlySelfAssessment
      }
      peerAssessmentTemplate {
        id
        assessmentType
        hasSelfAssessment
        isOnlySelfAssessment
        providers
      }
      participantStatus {
        edges {
          node {
            ...ComplianceProgramReportParticipantStatus
          }
        }
      }
      usersMissingAssessment {
        edges {
          node {
            ...ComplianceProgramMissingAssessment
          }
        }
      }
    }
  }
`;
