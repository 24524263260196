import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";

import Tooltip from "@components/tooltip/tooltip";

const RoleBasedAssessmentQuestionChoice = ({
  tooltip,
  value,
  integerAnswer,
  onUpdateAnswer,
  disabled,
}: {
  tooltip?: string;
  value: number;
  integerAnswer?: number | null;
  onUpdateAnswer: (answer: number) => void;
  disabled?: boolean;
}) => {
  const isSelected = integerAnswer === value;

  if (disabled) {
    return (
      <Tooltip text={tooltip}>
        <div className="w-10 h-10 flex items-center justify-center hover:bg-gray-100 rounded-md">
          {isSelected ? (
            <div className="w-3 h-3 bg-blue-600 rounded-full" />
          ) : (
            <div className="w-2 h-2 bg-gray-400 rounded-full" />
          )}
        </div>
      </Tooltip>
    );
  }

  return (
    <Tooltip text={tooltip}>
      <button
        contentEditable={false}
        className="w-10 h-10 hover:bg-gray-100 rounded-md cursor-pointer"
        onClick={(evt) => {
          evt.preventDefault();
          onUpdateAnswer(value);
        }}
      >
        <div className="flex w-full justify-center">
          {isSelected ? (
            <MdRadioButtonChecked className="h-4 w-4 text-blue-600" />
          ) : (
            <MdRadioButtonUnchecked className="h-4 w-4 text-gray-500" />
          )}
        </div>
      </button>
    </Tooltip>
  );
};

export default RoleBasedAssessmentQuestionChoice;
