import { useMutation, useQuery } from "@apollo/client";
import { Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { isEqual } from "lodash";
import numeral from "numeral";
import {
  ChangeEvent,
  ChangeEventHandler,
  FormEventHandler,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  GoalArtifactNode,
  GoalProgressType,
  GoalState,
  GoalStatus,
} from "types/graphql-schema";

import getArtifactActivitiesQuery from "@apps/artifact-sidebar/graphql/get-artifact-activities-query";
import getDashboardInsightsQuery from "@apps/dashboard-new/graphql/get-dashboard-insights-query";
import useLabel from "@apps/use-label/use-label";
import { successNotificationVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Button, { ButtonSize } from "@components/button/button";
import CurrentValueInput from "@components/current-value-input/current-value-input";
import GraphqlError from "@components/error/graphql-error";
import GoalStatusPill from "@components/goal-status-pill/goal-status-pill";
import Loading from "@components/loading/loading";
import Tooltip from "@components/tooltip/tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import CommentWYSIWYG from "@components/wysiwyg/comment-wysiwyg";
import {
  progressTypeBooleanStartValue,
  progressTypeBooleanTargetValue,
} from "@helpers/constants";
import { classNames } from "@helpers/css";
import { getProgressFromStartCurrentTargetValues } from "@helpers/helpers";

import getGoalProgressQuery from "./graphql/get-goal-progress-query";
import goalCheckinMutation from "./graphql/goal-checkin-mutation";

const getFormDataFromArtifact = (artifact: any) => {
  return {
    message: "",
    goalStatus: artifact.status,
    currentValue: artifact.currentValue,
    keyResults:
      artifact.keyResults?.edges.map(({ node }: { node: any }) => ({
        ...node,
        initialCurrentValue: node.currentValue,
      })) || [],
  };
};

const CheckinDialog = ({
  artifact,
  onClose,
}: {
  artifact: Pick<GoalArtifactNode, "id" | "title" | "progressType">;
  onClose: () => void;
}) => {
  const label = useLabel();
  const inputTitleRef = useRef(null);
  const [isShowingInvalidForm, setIsShowingInvalidForm] = useState(false);

  // use to track changes between form and initial form
  const [initialForm, setInitialForm] = useState(
    getFormDataFromArtifact(artifact)
  );
  const [form, setForm] = useState(initialForm);

  const { data, loading: loadingQuery } = useQuery(getGoalProgressQuery, {
    variables: { artifactId: artifact.id },
    onError: onNotificationErrorHandler(),
  });

  let formInvalid = "";
  const invalidKeyResult = form.keyResults.find((kr: any) => {
    return (
      String(kr.currentValue).trim() === "" || isNaN(Number(kr.currentValue))
    );
  });
  if (isEqual(form, initialForm)) {
    formInvalid = "Add a message or edit the progress.";
  } else if (String(form.currentValue).trim() === "") {
    formInvalid = "Enter a progress value";
  } else if (isNaN(Number(form.currentValue))) {
    formInvalid = "Set progress as a valid number";
  } else if (invalidKeyResult) {
    formInvalid = `Fix value of ${label("key result")}: ${
      invalidKeyResult.title
    }`;
  }

  const [goalCheckin, { error, loading: loadingMutation }] =
    useMutation(goalCheckinMutation);

  const handleClose = () => {
    onClose();
    setIsShowingInvalidForm(false);
  };

  const handleSubmitForm: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (formInvalid) {
      setIsShowingInvalidForm(true);
      return;
    }
    return goalCheckin({
      variables: {
        goalId: artifact.id,
        currentValue:
          artifact.progressType === GoalProgressType.AlignedAverage
            ? undefined
            : parseFloat(form.currentValue),
        goalStatus: form.goalStatus,
        keyResults: form.keyResults.map(
          ({ id, currentValue }: { id: any; currentValue: number }) => ({
            keyResultId: id,
            currentValue,
          })
        ),
        comment: JSON.stringify(form.message),
      },
      onError: onNotificationErrorHandler(),
      onCompleted: () => {
        handleClose();
        setIsShowingInvalidForm(false);
        successNotificationVar({
          title: `${artifact.title} updated.`,
          timeout: 2000,
        });
      },
      refetchQueries: [getArtifactActivitiesQuery, getDashboardInsightsQuery],
    });
  };

  const handleChangeMessage = ({ editor }: { editor: any }) => {
    setForm({ ...form, message: editor.getJSON() });
  };

  const handleChangeStatus = (goalStatus: any) => () => {
    setForm({ ...form, goalStatus });
  };

  const handleChangeCurrentValue: ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    const currentValue =
      artifact.progressType !== GoalProgressType.Boolean
        ? e.target.value
        : e.target.checked
        ? progressTypeBooleanTargetValue
        : progressTypeBooleanStartValue;
    setForm({ ...form, currentValue });
  };

  const handleChangeKeyResultCurrentValue = (
    e: ChangeEvent<HTMLInputElement>,
    keyResultToUpdate: any
  ) => {
    const currentValue =
      keyResultToUpdate.progressType !== GoalProgressType.Boolean
        ? Number(e.target.value)
        : e.target.checked
        ? progressTypeBooleanTargetValue
        : progressTypeBooleanStartValue;
    const updatedKeyResults = form.keyResults.map((keyResult: any) => {
      if (keyResultToUpdate.id === keyResult.id) {
        return {
          ...keyResult,
          currentValue,
        };
      }
      return keyResult;
    });
    setForm({ ...form, keyResults: updatedKeyResults });
  };

  // 2 lines below are a hack to make sure
  // the title input has a minimum of 1 line when empty
  // sometimes it loads with multiple lines.
  const [, setIsRerendered] = useState(false);
  useLayoutEffect(() => setIsRerendered(true), []);

  useEffect(() => {
    const formHasNotChanged = isEqual(initialForm, form);
    if (data && formHasNotChanged) {
      setInitialForm(getFormDataFromArtifact(data.artifact));
      setForm(getFormDataFromArtifact(data.artifact));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const statusClassName =
    "rounded-full border flex items-center gap-1.5 px-2.5 py-0.5 text-sm text-gray-500 hover:bg-gray-50 tracking-tight";
  const sumKrProgress = form.keyResults.reduce((memo: number, kr: any) => {
    return memo + (getProgressFromStartCurrentTargetValues(kr) || 0);
  }, 0);
  const averageKRProgress =
    form.keyResults.length === 0
      ? 0
      : sumKrProgress / form.keyResults.length / 100 || 0;
  return (
    <Dialog
      as="div"
      aria-label="Checkin dialog"
      className="fixed z-modal inset-0 overflow-y-auto text-gray-800"
      open
      onClose={handleClose}
      initialFocus={inputTitleRef}
    >
      <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
      <div className="flex min-h-full p-4 items-start sm:pt-20 sm:justify-center text-center">
        <form
          className="w-full md:max-w-2xl mt-4 align-top transform rounded-lg bg-white text-left shadow-xl text-sm"
          onSubmit={handleSubmitForm}
        >
          <div className="z-dropdown absolute -top-4 right-0 bg-white rounded-full p-1 flex gap-2">
            <button
              type="button"
              aria-label="Meeting dialog close button"
              className="p-1 rounded-full text-gray-400 hover:bg-gray-100 focus:outline-none"
              onClick={handleClose}
            >
              <span className="sr-only">Close panel</span>
              <XIcon className="h-5 w-5" />
            </button>
          </div>
          <div className="relative flex flex-col divide-y">
            <div className="flex items-start gap-4 py-4 px-6 font-medium text-lg">
              {artifact.title}
            </div>
            <div className="flex items-start gap-4 py-4 px-6">
              <label className="text-gray-500 text-sm w-24 font-medium mt-1">
                Message
              </label>
              <div className="flex-1">
                <CommentWYSIWYG
                  key={artifact.id}
                  autofocus
                  editable
                  className="px-3 py-1 bg-white border rounded-md text-sm"
                  value={form.message}
                  onUpdateContent={handleChangeMessage}
                  uploadVariable={{ artifactId: artifact.id }}
                  mentionsConfig={{
                    artifactId: artifact.id,
                  }}
                  placeholder={`Describe the update...`}
                />
              </div>
            </div>
            <div className="flex items-start gap-4 py-4 px-6">
              <label className="text-gray-500 text-sm w-24 font-medium mt-1">
                Status
              </label>
              <div className="flex-1 flex items-center gap-2">
                <button
                  type="button"
                  className={classNames(
                    statusClassName,
                    form.goalStatus === GoalStatus.OnTrack &&
                      "bg-emerald-200 border-emerald-200 hover:bg-emerald-200 text-emerald-800"
                  )}
                  aria-label={
                    form.goalStatus === GoalStatus.OnTrack
                      ? "Active goal status"
                      : ""
                  }
                  onClick={handleChangeStatus(GoalStatus.OnTrack)}
                >
                  <GoalStatusPill
                    status={GoalStatus.OnTrack}
                    state={GoalState.Open}
                  />
                  On track
                </button>
                <button
                  type="button"
                  className={classNames(
                    statusClassName,
                    form.goalStatus === GoalStatus.AtRisk &&
                      "bg-amber-200 border-amber-200 hover:bg-amber-200 text-amber-900"
                  )}
                  aria-label={
                    form.goalStatus === GoalStatus.AtRisk
                      ? "Active goal status"
                      : ""
                  }
                  onClick={handleChangeStatus(GoalStatus.AtRisk)}
                >
                  <GoalStatusPill
                    status={GoalStatus.AtRisk}
                    state={GoalState.Open}
                  />
                  At risk
                </button>
                <button
                  type="button"
                  className={classNames(
                    statusClassName,
                    form.goalStatus === GoalStatus.OffTrack &&
                      "bg-rose-200 border-rose-200 hover:bg-rose-200 text-rose-800"
                  )}
                  aria-label={
                    form.goalStatus === GoalStatus.OffTrack
                      ? "Active goal status"
                      : ""
                  }
                  onClick={handleChangeStatus(GoalStatus.OffTrack)}
                >
                  <GoalStatusPill
                    status={GoalStatus.OffTrack}
                    state={GoalState.Open}
                  />
                  Off track
                </button>
              </div>
            </div>
            <div className="flex items-start gap-4 py-4 px-6">
              <label className="text-gray-500 text-sm w-24 font-medium mt-1">
                Progress
              </label>
              <div className="flex-1 flex flex-col gap-2">
                <div>
                  <CurrentValueInput
                    disabled={
                      artifact.progressType === GoalProgressType.AlignedAverage
                    }
                    currentValue={form.currentValue}
                    goalOrKeyresult={artifact}
                    onChange={handleChangeCurrentValue}
                  />
                </div>
                {artifact.progressType === GoalProgressType.AlignedAverage && (
                  <div className="text-xs tracking-tight text-gray-400">
                    Calculated by averaging the progress of the{" "}
                    {label("key result", { pluralize: true })} and the aligned{" "}
                    {label("goal", { pluralize: true })}.
                  </div>
                )}
              </div>
            </div>
            {form.keyResults.length > 0 && (
              <div className="flex items-start gap-4 py-4 px-6">
                <div className="w-24 mt-1">
                  <label className="block text-gray-500 text-sm w-24 font-medium">
                    {label("key result", { pluralize: true, capitalize: true })}
                  </label>
                  <div className="mt-1 text-xs tracking-tighter text-gray-500">
                    <Tooltip
                      text={`${label("key result", {
                        pluralize: true,
                        capitalize: true,
                      })} average: ${numeral(averageKRProgress).format(
                        "0[.]00%"
                      )}`}
                    >
                      <span>
                        Avg: {numeral(averageKRProgress).format("0[.]00%")}
                      </span>
                    </Tooltip>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="flex flex-col divide-y divide-gray-100">
                    {form.keyResults.map((keyResult: any, i: number) => (
                      <div
                        className={classNames(
                          "flex gap-2 min-w-0",
                          i > 0 && "pt-2",
                          i < form.keyResults.length && "pb-2"
                        )}
                        aria-label="Checkin dialog key result item"
                        key={keyResult.id}
                      >
                        <div>
                          <CurrentValueInput
                            currentValue={keyResult.currentValue}
                            goalOrKeyresult={keyResult}
                            onChange={handleChangeKeyResultCurrentValue}
                          />
                        </div>
                        <div className="pt-1">
                          <Avatar user={keyResult.assignee} size="5" />
                        </div>
                        <div className="flex-1 flex items-center">
                          <div className="[overflow-wrap:anywhere]">
                            {keyResult.title}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          {formInvalid && isShowingInvalidForm && (
            <div className="py-4 px-6 flex flex-col gap-2 border-t text-red-700 empty:hidden">
              {formInvalid}
            </div>
          )}
          <div className="py-4 px-6 flex flex-col gap-2 border-t empty:hidden">
            <div className="grid grid-cols-2 gap-4">
              <Button
                size={ButtonSize.large}
                disabled={loadingQuery || loadingMutation}
                type="submit"
                theme="primary"
                className="w-full"
              >
                {loadingMutation ? "Updating" : "Update"} progress
                {loadingMutation && <Loading mini size="4" color="white" />}
              </Button>
              <Button
                size={ButtonSize.large}
                disabled={loadingMutation}
                onClick={handleClose}
                type="button"
              >
                Cancel
              </Button>
            </div>
            {error && (
              <div className="mt-4">
                <GraphqlError
                  whatDidNotWork="The artifact could not be created."
                  error={error}
                />
              </div>
            )}
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default CheckinDialog;
