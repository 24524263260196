import { EyeOffIcon } from "@heroicons/react/outline";
import {
  AssessmentQuestionResponseVisibility,
  GoalState,
  GoalVisibility,
} from "types/graphql-schema";

import ArtifactDueDate from "@apps/artifact/components/artifact-due-date";
import DraftLabel from "@components/draft-label/draft-label";
import GoalIcon from "@components/goal-icon/goal-icon";
import GoalProgressPill from "@components/goal-progress-pill/goal-progress-pill";
import GoalVisibilityIcon from "@components/goal-visibility-icon/goal-visibility-icon";
import AppLink from "@components/link/link";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";
import { assertEdgesNonNull, getUrl } from "@helpers/helpers";

import { AssessmentQuestion } from "./types";

const AssessmentQuestionHeading = ({
  index,
  question,
}: {
  index: number;
  question: AssessmentQuestion;
}) => {
  let questionTitle = null;
  let questionDescription = null;
  if (question.__typename === "ResponsibilityAssessmentQuestionNode") {
    questionTitle = (
      <div className="flex">
        <div className="font-bold mt-0.5">{index + 1}.</div>
        <div className="ml-2">
          <TextareaWysiwyg
            editable={false}
            value={question.responsibility?.content}
          />
        </div>
      </div>
    );
  } else if (question.__typename === "CompetencyAssessmentQuestionNode") {
    questionTitle = (
      <div className="font-bold">
        {index + 1}. {question.title}
      </div>
    );
    questionDescription = (
      <>
        <TextareaWysiwyg
          className="max-w-max text-gray-400"
          value={question.description}
        />
        {assertEdgesNonNull(question.competency?.criteria).map(
          (criteria, index) => (
            <div key={index} className="mt-2">
              <TextareaWysiwyg editable={false} value={criteria.content} />
            </div>
          )
        )}
      </>
    );
  } else if (question.__typename === "IndividualGoalAssessmentQuestionNode") {
    if (!question.goal) {
      return null;
    }
    questionTitle = (
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-1">
          <GoalIcon
            scope={question.goal.scope}
            size={5}
            state={question.goal.state}
          />
          <AppLink
            to={getUrl({
              artifactId: question.goal.id,
              artifactType: question.goal.artifactType,
            })}
            target="_blank"
            className="font-medium hover:underline"
          >
            {question.goal.title}{" "}
            {question.goal.goalVisibility === GoalVisibility.Private && (
              <GoalVisibilityIcon
                className="inline"
                goalVisibility={question.goal.goalVisibility}
                size="4"
              />
            )}{" "}
            {question.goal.state === GoalState.Draft && <DraftLabel />}
          </AppLink>
        </div>
        <div className="flex items-center gap-2">
          <div className="text-xs text-gray-500">
            <ArtifactDueDate
              labelName="Due date"
              artifact={question.goal}
              canEdit={false}
            />
          </div>
          <GoalProgressPill
            progress={question.goal.progress}
            status={question.goal.status}
            state={question.goal.state}
            isStale={question.goal.isStale}
          />
        </div>
      </div>
    );
  } else if (question.__typename === "OverallGoalAssessmentQuestionNode") {
    return null;
  } else {
    questionTitle = (
      <div className="font-bold">
        {index + 1}. {question.title}
      </div>
    );
    if (!isEmptyValue(question.description)) {
      questionDescription = (
        <TextareaWysiwyg
          className="max-w-max text-gray-400"
          value={question.description}
        />
      );
    }
  }

  return (
    <>
      {questionTitle}
      {question.responseVisibility ===
        AssessmentQuestionResponseVisibility.HiddenFromSubject && (
        <div className="text-xs text-gray-500 flex items-center">
          <EyeOffIcon className="text-gray-400 h-4 w-4 mr-1 shrink-0" />
          <div className="flex-1">The subject does not see this response</div>
        </div>
      )}
      {questionDescription}
    </>
  );
};

export default AssessmentQuestionHeading;
