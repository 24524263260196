import { withErrorBoundary } from "@sentry/react";
import moment from "moment";
import {
  ArtifactType,
  GetMeetingTranscriptQueryQuery,
} from "types/graphql-schema";

import Artifact from "@apps/artifact/artifact";
import NoTranscriptCta from "@apps/meeting-new/components/no-transcript-cta";
import TranscriptionParticipation from "@apps/meeting-transcription-dialog/participation";
import TranscriptionText from "@apps/meeting-transcription-dialog/transcription-text";
import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache, {
  MeetingSummaryLengthButtonToggle,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import BillingDialogCTA from "@components/billing-dialog-cta/billing-dialog-cta";
import Button from "@components/button/button";
import Error from "@components/error/error";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import {
  ToggleButtonGroup,
  ToggleButtonGroupTheme,
  ToggleButtonGroupType,
} from "@components/toggle-button-group/toggle-button-group";
import {
  assertEdgesNonNull,
  assertEdgesNonNullWithStringId,
  getUrl,
} from "@helpers/helpers";

import PrepGroup from "./prep-group";
import MeetingSummaryGroupTranscriptionSuggestedArtifact from "./summary-group-transcription-suggested-artifact";

const MeetingSummaryGroupTranscription = ({
  meeting,
  meetingGroupId,
  refetch,
  loading,
}: {
  meeting: NonNullable<GetMeetingTranscriptQueryQuery["meeting"]>;
  meetingGroupId: number;
  refetch: () => void;
  loading: boolean;
}) => {
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const label = useLabel();
  const summary = meeting.transcript?.summary || meeting.notesSummary;
  const orderedTranscriptData = meeting.transcript?.orderedTranscriptData;
  const highlightList = meeting.transcript?.highlightList
    ? assertEdgesNonNullWithStringId(meeting.transcript.highlightList)
    : [];

  const suggestedArtifacts = meeting.transcript?.suggestedArtifacts
    ? assertEdgesNonNull(meeting.transcript.suggestedArtifacts)
    : [];
  const suggestedActionItems = suggestedArtifacts
    .filter((node) => node.artifactType === ArtifactType.ActionItem)
    .filter(({ hidden }: { hidden: boolean }) => !hidden);
  const suggestedRecognitions = suggestedArtifacts
    .filter((node) => node.artifactType === ArtifactType.Recognition)
    .filter(({ hidden }: { hidden: boolean }) => !hidden);
  const suggestedFeedback = suggestedArtifacts
    .filter((node) => node.artifactType === ArtifactType.Feedback)
    .filter(({ hidden }: { hidden: boolean }) => !hidden);
  const createdArtifacts = assertEdgesNonNull(meeting.artifacts).filter(
    (node) => node.id
  );

  const handleChangeSummaryLength = (
    option: ToggleButtonGroupType<MeetingSummaryLengthButtonToggle>
  ) => {
    saveUiPreference({ meetingSummaryLength: option.value });
  };

  if (!meeting.organization)
    return (
      <PrepGroup title="Summary">
        <div className="mt-1 fs-mask text-gray-500">
          The meeting is not associated to an existing organization. The notes
          and/or transcript cannot be summarized.
        </div>
      </PrepGroup>
    );

  return (
    <>
      {summary ? ( // has summary
        <PrepGroup
          title={
            <div className="flex items-center gap-2 mr-2">
              Summary
              {(meeting.transcript?.summary ||
                meeting.transcript?.mediumSummary ||
                meeting.transcript?.longSummary) && (
                <ToggleButtonGroup<MeetingSummaryLengthButtonToggle>
                  theme={ToggleButtonGroupTheme.roundedCompact}
                  buttons={[
                    {
                      active:
                        uiPreferenceCache.meetingSummaryLength ===
                        MeetingSummaryLengthButtonToggle.short,
                      title: MeetingSummaryLengthButtonToggle.short,
                      value: MeetingSummaryLengthButtonToggle.short,
                    },
                    {
                      active:
                        uiPreferenceCache.meetingSummaryLength ===
                        MeetingSummaryLengthButtonToggle.medium,
                      title: MeetingSummaryLengthButtonToggle.medium,
                      value: MeetingSummaryLengthButtonToggle.medium,
                    },
                    {
                      active:
                        uiPreferenceCache.meetingSummaryLength ===
                        MeetingSummaryLengthButtonToggle.long,
                      title: MeetingSummaryLengthButtonToggle.long,
                      value: MeetingSummaryLengthButtonToggle.long,
                    },
                  ]}
                  onClick={handleChangeSummaryLength}
                />
              )}
            </div>
          }
        >
          <div className="mt-2 fs-mask text-gray-500">
            {meeting.transcript?.summary ||
            meeting.transcript?.mediumSummary ||
            meeting.transcript?.longSummary ? (
              <div
                className="text-gray-500"
                dangerouslySetInnerHTML={{
                  __html:
                    (uiPreferenceCache.meetingSummaryLength ===
                    MeetingSummaryLengthButtonToggle.short
                      ? meeting.transcript?.summary
                      : uiPreferenceCache.meetingSummaryLength ===
                        MeetingSummaryLengthButtonToggle.medium
                      ? meeting.transcript?.mediumSummary
                      : meeting.transcript?.longSummary) || "No summary",
                }}
              />
            ) : (
              <div
                className="text-gray-500"
                dangerouslySetInnerHTML={{ __html: summary }}
              />
            )}
          </div>
        </PrepGroup>
      ) : meeting.organization.effectivePricingTier !== "ultimate" ? ( // not on a plan that enables summarization
        <PrepGroup title="Summary">
          <div className="mt-1 fs-mask text-gray-500">
            <BillingDialogCTA className="text-blue-link underline">
              <span>Upgrade your plan</span>
            </BillingDialogCTA>{" "}
            to have your meetings automatically summarized
          </div>
        </PrepGroup>
      ) : !meeting.organization.enableMeetingSummarization ? ( // does not have summarization enabled
        <PrepGroup title="Summary">
          <div className="mt-1 fs-mask text-gray-500">
            Enable AI Meeting Summarization in{" "}
            <AppLink
              to={`/settings/organization/${meeting.organization.id}/settings`}
              className="text-blue-600"
            >
              Organization Settings
            </AppLink>{" "}
            to have your meetings automatically summarized
          </div>
        </PrepGroup>
      ) : moment().isBefore(meeting.endDatetime) ? ( // is before meeting
        <PrepGroup title="Summary">
          <div className="mt-1 fs-mask text-gray-500">
            The summary will be generated after the meeting has ended.
          </div>
        </PrepGroup>
      ) : (
        <PrepGroup title="Summary">
          <div className="mt-1 fs-mask text-gray-500">
            No summary has been generated for this meeting.
          </div>
        </PrepGroup>
      )}

      <PrepGroup title="Highlights">
        <div className="mt-1 text-gray-500 whitespace-pre-line fs-mask">
          {highlightList.length > 0 ? (
            <ul className="list-disc ml-4 mt-2">
              {highlightList.map((node) => (
                <li key={node.id}>{node.highlight}</li>
              ))}
            </ul>
          ) : (
            <span>No highlights.</span>
          )}
        </div>
      </PrepGroup>

      {createdArtifacts.length > 0 && (
        <PrepGroup title="Created in meeting">
          <div className="mt-2 text-gray-500">
            <div className="">
              {createdArtifacts.map((artifact) => (
                <div
                  className="px-2 py-1.5 border rounded-lg"
                  key={artifact.id}
                >
                  <Artifact
                    urlPrefix={getUrl({
                      meetingGroupId: meetingGroupId,
                      meetingId: meeting.id,
                    })}
                    artifact={artifact}
                  />
                </div>
              ))}
            </div>
          </div>
        </PrepGroup>
      )}

      <PrepGroup title="Suggestions">
        <>
          {suggestedActionItems.length > 0 && (
            <div>
              <div className="font-medium mt-4">
                {label("action item", {
                  pluralize: true,
                  capitalize: true,
                })}
              </div>
              <div className="mt-2 text-gray-500 flex flex-col w-full gap-1.5">
                {suggestedActionItems.map((suggestedArtifact) => (
                  <MeetingSummaryGroupTranscriptionSuggestedArtifact
                    key={suggestedArtifact.id}
                    meeting={meeting}
                    suggestedArtifact={suggestedArtifact}
                  />
                ))}
              </div>
            </div>
          )}
          {suggestedRecognitions.length > 0 && (
            <div>
              <div className="font-medium mt-4">
                {label("recognition", {
                  pluralize: true,
                  capitalize: true,
                })}
              </div>
              <div className="mt-2 text-gray-500 flex flex-col w-full gap-1.5">
                {suggestedRecognitions.map((suggestedArtifact) => (
                  <MeetingSummaryGroupTranscriptionSuggestedArtifact
                    key={suggestedArtifact.id}
                    meeting={meeting}
                    suggestedArtifact={suggestedArtifact}
                  />
                ))}
              </div>
            </div>
          )}
          {suggestedFeedback.length > 0 && (
            <div>
              <div className="font-medium mt-4">
                {label("feedback", {
                  pluralize: true,
                  capitalize: true,
                })}
              </div>
              <div className="mt-2 text-gray-500 flex flex-col w-full gap-1.5">
                {suggestedFeedback.map((suggestedArtifact) => (
                  <MeetingSummaryGroupTranscriptionSuggestedArtifact
                    key={suggestedArtifact.id}
                    meeting={meeting}
                    suggestedArtifact={suggestedArtifact}
                  />
                ))}
              </div>
            </div>
          )}

          {suggestedActionItems.length +
            suggestedRecognitions.length +
            suggestedFeedback.length ===
            0 && <div className="mt-1 text-gray-500">No suggestions.</div>}
        </>
      </PrepGroup>

      {meeting.hasTranscript &&
      orderedTranscriptData &&
      orderedTranscriptData.length > 0 ? (
        <PrepGroup
          title="Transcript"
          collapsible={true}
          isCollapsedByDefault={true}
        >
          <div className="mt-1.5">
            <TranscriptionParticipation meeting={meeting} />
          </div>
          <div className="mt-4 fs-mask">
            <TranscriptionText meeting={meeting} />
          </div>
          <div className="mt-4 flex justify-center gap-2">
            <Button
              onClick={() => refetch()}
              disabled={loading}
              text="Refresh"
            />
            <Loading
              mini
              size={5}
              className={loading ? "opacity-100" : "opacity-0"}
            />
          </div>
        </PrepGroup>
      ) : (
        <PrepGroup title="Transcription">
          <div className="mt-1 fs-mask text-gray-500">
            <NoTranscriptCta
              videoConferenceType={meeting.videoConferenceType}
            />
          </div>
        </PrepGroup>
      )}
    </>
  );
};

export default withErrorBoundary(MeetingSummaryGroupTranscription, {
  fallback: (
    <Error description={"The meeting summary could not be rendered."} />
  ),
});
