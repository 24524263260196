import { Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { debounce, findLast } from "lodash";
import { useCallback, useState } from "react";
import {
  MeetingViewMeetingNodeNewPageFragmentFragment,
  TopicNodeNewPageFragmentFragment,
} from "types/graphql-schema";

import MeetingViewSidebar from "@apps/meeting-new/components/meeting-sidebar/meeting-view-sidebar";
import Topic from "@apps/meeting-new/components/topic";
import { MeetingWebsocketProviderContext } from "@apps/meeting-new/context";
import useMeetingWebsocketProvider from "@apps/meeting-new/use-meeting-websocket-provider";
import Button, { buttonTheme } from "@components/button/button";
import Layout from "@components/layout/layout";
import { assertEdgesNonNull } from "@helpers/helpers";

export const meetingDialogInputClassName =
  "hover:bg-gray-100 rounded text-sm px-2 py-1.5 focus:outline-0 focus:ring-0";

const MeetingPresentationDialogTopic = ({
  meeting,
  topic,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  topic: TopicNodeNewPageFragmentFragment;
}) => {
  return (
    <div
      id={`meeting-presentation-topic-${topic.id}`}
      key={topic.id}
      className="pt-6 pb-36"
    >
      <Topic meeting={meeting} topic={topic} isInPresentation />
    </div>
  );
};

const MeetingPresentationDialog = ({
  onClose,
  meeting,
}: {
  onClose: () => void;
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
}) => {
  const topics = assertEdgesNonNull(meeting.topics);
  const topicIds = topics.map(({ id }) => id);
  const [currentTopicId, setCurrentTopicId] = useState(topics[0].id);

  const handleClickTopic = ({ id }: { id: number }) => {
    const el = document.getElementById(`meeting-presentation-topic-${id}`);
    if (el) {
      setCurrentTopicId(id);
      el.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScroll = debounce(() => {
    const topicScrollTop = topicIds.map((topicId) => ({
      topicId,
      scrollTop: document
        .getElementById(`meeting-presentation-topic-${topicId}`)
        ?.getBoundingClientRect()?.top,
    }));
    const lastVisibleTopic = findLast(topicScrollTop, (topic) =>
      topic.scrollTop ? topic?.scrollTop < 200 : false
    );
    setCurrentTopicId(lastVisibleTopic?.topicId || topicIds[0]);
  }, 1000);

  const debouncedHandleScroll = useCallback(() => {
    handleScroll();
  }, [topicIds]);

  const meetingWebsocketContext = useMeetingWebsocketProvider(meeting);

  return (
    <Dialog
      as="div"
      aria-label="Meeting presentation dialog"
      className="fixed z-presentation inset-0 bg-white"
      open
      onClose={() => {}}
    >
      <Layout>
        <Layout.Header
          sidebarExpandedUiPreferenceKey="meetingSidebarExpanded"
          title={meeting.title}
        >
          <Button theme={buttonTheme.iconGray} onClick={onClose} icon={XIcon} />
        </Layout.Header>
        <Layout.Container className="flex-col">
          <Layout.Main
            sidebarExpandedUiPreferenceKey="meetingSidebarExpanded"
            onScroll={debouncedHandleScroll}
          >
            {topics.length > 0 ? (
              <ul className="min-h-0 px-4 sm:px-6 @2xl/layout-main:px-6 @5xl/layout-main:px-0 overflow-y-auto list-none divide-y">
                <MeetingWebsocketProviderContext.Provider
                  value={meetingWebsocketContext}
                >
                  {topics.map((topic) => (
                    <MeetingPresentationDialogTopic
                      topic={topic}
                      meeting={meeting}
                      key={topic.id}
                    />
                  ))}
                </MeetingWebsocketProviderContext.Provider>
              </ul>
            ) : (
              <div>No topics.</div>
            )}
          </Layout.Main>

          <MeetingViewSidebar
            meeting={meeting}
            isInFullScreen
            selectedTopicId={currentTopicId}
            onClickTopic={handleClickTopic}
          />
        </Layout.Container>
      </Layout>
    </Dialog>
  );
};

export default MeetingPresentationDialog;
