import { isInteger } from "lodash";
import { useEffect, useState } from "react";
import { AssessmentQuestionType } from "types/graphql-schema";

import { isEmptyValue } from "@components/wysiwyg/helpers";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";
import { assertNonNull } from "@helpers/helpers";

import AssessmentQuestionMultiSelectOptions from "../assessment-question-multi-select-options";
import AssessmentQuestionChrome from "./assessment-question-chrome";
import AssessmentQuestionHeading from "./assessment-question-heading";
import IndividualGoalAssessmentQuestionContent from "./individual-goal-assessment-question-content";
import OverallGoalAssessmentQuestionContent from "./overall-goal-assessment-question-content";
import RangeAssessmentQuestionContent from "./range-assessment-question-content";
import { AssessmentAnswer, AssessmentQuestion } from "./types";

const AssessmentQuestionItem = ({
  answer,
  index,
  formDisabled,
  question,
  isQuestionWeightingEnabled,
  weight,
  showError,
  totalQuestionWeight,
  onUpdateAnswer,
}: {
  isQuestionWeightingEnabled: boolean;
  answer?: AssessmentAnswer;
  index: number;
  formDisabled: boolean;
  question: AssessmentQuestion;
  weight: number;
  showError: boolean;
  totalQuestionWeight: number;
  onUpdateAnswer: (answer: AssessmentAnswer) => void;
}) => {
  const comment = answer?.comment ?? "{}";
  const textAnswer = answer?.textAnswer ?? "{}";
  const choices = answer?.choices;

  // By default answers are not saved and don't have an id,
  // so when rendered them for the first time, the id & content is undefined.
  // Once the user types text, we save it to the DB.
  // When editing a draft answer, it initially comes as undefined then once we have the api response with answers
  // it passes the id and text content therefore we need to update the wysiwyg key or deps to re-render the component.
  // Here we optimize so we re-render the wysiwyg only when user is not focused on it to prevent losing focus while typing.
  const [isFocused, setIsFocused] = useState(false);
  const answerDependencyId = answer?.questionId ? answer?.questionId : 0;
  const [dependencyId, setDependencyId] = useState(answerDependencyId);
  useEffect(() => {
    if (!isFocused) {
      // don't update the dependency if user is focused on field.
      setDependencyId(answerDependencyId);
    }
  }, [isFocused, answerDependencyId]);

  const questionAnswered =
    (((question.questionType === AssessmentQuestionType.Range ||
      question.questionType === AssessmentQuestionType.OverallGoal ||
      question.questionType === AssessmentQuestionType.IndividualGoal) &&
      isInteger(answer?.integerAnswer)) ||
      (question.questionType === AssessmentQuestionType.Text &&
        !isEmptyValue(textAnswer)) ||
      (question.questionType === AssessmentQuestionType.Multichoice &&
        choices &&
        choices.length > 0)) &&
    (!question.isCommentMandatory || !isEmptyValue(comment));

  const weightedScore = answer &&
    question.questionType === AssessmentQuestionType.Range && (
      <span>
        Weighted score:{" "}
        {(
          (weight * assertNonNull(answer.integerAnswer)) /
          totalQuestionWeight
        ).toFixed(1)}
      </span>
    );

  let questionContent = null;

  if (
    question.__typename === "CompetencyAssessmentQuestionNode" ||
    question.__typename === "CompetencyCriteriaAssessmentQuestionNode" ||
    question.__typename === "ResponsibilityAssessmentQuestionNode"
  ) {
    throw new Error("Not implemented");
  }

  if (question.__typename === "RangeAssessmentQuestionNode") {
    questionContent = (
      <div className="mt-4">
        <RangeAssessmentQuestionContent
          disabled={formDisabled}
          answer={answer}
          isQuestionWeightingEnabled={isQuestionWeightingEnabled}
          weightedScore={weightedScore}
          question={question}
          onChangeAnswer={(newAnswer) => {
            onUpdateAnswer({
              questionId: question.id,
              ...answer,
              ...newAnswer,
              textAnswer: newAnswer.textAnswer ?? answer?.textAnswer ?? "{}",
              comment: newAnswer.comment ?? answer?.comment ?? "{}",
            });
          }}
        />
      </div>
    );
  } else if (question.__typename === "OverallGoalAssessmentQuestionNode") {
    questionContent = (
      <div className="">
        <OverallGoalAssessmentQuestionContent
          disabled={formDisabled}
          answer={answer}
          isQuestionWeightingEnabled={isQuestionWeightingEnabled}
          weightedScore={weightedScore}
          question={question}
          onChangeAnswer={(newAnswer) => {
            onUpdateAnswer({
              questionId: question.id,
              ...answer,
              ...newAnswer,
              textAnswer: newAnswer.textAnswer ?? answer?.textAnswer ?? "{}",
              comment: newAnswer.comment ?? answer?.comment ?? "{}",
            });
          }}
        />
      </div>
    );
  } else if (question.__typename === "IndividualGoalAssessmentQuestionNode") {
    questionContent = (
      <div className="mt-4">
        <IndividualGoalAssessmentQuestionContent
          disabled={formDisabled}
          answer={answer}
          isQuestionWeightingEnabled={isQuestionWeightingEnabled}
          weightedScore={weightedScore}
          question={question}
          onChangeAnswer={(newAnswer) => {
            onUpdateAnswer({
              questionId: question.id,
              ...answer,
              ...newAnswer,
              textAnswer: newAnswer.textAnswer ?? answer?.textAnswer ?? "{}",
              comment: newAnswer.comment ?? answer?.comment ?? "{}",
            });
          }}
        />
      </div>
    );
  } else if (question.__typename === "TextAssessmentQuestionNode") {
    questionContent = (
      <TextareaWysiwyg
        editable={!formDisabled}
        className="mt-4 bg-white"
        value={textAnswer}
        onChangeValue={(text) =>
          onUpdateAnswer({
            questionId: question.id,
            integerAnswer: null,
            textAnswer: text,
            choices,
            comment: null,
          })
        }
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
        deps={[dependencyId]}
      />
    );
  } else if (question.__typename === "MultiChoiceAssessmentQuestionNode") {
    questionContent = (
      <>
        <div className="mt-4">
          <AssessmentQuestionMultiSelectOptions
            choices={answer?.choices}
            disabled={formDisabled}
            options={question.options}
            optionDescriptions={question.optionDescriptions}
            onClickAnswer={(newChoices) =>
              onUpdateAnswer({
                questionId: question.id,
                integerAnswer: null,
                textAnswer,
                choices: newChoices,
                comment,
              })
            }
          />
        </div>
        {answer && (!formDisabled || !isEmptyValue(answer.comment)) && (
          <div className="mt-2 flex flex-col gap-2">
            <div className="flex items-center justify-between">
              <div className="text-gray-500 text-xs uppercase font-semibold">
                Comment {question.isCommentMandatory ? "*" : ""}
              </div>
            </div>
            <TextareaWysiwyg
              editable={!formDisabled}
              className="mt-1 bg-white"
              value={comment}
              onChangeValue={(comment) =>
                onUpdateAnswer({
                  questionId: question.id,
                  integerAnswer: null,
                  textAnswer,
                  choices,
                  comment,
                })
              }
            />
          </div>
        )}
      </>
    );
  }

  return (
    <AssessmentQuestionChrome
      key={question.id}
      isQuestionAnswered={!!questionAnswered}
      isShowingError={showError}
      aria-label={`Assessment question: ${question.title}`}
    >
      <AssessmentQuestionHeading index={index} question={question} />

      {questionContent}
    </AssessmentQuestionChrome>
  );
};

export default AssessmentQuestionItem;
