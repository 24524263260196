import { useMemo } from "react";

import { classNames } from "@helpers/css";

import Tooltip from "./tooltip/tooltip";

const Checkbox = ({
  tooltip,
  disabled,
  onChange,
  checked,
  label,
}: {
  label: string;
  tooltip?: string;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
  checked: boolean;
}) => {
  const content = useMemo(() => {
    return (
      <div
        className={classNames(
          "flex items-center gap-2",
          !disabled && "cursor-pointer"
        )}
        onClick={() => {
          if (disabled) {
            return;
          }
          onChange(!checked);
        }}
      >
        <input
          className={classNames(!disabled && "cursor-pointer")}
          disabled={disabled}
          type="checkbox"
          checked={checked}
        />
        <div>{label}</div>
      </div>
    );
  }, [checked, disabled, label, onChange]);

  if (tooltip) {
    return <Tooltip text={tooltip}>{content}</Tooltip>;
  }
  return content;
};

export default Checkbox;
