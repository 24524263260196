import { gql } from "@apollo/client";

export default gql`
  mutation addTeamMemberMutation($teamId: Int!, $userId: Int!) {
    addTeamMember(teamId: $teamId, userId: $userId) {
      user {
        id
        teams {
          edges {
            node {
              id
              title
            }
          }
        }
      }
    }
  }
`;
