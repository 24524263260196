import { gql } from "@apollo/client";

export default gql`
  fragment OrganizationMember on UserMemberNode {
    id
    user {
      id
      name
      avatar
      email
      status
      lastSeenStr
      managers {
        edges {
          node {
            id
            name
            avatar
          }
        }
      }
      teams {
        edges {
          node {
            id
            title
          }
        }
      }
    }
    position
    role
    startDate
    employeeId
    jobLevel
    membershipStatus
  }
  query getOrganizationMembersQuery(
    $organizationId: Int!
    $after: String
    $managerId: Int
    $showAdminsOnly: Boolean
    $search: String = ""
    $teamId: Int
    $orderBy: String
    $membershipStatus: AccountToOrganizationMembershipStatus
  ) {
    organization(pk: $organizationId) {
      id
      members(
        first: 20
        after: $after
        search: $search
        orderBy: $orderBy
        isAdmin: $showAdminsOnly
        membershipStatus: $membershipStatus
        teamId: $teamId
        managerId: $managerId
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...OrganizationMember
          }
        }
      }
    }
  }
`;
