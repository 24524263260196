import { classNames } from "@helpers/css";

const tabClassName =
  "text-gray-400 font-medium py-2 text-sm -mb-px border-b-2 border-transparent";

const Tabs = ({
  options,
  selectedValue,
  onClickOptionValue,
  className = "",
}: {
  options: {
    value: string;
    label: string;
  }[];
  selectedValue: string;
  onClickOptionValue: (value: string) => void;
  className?: string;
}) => {
  return (
    <div className={classNames("flex items-center gap-8 border-b", className)}>
      {options.map(({ value, label }) => (
        <button
          key={value}
          className={classNames(
            tabClassName,
            value === selectedValue
              ? "border-blue-link text-blue-link hover:text-blue-link"
              : " hover:text-gray-700 hover:border-gray-200"
          )}
          onClick={() => onClickOptionValue(value)}
        >
          {label}
        </button>
      ))}
      <div className={classNames(tabClassName, "flex-1")}>&nbsp;</div>
    </div>
  );
};

export default Tabs;
