import { Popover } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { uniqBy } from "lodash";
import { useState } from "react";
import { BasicUser } from "types/topicflow";

import Avatar from "@components/avatar/avatar";
import Button, { ButtonSize, buttonTheme } from "@components/button/button";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
} from "@components/user-combobox/user-combobox-list";
import { classNames } from "@helpers/css";

const GoalOwnerContributorDropdown = ({
  placeholder,
  users,
  onChangeUsers,
  isSettingMultipleUsers = false,
  excludeUserIds,
  mustHaveOneUserMinimum = false,
}: {
  excludeUserIds: number[];
  users: BasicUser[];
  onChangeUsers: (users: BasicUser[]) => void;
  isSettingMultipleUsers: boolean;
  placeholder: string;
  mustHaveOneUserMinimum?: boolean;
}) => {
  const showName = true;

  const [canLoadData, setCanLoadData] = useState(false);
  const selectedUser = users[0];
  const { loading, options, query, setQuery } = useUserComboboxQuery({
    queryOptions: {
      skip: !canLoadData,
    },
    selected: selectedUser
      ? { type: UserComboboxOptionType.USER, ...selectedUser }
      : undefined,
    excludeUserIds,
  });

  const handleAddOwner = (option: UserComboboxOption) => {
    if (option.type === UserComboboxOptionType.USER) {
      onChangeUsers(uniqBy([...users, option], "id"));
    }
  };

  const handleRemoveOwner = (ownerToRemove: BasicUser) => {
    onChangeUsers(users.filter((user) => user.id !== ownerToRemove.id));
  };

  const handlePreloadData = () => {
    setCanLoadData(true);
  };

  return (
    <>
      {isSettingMultipleUsers
        ? users.map((user) => (
            <div key={user.id} className="flex items-center gap-1.5">
              <Avatar user={user} size={5} />
              {user.name}
              {(!mustHaveOneUserMinimum || users.length > 1) && (
                <Button
                  theme={buttonTheme.iconGray}
                  icon={XIcon}
                  size={ButtonSize.small}
                  onClick={() => handleRemoveOwner(user)}
                />
              )}
            </div>
          ))
        : null}
      <UserCombobox
        aria-label="Goal user dropdown"
        loading={loading}
        width="full"
        query={query}
        value={
          !isSettingMultipleUsers && users[0]
            ? { type: UserComboboxOptionType.USER, ...users[0] }
            : null
        }
        options={options}
        onChangeValue={handleAddOwner}
        onChangeQuery={setQuery}
        onClickButton={handlePreloadData}
        placeholder={placeholder}
        className="px-2 py-0.5"
      >
        {isSettingMultipleUsers
          ? undefined
          : ({ value, setReferenceElement, onClickButton }) => (
              <div className={"flex justify-between bg-white rounded-md"}>
                <Popover.Button
                  className="flex items-center text-sm"
                  onClick={onClickButton}
                  ref={setReferenceElement}
                >
                  {value && value.type === "user" && (
                    <Avatar
                      user={value}
                      tooltipPrefix="Assigned to "
                      size="5"
                      className={classNames(showName && "mr-1")}
                      unassignedTooltipText="Unassigned"
                    />
                  )}
                  {value && value.type === "user" ? value.name : placeholder}
                </Popover.Button>
              </div>
            )}
      </UserCombobox>
    </>
  );
};

export default GoalOwnerContributorDropdown;
