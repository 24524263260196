import { TrashIcon } from "@heroicons/react/outline";
import { Editor } from "@tiptap/core";
import { useRef } from "react";

import Button, { ButtonSize, buttonTheme } from "@components/button/button";
import { convertToTiptapParagraph } from "@components/wysiwyg/helpers";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";
import { classNames } from "@helpers/css";

const CriteriaItem = ({
  uuid,
  content,
  disabled,
  onDelete,
  editable,
  textAreaEnabled,
  onChange,
  onPasteMultipleCriteria,
}: {
  key: string | number;
  uuid?: string;
  content?: string | null;
  disabled?: boolean;
  onDelete: () => void;
  textAreaEnabled?: boolean;
  editable?: boolean;
  onChange: (content: string) => void;
  onPasteMultipleCriteria: (
    items: ReadonlyArray<{
      uuid?: string;
      content?: string | null;
    }>
  ) => void;
}) => {
  const editorRef = useRef<Editor | null>(null);
  return (
    <div
      aria-label="Criteria input"
      className="flex items-start gap-4"
      onPaste={(evt) => {
        const pastedData = evt.clipboardData.getData("Text");
        const items = pastedData
          .split("\n")
          .map((text) => text.trim())
          .filter((text) => text !== "");
        if (items.length > 0 && editorRef.current) {
          editorRef.current.chain().focus().insertContent(items[0]).run();
          onPasteMultipleCriteria(
            items.map((text, index) => {
              if (index === 0) {
                return {
                  content: JSON.stringify(editorRef.current?.getJSON()),
                  uuid,
                };
              }
              return {
                content: JSON.stringify(convertToTiptapParagraph(text)),
              };
            })
          );
        }
      }}
    >
      <div
        className={classNames(
          "bg-gray-200 rounded-full h-2 w-2",
          textAreaEnabled && "mt-6",
          !textAreaEnabled && "mt-2.5"
        )}
      ></div>
      <TextareaWysiwyg
        ref={editorRef}
        overridePasteEvent
        editable={textAreaEnabled}
        className="mt-1 w-full bg-white"
        value={content}
        deps={[uuid]}
        onChangeValue={onChange}
      />
      {editable && (
        <Button
          theme={buttonTheme.iconGray}
          icon={TrashIcon}
          size={ButtonSize.small}
          className="mt-3"
          onClick={onDelete}
          disabled={disabled}
        />
      )}
    </div>
  );
};

const CriteriaEditor = ({
  disabled,
  editable,
  criteria,
  onAddCriteria,
  onPasteMultipleCriteria,
  onCriteriaChange,
  onDeleteCriteria,
  addButtonText,
}: {
  onAddCriteria?: () => void;
  onPasteMultipleCriteria: (
    items: ReadonlyArray<{
      uuid?: string;
      content?: string | null;
    }>
  ) => void;
  disabled?: boolean;
  editable?: boolean;
  criteria: ReadonlyArray<{
    uuid?: string;
    content?: string | null;
  }>;
  onDeleteCriteria?: (uuid: string) => void;
  onCriteriaChange?: (uuid: string, content: string) => void;
  addButtonText?: string;
}) => {
  const textAreaEnabled = !disabled && editable && !!onCriteriaChange;

  return (
    <div>
      {criteria.map((criteriaItem, index) => {
        return (
          <CriteriaItem
            key={criteriaItem.uuid ?? index}
            disabled={disabled}
            uuid={criteriaItem.uuid}
            content={criteriaItem.content}
            editable={editable}
            textAreaEnabled={textAreaEnabled}
            onPasteMultipleCriteria={onPasteMultipleCriteria}
            onDelete={() => {
              if (!criteriaItem.uuid || !onDeleteCriteria) {
                return;
              }
              onDeleteCriteria(criteriaItem.uuid);
            }}
            onChange={(content) => {
              if (!criteriaItem.uuid || !onCriteriaChange) {
                return;
              }
              onCriteriaChange(criteriaItem.uuid, content);
            }}
          />
        );
      })}
      {editable && onAddCriteria && (
        <Button
          disabled={disabled}
          theme={buttonTheme.lightBlue}
          className="mt-2 ml-6 text-xs"
          text={addButtonText ?? "Add Criteria"}
          aria-label="Add criteria button"
          onClick={onAddCriteria}
        />
      )}
    </div>
  );
};

export default CriteriaEditor;
