import { gql } from "@apollo/client";

export default gql`
  mutation deleteAssessmentAnswerMutation(
    $assessmentId: Int!
    $questionIds: [Int]!
  ) {
    deleteAssessmentAnswer(
      assessmentId: $assessmentId
      questionIds: $questionIds
    ) {
      ok
    }
  }
`;
