import { Popover, Portal } from "@headlessui/react";
import { CogIcon } from "@heroicons/react/outline";
import { compact, isEqual, uniq } from "lodash";
import {
  Dispatch,
  MouseEvent,
  ReactElement,
  SetStateAction,
  useState,
} from "react";
import { MdOutlineBookmarks } from "react-icons/md";
import { TbCaretDownFilled, TbCaretUpFilled } from "react-icons/tb";
import { usePopper } from "react-popper";
import { ArtifactType, GoalScope, GoalState } from "types/graphql-schema";

import useLabel, { getLabel } from "@apps/use-label/use-label";
import useUiPreferenceCache, {
  getUiPreferenceCache,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import { currentOrganizationVar, currentUserVar } from "@cache/cache";
import Button, { ButtonSize, buttonTheme } from "@components/button/button";
import AppLink from "@components/link/link";
import {
  ToggleButtonGroup,
  ToggleButtonGroupTheme,
  ToggleButtonGroupType,
} from "@components/toggle-button-group/toggle-button-group";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

import {
  ExplorerFilterType,
  explorerUserType,
  getDefaultFilters,
  getExplorerFiltersUrl,
} from "../helpers";
import ExplorerBookmarkIcon from "./bookmark-icon";

export enum BookmarkFilterEnum {
  onlyYou = "onlyYou",
  organization = "organization",
  default = "default",
}

export type ExplorerBookmarkLinks = {
  title: string;
  filters: ExplorerFilterType;
  showInSlashCommand: boolean;
  default: boolean;
}[];

export const getExplorerBookmarks = (
  bookmarkFilter: BookmarkFilterEnum | null = null
) => {
  const label = getLabel();
  const currentUser = currentUserVar();
  const currentOrganization = currentOrganizationVar();
  const uiPreferenceCache = getUiPreferenceCache();
  const defaultFilters = getDefaultFilters();
  const userExplorerBookmarks = assertEdgesNonNull(
    currentUser.explorerBookmarks
  ).map((bookmark) => ({ ...bookmark, default: false }));

  const defaultBookmarks = compact([
    currentOrganization.featureFlags.actionItems && {
      filters: { type: ArtifactType.ActionItem },
      title: `All action items`,
    },
    ...(currentOrganization.featureFlags.actionItems
      ? compact(currentOrganization.actionItemStates).map(
          (actionItemState) => ({
            filters: {
              type: ArtifactType.ActionItem,
              actionItemAssignee: currentUser.id,
              actionItemState: actionItemState.value,
            },
            title: `My "${actionItemState.label}" action items`,
          })
        )
      : []),
    currentOrganization.featureFlags.goals && {
      filters: {
        ...defaultFilters,
        type: ArtifactType.Goal,
      },
      title: `All ${label("goal", { pluralize: true })}`,
    },
    currentOrganization.featureFlags.goals && {
      filters: {
        type: ArtifactType.Goal,
        goalOwners: [currentUser.id],
        goalState: [GoalState.Open],
      },
      title: `My opened ${label("goal", { pluralize: true })}`,
    },
    currentOrganization.featureFlags.goals && {
      filters: {
        type: ArtifactType.Goal,
        goalOwners: [currentUser.id],
        goalState: [GoalState.Draft],
      },
      title: `My draft ${label("goal", { pluralize: true })}`,
    },
    currentOrganization.featureFlags.goals && {
      filters: {
        type: ArtifactType.Goal,
        goalScope: GoalScope.Organization,
        goalState: [GoalState.Open],
      },
      title: `Opened  ${label("organization")} ${label("goal", {
        pluralize: true,
      })}`,
    },
    currentOrganization.featureFlags.decisions && {
      filters: { type: ArtifactType.Decision },
      title: `All decisions`,
    },
    currentOrganization.featureFlags.decisions && {
      filters: { type: ArtifactType.Decision, createdBy: currentUser.id },
      title: `Decisions created by me`,
    },
    currentOrganization.featureFlags.documents && {
      filters: { type: ArtifactType.Document },
      title: `All documents`,
    },
    currentOrganization.featureFlags.documents && {
      filters: { type: ArtifactType.Document, createdBy: currentUser.id },
      title: `Documents created by me`,
    },
    currentOrganization.featureFlags.recognitions && {
      filters: { type: ArtifactType.Recognition },
      title: `All ${label("recognition", {
        pluralize: true,
        capitalize: false,
      })}`,
    },
    currentOrganization.featureFlags.recognitions && {
      filters: {
        type: ArtifactType.Recognition,
        recognitionRecipient: currentUser.id,
      },
      title: `My ${label("recognition", {
        pluralize: true,
        capitalize: false,
      })}`,
    },
    currentOrganization.featureFlags.feedbacks && {
      filters: {
        type: ArtifactType.Feedback,
        feedbackRecipient: currentUser.id,
      },
      title: `${label("feedback", {
        pluralize: true,
        capitalize: true,
      })} I received`,
    },
    {
      filters: { type: explorerUserType },
      title: `All users`,
    },
  ]).map((bookmark) => ({
    ...bookmark,
    filters: JSON.stringify({
      ...defaultFilters,
      ...bookmark.filters,
    }),
    showInSlashCommand: true,
    private: false,
    default: true,
  }));

  const explorerBookmarks = [
    ...userExplorerBookmarks,
    ...defaultBookmarks,
  ].filter(
    (bookmark) =>
      bookmarkFilter === null ||
      (bookmarkFilter === BookmarkFilterEnum.onlyYou &&
        bookmark.private &&
        !bookmark.default) ||
      (bookmarkFilter === BookmarkFilterEnum.default && bookmark.default) ||
      (bookmarkFilter === BookmarkFilterEnum.organization &&
        !bookmark.private &&
        !bookmark.default)
  );

  const bookmarksGroupedByType = explorerBookmarks.reduce(
    (memo, bookmark) => {
      const filters = JSON.parse(bookmark.filters);
      const key = filters.type || "search";
      if (!memo[key]) {
        memo[key] = {
          id: key,
          label: label(key, { capitalize: true }),
          icon: <ExplorerBookmarkIcon type={key} />,
          links: [],
          expanded: !uiPreferenceCache.explorerCollapsedGroupIds.includes(key),
        };
      }
      memo[key].links = [
        ...memo[key].links,
        {
          title: bookmark.title,
          filters,
          showInSlashCommand: true,
          default: false,
        },
      ];
      return memo;
    },
    {} as {
      [key: string]: {
        id: string;
        label: string;
        icon: ReactElement;
        expanded: boolean;
        links: ExplorerBookmarkLinks;
      };
    }
  );
  return Object.values(bookmarksGroupedByType);
};

const ExplorerBookmarkPopover = ({
  filters,
  onClickBookmark,
  children = null,
  className = "",
}: {
  filters?: ExplorerFilterType;
  className?: string;
  children?:
    | null
    | ((props: {
        setReferenceElement: Dispatch<SetStateAction<HTMLElement | null>>;
      }) => JSX.Element);
  onClickBookmark?: (filters: ExplorerFilterType) => void;
}) => {
  const label = useLabel();
  const [bookmarkFilter, setBookmarkFilter] = useState(
    BookmarkFilterEnum.onlyYou
  );
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "right-start",
  });
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();

  const bookmarksByGroup = getExplorerBookmarks(bookmarkFilter);

  const handleClickToggleGroup = (e: MouseEvent<HTMLButtonElement>) => {
    const groupId = e.currentTarget.dataset.groupid;
    const matchingGroup = bookmarksByGroup.find(({ id }) => id === groupId);
    const newValue = matchingGroup?.expanded
      ? uiPreferenceCache.explorerCollapsedGroupIds.concat(groupId)
      : uiPreferenceCache.explorerCollapsedGroupIds.filter(
          (id: any) => id !== groupId
        );
    saveUiPreference({
      explorerCollapsedGroupIds: uniq(compact(newValue)) || [],
    });
  };

  const handleClickBookmarkContext = (
    button: ToggleButtonGroupType<BookmarkFilterEnum>,
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (button.value) {
      setBookmarkFilter(button.value);
    }
  };

  // RENDER
  return (
    <Popover className={classNames("relative", className)}>
      {children ? (
        children({ setReferenceElement })
      ) : (
        <Popover.Button className="text-gray-500 hover:text-gray-800 p-0.5 focus:outline-none focus:ring-0 rounded hover:bg-black/5">
          <MdOutlineBookmarks className="h-4 w-4" />
        </Popover.Button>
      )}
      <Portal>
        <Popover.Panel
          className="absolute left-0 z-dropdown bg-white rounded-lg p-3 border shadow-lg w-84 min-h-48 max-h-96 overflow-y-scroll"
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <div
            aria-label="Explorer bookmarks container"
            className="flex flex-col gap-2 tracking-tight text-sm"
          >
            <div className="flex mb-1 justify-between">
              <ToggleButtonGroup<BookmarkFilterEnum>
                theme={ToggleButtonGroupTheme.roundedCompact}
                onClick={handleClickBookmarkContext}
                buttons={[
                  {
                    active: bookmarkFilter === BookmarkFilterEnum.onlyYou,
                    title: "My bookmarks",
                    value: BookmarkFilterEnum.onlyYou,
                  },
                  {
                    active: bookmarkFilter === BookmarkFilterEnum.organization,
                    title: label("organization", { capitalize: true }),
                    value: BookmarkFilterEnum.organization,
                  },
                  {
                    active: bookmarkFilter === BookmarkFilterEnum.default,
                    title: "Default",
                    value: BookmarkFilterEnum.default,
                  },
                ]}
              />
              <Button
                size={ButtonSize.small}
                icon={CogIcon}
                to="/explorer/bookmarks"
                theme={buttonTheme.iconGray}
                tooltip="Manage bookmarks"
              />
            </div>
            {bookmarksByGroup.length > 0 &&
              bookmarksByGroup.map((group) => (
                <div key={group.id}>
                  <button
                    className="flex items-center gap-1 hover:bg-black/5 rounded-lg p-0.5 px-1.5"
                    onClick={handleClickToggleGroup}
                    data-groupid={group.id}
                  >
                    {group.icon}
                    <div className="flex items-center text-gray-900 font-medium gap-0.5">
                      {label(group.label, {
                        capitalize: true,
                        pluralize: true,
                      })}
                      {group.expanded ? (
                        <TbCaretDownFilled className="w-4 h-4 text-gray-500" />
                      ) : (
                        <TbCaretUpFilled className="w-4 h-4 text-gray-500" />
                      )}
                    </div>
                  </button>
                  {group.expanded && (
                    <ul className="mb-1 ml-6 pl-4 list-disc">
                      {group.links.map((link) => (
                        <li className="text-gray-700" key={link.title}>
                          {onClickBookmark ? (
                            <button
                              onClick={() => onClickBookmark(link.filters)}
                              className={classNames(
                                "hover:underline",
                                isEqual(link.filters, filters) &&
                                  "text-blue-link underline font-medium"
                              )}
                            >
                              {link.title}
                            </button>
                          ) : (
                            <AppLink
                              to={getExplorerFiltersUrl(link.filters)}
                              className={classNames(
                                "hover:underline",
                                isEqual(link.filters, filters) &&
                                  "text-blue-link underline font-medium"
                              )}
                            >
                              {link.title}
                            </AppLink>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            {bookmarksByGroup.length === 0 && (
              <div className="text-gray-500 text-sm">No bookmarks.</div>
            )}
          </div>
        </Popover.Panel>
      </Portal>
    </Popover>
  );
};

export default ExplorerBookmarkPopover;
