import { useMutation } from "@apollo/client";
import { CheckIcon } from "@heroicons/react/solid";
import asanaLogo from "@static/img/asana-logo.png";
import clickupLogo from "@static/img/clickup.png";
import githubLogo from "@static/img/github.png";
import hubspotLogo from "@static/img/hubspot.png";
import jiraLogo from "@static/img/jira.png";
import salesforceLogo from "@static/img/salesforce.png";
import zoomLogo from "@static/img/zoom.png";
import { GetAccountQuery } from "types/graphql-schema";

import { successNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertNonNull, getAssetUrl } from "@helpers/helpers";

import getAccountQuery from "../graphql/get-account-query";
import removeAsanaIntegrationMutation from "../graphql/remove-asana-integration-mutation";
import removeClickupIntegrationMutation from "../graphql/remove-clickup-integration-mutation";
import removeGitHubIntegrationMutation from "../graphql/remove-github-integration-mutation";
import removeHubspotIntegrationMutation from "../graphql/remove-hubspot-integration-mutation";
import removeJiraIntegrationMutation from "../graphql/remove-jira-integration-mutation";
import removeSalesforceIntegrationMutation from "../graphql/remove-salesforce-integration-mutation";
import removeZoomIntegrationMutation from "../graphql/remove-zoom-integration-mutation";

export const IntegrationRow = ({
  logoAssetUrl,
  logoAlt,
  text,
  buttons,
}: {
  logoAssetUrl: string | URL;
  logoAlt: string;
  text: JSX.Element;
  buttons: JSX.Element;
}) => (
  <li className="flex gap-8 py-6 border-0 border-b border-gray-200">
    <div className="col font-medium mb-4 flex items-start w-28 shrink-0">
      <div className="border rounded flex justify-center items-center h-24 w-28 p-4">
        <img
          src={getAssetUrl(logoAssetUrl)}
          alt={logoAlt}
          className="object-contain h-16"
        />
      </div>
    </div>
    <div className="flex-1">{text}</div>
    <div className="w-48 shrink-0 flex flex-col gap-2 items-center">
      {buttons}
    </div>
  </li>
);

const Integrations = ({ me }: { me: GetAccountQuery["me"] }) => {
  const account = assertNonNull(me);
  const [removeAsanaIntegration] = useMutation(removeAsanaIntegrationMutation);
  const [removeGitHubIntegration] = useMutation(
    removeGitHubIntegrationMutation,
    { refetchQueries: [getAccountQuery] }
  );
  const [removeJiraIntegration] = useMutation(removeJiraIntegrationMutation);
  const [removeClickupIntegration] = useMutation(
    removeClickupIntegrationMutation
  );
  const [removeSalesforceIntegration] = useMutation(
    removeSalesforceIntegrationMutation
  );
  const [removeHubspotIntegration] = useMutation(
    removeHubspotIntegrationMutation
  );
  const [removeZoomIntegration] = useMutation(removeZoomIntegrationMutation);

  const handleRemoveZoomIntegration = () => {
    removeZoomIntegration();
  };
  const handleRemoveAsanaIntegration = () => {
    removeAsanaIntegration({
      onError: onNotificationErrorHandler(),
    });
  };
  const handleRemoveGitHubIntegration = () => {
    removeGitHubIntegration({
      onError: onNotificationErrorHandler(),
      onCompleted: () => {
        successNotificationVar({ title: "GitHub integration removed" });
      },
    });
  };

  const handleRemoveJiraIntegration = () => {
    removeJiraIntegration({
      onError: onNotificationErrorHandler(),
    });
  };

  const handleRemoveClickupIntegration = () => {
    removeClickupIntegration({
      onError: onNotificationErrorHandler(),
    });
  };
  const handleRemoveSalesforceIntegration = () => {
    removeSalesforceIntegration({
      onError: onNotificationErrorHandler(),
    });
  };

  const handleRemoveHubspotIntegration = () => {
    removeHubspotIntegration({
      onError: onNotificationErrorHandler(),
    });
  };

  return (
    <div>
      <h2 className="text-2xl pb-2 font-medium text-gray-800">Integrations</h2>
      <ul className="bg-white py-4" aria-label="Integration list">
        <IntegrationRow
          logoAlt={"Zoom"}
          logoAssetUrl={zoomLogo}
          text={
            <>
              <p>
                Add Zoom meetings automatically to meetings you create in
                Topicflow.
              </p>
              <p className="font-bold uppercase py-2 text-gray-400">Note:</p>
              <ul className="list-disc ml-4 text-gray-400">
                <li>
                  An administrator for your Zoom account must pre-approve our{" "}
                  <a
                    href="https://marketplace.zoom.us/apps/o5pQpYQeT5CCiZcXtdY73w"
                    title="Topicflow on Zoom Marketplace"
                    className="text-blue-600"
                  >
                    Zoom Marketplace app
                  </a>
                  .
                </li>
              </ul>
            </>
          }
          buttons={
            account.hasZoomAuth ? (
              <>
                <Button
                  theme={buttonTheme.green}
                  rounded
                  className="flex"
                  onClick={() => {
                    handleRemoveZoomIntegration();
                  }}
                >
                  <CheckIcon className="text-green-500 h-5 w-5" />
                  Connected
                </Button>
                <Button
                  theme={buttonTheme.text}
                  onClick={() => {
                    handleRemoveZoomIntegration();
                  }}
                  className="font-light text-xs py-1 px-2 flex"
                >
                  Disconnect from Zoom
                </Button>
              </>
            ) : (
              <Button rounded url={`/sso/zoom?next=/account/integrations`}>
                Connect to Zoom
              </Button>
            )
          }
        />
        <IntegrationRow
          logoAlt={"Asana"}
          logoAssetUrl={asanaLogo}
          text={
            <>
              <p>Sync your Topicflow action items into Asana.</p>
            </>
          }
          buttons={
            account.hasAsanaAuth ? (
              <>
                <Button
                  theme={buttonTheme.green}
                  rounded
                  className="flex"
                  onClick={() => {
                    handleRemoveAsanaIntegration();
                  }}
                >
                  <CheckIcon className="text-green-500 h-5 w-5" />
                  Connected
                </Button>
                <Button
                  theme={buttonTheme.text}
                  onClick={() => {
                    handleRemoveAsanaIntegration();
                  }}
                  className="font-light text-xs py-1 px-2 flex"
                >
                  Disconnect from Asana
                </Button>
              </>
            ) : (
              <Button rounded url={`/sso/asana?next=/account/integrations`}>
                Connect to Asana
              </Button>
            )
          }
        />
        <IntegrationRow
          logoAlt={"Github"}
          logoAssetUrl={githubLogo}
          text={
            <>
              <p>Connect your Topicflow and GitHub accounts.</p>
              <ul className="list-disc ml-4 py-2 italic">
                <li>
                  Preview GitHub issues and pull requests in Topicflow meeting
                  notes
                </li>
                <li>
                  Search for GitHub issues and pull requests from the Explorer,
                  and embed lists in meeting notes
                </li>
                <li>Link GitHub metrics to Topicflow KPIs</li>
              </ul>
            </>
          }
          buttons={
            account.hasGithubAuth ? (
              <>
                <Button
                  theme={buttonTheme.green}
                  rounded
                  className="flex"
                  onClick={() => {
                    handleRemoveGitHubIntegration();
                  }}
                >
                  <CheckIcon className="text-green-500 h-5 w-5" />
                  Connected
                </Button>
              </>
            ) : (
              <Button
                rounded
                url={`https://github.com/login/oauth/authorize?client_id=${window.githubAppClientId}`}
              >
                Connect to Github
              </Button>
            )
          }
        />
        <IntegrationRow
          logoAlt={"Jira"}
          logoAssetUrl={jiraLogo}
          text={
            <>
              <p>Connect your Topicflow and Jira accounts.</p>
              <ul className="list-disc ml-4 py-2 italic">
                <li>Preview Jira issues in Topicflow meeting notes</li>
                <li>
                  Search for Jira issues from the Explorer, and embed lists in
                  meeting notes
                </li>
              </ul>
            </>
          }
          buttons={
            account.hasJiraAuth ? (
              <>
                <Button
                  theme={buttonTheme.green}
                  rounded
                  className="flex"
                  onClick={() => {
                    handleRemoveJiraIntegration();
                  }}
                >
                  <CheckIcon className="text-green-500 h-5 w-5" />
                  Connected
                </Button>
                <Button
                  theme={buttonTheme.text}
                  onClick={() => {
                    handleRemoveJiraIntegration();
                  }}
                  className="font-light text-xs py-1 px-2 flex"
                >
                  Disconnect from Jira
                </Button>
              </>
            ) : (
              <Button rounded url={`/sso/jira?next=/account/integrations`}>
                Connect to Jira
              </Button>
            )
          }
        />
        <IntegrationRow
          logoAlt={"Clickup"}
          logoAssetUrl={clickupLogo}
          text={
            <>
              <p>Connect your Topicflow and Clickup accounts.</p>
              <ul className="list-disc ml-4 py-2 italic">
                <li>Preview Clickup tasks in Topicflow meeting notes</li>
              </ul>
            </>
          }
          buttons={
            account.hasClickupAuth ? (
              <>
                <Button
                  theme={buttonTheme.green}
                  rounded
                  className="flex"
                  onClick={() => {
                    handleRemoveClickupIntegration();
                  }}
                >
                  <CheckIcon className="text-green-500 h-5 w-5" />
                  Connected
                </Button>
                <Button
                  theme={buttonTheme.text}
                  onClick={() => {
                    handleRemoveClickupIntegration();
                  }}
                  className="font-light text-xs py-1 px-2 flex"
                >
                  Disconnect from Clickup
                </Button>
              </>
            ) : (
              <Button rounded url={`/sso/clickup?next=/account/integrations`}>
                Connect to Clickup
              </Button>
            )
          }
        />
        <IntegrationRow
          logoAlt={"Salesforce"}
          logoAssetUrl={salesforceLogo}
          text={
            <>
              <p>Connect your Topicflow and Salesforce accounts.</p>
              <ul className="list-disc ml-4 py-2 italic">
                <li>
                  Preview Salesforce Opportunities in Topicflow meeting notes
                </li>
                <li>Link Salesforce data to Topicflow KPIs</li>
              </ul>
            </>
          }
          buttons={
            account.hasSalesforceAuth ? (
              <>
                <Button
                  theme={buttonTheme.green}
                  rounded
                  className="flex"
                  onClick={() => {
                    handleRemoveSalesforceIntegration();
                  }}
                >
                  <CheckIcon className="text-green-500 h-5 w-5" />
                  Connected
                </Button>
                <Button
                  theme={buttonTheme.text}
                  onClick={() => {
                    handleRemoveSalesforceIntegration();
                  }}
                  className="font-light text-xs py-1 px-2 flex"
                >
                  Disconnect from Salesforce
                </Button>
              </>
            ) : (
              <Button
                rounded
                url={`/sso/salesforce?next=/account/integrations`}
              >
                Connect to Salesforce
              </Button>
            )
          }
        />
        <IntegrationRow
          logoAlt={"Hubspot"}
          logoAssetUrl={hubspotLogo}
          text={
            <>
              <p>Connect your Topicflow and Hubspot accounts.</p>
              <ul className="list-disc ml-4 py-2 italic">
                <li>Preview Hubspot Deals in Topicflow meeting notes</li>
                <li>
                  Search for Hubspot Deals from the Explorer, and embed lists in
                  meeting notes
                </li>
                <li>Link Hubspot deal data to Topicflow KPIs</li>
              </ul>
            </>
          }
          buttons={
            account.hasHubspotAuth ? (
              <>
                <Button
                  theme={buttonTheme.green}
                  rounded
                  className="flex"
                  onClick={() => {
                    handleRemoveHubspotIntegration();
                  }}
                >
                  <CheckIcon className="text-green-500 h-5 w-5" />
                  Connected
                </Button>
                <Button
                  theme={buttonTheme.text}
                  onClick={() => {
                    handleRemoveHubspotIntegration();
                  }}
                  className="font-light text-xs py-1 px-2 flex"
                >
                  Disconnect from Hubspot
                </Button>
              </>
            ) : (
              <Button rounded url={`/sso/hubspot?next=/account/integrations`}>
                Connect to Hubspot
              </Button>
            )
          }
        />
      </ul>
    </div>
  );
};

export default Integrations;
