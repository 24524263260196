import { ArrowRightIcon } from "@heroicons/react/outline";
import { useState } from "react";

import MeetingRecurringPopover from "@apps/meeting-new/components/meeting-recurring-popover";
import Button, { ButtonSize } from "@components/button/button";
import Loading from "@components/loading/loading";

import { meetingDialogAction } from "../meeting-dialog";

const MeetingDialogFormButtons = ({
  hasDates,
  action,
  calendarOptions,
  loadingExistingMeeting,
  loadingSave,
  selectedCalendar,
  showAllEventsOption,
  recurrence,
  onSaveMeeting,
  onClose,
  onSaveChangesPublishedMeeting,
}: {
  hasDates: boolean;
  action: meetingDialogAction;
  loadingExistingMeeting: boolean;
  loadingSave: boolean;
  showAllEventsOption: boolean;
  calendarOptions: any[];
  selectedCalendar: any;
  recurrence: any;
  onSaveMeeting: (option: { publish: boolean }) => () => void;
  onClose: () => void;
  onSaveChangesPublishedMeeting: (value: any) => void;
}) => {
  const selectedCalendarHasWriteCredentials =
    selectedCalendar && !selectedCalendar.hasCalendarWriteCredentials;
  const [createMeetingOption, setCreateMeetingOption] = useState({
    publish: false,
  });

  const handleCreateMeeting = (options: { publish: boolean }) => () => {
    onSaveMeeting(options)();
    setCreateMeetingOption(options);
  };

  const noDateTooltip = hasDates
    ? ""
    : "Select a date and time to be able to publish the meeting";

  if (action === meetingDialogAction.publish) {
    return (
      <div className="grid grid-cols-2 gap-4">
        <Button
          size={ButtonSize.large}
          tooltip={noDateTooltip}
          disabled={
            !!noDateTooltip ||
            calendarOptions.length === 0 ||
            loadingExistingMeeting ||
            loadingSave ||
            (selectedCalendar && !selectedCalendar.hasCalendarWriteCredentials)
          }
          onClick={onSaveMeeting({ publish: true })}
          theme="primary"
          className="w-full"
        >
          Publish & send invites
          {loadingSave && <Loading mini size="4" color="white" />}
        </Button>
        <Button
          size={ButtonSize.large}
          className="w-full"
          disabled={loadingExistingMeeting || loadingSave}
          onClick={onSaveMeeting({ publish: false })}
        >
          Save
          {loadingSave && <Loading mini size="4" color="white" />}
        </Button>
      </div>
    );
  }
  if (action === meetingDialogAction.create) {
    return (
      <div className="grid grid-cols-2 gap-4">
        <Button
          size={ButtonSize.large}
          disabled={loadingSave}
          onClick={handleCreateMeeting({ publish: false })}
          theme="primary"
          className="w-full"
        >
          Save as draft
          {loadingSave && !createMeetingOption.publish ? (
            <Loading mini size="4" color="white" />
          ) : (
            <ArrowRightIcon className="h-4 w-4" />
          )}
        </Button>
        <Button
          size={ButtonSize.large}
          className="w-full"
          onClick={handleCreateMeeting({ publish: true })}
          tooltip={
            calendarOptions.length === 0
              ? "No calendar connected."
              : selectedCalendarHasWriteCredentials
              ? "Please allow calendar write access"
              : noDateTooltip
          }
          disabled={
            calendarOptions.length === 0 ||
            selectedCalendarHasWriteCredentials ||
            noDateTooltip
          }
        >
          Publish & send invites
          {loadingSave && createMeetingOption.publish && (
            <Loading mini size="4" />
          )}
        </Button>
      </div>
    );
  }

  if (action === meetingDialogAction.edit) {
    return (
      <div className="grid grid-cols-2 gap-4">
        {recurrence ? (
          <MeetingRecurringPopover
            onSubmit={onSaveChangesPublishedMeeting}
            disabled={loadingSave}
            showAllEventsOption={showAllEventsOption}
          />
        ) : (
          <Button
            size={ButtonSize.large}
            tooltip={
              !selectedCalendar
                ? "Connect your calendar."
                : !selectedCalendar.hasCalendarWriteCredentials
                ? "Allow calendar write access"
                : null
            }
            disabled={
              loadingSave ||
              !selectedCalendar ||
              !selectedCalendar.hasCalendarWriteCredentials
            }
            onClick={onSaveMeeting({ publish: true })}
            theme="primary"
            className="w-full"
          >
            Save changes
            {loadingSave && <Loading mini size="4" color="white" />}
          </Button>
        )}
        <Button size={ButtonSize.large} className="w-full" onClick={onClose}>
          Cancel
        </Button>
      </div>
    );
  }

  return null;
};

export default MeetingDialogFormButtons;
