import { useReactiveVar } from "@apollo/client";
import { LocationDescriptor } from "history";
import {
  AnchorHTMLAttributes,
  ReactNode,
  forwardRef,
  useCallback,
} from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { TFLocationState } from "types/topicflow";

import { appVersionVar } from "@cache/cache";

let trackedVersion: null;

export type toObjectProps = {
  pathname: string;
  state?: TFLocationState;
};

type Props = AnchorHTMLAttributes<HTMLAnchorElement> & {
  children?: ReactNode;
  to: string | toObjectProps;
  className?: string;
  id?: string;
  disabled?: boolean;
};

const AppLink = forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
  (props: Props, ref) => {
    const latestAppVersion = useReactiveVar(appVersionVar);

    if (props.disabled) {
      return <span {...props}>{props.children}</span>;
    }
    // tracked version is empty on initial load
    if (!trackedVersion) {
      trackedVersion = latestAppVersion;
    }
    // We show an `a` tag when the app version has changed
    // so we can load a new build of the frontend app from server
    // https://github.com/ParmAngTech/manageros/issues/95
    else if (latestAppVersion && latestAppVersion !== trackedVersion) {
      const { children, to, ...rest } = props;
      const isStrTo = typeof to === "string";
      const href = isStrTo ? to : to.pathname;
      if (href && typeof href === "string") {
        return (
          <a {...rest} href={href}>
            {children}
          </a>
        );
      }
    }
    return <RouterLink {...props} />;
  }
);
AppLink.displayName = "AppLink";

export default AppLink;

export function useLink() {
  const history = useHistory();
  const latestAppVersion = useReactiveVar(appVersionVar);

  const redirect = useCallback(
    (to: string | toObjectProps, ignoredRefresh = false) => {
      // tracked version is empty on initial load
      if (!trackedVersion) {
        trackedVersion = latestAppVersion;
      }
      // We show an `a` tag when the app version has changed
      // so we can load a new build of the frontend app from server
      // https://github.com/ParmAngTech/manageros/issues/95
      else if (
        latestAppVersion &&
        latestAppVersion !== trackedVersion &&
        !ignoredRefresh
      ) {
        const isStrTo = typeof to === "string";
        const isLocationTo = to instanceof Location;
        const href = isStrTo ? to : to.pathname;
        if (!isStrTo && !isLocationTo && !to.state?.background) {
          return (window.location.href = href);
        }
      }
      history.push(to);
    },
    [history, latestAppVersion]
  );
  const replace = useCallback(
    (to: LocationDescriptor<unknown>) => {
      history.replace(to);
    },
    [history]
  );
  return {
    redirect,
    replace,
  };
}
