import { gql } from "@apollo/client";

export default gql`
  query getOrganizationCareerTracks(
    $organizationId: Int!
    $teamId: Int
    $after: String
    $first: Int
  ) {
    careerTracks(
      organizationId: $organizationId
      teamId: $teamId
      after: $after
      first: $first
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          title
          roles {
            edges {
              node {
                id
                title
                level
                roleType
              }
            }
          }
        }
      }
    }
  }
`;
