import { useMutation } from "@apollo/client";
import { useState } from "react";

import getMeetingQuery from "@apps/meeting-new/graphql/get-meeting-query";
import { MeetingOrTemplateVisibility } from "@apps/meeting-new/helpers";
import Button from "@components/button/button";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import addTemplateToMeetingMutation from "../graphql/add-template-to-meeting-mutation";
import TemplateNoteAccessModal from "./note-access-modal";

const TemplatePreview = ({
  template,
  meeting,
  meetingGroup,
  onClose,
}: {
  template: any;
  meeting: any;
  meetingGroup: any;
  onClose: () => void;
}) => {
  const [futureMeetingsChecked, setFutureMeetingsChecked] = useState(
    meeting ? !!meeting?.isRecurring : true
  );
  const [showChangeAccessModal, setShowChangeAccessModal] = useState(false);
  const [addTemplateToMeeting, { loading }] = useMutation(
    addTemplateToMeetingMutation,
    {
      onCompleted: onClose,
    }
  );

  const handleApply =
    ({
      setAsSeriesTemplate,
      allowAdminVisibility,
      allowOrgVisibility,
      allowManagementTreeVisibility,
    }: {
      setAsSeriesTemplate: boolean;
    } & MeetingOrTemplateVisibility) =>
    () => {
      const meetingId = meeting?.id || null;
      addTemplateToMeeting({
        variables: {
          meetingId: setAsSeriesTemplate ? null : meetingId,
          meetingGroupId: setAsSeriesTemplate ? meetingGroup.id : null,
          topicTemplateId: template.id,
          setAsSeriesTemplate,
          allowAdminVisibility,
          allowOrgVisibility,
          allowManagementTreeVisibility,
        },
        onError: onNotificationErrorHandler(),
        refetchQueries: [getMeetingQuery],
      });
    };

  const handleShowChangeAccessModal = () => {
    setShowChangeAccessModal(true);
  };

  const handleConfirmChangeAccess = ({
    allowAdminVisibility,
    allowOrgVisibility,
    allowManagementTreeVisibility,
  }: MeetingOrTemplateVisibility) => {
    setShowChangeAccessModal(false);
    handleApply({
      setAsSeriesTemplate: true,
      allowAdminVisibility,
      allowOrgVisibility,
      allowManagementTreeVisibility,
    })();
  };

  return (
    <div aria-label="Template preview container">
      {showChangeAccessModal && (
        <TemplateNoteAccessModal
          meetingGroup={meetingGroup}
          isRecurring={!!meeting?.isRecurring}
          template={template}
          onCancel={() => setShowChangeAccessModal(false)}
          onConfirm={handleConfirmChangeAccess}
        />
      )}
      <div className="mb-2 font-medium text-lg">{template.title}</div>

      {template.description && (
        <div>
          <div className="text-2xs text-gray-600 uppercase">Description</div>
          <div className="text-sm text-gray-700 whitespace-pre-line mb-6">
            {template.description}
          </div>
        </div>
      )}
      <div className="text-2xs text-gray-600 uppercase mb-0.5">Topics</div>
      <div className="border-l border-r border-t rounded">
        <ul>
          {template.topicsList.map((topic: any) => (
            <li key={topic.id} className="group border-b">
              <div className="flex w-full pl-3.5 pr-2 py-1.5">
                <div className="flex-1 ml-2 mr-2 text-left">
                  <div className="text-sm">{topic.title}</div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="mt-10 flex gap-8 items-center">
        <Button
          aria-label="Apply template to meeting series"
          text={meeting ? "Add template to meeting" : "Apply template"}
          theme="primary"
          onClick={
            template.allowAdminVisibility !==
              meetingGroup.allowAdminVisibility ||
            template.allowOrgVisibility !== meetingGroup.allowOrgVisibility
              ? handleShowChangeAccessModal
              : handleApply({
                  setAsSeriesTemplate: futureMeetingsChecked,
                  allowAdminVisibility: meetingGroup.allowAdminVisibility,
                  allowOrgVisibility: meetingGroup.allowOrgVisibility,
                  allowManagementTreeVisibility:
                    meetingGroup.allowManagementTreeVisibility,
                })
          }
          disabled={loading}
        />
        {meeting?.isRecurring && (
          <label className="flex gap-1 items-center text-sm text-gray-600 cursor-pointer">
            <input
              type="checkbox"
              checked={futureMeetingsChecked}
              onChange={(e) => setFutureMeetingsChecked(e.target.checked)}
            />
            <span>Use in future meetings</span>
          </label>
        )}
      </div>
    </div>
  );
};

export default TemplatePreview;
