import { useMutation } from "@apollo/client";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { ChevronUpIcon } from "@heroicons/react/outline";
import pluralize from "pluralize";
import { ChangeEvent, useCallback, useState } from "react";
import {
  GetComplianceProgramProgressQuery,
  SendProgramReminderEmailsMutation,
  SendProgramReminderEmailsMutationVariables,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import { isAdminVar, successNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import CustomTextareaAutosize from "@components/textarea-autosize/textarea-autosize";
import Tooltip from "@components/tooltip/tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
  UserComboboxTeamOption,
} from "@components/user-combobox/user-combobox-list";

import sendProgramReminderMutation from "../graphql/send-program-reminder-mutation";
import {
  ComplianceProgramReportingEntityTableCol,
  ComplianceProgramReportingEntityTableKeyName,
} from "./compliance-program-progress-entity-table";

const ComplianceProgramEmailReminderDialogRow = ({
  selectedTeamId,
  checked,
  col,
  onChangeCheckbox,
}: {
  selectedTeamId?: number;
  checked: boolean;
  col: ComplianceProgramReportingEntityTableCol;
  onChangeCheckbox: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const [isShowingUsers, setIsShowingUsers] = useState(false);
  const users = col.remindUsers.filter(
    (remindUser) =>
      !selectedTeamId ||
      remindUser.teams?.edges.some((edge) => edge?.node?.id === selectedTeamId)
  );
  const count = users.length;
  const subjectLabel = `${count} ${pluralize("subject", count)}`;
  return (
    <div className="flex flex-col gap-2 w-full py-2 px-2" key={col.keyName}>
      <div className="flex items-center gap-1 justify-between w-full">
        <label className="flex items-center gap-2 cursor-pointer">
          <input
            type="checkbox"
            checked={checked && count > 0}
            name={col.keyName}
            onChange={onChangeCheckbox}
            className="cursor-pointer"
            disabled={count === 0}
          />
          {col.label}
        </label>
        {count > 0 ? (
          <Tooltip text={col.remindUserTooltip}>
            <button
              className="text-gray-400 hover:text-gray-500 text-xs tracking-tighter flex items-center gap-1"
              onClick={() => setIsShowingUsers(!isShowingUsers)}
            >
              {isShowingUsers ? (
                <ChevronUpIcon className="w-4 h-4" />
              ) : (
                <ChevronDownIcon className="w-4 h-4" />
              )}
              {col.remindUserType === "manager" &&
                `Managers of ${subjectLabel}`}
              {col.remindUserType === "subject" && subjectLabel}
              {col.remindUserType === "peer" &&
                `Peers nominated for ${subjectLabel}`}
              {col.remindUserType === "directReport" &&
                `Reports of ${subjectLabel}`}
            </button>
          </Tooltip>
        ) : (
          <span className="text-gray-400 text-xs tracking-tighter">None</span>
        )}
      </div>
      {isShowingUsers && (
        <div className="px-3 py-1.5 bg-gray-100 rounded-md text-xs text-gray-600 tracking-tight">
          <span className="font-medium">
            Notify{col.remindUserType === "manager" && ` managers of`}
            {col.remindUserType === "directReport" && ` reports of`}
            {col.remindUserType === "peer" && ` peers nominated for`}
          </span>{" "}
          {users.map((user) => user.name).join(", ")}
        </div>
      )}
    </div>
  );
};

const ComplianceProgramEmailReminderDialog = ({
  complianceProgram,
  cols,
  onClose,
  selectedTeam,
}: {
  complianceProgram: NonNullable<
    GetComplianceProgramProgressQuery["complianceProgram"]
  >;
  cols: ComplianceProgramReportingEntityTableCol[];
  onClose: () => void;
  selectedTeam: UserComboboxTeamOption | null;
}) => {
  const isAdmin = isAdminVar();
  const label = useLabel();
  const [team, setTeam] = useState<UserComboboxTeamOption | null>(
    isAdmin ? selectedTeam : null
  );
  const [message, setMessage] = useState("");

  const initialCheckboxes = cols.reduce((acc, col) => {
    acc[col.keyName] = false;
    return acc;
  }, {} as Record<string, boolean>);

  const [checkboxes, setCheckboxes] =
    useState<Record<string, boolean>>(initialCheckboxes);

  const { options, query, setQuery } = useUserComboboxQuery({
    types: [UserComboboxOptionType.TEAM],
    selected: team,
  });

  const [sendProgramReminder, { loading }] = useMutation<
    SendProgramReminderEmailsMutation,
    SendProgramReminderEmailsMutationVariables
  >(sendProgramReminderMutation);

  const handleChangeTeam = useCallback(
    (team: UserComboboxOption | null) => {
      if (team?.type === UserComboboxOptionType.TEAM) setTeam(team);
    },
    [setTeam]
  );

  const handleChangeCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    setCheckboxes({ ...checkboxes, [e.target.name]: e.target.checked });
  };

  const dataToSave = cols.reduce((acc, col) => {
    if (checkboxes[col.keyName]) {
      const userIds = col.remindUsers
        .filter(
          (user) =>
            !team ||
            user.teams?.edges.some((edge) => edge?.node?.id === team.id)
        )
        .map((user) => user.id);
      acc[`${col.keyName}UserIds`] = userIds;
    }
    return acc;
  }, {} as Record<`${ComplianceProgramReportingEntityTableKeyName}UserIds`, number[]>);

  const handleClickSend = useCallback(() => {
    sendProgramReminder({
      variables: {
        complianceProgramId: complianceProgram.id,
        message,
        ...dataToSave,
      },
      onError: onNotificationErrorHandler(),
      onCompleted: () => {
        successNotificationVar({ title: "Reminder sent" });
        onClose();
      },
    });
  }, [complianceProgram.id, message, dataToSave, sendProgramReminder, onClose]);

  return (
    <Modal onClose={onClose} className="">
      <div
        className="flex flex-col gap-6 p-6 text-sm max-h-[90vh] overflow-y-auto"
        aria-label="Program send email reminder dialog"
      >
        <ModalTitle onClose={onClose}>Send Email Reminder</ModalTitle>
        {isAdmin && (
          <div className="flex gap-2">
            <label className="text-gray-500 w-42 pt-1.5">
              Select {label("team")}
            </label>
            <div className="w-64">
              <UserCombobox
                className="w-64"
                value={team}
                onChangeValue={handleChangeTeam}
                query={query}
                onChangeQuery={setQuery}
                options={options}
                placeholder="Select..."
                clearable={team !== null}
                onClearValue={() => setTeam(null)}
              />
            </div>
          </div>
        )}
        <div className="flex gap-2">
          <label className="text-gray-500 w-42 pt-1.5">Message</label>
          <div className="flex-1">
            <CustomTextareaAutosize
              className="w-full max-h-36 border border-gray-300 rounded-md p-2"
              rows={1}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="text-gray-500 font-medium">
            Notify team members that need to take action on each of the steps
            below:
          </div>
          <div className="flex flex-col divide-y border-t border-b text-gray-600">
            {cols.map((col) => (
              <ComplianceProgramEmailReminderDialogRow
                key={col.keyName}
                col={col}
                checked={checkboxes[col.keyName]}
                selectedTeamId={team?.id}
                onChangeCheckbox={handleChangeCheckbox}
              />
            ))}
          </div>
        </div>
        <div className="mt-4 flex gap-2">
          <Button
            text="Send"
            theme={buttonTheme.primary}
            disabled={loading || Object.keys(dataToSave).length === 0}
            onClick={handleClickSend}
          />
          <Button text="Cancel" onClick={onClose} />
        </div>
      </div>
    </Modal>
  );
};
export default ComplianceProgramEmailReminderDialog;
