import { useQuery } from "@apollo/client";
import a from "indefinite";
import { compact } from "lodash";
import moment from "moment";
import { useMemo } from "react";
import {
  AssessmentGroupProviders,
  AssessmentType,
  ComplianceProgramMissingAssessmentFragment,
  ComplianceProgramParticipantStatus,
  ComplianceProgramPopoverContentAssessmentFragment,
  ComplianceProgramPopoverContentMeetingFragment,
  GetComplianceProgramPopoverContentQuery,
  GetComplianceProgramPopoverContentQueryVariables,
  GetComplianceProgramProgressQuery,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import useDebounce from "@components/use-debounce/use-debounce";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull, getUrl } from "@helpers/helpers";
import { pluralize } from "@helpers/string";

import getComplianceProgramPopoverContentQuery from "../graphql/get-compliance-program-popover-content-query";
import {
  ComplianceProgramReportingEntityTableCol,
  ComplianceProgramReportingEntityTableParticipantStatusWithNominations,
} from "./compliance-program-progress-entity-table";

const ProgressPillPopoverItem = ({
  assessment,
  oneonone,
}: {
  oneonone?: ComplianceProgramPopoverContentMeetingFragment;
  assessment?:
    | ComplianceProgramPopoverContentAssessmentFragment
    | ComplianceProgramMissingAssessmentFragment;
}) => {
  if (!assessment && !oneonone) {
    throw new Error("No assessment or oneonone provided");
  }
  if (oneonone) {
    const content = `${oneonone.title}`;
    const suffix = oneonone.isFinalized
      ? `finalized on ${moment(oneonone.finalizedAt).format("lll")}`
      : oneonone.startDatetime
      ? `scheduled for ${moment(oneonone.startDatetime).format("lll")}`
      : `drafted`;
    return (
      <li key={oneonone.id}>
        {oneonone.canRead?.permission ? (
          <>
            <AppLink
              to={getUrl({
                meetingGroupId: oneonone.meetingGroupId,
                meetingId: oneonone.id,
              })}
              className="hover:underline text-blue-500"
            >
              {content}
            </AppLink>{" "}
            {suffix}
          </>
        ) : (
          <span>
            {content} {suffix}
          </span>
        )}
      </li>
    );
  }
  if (assessment) {
    const content =
      assessment.__typename === "AssessmentNode" ? (
        <span>
          {assessment.responder?.name}
          {assessment.submittedDatetime
            ? ` on ${moment(assessment.submittedDatetime).format("lll")}`
            : ""}
        </span>
      ) : (
        <span>{assessment.responder?.name}</span>
      );
    return assessment.__typename === "AssessmentNode" ? (
      <li key={assessment.id}>
        {assessment.canRead?.permission ? (
          <AppLink
            to={`/assessments/assessment/${assessment.id}`}
            className="hover:underline text-blue-500"
          >
            {content}
          </AppLink>
        ) : (
          <span>{content}</span>
        )}
      </li>
    ) : (
      <li key={assessment.responder?.id}>
        <span>{content}</span>
      </li>
    );
  }
};

const ProgramProgressPillPopoverPanel = ({
  col,
  participantStatus,
  status,
  complianceProgram,
}: {
  participantStatus: ComplianceProgramReportingEntityTableParticipantStatusWithNominations;
  col: ComplianceProgramReportingEntityTableCol;
  status: ComplianceProgramParticipantStatus;
  complianceProgram: NonNullable<
    GetComplianceProgramProgressQuery["complianceProgram"]
  >;
}) => {
  const label = useLabel();
  const managers = assertEdgesNonNull(participantStatus.user.managers);
  const hasNoManagers = managers.length === 0;
  const participantName = participantStatus.user.name;
  const isNotApplicable =
    status === ComplianceProgramParticipantStatus.NotApplicable;
  const isCompleted = status === ComplianceProgramParticipantStatus.Complete;
  const isInProgress = status === ComplianceProgramParticipantStatus.InProgress;
  const isNotStarted = status === ComplianceProgramParticipantStatus.NotStarted;
  const isPerformanceAssessment =
    col.keyName === "performanceAssessmentStatus" &&
    complianceProgram.performanceAssessmentTemplate;
  const isPerformanceSelfAssessment =
    col.keyName === "performanceSelfAssessmentStatus" &&
    complianceProgram.performanceAssessmentTemplate;
  const isPeerAssessment =
    col.keyName === "peerAssessmentStatus" &&
    complianceProgram.peerAssessmentTemplate;
  const isManagerSelfAssessment =
    col.keyName === "managerSelfAssessmentStatus" &&
    complianceProgram.managerAssessmentTemplate;
  const isManagerAssessment =
    col.keyName === "managerAssessmentStatus" &&
    complianceProgram.managerAssessmentTemplate;

  const usersMissingAssessment = useMemo(
    () =>
      complianceProgram?.usersMissingAssessment
        ? compact(
            complianceProgram.usersMissingAssessment.edges.map(
              (edge) => edge?.node
            )
          )
        : [],
    [complianceProgram]
  );

  // Nominations
  const statusText = useMemo(() => {
    let statusText = "";
    if (col.keyName === "nominations") {
      const nominationCountByUserId = {} as Record<number, number>;
      const nominations =
        nominationCountByUserId[participantStatus.user.id] > 0
          ? ComplianceProgramParticipantStatus.Complete
          : ComplianceProgramParticipantStatus.NotStarted;
      statusText =
        complianceProgram.peerAssessmentTemplate &&
        nominations === ComplianceProgramParticipantStatus.NotStarted
          ? `${
              complianceProgram.peerAssessmentTemplate.providers ===
              AssessmentGroupProviders.SubjectSelect
                ? `${participantName} needs`
                : `${participantName}'s manager needs`
            } to nominate peers for the peer assessment.`
          : "";
    }

    // Peer assessment tooltips
    if (isPeerAssessment && isNotApplicable) {
      if (
        complianceProgram.peerAssessmentTemplate.providers ===
        AssessmentGroupProviders.Default
      ) {
        const prefix = `In this peer ${label(
          "assessment"
        )}, peers who report to ${participantName}'s manager are automatically chosen.`;
        statusText = hasNoManagers
          ? `${prefix} However ${participantName} does not have a manager.`
          : `${prefix} However the manager of ${participantName} has no other reports available to assess ${participantName}.`;
      } else {
        statusText =
          complianceProgram.peerAssessmentTemplate.providers ===
          AssessmentGroupProviders.SubjectSelect
            ? "Subject has no nominated peers"
            : "Subject has no direct reports";
      }
    }

    // Performance assessment tooltips
    if (isPerformanceAssessment && isNotApplicable) {
      statusText = complianceProgram.performanceAssessmentTemplate
        .isOnlySelfAssessment
        ? `Self ${label("review")} only`
        : hasNoManagers
        ? "Subject has no manager"
        : "";
    }

    if (isPerformanceSelfAssessment && isNotApplicable && hasNoManagers) {
      statusText = "Subject has no managers";
    }

    if (col.keyName === "oneononeStatus" && isNotStarted) {
      if (hasNoManagers) {
        statusText = `${participantName} has no managers`;
      } else {
        statusText = `${managers
          .map((manager) => manager.name)
          .join(", ")} ${pluralize("need", managers.length)} to organize ${a(
          label("1-on-1")
        )} with ${participantName}`;
      }
    }

    if (isManagerAssessment && isNotApplicable) {
      statusText = complianceProgram.managerAssessmentTemplate
        .isOnlySelfAssessment
        ? `Self ${label("review")} only`
        : "Subject has no reports";
    }
    if (isManagerSelfAssessment && isNotApplicable) {
      statusText = "Subject has no manager";
    }
    if (!statusText && isCompleted) {
      statusText = "Completed";
    }
    return statusText;
  }, [
    col.keyName,
    isPeerAssessment,
    isNotApplicable,
    isCompleted,
    isPerformanceAssessment,
    isPerformanceSelfAssessment,
    hasNoManagers,
    isManagerAssessment,
    isManagerSelfAssessment,
    participantStatus.user.id,
    complianceProgram.peerAssessmentTemplate,
    complianceProgram.performanceAssessmentTemplate?.isOnlySelfAssessment,
    complianceProgram.managerAssessmentTemplate?.isOnlySelfAssessment,
    participantName,
    isNotStarted,
    isInProgress,
    managers,
  ]);

  const templateId = useMemo(() => {
    return complianceProgram.requiredTopicTemplates?.edges[0]?.node?.id;
  }, [complianceProgram]);
  const startDatetimeGte = useMemo(() => {
    return moment(complianceProgram.startDate).startOf("day").format();
  }, [complianceProgram.startDate]);
  const { data, loading: loadingAssessments } = useQuery<
    GetComplianceProgramPopoverContentQuery,
    GetComplianceProgramPopoverContentQueryVariables
  >(getComplianceProgramPopoverContentQuery, {
    variables: {
      complianceProgramId: complianceProgram.id,
      fetchAssessments: col.keyName.includes("Assessment"),
      fetchOneonones: col.keyName === "oneononeStatus",
      assessmentTargetId:
        !isNotApplicable && (isPerformanceAssessment || isPerformanceAssessment)
          ? participantStatus.user.id
          : undefined,
      assessmentResponderId:
        !isNotApplicable && isPerformanceSelfAssessment
          ? participantStatus.user.id
          : undefined,
      oneononeStartDatetimeGte: startDatetimeGte,
      oneononeTemplateId: templateId,
      oneononeParticipants: [participantStatus.user.id],
    },
    skip: status === ComplianceProgramParticipantStatus.NotApplicable,
    onError: onNotificationErrorHandler(),
  });
  const loading = useDebounce(loadingAssessments, 1000);
  const oneonones = useMemo(() => {
    return data?.complianceProgram?.matchingOneonones
      ? assertEdgesNonNull(data.complianceProgram.matchingOneonones)
      : [];
  }, [data]);
  const allAssessments = useMemo(
    () =>
      data?.complianceProgram?.assessments
        ? assertEdgesNonNull(data.complianceProgram.assessments)
        : [],
    [data]
  );
  const assessments = useMemo(
    () =>
      allAssessments.filter((assessment) => {
        if (isPerformanceAssessment) {
          return (
            assessment.template.assessmentType === AssessmentType.Performance &&
            assessment.target?.id === participantStatus.user.id &&
            assessment.responder?.id !== participantStatus.user.id
          );
        }
        if (isPerformanceSelfAssessment) {
          return (
            assessment.template.assessmentType === AssessmentType.Performance &&
            assessment.target?.id === participantStatus.user.id &&
            assessment.responder?.id === participantStatus.user.id
          );
        }
        if (isPeerAssessment) {
          return (
            assessment.template.assessmentType === AssessmentType.Peer &&
            assessment.target?.id === participantStatus.user.id &&
            assessment.responder?.id !== participantStatus.user.id
          );
        }
        if (isManagerAssessment) {
          return (
            assessment.template.assessmentType === AssessmentType.Manager &&
            assessment.target?.id === participantStatus.user.id &&
            assessment.responder?.id !== participantStatus.user.id
          );
        }
        if (isManagerSelfAssessment) {
          return (
            assessment.template.assessmentType === AssessmentType.Manager &&
            assessment.target?.id === participantStatus.user.id &&
            assessment.responder?.id === participantStatus.user.id
          );
        }
        return false;
      }),
    [
      allAssessments,
      isPerformanceAssessment,
      isPerformanceSelfAssessment,
      isPeerAssessment,
      isManagerAssessment,
      isManagerSelfAssessment,
      participantStatus.user.id,
    ]
  );
  const assessmentsInProgress = useMemo(
    () => assessments.filter((assessment) => !assessment.submittedDatetime),
    [assessments]
  );
  const completedAssessments = useMemo(
    () => assessments.filter((assessment) => assessment.submittedDatetime),
    [assessments]
  );
  const assessmentUIds = useMemo(
    () =>
      assessments.map(
        (assessment) =>
          `${assessment.template.id}-${assessment.responder?.id}-${assessment.target?.id}`
      ),
    [assessments]
  );
  const missingAssessments = useMemo(
    () =>
      usersMissingAssessment.filter((missingAssessment) => {
        const uid = `${missingAssessment.assessmentTemplate.id}-${missingAssessment.responder?.id}-${missingAssessment.target?.id}`;
        if (assessmentUIds.includes(uid)) {
          return false;
        }
        if (isPerformanceAssessment) {
          return (
            missingAssessment.assessmentTemplate.assessmentType ===
              AssessmentType.Performance &&
            missingAssessment.target?.id === participantStatus.user.id &&
            missingAssessment.responder?.id !== participantStatus.user.id
          );
        }
        if (isPerformanceSelfAssessment) {
          return (
            missingAssessment.assessmentTemplate.assessmentType ===
              AssessmentType.Performance &&
            missingAssessment.target?.id === participantStatus.user.id &&
            missingAssessment.responder?.id === participantStatus.user.id
          );
        }
        if (isPeerAssessment) {
          return (
            missingAssessment.assessmentTemplate.assessmentType ===
              AssessmentType.Peer &&
            missingAssessment.target?.id === participantStatus.user.id &&
            missingAssessment.responder?.id !== participantStatus.user.id
          );
        }
        if (isManagerAssessment) {
          return (
            missingAssessment.assessmentTemplate.assessmentType ===
              AssessmentType.Manager &&
            missingAssessment.target?.id === participantStatus.user.id &&
            missingAssessment.responder?.id !== participantStatus.user.id
          );
        }
        if (isManagerSelfAssessment) {
          return (
            missingAssessment.assessmentTemplate.assessmentType ===
              AssessmentType.Manager &&
            missingAssessment.target?.id === participantStatus.user.id &&
            missingAssessment.responder?.id === participantStatus.user.id
          );
        }
        return false;
      }),
    [
      usersMissingAssessment,
      assessmentUIds,
      isPerformanceAssessment,
      isPerformanceSelfAssessment,
      isPeerAssessment,
      isManagerAssessment,
      isManagerSelfAssessment,
      participantStatus.user.id,
    ]
  );

  return (
    <div className="flex flex-col gap-4 z-1 bg-white rounded-md px-4 pt-2 pb-3">
      {statusText && (
        <div>
          <div className="text-2xs text-gray-400 font-medium mb-0.5">
            Status
          </div>
          <div className="text-sm text-gray-700">{statusText}</div>
        </div>
      )}
      {!isNotApplicable && (
        <>
          {loading ? (
            <div>
              <Loading size="5" />
            </div>
          ) : (
            <>
              {assessmentsInProgress.length > 0 && (
                <div>
                  <div className="text-2xs text-gray-400 font-medium mb-0.5">
                    {label("assessment", { capitalize: true, pluralize: true })}{" "}
                    in Progress by
                  </div>
                  {assessmentsInProgress.length > 0 && (
                    <ul className="text-sm text-gray-700 list-disc list-inside ml-1">
                      {assessmentsInProgress.map((assessment) => (
                        <ProgressPillPopoverItem
                          assessment={assessment}
                          key={assessment.id}
                        />
                      ))}
                    </ul>
                  )}
                </div>
              )}
              {missingAssessments.length > 0 && (
                <div>
                  <div className="text-2xs text-gray-400 font-medium mb-0.5">
                    Missing {label("assessment", { pluralize: true })} by
                  </div>
                  {missingAssessments.length > 0 && (
                    <ul className="text-sm text-gray-700 list-disc list-inside ml-1">
                      {missingAssessments.map((assessment) => (
                        <ProgressPillPopoverItem
                          assessment={assessment}
                          key={assessment.responder?.id}
                        />
                      ))}
                    </ul>
                  )}
                </div>
              )}
              {completedAssessments.length > 0 && (
                <div>
                  <div className="text-2xs text-gray-400 font-medium mb-0.5">
                    Completed {label("assessment", { pluralize: true })} by
                  </div>
                  {completedAssessments.length > 0 && (
                    <ul className="text-sm text-gray-700 list-disc list-inside ml-1">
                      {completedAssessments.map((assessment) => (
                        <ProgressPillPopoverItem
                          assessment={assessment}
                          key={assessment.id}
                        />
                      ))}
                    </ul>
                  )}
                </div>
              )}
              {oneonones.length > 0 && (
                <div>
                  <div className="text-2xs text-gray-400 font-medium mb-0.5">
                    {label("1-on-1", { capitalize: true, pluralize: true })}
                  </div>
                  {oneonones.length > 0 && (
                    <ul className="text-sm text-gray-700 list-disc list-inside ml-1">
                      {oneonones.map((oneonone) => (
                        <ProgressPillPopoverItem
                          oneonone={oneonone}
                          key={oneonone.id}
                        />
                      ))}
                    </ul>
                  )}
                </div>
              )}
              {col.keyName.includes("Assessment") &&
                missingAssessments.length === 0 &&
                completedAssessments.length === 0 &&
                assessmentsInProgress.length === 0 && (
                  <div>
                    <div className="text-2xs text-gray-400 font-medium mb-0.5">
                      {label("assessment", {
                        pluralize: true,
                        capitalize: true,
                      })}
                    </div>
                    <div className="text-sm text-gray-700">
                      No {label("assessment", { pluralize: true })}.
                    </div>
                  </div>
                )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ProgramProgressPillPopoverPanel;
