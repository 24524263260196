import { useQuery } from "@apollo/client";
import moment from "moment";
import {
  GetPreviousMeetingsQueryQuery,
  GetPreviousMeetingsQueryQueryVariables,
  MeetingViewMeetingNodeNewPageFragmentFragment,
} from "types/graphql-schema";

import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, getUrl } from "@helpers/helpers";

import getPreviousMeetingsQuery from "../graphql/get-previous-meetings-query";

const PreviousMeetingsPopoverPanel = ({
  meeting,
  open,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  open: boolean;
}) => {
  const now = moment().endOf("day").format();
  const { data, loading, fetchMore } = useQuery<
    GetPreviousMeetingsQueryQuery,
    GetPreviousMeetingsQueryQueryVariables
  >(getPreviousMeetingsQuery, {
    variables: {
      beforeDate: now,
      meetingGroupId: meeting.meetingGroup?.id || -1,
      limit: 7,
      after: null,
    },
    skip: !meeting.hasPreviousMeetings || !open,
    onError: onNotificationErrorHandler(),
  });

  const meetings = data?.meetings ? assertEdgesNonNull(data.meetings) : [];

  const handleClickMore = () => {
    if (data?.meetings?.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          after: data.meetings.pageInfo.endCursor,
          merge: true,
        },
      });
    }
  };

  return (
    <div
      aria-label="Meeting draft popover panel"
      className={classNames(
        "w-120 max-w-120 max-h-144 overflow-y-auto py-4 px-6 flex flex-col gap-4 text-sm"
      )}
    >
      <div className="flex flex-col gap-4 text-sm">
        {meeting.hasPreviousMeetings ? (
          <div>
            <div className="font-semibold text-base mb-2">
              Select a previous meeting
            </div>
            {loading ? (
              <Loading>Fetching previous meetings</Loading>
            ) : meetings.length === 0 ? (
              <div className="text-gray-500">Test</div>
            ) : (
              <div className="flex flex-col gap-1">
                {meetings.map((previousMeeting) => (
                  <div key={previousMeeting.id}>
                    <AppLink
                      className="text-blue-link hover:underline"
                      to={getUrl({
                        meetingGroupId: previousMeeting.meetingGroupId,
                        meetingId: previousMeeting.id,
                      })}
                    >
                      {previousMeeting.title}
                    </AppLink>
                    <span className="text-gray-400">
                      {" "}
                      (
                      {moment(previousMeeting.startDatetime).format(
                        "ddd, MMM Do YYYY, h:mma"
                      )}
                      )
                    </span>
                  </div>
                ))}
                {data?.meetings?.pageInfo.hasNextPage && (
                  <div className="mt-2">
                    <button
                      type="button"
                      className="hover:underline"
                      onClick={handleClickMore}
                    >
                      Load more
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="text-gray-500">No previous meetings.</div>
        )}
      </div>
    </div>
  );
};

export default PreviousMeetingsPopoverPanel;
