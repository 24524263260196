import { DetailedHTMLProps, HTMLAttributes, PropsWithChildren } from "react";

import { classNames } from "@helpers/css";

const AssessmentQuestionChrome = ({
  children,
  isQuestionAnswered,
  isShowingError,
  noBorder,
  ...htmlAttributes
}: PropsWithChildren<
  {
    isShowingError?: boolean;
    isQuestionAnswered?: boolean;
    noBorder?: boolean;
  } & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>) => {
  return (
    <div
      className={classNames(
        "m-6",
        !noBorder && "pb-6 last:pb-0 border-b last:border-0",
        isShowingError && !isQuestionAnswered && "bg-red-50"
      )}
      {...htmlAttributes}
    >
      {children}
    </div>
  );
};

export default AssessmentQuestionChrome;
