import { useMutation, useQuery } from "@apollo/client";
import { DotsHorizontalIcon } from "@heroicons/react/outline";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { sortBy } from "lodash";
import { useCallback, useMemo, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import {
  GetOrganizationCoreValuesQuery,
  GetOrganizationCoreValuesQueryVariables,
  OrgSettingsCreateOrUpdateCoreValueMutation,
  OrgSettingsCreateOrUpdateCoreValueMutationVariables,
  RecognitionCoreValueStatus,
} from "types/graphql-schema";

import getLoggedInUserQuery from "@apps/main/graphql/get-logged-in-user-query";
import Button from "@components/button/button";
import Heading from "@components/heading/heading";
import Loading from "@components/loading/loading";
import Placeholder from "@components/placeholder/placeholder";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

import createOrUpdateCoreValueMutation from "../graphql/create-or-update-core-value-mutation";
import getOrganizationCoreValuesQuery from "../graphql/get-organization-core-values-query";
import CoreValueEditDialog, {
  CoreValue,
  emptyCoreValue,
} from "./core-value-edit-dialog";

type Props = {
  organizationId: number;
};

const CoreValues: React.FC<Props> = ({ organizationId }) => {
  const [proposedCoreValue, setProposedCoreValue] = useState<CoreValue | null>(
    null
  );
  const { data, loading } = useQuery<
    GetOrganizationCoreValuesQuery,
    GetOrganizationCoreValuesQueryVariables
  >(getOrganizationCoreValuesQuery, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    variables: { organizationId },
    onError: onNotificationErrorHandler(),
  });
  const [
    updateCoreValueMutation,
    { loading: isUpdatingCoreValue, error: updateCoreValueError },
  ] = useMutation<
    OrgSettingsCreateOrUpdateCoreValueMutation,
    OrgSettingsCreateOrUpdateCoreValueMutationVariables
  >(createOrUpdateCoreValueMutation);

  const updateCoreValue = useCallback(
    (variables: OrgSettingsCreateOrUpdateCoreValueMutationVariables) => {
      return updateCoreValueMutation({
        variables,
        refetchQueries: [getOrganizationCoreValuesQuery, getLoggedInUserQuery],
        onError: onNotificationErrorHandler(),
      });
    },
    [updateCoreValueMutation]
  );

  const deactivateCoreValue = useCallback(
    (coreValueId: number) => {
      return updateCoreValue({
        id: coreValueId,
        organizationId,
        status: RecognitionCoreValueStatus.Inactive,
      });
    },
    [organizationId, updateCoreValue]
  );

  const activateCoreValue = useCallback(
    (coreValueId: number) => {
      return updateCoreValue({
        id: coreValueId,
        organizationId,
        status: RecognitionCoreValueStatus.Active,
      });
    },
    [organizationId, updateCoreValue]
  );

  const coreValues = useMemo(
    () =>
      data?.organization?.coreValues
        ? assertEdgesNonNull(data.organization.coreValues)
        : [],
    [data]
  );
  const sortedCoreValues = sortBy(coreValues, (coreValue) => coreValue.status);

  if (loading) {
    return (
      <div className="flex justify-center mt-8">
        <Loading mini size="5" />
      </div>
    );
  }

  return (
    <div className="pb-12" aria-label="Org settings core values">
      {proposedCoreValue && (
        <CoreValueEditDialog
          organizationId={organizationId}
          coreValue={proposedCoreValue}
          isUpdatingCoreValue={isUpdatingCoreValue}
          updatingCoreValueError={updateCoreValueError}
          onSave={updateCoreValue}
          onClose={() => setProposedCoreValue(null)}
        />
      )}
      <div className="flex justify-between items-center mb-6">
        <Heading small title="Core values" />
        <div>
          <Button
            theme="primary"
            text="New core value"
            disabled={loading}
            onClick={() => {
              setProposedCoreValue(emptyCoreValue);
            }}
          />
        </div>
      </div>

      {sortedCoreValues.length > 0 ? (
        <ResponsiveMasonry
          columnsCountBreakPoints={{
            300: 1,
            600: 2,
            900: 3,
            1280: 4,
            1500: 5,
          }}
        >
          <Masonry gutter="16px">
            {sortedCoreValues.map((coreValue) => (
              <div
                key={coreValue.id}
                className={classNames(
                  "relative flex flex-col items-center justify-center border rounded p-8",
                  coreValue.status === RecognitionCoreValueStatus.Inactive &&
                    "bg-gray-100"
                )}
              >
                {coreValue.status === RecognitionCoreValueStatus.Inactive && (
                  <div className="absolute top-2 left-2 text-sm py-1 px-2 border text-gray-500 border-gray-400 rounded">
                    Inactive
                  </div>
                )}
                <Menu
                  align="end"
                  transition
                  aria-label="Core value dropdown menu list"
                  className="text-sm not-prose z-dropdown"
                  menuButton={
                    <button
                      className={classNames(
                        "absolute top-2 right-2 text-gray-400 rounded-lg py-1.5 px-1 hover:bg-black/5"
                      )}
                      aria-label="Core value dropdown menu list button"
                    >
                      <span className="sr-only">Open options</span>
                      <DotsHorizontalIcon className={`h-5 w-5`} />
                    </button>
                  }
                >
                  {coreValue.status === RecognitionCoreValueStatus.Active ? (
                    <>
                      <MenuItem
                        onClick={() => {
                          setProposedCoreValue(coreValue);
                        }}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          deactivateCoreValue(coreValue.id);
                        }}
                      >
                        Deactivate
                      </MenuItem>
                    </>
                  ) : (
                    <MenuItem
                      onClick={() => {
                        activateCoreValue(coreValue.id);
                      }}
                    >
                      Activate
                    </MenuItem>
                  )}
                </Menu>

                {coreValue.image ? (
                  <img
                    src={coreValue.image}
                    className="mb-4 w-[96px] h-[96px]"
                    alt={coreValue.title}
                  />
                ) : coreValue.emoji ? (
                  <div className="text-8xl mb-4">{coreValue.emoji}</div>
                ) : null}
                <div className="font-bold text-xl">{coreValue.title}</div>
                <div className="whitespace-pre-wrap text-sm">
                  {coreValue.description}
                </div>
              </div>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      ) : (
        <Placeholder>No core values.</Placeholder>
      )}
    </div>
  );
};

export default CoreValues;
