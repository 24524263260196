import { compact } from "lodash";
import {
  Link,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { AssessmentType } from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import { isAdminVar } from "@cache/cache";
import AppLink from "@components/link/link";
import useDocumentTitle from "@components/use-document-title/use-document-title";
import { assessmentTypeLabels } from "@helpers/constants";
import { classNames } from "@helpers/css";

import AdoptionReport from "./components/adoption-report";
import AssessmentReport from "./components/assessment-report";
import OneononeReport from "./components/oneonone-report";

const Nav = () => {
  const label = useLabel();
  const isAdmin = isAdminVar();
  const tabs = compact([
    {
      name: `${assessmentTypeLabels[AssessmentType.Performance]} ${label(
        "review",
        {
          pluralize: true,
        }
      )}`,
      id: "performance-assessments",
    },
    {
      name: assessmentTypeLabels[AssessmentType.Manager],
      id: "manager-effectiveness",
    },
    isAdmin && {
      name: label("oneonone", { capitalize: true, pluralize: true }),
      id: "oneonone",
    },
    {
      name: "Usage & engagement",
      id: "adoption",
    },
  ]);

  const { navId } = useParams<{ navId: string }>();
  const displayedTabId = navId || tabs[0].id;
  return (
    <div className="mb-6">
      <nav className="-mb-px flex gap-6" aria-label="Tabs">
        {tabs.map((tab) => (
          <Link
            key={tab.name}
            to={`/reporting/${tab.id}`}
            className={classNames(
              tab.id === displayedTabId
                ? "border-blue-700 text-gray-700"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
              "whitespace-nowrap py-2 px-1 border-b-3 font-medium text-base"
            )}
            aria-current={tab.id === displayedTabId ? "page" : undefined}
          >
            {tab.name}
          </Link>
        ))}
      </nav>
    </div>
  );
};

const Reporting = () => {
  useDocumentTitle("Reporting");
  const { path } = useRouteMatch();

  return (
    <div aria-label="Reporting" className="flex flex-col flex-1 fs-unmask">
      <div className="flex flex-wrap items-center justify-between h-14 px-4 sm:px-6 border-b bg-gray-50">
        <AppLink
          className="mb-2 sm:mb-0 text-2xl font-semibold mr-8 flex items-center"
          to="/assessments"
        >
          Reporting
        </AppLink>
      </div>
      <div className="p-6">
        <div className="mb-6">
          <Switch>
            <Route exact path={[`${path}/:navId`, path]}>
              <Nav />
            </Route>
          </Switch>
        </div>
        <div className="bg-white flex rounded-lg shadow-md ">
          <Switch>
            <Route exact path={[`${path}/performance-assessments`, path]}>
              <AssessmentReport assessmentType={AssessmentType.Performance} />
            </Route>
            <Route exact path={`${path}/manager-effectiveness`}>
              <AssessmentReport assessmentType={AssessmentType.Manager} />
            </Route>
            <Route exact path={`${path}/oneonone`}>
              <OneononeReport />
            </Route>
            <Route exact path={`${path}/adoption`}>
              <AdoptionReport />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Reporting;
