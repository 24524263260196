import { gql } from "@apollo/client";

export const OrgSettingsOrganizationFragment = gql`
  fragment OrgSettingsOrganizationFragment on OrganizationNode {
    id
    name
    pricingTier
    pricingTierValidUntil
    membershipSetting
    domains
    goalLabel
    personal
    keyResultLabel
    teamLabel
    orgLabel
    recognitionLabel
    oneononeLabel
    reviewLabel
    expectationLabel
    conversationLabel
    developmentLabel
    competencyLabel
    enableMeetingSummarization
    transcribeMeetingsByDefault
    effectivePricingTier
    defaultGoalState
    defaultDecisionState
    hasSlackConnection
    hasTeamsTranscriptConnection
    hasGithubInstallation
    quarterStartMonth
    directorySync {
      id
      state
      type
      name
    }
    userMembership {
      id
      role
    }
    featureFlags {
      actionItems
      decisions
      feedbacks
      goals
      documents
      recognitions
      kpis
      explorer
      homepageMyConnections
      workHistory
    }
  }
`;

export default gql`
  ${OrgSettingsOrganizationFragment}
  query getOrganizationSettings {
    me {
      id
      organizations(personal: false) {
        edges {
          node {
            ...OrgSettingsOrganizationFragment
          }
        }
      }
    }
  }
`;
