import { Route, Switch, useRouteMatch } from "react-router-dom";

import Button, { ButtonSize } from "@components/button/button";
import AppLink from "@components/link/link";

import ExplorerBookmarks from "./bookmarks";
import Explorer from "./explorer";

const ExplorerPage = () => {
  const { path } = useRouteMatch();

  // RENDER
  return (
    <div
      aria-label="Artifact explorer container"
      className="flex flex-col flex-1 text-sm @container"
    >
      <div className="flex flex-col bg-gray-50 justify-center md:h-14 py-4 md:py-0 px-4 lg:px-6 border-b">
        <div className="flex justify-between">
          <AppLink to="/explorer" className="text-xl font-medium flex-1">
            Explorer
          </AppLink>
          <div className="flex items-center gap-4">
            <Button size={ButtonSize.small} to="/explorer/bookmarks">
              Manage bookmarks
            </Button>
          </div>
        </div>
      </div>

      <Switch>
        <Route exact path={path}>
          <Explorer />
        </Route>
        <Route exact path={`${path}/bookmarks`}>
          <ExplorerBookmarks />
        </Route>
      </Switch>
    </div>
  );
};

export default ExplorerPage;
