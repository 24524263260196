import { useMutation } from "@apollo/client";
import { SyntheticEvent, useEffect, useState } from "react";

import Button from "@components/button/button";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import setAgendaAsTemplateMutation from "./graphql/set-agenda-as-template-mutation";

enum DialogState {
  Saving,
  Prompting,
  PromptingExistingTemplate,
  Saved,
  CanSave,
}

type Props = {
  meeting: {
    id: number;
  };
  meetingGroup: {
    id: number;
    hasTemplate?: boolean | null;
  };
  onClose: (value: boolean) => void;
};

const SetAgendaAsTemplateDialog = ({
  meeting,
  meetingGroup,
  onClose,
}: Props) => {
  const [dialogState, setDialogState] = useState(() =>
    meetingGroup.hasTemplate === false
      ? DialogState.PromptingExistingTemplate
      : DialogState.Prompting
  );

  useEffect(() => {
    if (dialogState === DialogState.CanSave) {
      setDialogState(DialogState.Saving);
      setAgendaAsTemplate({
        variables: { meetingId: meeting.id },
        onError: onNotificationErrorHandler(),
      });
    }
  }, [dialogState]);

  const [setAgendaAsTemplate] = useMutation(setAgendaAsTemplateMutation, {
    onCompleted: () => {
      setDialogState(DialogState.Saved);
    },
  });

  const handleSubmitForm = (e: SyntheticEvent) => {
    e.preventDefault();
    setDialogState(DialogState.Saving);
    setAgendaAsTemplate({
      variables: { meetingId: meeting.id },
      onError: onNotificationErrorHandler(),
    });
  };

  const renderContent = (state: DialogState) => {
    switch (state) {
      case DialogState.Saving:
        return (
          <div className="mt-6 mb-8 flex items-center gap-2">
            <Loading size="5" mini className="shrink-0" />
            Updating template...
          </div>
        );
      case DialogState.Saved:
        return (
          <div className="mt-6 mb-8">
            Meeting template updated!{" "}
            <AppLink
              className="text-blue-600"
              to={`/meeting/${meetingGroup.id}/template`}
            >
              View template
            </AppLink>
          </div>
        );
      case DialogState.Prompting:
        return (
          <div className="mt-6 mb-8">
            Reuse the current agenda as template of upcoming meetings.
          </div>
        );
      case DialogState.PromptingExistingTemplate:
        return (
          <div className="mt-6 mb-8">
            This meeting already has a template applied. This action will
            replace the template applied to future meetings.
          </div>
        );
    }
  };

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Modal open onClose={onClose}>
      <div
        className="px-4 py-5 sm:p-6"
        aria-label="Set agenda as template dialog"
      >
        <ModalTitle onClose={handleClose}>
          Set agenda as meeting template
        </ModalTitle>
        <div className="text-sm">{renderContent(dialogState)}</div>
        <form className="mt-5" onSubmit={handleSubmitForm}>
          {dialogState === DialogState.Prompting ||
          dialogState === DialogState.PromptingExistingTemplate ? (
            <div className="mt-4 flex items-center gap-4">
              <Button
                theme="primary"
                type="submit"
                aria-label="Replace template button"
              >
                Continue
              </Button>
              <Button type="button" onClick={handleClose}>
                Cancel
              </Button>
            </div>
          ) : (
            <div className="mt-4">
              <Button type="button" onClick={handleClose}>
                Ok
              </Button>
            </div>
          )}
        </form>
      </div>
    </Modal>
  );
};

export default SetAgendaAsTemplateDialog;
