import { useMutation, useQuery } from "@apollo/client";
import { XIcon } from "@heroicons/react/outline";
import moment from "moment";
import { ChangeEvent, FormEventHandler, MouseEvent, useState } from "react";
import DatePicker from "react-datepicker";
import {
  AlignmentGoalFragmentFragment,
  ArtifactComponentFragmentFragment,
  DuplicateGoalMutationMutation,
  DuplicateGoalMutationMutationVariables,
  GetArtifactSidebarQueryQuery,
  GetArtifactSidebarQueryQueryVariables,
  GoalAlignmentDialogGoalFragmentFragment,
  GoalScope,
} from "types/graphql-schema";

import getArtifactActivitiesQuery from "@apps/artifact-sidebar/graphql/get-artifact-activities-query";
import getArtifactSidebarQuery from "@apps/artifact-sidebar/graphql/get-artifact-sidebar-query";
import getDashboardInsightsQuery from "@apps/dashboard-new/graphql/get-dashboard-insights-query";
import GoalAlignmentPickerDialog from "@apps/goal-alignment-dialog-picker/goal-alignment-dialog-picker";
import useLabel from "@apps/use-label/use-label";
import { successNotificationVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import DatePickerCustomHeaderWithClearButton from "@components/datepicker/custom-header-with-clear-button";
import GraphqlError from "@components/error/graphql-error";
import GoalIcon from "@components/goal-icon/goal-icon";
import Input from "@components/input/input";
import AppLink, { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { isGoalArtifactNode } from "@helpers/helpers";

import duplicateGoalMutation from "./graphql/duplicate-goal-mutation";

const initialForm: {
  title: string;
  copyProgress: boolean;
  duplicateKeyResults: boolean;
  startDate?: string | null;
  dueDate?: string | null;
  parentGoal?:
    | ArtifactComponentFragmentFragment
    | GoalAlignmentDialogGoalFragmentFragment
    | AlignmentGoalFragmentFragment
    | null;
} = {
  title: "",
  copyProgress: false,
  duplicateKeyResults: false,
  startDate: null,
  dueDate: null,
  parentGoal: null,
};

const indexedGoalScopes = [
  GoalScope.Organization,
  GoalScope.Team,
  GoalScope.Personal,
];

const GoalDuplicationDialogLabel = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <label
    className={classNames(
      "text-gray-500 text-sm flex items-center gap-2 cursor-pointer font-medium",
      className
    )}
  >
    {children}
  </label>
);

const GoalDuplicationDialog = ({
  artifactId,
  onClose,
}: {
  artifactId: number;
  onClose: () => void;
}) => {
  const label = useLabel();
  const link = useLink();
  const [parentGoalButtonRef, setParentGoalButtonRef] =
    useState<HTMLElement | null>(null);
  const [isShowingInvalidForm, setIsShowingInvalidForm] = useState(false);
  const [isOpenGoalAlignmentDialog, setIsOpenGoalAlignmentDialog] =
    useState(false);

  // use to track changes between form and initial form
  const [form, setForm] = useState(initialForm);

  let formInvalid = "";
  if (form.title.trim() === "") {
    formInvalid = "Enter a title";
  }

  const { data, loading: loadingQuery } = useQuery<
    GetArtifactSidebarQueryQuery,
    GetArtifactSidebarQueryQueryVariables
  >(getArtifactSidebarQuery, {
    variables: {
      artifactId,
      keyResultLimit: 0,
    },
    onError: onNotificationErrorHandler(),
    onCompleted: (data) => {
      const artifact = data.artifact;
      if (isGoalArtifactNode(artifact)) {
        setForm({
          ...form,
          startDate: artifact.startDate,
          dueDate: artifact.dueDate,
          title: `Copy of ${artifact.title}` || "Copy",
          parentGoal: artifact.parentGoal,
        });
      }
    },
  });
  const goalToDuplicate = data?.artifact;

  const [duplicateGoal, { error, loading: loadingMutation }] = useMutation<
    DuplicateGoalMutationMutation,
    DuplicateGoalMutationMutationVariables
  >(duplicateGoalMutation);

  const handleClose = () => {
    onClose();
    setIsShowingInvalidForm(false);
  };

  const handleSubmitForm: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClickDuplicateGoal = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (formInvalid) {
      setIsShowingInvalidForm(true);
      return;
    }
    return duplicateGoal({
      variables: {
        goalId: artifactId,
        parentGoalId: form.parentGoal?.id,
        title: form.title,
        startDate: form.startDate,
        dueDate: form.dueDate,
        resetProgress: !form.copyProgress,
        duplicateKeyResults: form.duplicateKeyResults,
      },
      onError: onNotificationErrorHandler(),
      onCompleted: (response) => {
        setIsShowingInvalidForm(false);
        successNotificationVar({
          title: `${label("goal", { capitalize: true })} duplicated.`,
          timeout: 2000,
        });
        link.redirect(
          `/artifact/${response.duplicateGoal?.duplicatedGoal?.id}`
        );
        handleClose();
      },
      refetchQueries: [getArtifactActivitiesQuery, getDashboardInsightsQuery],
    });
  };

  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, title: e.target.value });
  };

  const handleChangeCopyProgress = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, copyProgress: e.target.checked });
  };

  const handleChangeDuplicateKeyResults = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setForm({ ...form, duplicateKeyResults: e.target.checked });
  };

  const handleChangeStartDate = (updatedDate: Date | null) => {
    const startDate = updatedDate
      ? moment(updatedDate).format("YYYY-MM-DD")
      : null;
    setForm({ ...form, startDate });
  };

  const handleChangeEndDate = (updatedDate: Date | null) => {
    const dueDate = updatedDate
      ? moment(updatedDate).format("YYYY-MM-DD")
      : null;
    setForm({ ...form, dueDate });
  };

  const handleClickClearParentGoal = () => {
    setIsOpenGoalAlignmentDialog(false);
    setForm({ ...form, parentGoal: null });
  };

  const handleSelectParentGoal = (
    goal:
      | GoalAlignmentDialogGoalFragmentFragment
      | AlignmentGoalFragmentFragment
  ) => {
    setIsOpenGoalAlignmentDialog(false);
    setForm({ ...form, parentGoal: goal });
  };

  const scopeIndexOfCurrentGoal = indexedGoalScopes.findIndex(
    (scope) =>
      goalToDuplicate &&
      isGoalArtifactNode(goalToDuplicate) &&
      scope === goalToDuplicate.scope
  );
  const allowedGoalScopes = indexedGoalScopes.slice(
    0,
    scopeIndexOfCurrentGoal + 1
  );

  const loading = loadingQuery || loadingMutation;
  return (
    <Modal onClose={handleClose}>
      <form className="flex flex-col gap-6 p-6" onSubmit={handleSubmitForm}>
        <ModalTitle onClose={handleClose}>
          <div className="flex items-center gap-2">
            Duplicate {label("goal")}
            {loading && <Loading mini size={5} />}
          </div>
        </ModalTitle>
        <div className="">
          <GoalDuplicationDialogLabel className="mb-1">
            Title
          </GoalDuplicationDialogLabel>
          <div>
            <Input
              value={form.title}
              disabled={loading}
              onChange={handleChangeTitle}
            />
          </div>
        </div>
        <div className="flex gap-2 flex-col">
          <GoalDuplicationDialogLabel>
            <input
              type="checkbox"
              checked={form.copyProgress}
              onChange={handleChangeCopyProgress}
              disabled={loading}
            />
            Copy progress value
          </GoalDuplicationDialogLabel>
          <GoalDuplicationDialogLabel>
            <input
              type="checkbox"
              checked={form.duplicateKeyResults}
              onChange={handleChangeDuplicateKeyResults}
              disabled={loading}
            />
            Copy key results
          </GoalDuplicationDialogLabel>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex gap-4 items-center">
            <GoalDuplicationDialogLabel className="w-20">
              Start date
            </GoalDuplicationDialogLabel>
            <div className="flex-1 min-h-8">
              <DatePicker
                selected={
                  form.startDate ? moment(form.startDate).toDate() : null
                }
                disabled={loading}
                placeholderText="No start date"
                onChange={handleChangeStartDate}
                dateFormat="MMM d, yyyy"
                className={classNames(
                  "text-sm px-3 py-1.5 outline-0 border bg-transparent rounded-lg",
                  loading && "bg-gray-100"
                )}
                renderCustomHeader={({
                  date: currentDate,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <DatePickerCustomHeaderWithClearButton
                    date={
                      form.startDate ? moment(form.startDate).toDate() : null
                    }
                    onChangeDate={handleChangeStartDate}
                    clearable
                    currentDate={currentDate}
                    decreaseMonth={decreaseMonth}
                    increaseMonth={increaseMonth}
                    prevMonthButtonDisabled={prevMonthButtonDisabled}
                    nextMonthButtonDisabled={nextMonthButtonDisabled}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center">
            <GoalDuplicationDialogLabel className="w-20">
              End date
            </GoalDuplicationDialogLabel>
            <div className="flex-1 min-h-8">
              <DatePicker
                selected={form.dueDate ? moment(form.dueDate).toDate() : null}
                placeholderText="No end date"
                onChange={handleChangeEndDate}
                dateFormat="MMM d, yyyy"
                className={classNames(
                  "text-sm px-3 py-1.5 outline-0 border bg-transparent rounded-lg",
                  loading && "bg-gray-100"
                )}
                renderCustomHeader={({
                  date: currentDate,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <DatePickerCustomHeaderWithClearButton
                    date={form.dueDate ? moment(form.dueDate).toDate() : null}
                    onChangeDate={handleChangeEndDate}
                    clearable
                    currentDate={currentDate}
                    decreaseMonth={decreaseMonth}
                    increaseMonth={increaseMonth}
                    prevMonthButtonDisabled={prevMonthButtonDisabled}
                    nextMonthButtonDisabled={nextMonthButtonDisabled}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center">
            <GoalDuplicationDialogLabel className="w-20">
              Parent
            </GoalDuplicationDialogLabel>
            <div className="flex-1 min-h-8 text-sm flex items-center">
              {loading ? null : form.parentGoal &&
                isGoalArtifactNode(form.parentGoal) ? (
                <div className="flex items-center gap-2">
                  <AppLink
                    to={`/artifact/${form.parentGoal.id}`}
                    target="_blank"
                    className="flex items-center gap-1 hover:underline"
                  >
                    <GoalIcon
                      scope={form.parentGoal.scope}
                      state={form.parentGoal.state}
                      size={5}
                    />
                    {form.parentGoal.title}
                  </AppLink>
                  <Button
                    onClick={handleClickClearParentGoal}
                    icon={XIcon}
                    theme={buttonTheme.iconGray}
                    tooltip={`Remove parent ${label("goal")}`}
                  />
                </div>
              ) : (
                <div className="flex items-center gap-2">
                  <Button
                    ref={setParentGoalButtonRef}
                    onClick={() => setIsOpenGoalAlignmentDialog(true)}
                  >
                    Select parent {label("goal")}
                  </Button>
                  {isOpenGoalAlignmentDialog && parentGoalButtonRef && (
                    <GoalAlignmentPickerDialog
                      externalReferenceElement={parentGoalButtonRef}
                      alignmentType="parent"
                      onClose={() => setIsOpenGoalAlignmentDialog(false)}
                      allowedGoalScopes={allowedGoalScopes}
                      onSelectGoal={handleSelectParentGoal}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-6 text-sm">
          {formInvalid && isShowingInvalidForm && (
            <div className="text-red-700 empty:hidden mb-4">{formInvalid}</div>
          )}
          <div className="flex gap-4">
            <Button
              disabled={loadingMutation}
              type="submit"
              theme="primary"
              onClick={handleClickDuplicateGoal}
              className="flex items-center gap-2"
            >
              {loadingMutation ? "Duplicating" : "Duplicate"} {label("goal")}
              {loadingMutation && <Loading mini size="4" color="white" />}
            </Button>
            <Button
              disabled={loadingMutation}
              onClick={handleClose}
              type="button"
            >
              Cancel
            </Button>
          </div>
          {error && (
            <div className="mt-4">
              <GraphqlError
                whatDidNotWork="The artifact could not be created."
                error={error}
              />
            </div>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default GoalDuplicationDialog;
