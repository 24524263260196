import { compact } from "lodash";
import { useState } from "react";
import { AttendeeRole, AttendeeStatus } from "types/graphql-schema";

import MeetingDialog from "@apps/meeting-dialog/meeting-dialog";
import { currentUserVar } from "@cache/cache";

import { ComplianceProgramActionOneononeItemProps } from "../helpers";
import { UserRelationEnum } from "../helpers";
import ComplianceProgramActionItem from "./compliance-program-action-item";

const ComplianceProgramActionItemFor1on1 = ({
  item,
}: {
  item: ComplianceProgramActionOneononeItemProps;
}) => {
  const currentUser = currentUserVar();
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const { key, ...extendedItem } = {
    ...item,
    button: {
      ...item.button,
      onClick: () => setIsOpen(true),
    },
  };

  return (
    <>
      {isOpen && (
        <MeetingDialog
          onClose={handleCloseDialog}
          formOptions={{
            templateId: item.requiredTopicTemplateId,
            isFormalOneonone: true,
            facilitatorId:
              item.userRelation === UserRelationEnum.report
                ? currentUser.id
                : item.user?.id,
            attendees: compact([
              {
                ...currentUser,
                role: AttendeeRole.Required,
                participantStatus: AttendeeStatus.NotResponded,
              },
              item.user && {
                ...item.user,
                role: AttendeeRole.Required,
                participantStatus: AttendeeStatus.NotResponded,
                email: item.user.email || "",
              },
            ]),
          }}
        />
      )}
      <ComplianceProgramActionItem {...extendedItem} />
    </>
  );
};

export default ComplianceProgramActionItemFor1on1;
