import { useMutation, useQuery } from "@apollo/client";
import { compact, uniqueId } from "lodash";
import { MouseEvent, useState } from "react";
import { ArtifactType } from "types/graphql-schema";

import deleteCommentMutation from "@apps/comments/graphql/delete-comment-mutation";
import updateCommentMutation from "@apps/comments/graphql/update-comment-mutation";
import Button from "@components/button/button";
import Dropdown from "@components/dropdown/dropdown";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import TimeAgoCustom from "@components/time-ago/time-ago";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import CommentWYSIWYG from "@components/wysiwyg/comment-wysiwyg";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import { batchClient } from "@graphql/client";
import { classNames } from "@helpers/css";
import { getCommentUrl, parseStringToJSON } from "@helpers/helpers";

import getWysiwygArtifactCommentQuery from "./graphql/get-wysiwyg-artifact-comment-query";

const ArtifactComment = ({
  commentId,
  artifact,
  meetingGroupId,
  meetingId,
  onRemoveCommentAttribute,
}: {
  commentId: number;
  artifact: {
    id: number;
    artifactType: ArtifactType;
  };
  meetingGroupId: number;
  meetingId: number;
  onRemoveCommentAttribute: () => void;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formComment, setFormComment] = useState(null);
  const { data } = useQuery(getWysiwygArtifactCommentQuery, {
    variables: { commentId },
    client: batchClient,
    onError: onNotificationErrorHandler(),
  });
  const comment = data?.comment;

  const [deleteComment] = useMutation(deleteCommentMutation, {
    onCompleted: () => {
      onRemoveCommentAttribute();
    },
  });
  const [updateComment, { loading }] = useMutation(updateCommentMutation, {
    update: (cache, { data }) => {
      const commentId = cache.identify(comment);
      if (commentId) {
        cache.modify({
          id: commentId,
          fields: {
            comment() {
              return data.createOrUpdateComment.comment.comment;
            },
            id() {
              return data.createOrUpdateComment.comment.id;
            },
          },
        });
      }
    },
  });

  const handleDeleteComment = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure you want to delete this comment?")) {
      deleteComment({
        variables: { commentId },
        onError: onNotificationErrorHandler(),
      });
    }
  };

  const handleEditComment = () => {
    setFormComment(comment.comment);
    setIsEditing(true);
  };

  const handleCancel = () => {
    setFormComment(comment.comment);
    setIsEditing(false);
  };

  const handleSaveForm = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (isEmptyValue(formComment)) {
      return;
    }
    updateComment({
      variables: {
        comment: JSON.stringify(formComment),
        commentId: comment.id,
      },
      onError: onNotificationErrorHandler(),
      optimisticResponse: {
        createOrUpdateComment: {
          comment: {
            id: uniqueId("temp-comment"),
            comment: formComment,
          },
        },
      },
      onCompleted: () => {
        setIsEditing(false);
      },
    });
  };

  const handleChangeComment = (value: any) => {
    setFormComment(value);
  };

  const options: {
    label: string;
    onClick: () => void;
  }[] = compact([
    comment?.canUpdate.permission && {
      label: "Edit comment",
      onClick: handleEditComment,
    },
    comment?.canUpdate.permission && {
      label: "Hide comment",
      onClick: () => onRemoveCommentAttribute(),
    },
    comment?.canDelete.permission && {
      label: "Delete comment",
      onClick: handleDeleteComment,
    },
  ]);

  return comment && comment.artifact.id === artifact.id ? (
    <div
      className={classNames(
        "bg-white/60 text-sm rounded-b-lg",
        "relative -ml-3 -mr-2 -mb-0.5 border-t border-t-black/15 px-4 pt-3 pb-1.5"
      )}
    >
      <div>
        <div className="text-xs tracking-tight text-gray-500 mb-2 not-prose flex items-center gap-1">
          <div>
            <AppLink
              className="hover:underline"
              to={`/dashboard/user/${comment.creator.id}`}
            >
              {comment.creator.name}
            </AppLink>{" "}
            commented{" "}
            <AppLink
              className="hover:underline"
              to={getCommentUrl({
                artifactId: artifact?.id,
                artifactType: artifact?.artifactType,
                commentId: comment.id,
                meetingGroupId,
                meetingId,
              })}
            >
              <TimeAgoCustom date={comment.created} />
            </AppLink>
          </div>
          {options.length > 0 && <Dropdown options={options} />}
        </div>
        <div>
          <CommentWYSIWYG
            key={`comment-${String(isEditing)}-${commentId}-${comment.comment}`}
            editable={isEditing}
            mentionsConfig={{
              artifactId: artifact.id,
            }}
            uploadVariable={{
              artifactId: artifact?.id,
            }}
            className={classNames(
              isEditing
                ? "py-1 px-2 my-2 bg-white border rounded-md text-sm"
                : null
            )}
            value={parseStringToJSON(comment.comment)}
            onChange={handleChangeComment}
          />
          {isEditing && (
            <div className="mt-2 flex items-center gap-2">
              <Button
                type="button"
                aria-label="Comment form submit button"
                text="Save"
                theme="primary"
                disabled={isEmptyValue(formComment) || loading}
                className="mr-2"
                onClick={handleSaveForm}
              />
              <Button type="button" text="Cancel" onClick={handleCancel} />
              {loading && <Loading size="5" mini />}
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default ArtifactComment;
