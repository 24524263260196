import { useEffect, useRef } from "react";

const useVisibilityControlledInterval = (
  callback: () => void,
  delay: number
) => {
  const savedCallback = useRef<() => void>();

  // Save the latest callback to avoid re-subscribing on every render
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    let intervalId: number | null = null;

    // Function to check visibility and set/clear interval
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        // If page is visible, start the interval
        intervalId = window.setInterval(() => {
          if (savedCallback.current) {
            savedCallback.current();
          }
        }, delay);
      } else if (intervalId) {
        // Clear the interval if the page is hidden
        clearInterval(intervalId);
      }
    };

    // Listen for page visibility changes
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Initially call to set interval if page is visible
    handleVisibilityChange();

    return () => {
      if (intervalId) clearInterval(intervalId); // Cleanup interval
      document.removeEventListener("visibilitychange", handleVisibilityChange); // Cleanup event listener
    };
  }, [delay]);
};

export default useVisibilityControlledInterval;
