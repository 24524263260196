import { gql } from "@apollo/client";

export default gql`
  query getUrlPreview($url: String!, $forceRefresh: Boolean) {
    urlToInfoCard(url: $url, forceRefresh: $forceRefresh) {
      id
      title
      url
      closedAt
      assignees {
        username
        url
      }
      ... on SalesforceOpportunityNode {
        amount
      }
      ... on HubspotDealNode {
        amount
        closedWon
        stageName
      }
      ... on GithubIssueNode {
        isMerged
        status
      }
      ... on LinearProjectNode {
        lead {
          username
          url
        }
      }
    }
  }
`;
