import { Fragment } from "react";
import { AssessmentQuestionResponses } from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";

import AssessmentQuestionItem from "../questions/assessment-question-item";
import {
  AssessmentAnswer,
  AssessmentQuestionWithWeight,
} from "../questions/types";
import AssessmentQuestionChrome from "./assessment-question-chrome";

const GoalBasedQuestions = ({
  individualGoalBasedQuestions,
  overallGoalBasedQuestion,
  isSelfAssessment,
  proposedAnswers,
  formDisabled,
  showErrors,
  isQuestionWeightingEnabled,
  totalQuestionWeight,
  onUpdateAnswer,
}: {
  isSelfAssessment: boolean;
  overallGoalBasedQuestion?: AssessmentQuestionWithWeight;
  individualGoalBasedQuestions: AssessmentQuestionWithWeight[];
  proposedAnswers: AssessmentAnswer[];
  formDisabled: boolean;
  showErrors: boolean;
  isQuestionWeightingEnabled: boolean;
  totalQuestionWeight: number;
  onUpdateAnswer: (answer: AssessmentAnswer) => void;
}) => {
  const label = useLabel();
  const overallGoalBasedQuestionAnswer = proposedAnswers.find(
    (answer) =>
      overallGoalBasedQuestion &&
      answer.questionId === overallGoalBasedQuestion.id
  );
  const sumAnswersOfIndividualGoalBasedQuestions =
    individualGoalBasedQuestions.reduce((sum, question) => {
      const answer = proposedAnswers.find(
        (answer) => answer.questionId === question.id
      );
      if (!answer || !answer.integerAnswer) return sum;
      return sum + answer.integerAnswer;
    }, 0);
  const averageScoreOfIndividualGoalBasedQuestions =
    individualGoalBasedQuestions.length === 0 ||
    sumAnswersOfIndividualGoalBasedQuestions === 0
      ? 0
      : sumAnswersOfIndividualGoalBasedQuestions /
        individualGoalBasedQuestions.length;

  return (
    <Fragment>
      <AssessmentQuestionChrome>
        <div className="font-bold text-xl mb-6">
          {label("goal", { pluralize: true, capitalize: true })}
        </div>
        <div className="font-medium mb-2">
          Assess the {label("goal", { pluralize: true })} due in this{" "}
          {label("review")} cycle
        </div>
        <div className="border rounded-md">
          {individualGoalBasedQuestions.length === 0 && (
            <div className="p-6">
              <div className="text-sm text-gray-500">
                No {label("goal")}s to assess.
              </div>
            </div>
          )}
          {individualGoalBasedQuestions.map((questionWithWeight, index) => {
            const { question, weight } = questionWithWeight;
            if (
              question.responses ===
                AssessmentQuestionResponses.ExcludeSelfAssessment &&
              isSelfAssessment
            ) {
              return null;
            }
            if (
              question.responses ===
                AssessmentQuestionResponses.SelfAssessmentOnly &&
              !isSelfAssessment
            ) {
              return null;
            }
            const answer = proposedAnswers.find(
              (answer) => answer.questionId === question.id
            );
            return (
              <AssessmentQuestionItem
                key={question.id}
                answer={answer}
                question={question}
                weight={weight}
                totalQuestionWeight={totalQuestionWeight}
                index={index}
                onUpdateAnswer={onUpdateAnswer}
                formDisabled={formDisabled}
                showError={showErrors}
                isQuestionWeightingEnabled={isQuestionWeightingEnabled}
              />
            );
          })}
        </div>
      </AssessmentQuestionChrome>

      {individualGoalBasedQuestions.length > 0 && overallGoalBasedQuestion && (
        <AssessmentQuestionChrome>
          <div className="">
            <div className="font-medium">
              Provide an overall assessment on{" "}
              {label("goal", { pluralize: true })}
            </div>

            <div
              className="mt-1 text-sm text-gray-500"
              key={overallGoalBasedQuestion.id}
            >
              Average score based on above {label("goal")} assessments:{" "}
              {String(
                averageScoreOfIndividualGoalBasedQuestions.toFixed(1)
              ).replace(".0", "")}
              /5
            </div>
          </div>
          <div className="border rounded-md mt-3">
            <AssessmentQuestionItem
              key={overallGoalBasedQuestion.id}
              answer={overallGoalBasedQuestionAnswer}
              question={overallGoalBasedQuestion.question}
              weight={overallGoalBasedQuestion.weight}
              totalQuestionWeight={totalQuestionWeight}
              index={0}
              onUpdateAnswer={onUpdateAnswer}
              formDisabled={formDisabled}
              showError={showErrors}
              isQuestionWeightingEnabled={isQuestionWeightingEnabled}
            />
          </div>
        </AssessmentQuestionChrome>
      )}
    </Fragment>
  );
};

export default GoalBasedQuestions;
