import { useMutation, useQuery } from "@apollo/client";
import { XIcon } from "@heroicons/react/outline";
import { isEqual, uniqBy } from "lodash";
import { useState } from "react";
import {
  AssessmentNominationFragment,
  AssessmentNomineeResponderFragment,
  GetAssessmentProgramNomineesQuery,
  GetAssessmentProgramNomineesQueryVariables,
  SaveAssessmentNomineesMutation,
  SaveAssessmentNomineesMutationVariables,
} from "types/graphql-schema";

import getAssessmentProgramNomineesQuery from "@apps/assessments/graphql/get-assessment-program-nominees-query";
import saveAssessmentNomineesMutation from "@apps/assessments/graphql/save-assessment-nominees-mutation";
import Avatar from "@components/avatar/avatar";
import Button, { ButtonSize, buttonTheme } from "@components/button/button";
import Loading from "@components/loading/loading";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import { UserComboboxOption } from "@components/user-combobox/user-combobox-list";
import { assertEdgesNonNull } from "@helpers/helpers";
import { pluralize } from "@helpers/string";

const AssessmenNominationtDialog = ({
  assessmentNomination,
  onClose,
}: {
  assessmentNomination: AssessmentNominationFragment;
  onClose: () => void;
}) => {
  const [initialNominees, setInitialNominees] = useState<
    AssessmentNomineeResponderFragment[]
  >([]);
  const [nominees, setNominees] = useState<
    AssessmentNomineeResponderFragment[]
  >([]);

  const { loading } = useQuery<
    GetAssessmentProgramNomineesQuery,
    GetAssessmentProgramNomineesQueryVariables
  >(getAssessmentProgramNomineesQuery, {
    fetchPolicy: "network-only",
    variables: {
      programId: assessmentNomination.complianceProgram.id,
      targetId: assessmentNomination.targetUser.id,
    },
    onCompleted: (response) => {
      const newNominees = uniqBy(
        response?.complianceProgram?.nominationsForUser
          ? assertEdgesNonNull(response?.complianceProgram?.nominationsForUser)
          : [],
        (nominee) => nominee.responder.id
      ).map((nominee) => nominee.responder);
      setNominees(newNominees);
      setInitialNominees(newNominees);
    },
    onError: onNotificationErrorHandler(),
  });

  const nomineeResponderIds = nominees.map((nominee) => nominee.id);
  const nomineesWithoutTarget = nominees.filter(
    (nominee) => nominee.id !== assessmentNomination.targetUser.id
  );

  const {
    options,
    loading: comboboxLoading,
    query,
    setQuery,
  } = useUserComboboxQuery({
    excludeUserIds: nomineeResponderIds.concat(
      assessmentNomination.targetUser.id
    ),
  });

  const [saveAssessmentNominees, { loading: loadingSave }] = useMutation<
    SaveAssessmentNomineesMutation,
    SaveAssessmentNomineesMutationVariables
  >(saveAssessmentNomineesMutation);

  const handleSaveNominations = () => {
    saveAssessmentNominees({
      variables: {
        assessmentTemplateId: assessmentNomination.assessmentTemplate.id,
        complianceProgramId: assessmentNomination.complianceProgram.id,
        targetId: assessmentNomination.targetUser.id,
        responderIds: nomineeResponderIds,
      },
      onCompleted: () => {
        onClose();
      },
      onError: onNotificationErrorHandler(),
    });
  };

  const handleChangeUserComboboxValue = (option: UserComboboxOption) => {
    if (option.type === "user") {
      setQuery("");
      setNominees([
        ...nominees,
        {
          id: option.id,
          name: option.name,
          avatar: option.avatar,
          __typename: "UserNode",
          activeAssessmentNominationCount: 0,
        },
      ]);
    }
  };

  const handleRemoveNomination = (nomineeId: number) => {
    setNominees(nominees.filter(({ id }) => id !== nomineeId));
  };

  return (
    <Modal onClose={onClose} open alignedTop>
      <div className="p-6 flex flex-col gap-6">
        <div>
          <ModalTitle onClose={onClose}>
            Nominations: {assessmentNomination.complianceProgram.title}
          </ModalTitle>
          <div className="text-xs mt-1 text-gray-500">
            For{" "}
            <span className="font-medium">
              {assessmentNomination.targetUser.name}
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="font-medium">
            Nominees ({nomineesWithoutTarget.length})
          </div>
          <div className="text-sm">
            {loading ? (
              <Loading>Loading nominees</Loading>
            ) : nomineesWithoutTarget.length > 0 ? (
              <div className="divide-y border-t border-b">
                {nomineesWithoutTarget.map((nominee) => (
                  <div
                    key={nominee.id}
                    className="py-1.5 flex items-center gap-1 justify-between"
                  >
                    <div className="flex items-center gap-1.5 pl-1">
                      <Avatar user={nominee} size={4} />
                      {nominee.name}
                      {nominee.activeAssessmentNominationCount > 0 && (
                        <span>
                          ({nominee.activeAssessmentNominationCount} active{" "}
                          {pluralize(
                            "nominations",
                            nominee.activeAssessmentNominationCount
                          )}
                          )
                        </span>
                      )}
                    </div>
                    <Button
                      size={ButtonSize.small}
                      icon={XIcon}
                      tooltip="Remove nominee"
                      theme={buttonTheme.iconGray}
                      onClick={() => handleRemoveNomination(nominee.id)}
                    />
                  </div>
                ))}
              </div>
            ) : null}
            {!loading && (
              <div className="max-w-96 mt-3">
                <UserCombobox
                  loading={comboboxLoading}
                  value={null}
                  options={options}
                  onChangeValue={handleChangeUserComboboxValue}
                  onChangeQuery={setQuery}
                  query={query}
                  placeholder="Select a nominee"
                  className="py-1.5 px-3"
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <Button
            theme={buttonTheme.primary}
            text="Save nominations"
            onClick={handleSaveNominations}
            disabled={loadingSave || isEqual(initialNominees, nominees)}
          />
          <Button text="Close" onClick={onClose} />
          {loadingSave && <Loading mini size={4} />}
        </div>
      </div>
    </Modal>
  );
};

export default AssessmenNominationtDialog;
