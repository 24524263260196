import { useQuery } from "@apollo/client";
import { ChatAltIcon } from "@heroicons/react/solid";
import { useCallback } from "react";
import {
  GetArtifactActivitiesQueryQuery,
  GetArtifactActivitiesQueryQueryVariables,
  GetArtifactSidebarQueryQuery,
} from "types/graphql-schema";

import ArtifactActivity from "@apps/activities/components/artifact-activity";
import getArtifactActivitiesQuery from "@apps/artifact-sidebar/graphql/get-artifact-activities-query";
import CommentForm from "@apps/comments/components/comment-form";
import { currentUserVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Button from "@components/button/button";
import GraphqlError from "@components/error/graphql-error";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { isPageHidden } from "@helpers/hooks/use-page-visible";

const ArtifactActivities = ({
  artifact,
  meetingId,
  meetingGroupId,
}: {
  artifact: GetArtifactSidebarQueryQuery["artifact"];
  meetingId?: number;
  meetingGroupId?: number;
}) => {
  const currentUser = currentUserVar();
  const { loading, error, data, refetch, fetchMore } = useQuery<
    GetArtifactActivitiesQueryQuery,
    GetArtifactActivitiesQueryQueryVariables
  >(getArtifactActivitiesQuery, {
    variables: { artifactId: artifact.id },
    pollInterval: 15 * 60 * 1000, // every 15 minutes
    skipPollAttempt: isPageHidden,
    onError: onNotificationErrorHandler(),
  });

  const handleCreatedComment = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleFetchMoreActivities = useCallback(() => {
    if (data?.artifact.activities.pageInfo) {
      fetchMore({
        variables: {
          after: data.artifact.activities.pageInfo.endCursor,
          merge: true,
        },
      });
    }
  }, [data, fetchMore]);

  // Render
  if (error) {
    return (
      <GraphqlError
        error={error}
        whatDidNotWork="The activity feed could not be loaded."
      />
    );
  }

  if (!data && loading) {
    return <Loading />;
  }
  if (!data || !data.artifact) {
    return null;
  }

  const activities = data.artifact.activities.edges.map((edge) => edge?.node!);

  return (
    <div className="relative">
      {activities.length > 0 && (
        <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" />
      )}
      <div className="pb-6">
        <div className="flex space-x-3">
          <div className="flex-shrink-0">
            <div className="relative">
              <Avatar size="8" user={currentUser} />
              <span className="absolute -bottom-0.5 -right-1 bg-gray-50 rounded-tl px-0.5 py-px">
                <ChatAltIcon className="h-4 w-4 text-gray-400" />
              </span>
            </div>
          </div>
          <div className="min-w-0 flex-1">
            <CommentForm
              key={artifact.id} // prevent crashing when switching artifact in sidebar
              artifactId={artifact.id}
              artifact={artifact}
              meetingGroupId={meetingGroupId}
              meetingId={meetingId}
              onSavedComment={handleCreatedComment}
            />
          </div>
        </div>
      </div>

      {activities.length > 0 && (
        <>
          <div aria-label="Comments" className="flex flex-col gap-6">
            {activities.map((activity) => (
              <ArtifactActivity
                key={activity.id}
                artifact={artifact}
                activity={activity}
              />
            ))}
          </div>
          {data.artifact.activities.pageInfo.hasNextPage && (
            <div className="flex justify-center mt-6">
              <Button text="Load more" onClick={handleFetchMoreActivities} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ArtifactActivities;
