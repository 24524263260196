import { compact } from "lodash";
import { useState } from "react";

import AppPopover from "@components/popover/app-popover";
import { classNames } from "@helpers/css";

type ProgramProgressValueType =
  | number
  | undefined
  | { value: number; label: string };

const ProgramProgressBar = ({
  className,
  notApplicable = 0,
  notStarted = 0,
  inProgress = 0,
  complete = 0,
}: {
  className?: string;
  notApplicable?: ProgramProgressValueType;
  notStarted?: ProgramProgressValueType;
  inProgress?: ProgramProgressValueType;
  complete?: ProgramProgressValueType;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const notApplicableValue =
    typeof notApplicable === "number" || notApplicable === undefined
      ? notApplicable
      : notApplicable?.value;
  const notStartedValue =
    typeof notStarted === "number" || notStarted === undefined
      ? notStarted
      : notStarted?.value;
  const inProgressValue =
    typeof inProgress === "number" || inProgress === undefined
      ? inProgress
      : inProgress?.value;
  const completedValue =
    typeof complete === "number" || complete === undefined
      ? complete
      : complete?.value;

  const notApplicableLabel =
    typeof notApplicable === "number" || notApplicable === undefined
      ? "N/A"
      : notApplicable?.label;
  const notStartedLabel =
    typeof notStarted === "number" || notStarted === undefined
      ? "Not Started"
      : notStarted?.label;
  const inProgressLabel =
    typeof inProgress === "number" || inProgress === undefined
      ? "In Progress"
      : inProgress?.label;
  const completedLabel =
    typeof complete === "number" || complete === undefined
      ? "Completed"
      : complete?.label;

  const sum =
    notApplicableValue + notStartedValue + inProgressValue + completedValue;

  const notApplicableWidth = sum > 0 ? (notApplicableValue / sum) * 100 : 0;
  const notStartedWidth = sum > 0 ? (notStartedValue / sum) * 100 : 0;
  const inProgressWidth = sum > 0 ? (inProgressValue / sum) * 100 : 0;
  const completedWidth = sum > 0 ? (completedValue / sum) * 100 : 0;
  const notApplicableWidthLabel =
    sum > 0 ? Math.round(notApplicableWidth * 100) / 100 : 0;
  const notStartedWidthLabel =
    sum > 0 ? Math.round(notStartedWidth * 100) / 100 : 0;
  const inProgressWidthLabel =
    sum > 0 ? Math.round(inProgressWidth * 100) / 100 : 0;
  const completedWidthLabel =
    sum > 0 ? Math.round(completedWidth * 100) / 100 : 0;

  const data = compact([
    notStartedValue > 0 && {
      label: notStartedLabel,
      value: notStartedValue,
      width: notStartedWidth,
      widthLabel: notStartedWidthLabel,
      bgClassName: "bg-amber-300",
      roundClassName: "bg-amber-300",
    },
    inProgressValue > 0 && {
      label: inProgressLabel,
      value: inProgressValue,
      width: inProgressWidth,
      widthLabel: inProgressWidthLabel,
      bgClassName: "bg-blue-400",
      roundClassName: "bg-blue-400",
    },
    completedValue > 0 && {
      label: completedLabel,
      value: completedValue,
      width: completedWidth,
      widthLabel: completedWidthLabel,
      bgClassName: "bg-emerald-600",
      roundClassName: "bg-emerald-600",
    },
    notApplicableValue > 0 && {
      label: notApplicableLabel,
      value: notApplicableValue,
      width: notApplicableWidth,
      widthLabel: notApplicableWidthLabel,
      bgClassName: "bg-gray-300",
      roundClassName: "bg-gray-300",
    },
  ]);

  return (
    <div className="relative">
      <AppPopover
        options={{
          onMouseEnter: () => setIsOpen(true),
          onMouseLeave: () => setIsOpen(false),
          className: classNames(AppPopover.className, "w-64"),
          static: true,
        }}
        content={
          isOpen && (
            <div className="flex flex-col gap-2 text-sm bg-white rounded-lg px-4 py-3 text-gray-600">
              {data.map((item) => (
                <div
                  key={item.label}
                  className={classNames("flex justify-between gap-2")}
                >
                  <div className="flex items-center gap-2">
                    <div
                      className={classNames(
                        "w-2 h-2 rounded-full",
                        item.roundClassName
                      )}
                    />
                    <div>{item.label}</div>
                  </div>
                  <div className="text-gray-700">
                    <span className="font-medium text-gray-800">
                      {item.value}
                    </span>{" "}
                    ({item.widthLabel}%)
                  </div>
                </div>
              ))}
              {data.length === 0 && (
                <div className="text-gray-400">No items.</div>
              )}
            </div>
          )
        }
      >
        <AppPopover.Button
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
          className={classNames(
            "h-2 bg-gray-200 rounded-md overflow-hidden flex items-center",
            className
          )}
        >
          {data.map((item) => (
            <div
              key={item.label}
              style={{ width: `${item.width}%` }}
              className={classNames("h-2", item.bgClassName)}
            />
          ))}
        </AppPopover.Button>
      </AppPopover>
    </div>
  );
};

export default ProgramProgressBar;
