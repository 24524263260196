import { useQuery } from "@apollo/client";
import { compact, uniqBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import {
  AssessmentGroupProviders,
  AssessmentType,
  ComplianceProgramParticipantStatus,
  ComplianceProgramState,
  GetComplianceProgramProgressQuery,
  GetComplianceProgramProgressQueryVariables,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import {
  currentOrganizationVar,
  currentUserVar,
  isAdminVar,
} from "@cache/cache";
import Button from "@components/button/button";
import Layout from "@components/layout/layout";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import useDocumentTitle from "@components/use-document-title/use-document-title";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import useUserComboboxQuery from "@components/user-combobox/use-user-combobox-query";
import UserCombobox from "@components/user-combobox/user-combobox";
import {
  UserComboboxOption,
  UserComboboxOptionType,
  UserComboboxOrgOption,
  UserComboboxTeamOption,
} from "@components/user-combobox/user-combobox-list";
import { assessmentTypeLabels } from "@helpers/constants";
import { classNames } from "@helpers/css";
import {
  assertEdgesNonNull,
  assertEdgesNonNullWithStringId,
} from "@helpers/helpers";
import useUrlQueryParams from "@helpers/hooks/use-url-query-params";

import getComplianceProgramProgressQuery from "../graphql/get-compliance-program-progress-query";
import ComplianceProgramEmailReminderDialog from "./compliance-program-email-reminder-dialog";
import ComplianceProgramProgressEntityTable, {
  ComplianceProgramReportingEntityTableCol,
  ComplianceProgramReportingEntityTableKeyName,
  ComplianceProgramReportingEntityTableParticipantStatusWithNominations,
} from "./compliance-program-progress-entity-table";
import ComplianceProgramProgressUserTable from "./compliance-program-progress-user-table";

export const complianceProgramReportingTableColsClassName =
  "w-36 max-w-36 2xl:w-48 2xl:max-w-48 shrink-0";
export const complianceProgramReportingTableColNameClassName = classNames(
  complianceProgramReportingTableColsClassName,
  "truncate"
);

function typeGuardIsUserComboboxTeamOption(
  obj: UserComboboxOption
): obj is UserComboboxTeamOption {
  return obj.type === UserComboboxOptionType.TEAM;
}

const participantCanBeReminded =
  (keyName: ComplianceProgramReportingEntityTableKeyName) =>
  (
    participantStatus: ComplianceProgramReportingEntityTableParticipantStatusWithNominations
  ) => {
    return (
      participantStatus[keyName] ===
        ComplianceProgramParticipantStatus.NotStarted ||
      participantStatus[keyName] ===
        ComplianceProgramParticipantStatus.InProgress
    );
  };

const filterParticipantsToBeReminded = (
  participantStatuses: ComplianceProgramReportingEntityTableParticipantStatusWithNominations[],
  keyName: ComplianceProgramReportingEntityTableKeyName
) => {
  return participantStatuses
    .filter(participantCanBeReminded(keyName))
    .map((participantStatus) => participantStatus.user);
};

const ComplianceProgramProgress = ({
  complianceProgramId,
}: {
  complianceProgramId: number;
}) => {
  const [isShowingSendReminderDialog, setIsShowingSendReminderDialog] =
    useState(false);
  const link = useLink();
  const label = useLabel();
  const url = `/programs/${complianceProgramId}/progress`;
  const currentOrganization = currentOrganizationVar();
  const currentUser = currentUserVar();
  const directReportIds = assertEdgesNonNull(currentUser.directReports).map(
    ({ id }) => id
  );
  const isAdmin = isAdminVar();
  const isManager = directReportIds.length > 0;
  const urlParams = useUrlQueryParams({ team: null });
  const teamUrlParam = urlParams.team ? parseInt(urlParams.team) : null;

  const defaultOrgContext: UserComboboxOrgOption = useMemo(
    () => ({
      id: currentOrganization.id,
      name: currentOrganization.name,
      type: UserComboboxOptionType.ORG,
    }),
    [currentOrganization.id, currentOrganization.name]
  );

  const [context, setContext] = useState<
    UserComboboxTeamOption | UserComboboxOrgOption | null
  >(null);

  const {
    options,
    query,
    setQuery,
    loading: isLoadingUsers,
  } = useUserComboboxQuery({
    types: [UserComboboxOptionType.ORG, UserComboboxOptionType.TEAM],
    queryOptions: { skip: !complianceProgramId },
    selected: context,
  });

  const optionMatchingUrlParam = teamUrlParam
    ? isAdmin &&
      options
        .filter(typeGuardIsUserComboboxTeamOption)
        .find((option) => option.id === teamUrlParam)
    : defaultOrgContext;

  useEffect(() => {
    if (
      optionMatchingUrlParam &&
      (context?.id !== optionMatchingUrlParam.id ||
        context?.type !== optionMatchingUrlParam.type)
    ) {
      setContext(optionMatchingUrlParam);
    }
  }, [context?.id, context?.type, optionMatchingUrlParam]);

  const handleChangeContext = (value: UserComboboxOption) => {
    if (
      value.type === UserComboboxOptionType.ORG ||
      value.type === UserComboboxOptionType.TEAM
    ) {
      link.redirect(`${url}?${value.type}=${value.id}`);
    }
  };

  const handleClearContext = () => {
    link.redirect(`${url}?${defaultOrgContext.type}=${defaultOrgContext.id}`);
  };

  const { data: complianceProgramData, loading: isLoadingComplianceProgram } =
    useQuery<
      GetComplianceProgramProgressQuery,
      GetComplianceProgramProgressQueryVariables
    >(getComplianceProgramProgressQuery, {
      fetchPolicy: "network-only",
      variables: { complianceProgramId },
      onError: onNotificationErrorHandler(),
    });

  const complianceProgram = useMemo(
    () => complianceProgramData?.complianceProgram,
    [complianceProgramData]
  );
  const performanceAssessmentTemplate = useMemo(
    () =>
      complianceProgram?.performanceAssessmentTemplate
        ? complianceProgram.performanceAssessmentTemplate
        : null,
    [complianceProgram]
  );
  const managerAssessmentTemplate = useMemo(
    () =>
      complianceProgram?.managerAssessmentTemplate
        ? complianceProgram.managerAssessmentTemplate
        : null,
    [complianceProgram]
  );
  const peerAssessmentTemplate = useMemo(
    () =>
      complianceProgram?.peerAssessmentTemplate
        ? complianceProgram.peerAssessmentTemplate
        : null,
    [complianceProgram]
  );
  const topicTemplate = useMemo(
    () =>
      complianceProgram?.requiredTopicTemplates
        ? assertEdgesNonNull(complianceProgram.requiredTopicTemplates)[0]
        : null,
    [complianceProgram]
  );

  const assessmentsOpenForNominations = useMemo(() => {
    return complianceProgramData?.assessmentsOpenForNominations
      ? assertEdgesNonNullWithStringId(
          complianceProgramData.assessmentsOpenForNominations
        )
      : [];
  }, [complianceProgramData]);

  const nominationCountByUserId = useMemo(() => {
    return assessmentsOpenForNominations.reduce(
      (memo, assessmentOpenForNominations) => {
        const userId = assessmentOpenForNominations.targetUser.id;
        memo[userId] = assessmentOpenForNominations.nominations.totalCount;
        return memo;
      },
      {} as Record<number, number>
    );
  }, [assessmentsOpenForNominations]);

  const participantStatuses = useMemo(() => {
    return compact(
      complianceProgram?.participantStatus?.edges.map((edge) => edge?.node)
    )
      .filter((participantStatus) => {
        // if admin, show all participants
        // if not admin, only show direct reports
        return (
          isAdmin ||
          directReportIds.includes(participantStatus.user.id) ||
          participantStatus.user.id === currentUser.id
        );
      })
      .map((participantStatus) => {
        // Nominations
        const nominations =
          nominationCountByUserId[participantStatus.user.id] > 0
            ? ComplianceProgramParticipantStatus.Complete
            : ComplianceProgramParticipantStatus.NotStarted;

        return {
          ...participantStatus,
          nominations,
        };
      });
  }, [
    complianceProgram?.participantStatus?.edges,
    directReportIds,
    isAdmin,
    nominationCountByUserId,
    currentUser,
  ]);

  const flattenedParticipantStatuses = useMemo(() => {
    return participantStatuses.map((participantStatus) => {
      const flattenedTeams: UserComboboxTeamOption[] = assertEdgesNonNull(
        participantStatus.user.teams
      ).map((node) => ({ ...node, type: UserComboboxOptionType.TEAM }));
      return {
        ...participantStatus,
        user: {
          ...participantStatus.user,
          flattenedTeams: flattenedTeams,
          teamIds: flattenedTeams.map((team) => team.id),
          manager: participantStatus.user.managers.edges[0]?.node,
        },
      };
    });
  }, [participantStatuses]);

  const teams = useMemo(() => {
    const concatenatedTeams = flattenedParticipantStatuses.reduce(
      (memo, participantStatus) => {
        return [...memo, ...participantStatus.user.flattenedTeams];
      },
      [] as UserComboboxTeamOption[]
    );
    return uniqBy(concatenatedTeams, "id");
  }, [flattenedParticipantStatuses]);

  const participantStatusesWithMatchingTeam = useMemo(() => {
    return flattenedParticipantStatuses.filter(
      (participantStatus) =>
        context?.type === UserComboboxOptionType.TEAM &&
        participantStatus.user.teamIds.includes(context.id)
    );
  }, [flattenedParticipantStatuses, context?.id, context?.type]);

  const contextWithParticipantStatuses = useMemo(() => {
    return context
      ? {
          ...context,
          participantStatuses:
            context?.type === UserComboboxOptionType.TEAM
              ? participantStatusesWithMatchingTeam
              : participantStatuses,
        }
      : null;
  }, [context, participantStatusesWithMatchingTeam, participantStatuses]);

  const teamsWithParticipantStatuses = useMemo(() => {
    return teams.map((team) => ({
      ...team,
      participantStatuses: flattenedParticipantStatuses.filter(
        (participantStatus) => participantStatus.user.teamIds.includes(team.id)
      ),
    }));
  }, [teams, flattenedParticipantStatuses]);

  const cols: ComplianceProgramReportingEntityTableCol[] = useMemo(() => {
    const nominationRemindUsers =
      assessmentsOpenForNominations.length > 0
        ? assessmentsOpenForNominations
            .filter(
              (assessmentsOpenForNomination) =>
                assessmentsOpenForNomination.nominations.totalCount === 0
            )
            .map(
              (assessmentsOpenForNomination) =>
                assessmentsOpenForNomination.targetUser
            )
        : [];
    return compact([
      peerAssessmentTemplate &&
        assessmentsOpenForNominations.length > 0 && {
          label: "Nominations",
          keyName: "nominations",
          remindUsers: nominationRemindUsers,
          remindUserType: AssessmentGroupProviders.ManagerSelect
            ? "manager"
            : "subject",
          remindUserTooltip:
            peerAssessmentTemplate.providers ===
            AssessmentGroupProviders.ManagerSelect
              ? `Managers of the peer ${label(
                  "review"
                )} subjects will be reminded to nominate peers.`
              : peerAssessmentTemplate.providers ===
                AssessmentGroupProviders.SubjectSelect
              ? `Subjects will be reminded to nominate peers for their peer ${label(
                  "review"
                )}.`
              : "",
        },
      performanceAssessmentTemplate && {
        label: `Manager ${label("review", { capitalize: true })}`,
        keyName: "performanceAssessmentStatus",
        remindUserLabel: "Managers",
        remindUserType: "manager",
        remindUsers: filterParticipantsToBeReminded(
          participantStatuses,
          "performanceAssessmentStatus"
        ),
      },
      performanceAssessmentTemplate &&
        performanceAssessmentTemplate.hasSelfAssessment && {
          label: `Self ${label("review", {
            capitalize: true,
          })}`,
          keyName: "performanceSelfAssessmentStatus",
          remindUserType: "subject",
          remindUsers: filterParticipantsToBeReminded(
            participantStatuses,
            "performanceSelfAssessmentStatus"
          ),
        },
      peerAssessmentTemplate && {
        label: `${assessmentTypeLabels[AssessmentType.Peer]} ${label("review", {
          capitalize: true,
        })}`,
        keyName: "peerAssessmentStatus",
        remindUserType: "peer",
        remindUsers: filterParticipantsToBeReminded(
          participantStatuses,
          "peerAssessmentStatus"
        ),
      },
      managerAssessmentTemplate && {
        label: `${assessmentTypeLabels[AssessmentType.Manager]} ${label(
          "review",
          { capitalize: true }
        )}`,
        keyName: "managerAssessmentStatus",
        remindUserType: "directReport",
        remindUsers: filterParticipantsToBeReminded(
          participantStatuses,
          "managerAssessmentStatus"
        ),
      },
      managerAssessmentTemplate &&
        managerAssessmentTemplate.hasSelfAssessment && {
          label: `${assessmentTypeLabels[AssessmentType.Manager]} Self ${label(
            "review",
            { capitalize: true }
          )}`,
          keyName: "managerSelfAssessmentStatus",
          remindUserType: "subject",
          remindUsers: filterParticipantsToBeReminded(
            participantStatuses,
            "managerSelfAssessmentStatus"
          ),
        },
      topicTemplate && {
        label: "1-on-1 Meetings",
        keyName: "oneononeStatus",
        remindUserType: "manager",
        remindUsers: filterParticipantsToBeReminded(
          participantStatuses,
          "oneononeStatus"
        ),
      },
    ]);
  }, [
    peerAssessmentTemplate,
    assessmentsOpenForNominations,
    performanceAssessmentTemplate,
    participantStatuses,
    managerAssessmentTemplate,
    topicTemplate,
    label,
  ]);

  useDocumentTitle(complianceProgram?.title || "Program Reporting");

  return isLoadingComplianceProgram || !complianceProgram ? (
    <Loading />
  ) : (
    <>
      <div className="empty:hidden">
        {(isAdmin || isManager) && (
          <div
            className={classNames(
              "flex items-center gap-4 ",
              isAdmin ? "justify-between mb-6" : "justify-end"
            )}
          >
            {isAdmin && (
              <div className="flex items-center gap-4">
                <UserCombobox
                  disabled={isLoadingComplianceProgram}
                  className="w-64"
                  query={query}
                  options={options}
                  value={context}
                  onChangeValue={handleChangeContext}
                  onChangeQuery={setQuery}
                  loading={isLoadingUsers}
                  placeholder="Select..."
                  clearable={
                    context !== null &&
                    context.type !== UserComboboxOptionType.ORG
                  }
                  onClearValue={handleClearContext}
                />
                {isLoadingUsers && <Loading mini size={5} />}
              </div>
            )}
            <div>
              {complianceProgram.state === ComplianceProgramState.Published && (
                <Button onClick={() => setIsShowingSendReminderDialog(true)}>
                  Send email reminders
                </Button>
              )}
              {isShowingSendReminderDialog && (
                <ComplianceProgramEmailReminderDialog
                  onClose={() => setIsShowingSendReminderDialog(false)}
                  cols={cols}
                  complianceProgram={complianceProgram}
                  selectedTeam={
                    context?.type === UserComboboxOptionType.TEAM
                      ? context
                      : null
                  }
                />
              )}
            </div>
          </div>
        )}

        {isAdmin && contextWithParticipantStatuses && (
          <Layout.MainSubSection>
            <ComplianceProgramProgressEntityTable
              complianceProgramId={complianceProgram.id}
              entities={[contextWithParticipantStatuses]}
              cols={cols}
            />
          </Layout.MainSubSection>
        )}
      </div>

      {(context?.type === UserComboboxOptionType.ORG || context === null) && (
        <>
          {isAdmin && (
            <Layout.MainSubSection title="Departments">
              <ComplianceProgramProgressEntityTable
                complianceProgramId={complianceProgram.id}
                entities={teamsWithParticipantStatuses}
                cols={cols}
              />
            </Layout.MainSubSection>
          )}

          <ComplianceProgramProgressUserTable
            title="Status of Individuals"
            participantStatuses={participantStatuses}
            cols={cols}
            usersLabel="members"
            complianceProgram={complianceProgram}
          />
        </>
      )}

      {isAdmin && context?.type === UserComboboxOptionType.TEAM && (
        <ComplianceProgramProgressUserTable
          title={`Status of ${label("team")} members`}
          usersLabel={`${label("team")} members`}
          participantStatuses={participantStatusesWithMatchingTeam}
          cols={cols}
          complianceProgram={complianceProgram}
        />
      )}
    </>
  );
};

export default ComplianceProgramProgress;
