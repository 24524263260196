import { gql } from "@apollo/client";

export default gql`
  fragment OrgSettingOrgTeam on TeamNode {
    id
    title
    members(orderBy: "name") {
      totalCount
      edges {
        node {
          id
          name
          avatar
        }
      }
    }
  }
  query getOrganizationTeamsQuery($organizationId: Int!, $after: String) {
    organization(pk: $organizationId) {
      id
      teams(first: 20, after: $after, orderBy: "title") {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...OrgSettingOrgTeam
          }
        }
      }
    }
  }
`;
