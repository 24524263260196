import { useMutation } from "@apollo/client";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import { EditorContent, useEditor } from "@tiptap/react";
import { FormEvent, KeyboardEvent, useEffect, useState } from "react";
import { useBeforeunload } from "react-beforeunload";
import { Prompt } from "react-router";
import {
  FeedbackState,
  GetArtifactSidebarQueryQuery,
  UpdateArtifactTitleMutationMutation,
  UpdateArtifactTitleMutationMutationVariables,
} from "types/graphql-schema";

import Button from "@components/button/button";
import GraphqlError from "@components/error/graphql-error";
import Form from "@components/form/form";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import {
  isCommandEnterEvent,
  isEnterEvent,
  isEscapeEvent,
} from "@helpers/helpers";

import getArtifactActivitiesQuery from "../graphql/get-artifact-activities-query";
import updateArtifactTitleMutation from "../graphql/update-artifact-title-mutation";

const ArtifactSidebarHeader = ({
  artifact,
  className = "",
}: {
  artifact: GetArtifactSidebarQueryQuery["artifact"];
  className?: string;
}) => {
  const titleClassName = classNames(
    "font-medium text-2xl px-2 -ml-2 py-1 rounded border border-white flex-1 tracking-tight",
    className
  );
  const isDecision = artifact.__typename === "DecisionArtifactNode";
  const isRating = artifact.__typename === "RatingArtifactNode";
  const isFeedback = artifact.__typename === "FeedbackArtifactNode";
  const artifactTitle =
    (isDecision
      ? artifact.decision
      : isRating
      ? artifact.rating?.title
      : artifact.title) || "";
  const [title, setTitle] = useState(artifactTitle);
  const canEdit =
    isFeedback && artifact.feedbackState === FeedbackState.Requested
      ? false
      : artifact.canUpdate.permission && !isRating;

  const trimmedTitle = title.trim();

  const [saveArtifact, { error, loading }] = useMutation<
    UpdateArtifactTitleMutationMutation,
    UpdateArtifactTitleMutationMutationVariables
  >(updateArtifactTitleMutation);

  const handleSaveTitle = (e: FormEvent) => {
    e.preventDefault();
    if (trimmedTitle && artifactTitle !== trimmedTitle) {
      saveArtifact({
        variables: {
          artifactId: artifact.id,
          ...(isDecision && {
            additionalFields: { decision: trimmedTitle },
          }),
          ...(!isDecision && { title: trimmedTitle }),
        },
        optimisticResponse: {
          createOrUpdateArtifact: {
            artifact: {
              id: artifact.id,
              __typename: artifact.__typename,
              decision: trimmedTitle,
              title: trimmedTitle,
            },
            __typename: "CreateOrUpdateArtifactMutation",
          },
          __typename: "Mutation",
        },
        refetchQueries: [getArtifactActivitiesQuery],
        onError: onNotificationErrorHandler(),
      });
    }
  };

  const handleKeyDownTitle = (e: KeyboardEvent) => {
    if (isCommandEnterEvent(e) || isEnterEvent(e)) {
      handleSaveTitle(e);
    }
    if (isEscapeEvent(e)) {
      handleCancelTitle();
    }
  };

  const handleChangeTitle = (title: string) => {
    setTitle(title);
  };

  const TitleDocument = Document.extend({
    name: "artifact-title-document",
    content: "block",
  });

  const editor = useEditor({
    editable: true,
    autofocus: false,
    extensions: [
      Text,
      Paragraph,
      TitleDocument,
      Placeholder.configure({ placeholder: "Title..." }),
    ],
    editorProps: {
      attributes: {
        class: classNames(
          titleClassName,
          "w-full block resize-none",
          "hover:bg-gray-100 hover:border-gray-200 focus:border-gray-100"
        ),
        "aria-label": "Textbox topic title",
      },
    },
    content: title,
    onUpdate: ({ editor }) => {
      handleChangeTitle(editor.getText());
    },
  });

  const handleCancelTitle = () => {
    setTitle(artifactTitle);
    editor?.chain().setContent(artifactTitle).run();
  };

  useBeforeunload((event) => {
    if (canEdit && artifactTitle !== title) {
      event.preventDefault();
    }
  });

  // when artifact changes we hide form
  // and reset form values to current artifact
  useEffect(() => {
    if (artifact.canRead.permission) {
      setTitle(artifactTitle);
      if (
        artifact.__typename !== "FeedbackArtifactNode" &&
        artifact.__typename !== "RecognitionArtifactNode"
      ) {
        editor?.chain().setContent(artifactTitle).focus("end").run();
      }
    }
  }, [artifact.canRead.permission, artifactTitle, editor]);

  const handleBlurTitle = () => {
    if (title.trim() === "") {
      handleCancelTitle();
    }
  };

  // Render
  return (
    <div data-testid="topic-sidebar-header" className="flex-1">
      <div className="flex gap-2">
        {canEdit ? (
          <Form
            name="title-form"
            onSubmit={handleSaveTitle}
            data-lpignore="true"
            autoComplete="off"
            aria-label="Artifact title form"
            className="flex-1"
          >
            <Prompt
              when={title !== artifactTitle}
              message="Leave page? Changes you made may not be saved."
            />
            <EditorContent
              editor={editor}
              className="inline"
              onKeyDown={handleKeyDownTitle}
              onBlur={handleBlurTitle}
            />
            {artifactTitle !== title && (
              <div className="flex gap-4 mt-2">
                <Button
                  type="submit"
                  text="Save"
                  theme="primary"
                  disabled={loading || trimmedTitle.length === 0}
                />
                <Button
                  onClick={handleCancelTitle}
                  text="Cancel"
                  disabled={loading}
                />
              </div>
            )}
          </Form>
        ) : (
          <h2 className={titleClassName} aria-label="Sidebar artifact title">
            {artifactTitle}
          </h2>
        )}
      </div>

      {error && (
        <div className="mt-3">
          <GraphqlError
            error={error}
            whatDidNotWork="The title could not be saved."
          />
        </div>
      )}
    </div>
  );
};

export default ArtifactSidebarHeader;
