import { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ArtifactType,
  CreatedArtifactFragmentFragment,
  GoalScope,
} from "types/graphql-schema";

import ArtifactCreationDialog from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import { refreshAlignmentOfGoalIds } from "@apps/goal-alignment/helpers";
import useLabel from "@apps/use-label/use-label";
import { currentUserVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import AppLink, { useLink } from "@components/link/link";
import {
  ToggleButtonGroup,
  ToggleButtonGroupTheme,
  ToggleButtonGroupType,
} from "@components/toggle-button-group/toggle-button-group";
import {
  UserComboboxOption,
  UserComboboxOptionType,
} from "@components/user-combobox/user-combobox-list";
import { isGoalArtifactNode } from "@helpers/helpers";

import getOverviewGoalsQuery from "./graphql/get-overview-goals-query";

enum ToggleButtonEnum {
  overview = "Overview",
  alignment = "Alignment",
}

const GoalPageHeader = ({
  selected,
}: {
  selected?: UserComboboxOption | null;
}) => {
  const link = useLink();
  const label = useLabel();
  const location = useLocation();
  const currentUser = currentUserVar();
  const [isShowingCreateDialog, setIsShowingCreateDialog] = useState(false);

  const handleClickToggle = (
    button: ToggleButtonGroupType<ToggleButtonEnum>
  ) => {
    if (button.value === ToggleButtonEnum.alignment) {
      link.redirect(`/goal-alignment`);
    } else {
      link.redirect(`/goals`);
    }
  };

  const handleCloseCreateDialog = (
    createdArtifact?: CreatedArtifactFragmentFragment
  ) => {
    setIsShowingCreateDialog(false);
    if (createdArtifact && isGoalArtifactNode(createdArtifact)) {
      refreshAlignmentOfGoalIds([
        createdArtifact.id,
        createdArtifact.parentGoal?.id,
      ]);
    }
  };

  return (
    <div className="flex flex-wrap items-center justify-between h-14 px-4 sm:px-6 border-b bg-gray-50">
      {isShowingCreateDialog && (
        <ArtifactCreationDialog
          formOptions={{
            artifactType: ArtifactType.Goal,
            owners:
              selected?.type === UserComboboxOptionType.USER
                ? [
                    {
                      id: selected.id,
                      name: selected.name,
                    },
                  ]
                : [currentUser],
            scope:
              selected?.type === UserComboboxOptionType.TEAM
                ? GoalScope.Team
                : selected?.type === UserComboboxOptionType.ORG
                ? GoalScope.Organization
                : GoalScope.Personal,
            teams:
              selected?.type === UserComboboxOptionType.TEAM
                ? [{ id: selected.id, title: selected.title }]
                : [],
          }}
          hideTypeSelector
          refetchQueries={[getOverviewGoalsQuery]}
          onClose={handleCloseCreateDialog}
        />
      )}
      <div className="mb-2 sm:mb-0 text-2xl font-medium tracking-tight mr-8 flex items-center gap-6">
        <AppLink to="/goals" className="hover:underline">
          Goals
        </AppLink>
        <ToggleButtonGroup<ToggleButtonEnum>
          theme={ToggleButtonGroupTheme.roundedCompact}
          buttons={[
            {
              title: "Overview",
              active: !location.pathname.startsWith("/goal-alignment"),
              value: ToggleButtonEnum.overview,
            },
            {
              title: "Alignment",
              active: location.pathname.startsWith("/goal-alignment"),
              value: ToggleButtonEnum.alignment,
            },
          ]}
          onClick={handleClickToggle}
        />
      </div>

      <Button
        theme={buttonTheme.primary}
        text={`Create ${label("goal")}`}
        onClick={() => setIsShowingCreateDialog(true)}
      />
    </div>
  );
};

export default GoalPageHeader;
