import { useMutation } from "@apollo/client";
import { Menu, Portal } from "@headlessui/react";
import { DotsHorizontalIcon } from "@heroicons/react/solid";
import { EditorContent, useEditor } from "@tiptap/react";
import copy from "copy-to-clipboard";
import moment from "moment";
import { MouseEvent, useState } from "react";
import { usePopper } from "react-popper";
import {
  GetInlineCommentsQueryQuery,
  GetInlineCommentsQueryQueryVariables,
} from "types/graphql-schema";

import deleteCommentMutation from "@apps/comments/graphql/delete-comment-mutation";
import { currentUserVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import getInlineCommentsQuery from "@components/wysiwyg/graphql/get-inline-comments-query";
import { typename } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { parseStringToJSON } from "@helpers/helpers";

import { CommentExtensions } from "./comment-form-tiptap";

export default function CommentItem({
  topicId,
  uuid,
  comment,
  atMentionSuggestions,
  uploadVariable,
  onDelete,
}: {
  topicId?: number;
  uuid: string;
  comment: any;
  atMentionSuggestions: any;
  uploadVariable: () => void;
  onDelete: () => void;
}) {
  const currentUser = currentUserVar();
  const commentEditor = useEditor({
    editable: false,
    extensions: CommentExtensions(atMentionSuggestions, uploadVariable),
    editorProps: {
      attributes: {
        class: "prose js-comment-input text-sm py-1",
        "aria-label": "Comment",
      },
    },
    content: parseStringToJSON(comment.comment),
  });

  const [referenceElement, setReferenceElement] = useState<
    HTMLButtonElement | null | undefined
  >(null);
  const [popperElement, setPopperElement] = useState<
    HTMLDivElement | null | undefined
  >();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
  });

  const [deleteComment] = useMutation(deleteCommentMutation, {
    update: (cache) => {
      cache.updateQuery<
        GetInlineCommentsQueryQuery,
        GetInlineCommentsQueryQueryVariables
      >(
        {
          query: getInlineCommentsQuery,
          variables: { uuid },
        },
        (data: any) => {
          return {
            ...data,
            comments: {
              ...data.comments,
              totalCount: data.comments.length - 1,
              edges: data.comments.edges.filter(
                (edge: any) => edge?.node.id !== comment.id
              ),
            },
          };
        }
      );
    },
  });

  const handleClickDeleteComment = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    deleteComment({
      variables: {
        commentId: comment.id,
      },
      onError: onNotificationErrorHandler(),
      optimisticResponse: {
        deleteComment: {
          topic: {
            id: topicId,
            __typename: typename.TopicNode,
          },
        },
      },
      onCompleted: () => {
        onDelete();
      },
    });
  };

  const handleClickCopyComment = () => {
    copy(`${window.location.origin}/topic/${topicId}?comment=${uuid}`);
  };

  return (
    <div
      key={comment.id}
      className="pl-3 pr-1 py-1.5 group"
      aria-label="Comment item container"
    >
      <div className="flex justify-between gap-2 items-center text-xs">
        <div className="py-0.5 flex gap-1">
          <Avatar user={comment.creator} size="4" />
          <span className="font-medium text-gray-600">
            {comment.creator.name}
          </span>
          <time
            className="text-gray-400"
            dateTime={comment.created}
            title={moment(comment.created).format("LLLL")}
          >
            {moment(comment.created).format("MMM D")}
          </time>
        </div>

        <Menu as="div" className="relative inline-block text-left">
          <Menu.Button
            className="border px-1 py-0.5 flex items-center rounded text-gray-400 hover:text-gray-600 focus:outline-none"
            ref={setReferenceElement}
            aria-label="Comment dropdown button"
          >
            <span className="sr-only">Open options</span>
            <DotsHorizontalIcon className="h-3 w-3" />
          </Menu.Button>
          <Portal>
            <Menu.Items
              className={`origin-top-right absolute right-0 z-tooltip w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              <div className="py-1 divide-y">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={classNames(
                        "block w-full text-left px-4 py-2 text-sm",
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                      )}
                      onClick={handleClickCopyComment}
                    >
                      Copy link
                    </button>
                  )}
                </Menu.Item>
                {comment.creator.id === currentUser.id && (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={classNames(
                          "block w-full text-left px-4 py-2 text-sm",
                          active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                        )}
                        onClick={handleClickDeleteComment}
                      >
                        Delete comment
                      </button>
                    )}
                  </Menu.Item>
                )}
              </div>
            </Menu.Items>
          </Portal>
        </Menu>
      </div>

      <span className="text-sm mt-1">
        <EditorContent editor={commentEditor} />
      </span>
    </div>
  );
}
