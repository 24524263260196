import { useQuery } from "@apollo/client";
import { Menu } from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CogIcon,
  DotsHorizontalIcon,
} from "@heroicons/react/outline";
import { intersection, without } from "lodash";
import moment from "moment";
import { ChangeEventHandler, useEffect, useState } from "react";
import {
  EventStatus,
  GetCalendarMeetingsQuery,
  GetCalendarMeetingsQueryVariables,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import useUiPreferenceCache, {
  CalendarViewEnum,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import { currentUserVar } from "@cache/cache";
import Loading from "@components/loading/loading";
import AppPopover from "@components/popover/app-popover";
import Tooltip from "@components/tooltip/tooltip";
import useDocumentTitle from "@components/use-document-title/use-document-title";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { meetingStatus } from "@helpers/constants";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

import Month from "./components/month/month";
import Week from "./components/week/week";
import getCalendarMeetings from "./graphql/get-calendar-meetings-query";

const ignored = "ignored";

export const topPixelMargin = 30;
export const minimumEventHeight = 28;
export const hourInPixels = 75;
export const minuteInPixels = hourInPixels / 60;

const Calendar = ({
  selectedUser,
  onClickMeetingLink,
}: {
  selectedUser: BasicUser;
  onClickMeetingLink: (meetingId: number) => void;
}) => {
  // HOOKS
  useDocumentTitle("Calendar");
  const currentUser = currentUserVar();
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();

  const [selectedDay, setSelectedDay] = useState(moment().format("YYYY-MM-DD"));
  const firstDayOfMonth = moment(selectedDay).startOf("month").format();
  const lastDayOfMonth = moment(selectedDay).endOf("month").format();
  const firstDayOfWeek = moment(selectedDay).startOf("isoWeek").format();
  const lastDayOfWeek = moment(selectedDay).endOf("isoWeek").format();
  const startDatetimeGte =
    uiPreferenceCache.calendarView === CalendarViewEnum.month
      ? firstDayOfMonth
      : firstDayOfWeek;
  const startDatetimeLte =
    uiPreferenceCache.calendarView === CalendarViewEnum.month
      ? lastDayOfMonth
      : lastDayOfWeek;

  const calendarFilters = uiPreferenceCache.calendarFilters;

  const meetingFilters = [
    {
      value: meetingStatus.cancelled,
      label: "Show deleted meetings",
    },
    {
      value: ignored,
      label: "Show hidden meetings",
    },
  ];

  if (selectedUser && selectedUser.id !== currentUser.id) {
    meetingFilters.push({
      value: "shared",
      label: "Only shared meetings",
    });
  }
  const meetingFilterKeys = meetingFilters.map(({ value }) => value);

  const { loading, data, refetch, fetchMore } = useQuery<
    GetCalendarMeetingsQuery,
    GetCalendarMeetingsQueryVariables
  >(getCalendarMeetings, {
    variables: {
      forUserId: selectedUser ? selectedUser.id : currentUser.id,
      startDatetime_Gte: startDatetimeGte,
      startDatetime_Lte: startDatetimeLte,
      ignored: calendarFilters.includes(ignored) ? null : false,
      statusIn: calendarFilters.includes(meetingStatus.cancelled)
        ? [EventStatus.Confirmed, EventStatus.Cancelled]
        : [EventStatus.Confirmed],
      participants: calendarFilters.includes("shared")
        ? [currentUser.id]
        : null,
    },
    onError: onNotificationErrorHandler(),
  });

  // fetch for new month in calendar
  useEffect(() => {
    refetch();
  }, [firstDayOfMonth, lastDayOfMonth, refetch]);

  // fetch more if data has multiple pages
  useEffect(() => {
    if (
      data &&
      data.calendar &&
      data.calendar.totalCount > data.calendar.edges.length
    ) {
      fetchMore({
        variables: {
          startDatetime_Gte: startDatetimeGte,
          endDatetime_Lte: startDatetimeLte,
          after: data.calendar.pageInfo.endCursor,
          merge: true,
        },
      });
    }
  }, [data]);

  // HANDLERS
  const handleClickPrevious = () => {
    setSelectedDay(
      moment(selectedDay)
        .subtract(1, uiPreferenceCache.calendarView)
        .format("YYYY-MM-DD")
    );
  };
  const handleClickNext = () => {
    setSelectedDay(
      moment(selectedDay)
        .add(1, uiPreferenceCache.calendarView)
        .format("YYYY-MM-DD")
    );
  };
  const handleClickPreviousDay = () => {
    setSelectedDay(moment(selectedDay).subtract(1, "day").format("YYYY-MM-DD"));
  };
  const handleClickNextDay = () => {
    setSelectedDay(moment(selectedDay).add(1, "day").format("YYYY-MM-DD"));
  };
  const handleClickToday = () => {
    setSelectedDay(moment().format("YYYY-MM-DD"));
  };
  const handleSelectDay = (day: string) => {
    setSelectedDay(day);
  };
  const handleChangeFilters: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newFilters = e.target.checked
      ? [...calendarFilters, e.target.name]
      : without(calendarFilters, e.target.name);
    saveUiPreference({
      calendarFilters: intersection(newFilters, meetingFilterKeys),
    });
  };
  const handleChangeView = (value: CalendarViewEnum) => {
    saveUiPreference({ calendarView: value });
  };

  const meetings = data?.calendar ? assertEdgesNonNull(data.calendar) : [];
  const showSharedEventsBanner =
    selectedUser &&
    selectedUser.id !== currentUser.id &&
    calendarFilters.includes("shared");

  // RENDER
  return (
    <div
      aria-label="Calendar container"
      className={classNames(
        "@container/calendar fs-unmask",
        "flex h-full flex-col bg-red-200"
      )}
    >
      <div className={classNames("flex h-full flex-col bg-white")}>
        <header
          className={classNames(
            "sticky",
            "bg-gray-50 z-40 flex flex-none items-center justify-between border-b border-gray-200 h-14 px-3 sm:px-6"
          )}
        >
          <div className="flex items-center gap-6">
            <h1 className="text-lg font-semibold text-gray-900 flex items-center gap-4">
              {moment(selectedDay).format("MMM D, YYYY")}
            </h1>
            {loading && (
              <div className="flex items-center gap-2">
                <Loading mini size="5" />
                <div className="text-gray-500 text-sm">
                  Fetching calendar events
                </div>
              </div>
            )}
          </div>
          <div className="flex items-center gap-2">
            <div
              className={classNames(
                "flex items-stretch rounded-md shadow-sm @4xl/calendar:flex @4xl/calendar:items-stretch",
                uiPreferenceCache.calendarView === CalendarViewEnum.week &&
                  "@lg/calendar:hidden"
              )}
            >
              <button
                onClick={handleClickPrevious}
                type="button"
                className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white px-2 py-1.5 text-gray-400 hover:text-gray-500 focus:relative hover:bg-gray-50"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" />
              </button>
              <button
                onClick={handleClickToday}
                type="button"
                className="hidden @md/calendar:block border-t border-b border-gray-300 bg-white px-2 py-1.5 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:relative"
              >
                Today
              </button>
              <div className="flex items-center @md/calendar:hidden">
                <span className="relative -mx-px h-5 w-px bg-gray-300" />
              </div>
              <button
                type="button"
                onClick={handleClickNext}
                className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white px-2 py-1.5 text-gray-400 hover:text-gray-500 focus:relative hover:bg-gray-50"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" />
              </button>
            </div>

            {/* cursor for previous/next day when looking at 3 days view */}
            {uiPreferenceCache.calendarView === CalendarViewEnum.week && (
              <div className="items-stretch rounded-md shadow-sm hidden @lg/calendar:flex @4xl/calendar:hidden">
                <button
                  onClick={handleClickPreviousDay}
                  type="button"
                  className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white px-2 py-1.5 text-gray-400 hover:text-gray-500 focus:relative hover:bg-gray-50"
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon className="h-5 w-5" />
                </button>
                <button
                  onClick={handleClickToday}
                  type="button"
                  className="hidden @lg/calendar:block border-t border-b border-gray-300 bg-white px-2 text-sm font-medium text-gray-700 hover:bg-gray-50  focus:relative"
                >
                  Today
                </button>
                <div className="flex items-center @lg/calendar:hidden">
                  <span className="relative -mx-px h-5 w-px bg-gray-300" />
                </div>
                <button
                  type="button"
                  onClick={handleClickNextDay}
                  className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white px-2 py-1.5 text-gray-400 hover:text-gray-500 focus:relative hover:bg-gray-50"
                >
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon className="h-5 w-5" />
                </button>
              </div>
            )}

            <div className="relative hidden @xl/calendar:flex">
              <Menu as="div" className="">
                <Menu.Button
                  type="button"
                  className="flex capitalize items-center rounded-md border border-gray-300 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 overflow-hidden truncate"
                  aria-label="Calendar view dropdown"
                >
                  {uiPreferenceCache.calendarView}
                  <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" />
                </Menu.Button>

                <Menu.Items className="absolute right-0 mt-0 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {Object.values(CalendarViewEnum).map((key) => (
                      <Menu.Item key={key}>
                        {({ active }) => (
                          <button
                            onClick={() => handleChangeView(key)}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block w-full text-left px-4 py-2 text-sm capitalize"
                            )}
                          >
                            {CalendarViewEnum[key]}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Menu>
            </div>

            <div className="flex items-center">
              <AppPopover
                content={
                  <div className="p-4 flex flex-col gap-4 text-sm">
                    {meetingFilters.map((meetingFilter) => (
                      <label
                        className="flex items-center gap-2 cursor-pointer"
                        key={meetingFilter.value}
                      >
                        <input
                          type="checkbox"
                          aria-label={`Calendar filter: ${meetingFilter.value}`}
                          name={meetingFilter.value}
                          className="cursor-pointer"
                          checked={calendarFilters.includes(
                            meetingFilter.value
                          )}
                          onChange={handleChangeFilters}
                        />{" "}
                        {meetingFilter.label}
                      </label>
                    ))}
                  </div>
                }
              >
                <AppPopover.Button
                  className="rounded-md border border-gray-300 bg-white px-2 py-1.5 text-sm font-medium text-gray-700 focus:relative hover:bg-gray-50 overflow-hidden truncate"
                  aria-label="Calendar settings button"
                >
                  <Tooltip text="Calendar settings">
                    <span>
                      <CogIcon className="w-5 h-5" />
                    </span>
                  </Tooltip>
                </AppPopover.Button>
              </AppPopover>
            </div>

            <Menu as="div" className="relative @xl/calendar:hidden">
              <Menu.Button
                className="-mr-2 flex items-center rounded-full border border-transparent px-2 py-1 text-gray-400 hover:text-gray-500"
                aria-label="Calendar dropdown menu"
              >
                <span className="sr-only">Open menu</span>
                <DotsHorizontalIcon className="h-5 w-5" />
              </Menu.Button>
              <Menu.Items className="absolute right-0 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={handleClickToday}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full text-left px-4 py-2 text-sm"
                        )}
                      >
                        Go to today
                      </button>
                    )}
                  </Menu.Item>
                </div>
                <div className="py-1">
                  {Object.values(CalendarViewEnum).map((key) => (
                    <Menu.Item key={key}>
                      {({ active }) => (
                        <button
                          onClick={() => handleChangeView(key)}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block w-full text-left px-4 py-2 text-sm capitalize"
                          )}
                        >
                          {CalendarViewEnum[key]} view
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Menu>
          </div>
        </header>
        {showSharedEventsBanner && (
          <div className="leading-3 bg-emerald-100 text-emerald-900 text-center flex items-center justify-center h-8 top-14 sticky z-30 text-xs border-b">
            Showing shared events between you and {selectedUser.name}
          </div>
        )}

        {uiPreferenceCache.calendarView === CalendarViewEnum.month ? (
          <Month
            meetings={meetings}
            selectedDay={selectedDay}
            onChangeSelectedDay={handleSelectDay}
            onClickMeetingLink={onClickMeetingLink}
          />
        ) : (
          uiPreferenceCache.calendarView === CalendarViewEnum.week && (
            <Week
              meetings={meetings}
              selectedDay={selectedDay}
              onChangeSelectedDay={handleSelectDay}
              showSharedEventsBanner={showSharedEventsBanner}
              onClickMeetingLink={onClickMeetingLink}
            />
          )
        )}
      </div>
    </div>
  );
};

export default Calendar;
