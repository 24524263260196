import { useQuery } from "@apollo/client";
import {
  GetOverviewGoalsQueryQuery,
  GetOverviewGoalsQueryQueryVariables,
  GoalOverviewGoalFragmentFragment,
  GoalScope,
} from "types/graphql-schema";
import { DateRangeEnum } from "types/topicflow";

import useLabel from "@apps/use-label/use-label";
import useUiPreferenceCache, {
  GoalOverviewGoalDisplayEnum,
  GoalOverviewGoalRoleFilterEnum,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import { currentOrganizationVar } from "@cache/cache";
import Loading from "@components/loading/loading";
import {
  ToggleButtonGroup,
  ToggleButtonGroupTheme,
  ToggleButtonGroupType,
} from "@components/toggle-button-group/toggle-button-group";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import {
  UserComboboxOption,
  UserComboboxOptionType,
} from "@components/user-combobox/user-combobox-list";
import { assertEdgesNonNull, dateRangeToDateArray } from "@helpers/helpers";
import { capitalize } from "@helpers/string";

import GoalOverviewGoalStats from "./goal-stats";
import GoalOverviewGoalTable from "./goal-table";
import getOverviewGoalsQuery from "./graphql/get-overview-goals-query";

const GoalOverviewGoalListStats = ({
  selected,
  selectedDateRange,
}: {
  selected: UserComboboxOption;
  selectedDateRange: DateRangeEnum;
}) => {
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const label = useLabel();
  const currentOrganization = currentOrganizationVar();

  const { data, loading } = useQuery<
    GetOverviewGoalsQueryQuery,
    GetOverviewGoalsQueryQueryVariables
  >(getOverviewGoalsQuery, {
    variables: {
      ownerIds:
        selected.type === UserComboboxOptionType.USER &&
        uiPreferenceCache.goalOverviewGoalRoleFilter ===
          GoalOverviewGoalRoleFilterEnum.owners
          ? [selected.id]
          : undefined,
      contributorIds:
        selected.type === UserComboboxOptionType.USER &&
        uiPreferenceCache.goalOverviewGoalRoleFilter ===
          GoalOverviewGoalRoleFilterEnum.contributors
          ? [selected.id]
          : undefined,
      involvingUserIds:
        selected.type === UserComboboxOptionType.USER &&
        uiPreferenceCache.goalOverviewGoalRoleFilter ===
          GoalOverviewGoalRoleFilterEnum.both
          ? [selected.id]
          : undefined,
      teamIds:
        selected.type === UserComboboxOptionType.TEAM
          ? [selected.id]
          : undefined,
      goalScopes:
        selected.type === UserComboboxOptionType.ORG
          ? [GoalScope.Organization]
          : selected.type === UserComboboxOptionType.TEAM
          ? [GoalScope.Team]
          : undefined,
      goalDueBetweenDates: dateRangeToDateArray({
        range: selectedDateRange,
        quarterStartMonth: currentOrganization.quarterStartMonth,
      }),
    },
    onError: onNotificationErrorHandler(),
  });
  const artifacts = data?.artifacts ? assertEdgesNonNull(data.artifacts) : [];
  const goals = artifacts.filter(
    (artifact) => artifact.__typename === "GoalArtifactNode"
  ) as GoalOverviewGoalFragmentFragment[];

  const handleToggleDisplay = (
    buttonGroup: ToggleButtonGroupType<GoalOverviewGoalDisplayEnum>
  ) => {
    if (buttonGroup.value) {
      saveUiPreference({ goalOverviewGoalDisplay: buttonGroup.value });
    }
  };

  const handleToggleRoleFilter = (
    buttonGroup: ToggleButtonGroupType<GoalOverviewGoalRoleFilterEnum>
  ) => {
    if (buttonGroup.value) {
      saveUiPreference({ goalOverviewGoalRoleFilter: buttonGroup.value });
    }
  };

  return !data && loading ? (
    <Loading className="text-sm">
      Loading {label("goal", { pluralize: true })}
    </Loading>
  ) : (
    <>
      <GoalOverviewGoalStats
        selected={selected}
        selectedDateRange={selectedDateRange}
        goals={goals}
      />
      <div className="text-gray-800">
        <div className="flex justify-between mb-2">
          <div className="flex items-center gap-2">
            <div className="font-medium">
              {label("goal", {
                capitalize: true,
                pluralize: true,
              })}{" "}
              ({goals.length})
            </div>
            {loading && <Loading mini size={4} />}
          </div>
          <div className="flex items-center gap-6">
            {selected.type === UserComboboxOptionType.USER && (
              <ToggleButtonGroup<GoalOverviewGoalRoleFilterEnum>
                theme={ToggleButtonGroupTheme.roundedCompact}
                buttons={[
                  {
                    title: capitalize(GoalOverviewGoalRoleFilterEnum.both),
                    active:
                      uiPreferenceCache.goalOverviewGoalRoleFilter ===
                      GoalOverviewGoalRoleFilterEnum.both,
                    value: GoalOverviewGoalRoleFilterEnum.both,
                  },
                  {
                    title: capitalize(GoalOverviewGoalRoleFilterEnum.owners),
                    active:
                      uiPreferenceCache.goalOverviewGoalRoleFilter ===
                      GoalOverviewGoalRoleFilterEnum.owners,
                    value: GoalOverviewGoalRoleFilterEnum.owners,
                  },
                  {
                    title: capitalize(
                      GoalOverviewGoalRoleFilterEnum.contributors
                    ),
                    active:
                      uiPreferenceCache.goalOverviewGoalRoleFilter ===
                      GoalOverviewGoalRoleFilterEnum.contributors,
                    value: GoalOverviewGoalRoleFilterEnum.contributors,
                  },
                ]}
                onClick={handleToggleRoleFilter}
              />
            )}
            <ToggleButtonGroup<GoalOverviewGoalDisplayEnum>
              theme={ToggleButtonGroupTheme.roundedCompact}
              buttons={[
                {
                  title: "Compact",
                  active:
                    uiPreferenceCache.goalOverviewGoalDisplay ===
                    GoalOverviewGoalDisplayEnum.compact,
                  value: GoalOverviewGoalDisplayEnum.compact,
                  tooltip: `Hide ${label("goal")} ${label("key result", {
                    pluralize: true,
                  })}`,
                },
                {
                  title: "Details",
                  active:
                    uiPreferenceCache.goalOverviewGoalDisplay ===
                    GoalOverviewGoalDisplayEnum.details,
                  value: GoalOverviewGoalDisplayEnum.details,
                  tooltip: `View ${label("goal")} ${label("key result", {
                    pluralize: true,
                  })}`,
                },
              ]}
              onClick={handleToggleDisplay}
            />
          </div>
        </div>
        <GoalOverviewGoalTable goals={goals} />
      </div>
    </>
  );
};

export default GoalOverviewGoalListStats;
