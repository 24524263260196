import { useMutation } from "@apollo/client";
import { PlusIcon } from "@heroicons/react/outline";
import { useState } from "react";
import {
  GoalArtifactSidebarFragmentFragment,
  KeyResultFragmentFragment,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import Button, { buttonTheme } from "@components/button/button";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import deleteGoalKeyResultMutation from "../graphql/delete-key-result-mutation";
import getArtifactActivitiesQuery from "../graphql/get-artifact-activities-query";
import GoalKeyResultItem from "./goal-key-result-item";
import GoalKeyResultModal from "./goal-key-result-modal";

const GoalKeyResultList = ({
  goalArtifact,
  isReadOnly = false,
}: {
  goalArtifact: GoalArtifactSidebarFragmentFragment;
  isReadOnly?: boolean;
}) => {
  const label = useLabel();
  const [deleteKeyResult] = useMutation(deleteGoalKeyResultMutation);
  const [showCreateKeyResultModal, setShowCreateKeyResultModal] =
    useState(false);

  const handleDeleteKeyResult = (keyResult: KeyResultFragmentFragment) => {
    const newKeyResults = assertEdgesNonNull(goalArtifact.keyResults).filter(
      (node) => node.id !== keyResult.id
    );
    deleteKeyResult({
      variables: {
        keyResultId: keyResult.id,
      },
      optimisticResponse: {
        deleteKeyResult: {
          keyResult,
          goal: {
            ...goalArtifact,
            keyResults: {
              ...goalArtifact.keyResults,
              edges: newKeyResults,
            },
          },
        },
      },
      refetchQueries: [getArtifactActivitiesQuery],
      onError: onNotificationErrorHandler(),
    });
  };

  if (
    goalArtifact.keyResults?.edges.length === 0 &&
    !goalArtifact.canUpdate.permission
  ) {
    return null;
  }

  const keyResults = assertEdgesNonNull(goalArtifact.keyResults);

  if (keyResults.length === 0 && isReadOnly) {
    return null;
  }
  return (
    <div className="w-full flex flex-col gap-2">
      <div className="-mx-3 px-3 flex items-center justify-between">
        <div className="text-sm font-medium">
          {label("key result", {
            pluralize: true,
            capitalize: true,
          })}
        </div>
        {!isReadOnly && goalArtifact.canUpdate.permission && (
          <div className="flex items-center gap-2">
            {showCreateKeyResultModal && (
              <GoalKeyResultModal
                goalArtifact={goalArtifact}
                onClose={() => setShowCreateKeyResultModal(false)}
              />
            )}
            <Button
              icon={PlusIcon}
              theme={buttonTheme.iconGray}
              onClick={() => setShowCreateKeyResultModal(true)}
              tooltip={`Add ${label("key result")}`}
            />
          </div>
        )}
      </div>
      <div className="empty:hidden">
        {keyResults.map((keyResult) => (
          <div
            className="hover:bg-gray-50 rounded-md -mx-2 pl-2 pr-3 py-1.5"
            key={keyResult.id}
          >
            <GoalKeyResultItem
              goalArtifact={goalArtifact}
              keyResult={keyResult}
              isReadOnly={isReadOnly}
              onDelete={handleDeleteKeyResult}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GoalKeyResultList;
