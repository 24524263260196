import { useMutation } from "@apollo/client";
import { Popover } from "@headlessui/react";
import { PencilIcon, PlusCircleIcon, XIcon } from "@heroicons/react/outline";
import { Editor } from "@tiptap/core";
import { uniqBy } from "lodash";
import { useState } from "react";
import {
  FeedbackState,
  GetArtifactSidebarQueryQuery,
  UserNode,
} from "types/graphql-schema";

import getFeedbackPageQuery from "@apps/feedback/graphql/get-feedback-page-query";
import { currentUserVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import FeedbackStateLabel from "@components/draft-label/feedback-state-label";
import RecipientDropdown from "@components/people-dropdown/recipient-dropdown";
import AppPopover from "@components/popover/app-popover";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { UserComboboxUserOption } from "@components/user-combobox/user-combobox-list";
import FeedbackMessageWYSIWYG from "@components/wysiwyg/feedback-message-wysiwyg";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import { classNames } from "@helpers/css";
import {
  assertEdgesNonNull,
  getFeedbackSentToString,
  parseStringToJSON,
} from "@helpers/helpers";
import { joinStringWithCommaAnd, pluralize } from "@helpers/string";

import getArtifactActivitiesQuery from "../graphql/get-artifact-activities-query";
import updateSidebarArtifactMutation from "../graphql/update-sidebar-artifact-mutation";

const ArtifactSidebarFeedback = ({
  artifact,
}: {
  artifact: GetArtifactSidebarQueryQuery["artifact"];
}) => {
  const [showConfirmFeedback, setShowConfirmFeedback] = useState(false);
  const isFeedback = artifact.__typename === "FeedbackArtifactNode";
  const [creatorCanView, setCreatorCanView] = useState(
    isFeedback ? artifact.creatorCanView : false
  );
  const [adminsCanView, setAdminsCanView] = useState(
    isFeedback ? artifact.adminsCanView : false
  );
  const [recipientsCanView, setRecipientsCanView] = useState(
    isFeedback ? artifact.recipientsCanView : false
  );
  const [recipientsManagersCanView, setRecipientsManagersCanView] = useState(
    isFeedback ? artifact.recipientsManagersCanView : false
  );
  const [initialFeedbackMessage, setInitialFeedbackMessage] = useState(
    isFeedback ? parseStringToJSON(artifact.message) : null
  );
  const [feedbackMessage, setFeedbackMessage] = useState(
    initialFeedbackMessage
  );
  const [forceRefresh, setForceRefresh] = useState(Date.now());
  const [updateArtifact, { loading: loadingSave }] = useMutation(
    updateSidebarArtifactMutation
  );
  if (artifact.__typename !== "FeedbackArtifactNode") return null;
  const currentUser = currentUserVar();
  const recipients = artifact.feedbackRecipients
    ? assertEdgesNonNull(artifact.feedbackRecipients)
    : [];

  const updateArtifactRecipients = (newRecipientEdges: any) => {
    const feedbackRecipients = newRecipientEdges.map(
      (edge: any) => edge?.node?.id
    );
    const optimisticResponse = {
      createOrUpdateArtifact: {
        artifact: {
          ...artifact,
          recipients: {
            ...artifact.feedbackRecipients,
            totalCount: feedbackRecipients.length,
            edges: newRecipientEdges,
          },
        },
      },
    };
    updateArtifact({
      variables: {
        artifactId: artifact.id,
        additionalFields: { feedbackRecipients },
      },
      optimisticResponse,
      refetchQueries: [getArtifactActivitiesQuery, getFeedbackPageQuery],
      onError: onNotificationErrorHandler(),
    });
  };

  const handleRemoveRecognitionRecipient =
    (recipientToRemove: Partial<UserNode>) => () => {
      if (
        window.confirm(
          `Are you sure you want to remove ${recipientToRemove.name}?`
        )
      ) {
        const newRecipientEdges = artifact.feedbackRecipients.edges.filter(
          (edge) => recipientToRemove.id !== edge?.node?.id
        );
        updateArtifactRecipients(newRecipientEdges);
      }
    };

  const handleAddRecognitionRecipient = (
    recipientToAdd: UserComboboxUserOption
  ) => {
    const newRecipientEdges = uniqBy(
      [
        ...artifact.feedbackRecipients.edges,
        {
          __typename: "UserNodeEdge",
          node: recipientToAdd,
        },
      ],
      (edge) => edge?.node?.id
    );
    updateArtifactRecipients(newRecipientEdges);
  };

  const handleSaveSendToOption = () => {
    const optimisticResponse = {
      createOrUpdateArtifact: {
        artifact: {
          ...artifact,
          creatorCanView,
          recipientsCanView,
          recipientsManagersCanView,
          adminsCanView,
        },
      },
    };
    updateArtifact({
      variables: {
        artifactId: artifact.id,
        additionalFields: {
          feedbackCreatorCanView: creatorCanView,
          feedbackRecipientsCanView: recipientsCanView,
          feedbackRecipientsManagersCanView: recipientsManagersCanView,
          feedbackAdminsCanView: adminsCanView,
        },
      },
      onError: onNotificationErrorHandler(),
      optimisticResponse,
      refetchQueries: [getArtifactActivitiesQuery, getFeedbackPageQuery],
    });
  };

  const handleChangeFeedbackMessage = ({ editor }: { editor: Editor }) => {
    setFeedbackMessage(editor.getJSON());
  };

  const handleDiscardChanges = () => {
    setFeedbackMessage(initialFeedbackMessage);
    setForceRefresh(Date.now());
  };

  const handleSaveFeedbackMessage = () => {
    const message = JSON.stringify(feedbackMessage);
    const optimisticResponse = {
      createOrUpdateArtifact: {
        artifact: {
          ...artifact,
          message,
        },
      },
    };
    updateArtifact({
      variables: {
        artifactId: artifact.id,
        additionalFields: { feedbackMessage: message },
      },
      optimisticResponse,
      onError: onNotificationErrorHandler(),
      refetchQueries: [],
      onCompleted: () => {
        setInitialFeedbackMessage(feedbackMessage);
      },
    });
  };

  const handleSendFeedback = () => {
    setShowConfirmFeedback(false);
    const message = JSON.stringify(feedbackMessage);
    const optimisticResponse = {
      createOrUpdateArtifact: {
        artifact: {
          ...artifact,
          feedbackState: FeedbackState.Sent,
          message,
        },
      },
    };
    updateArtifact({
      variables: {
        artifactId: artifact.id,
        additionalFields: {
          feedbackState: FeedbackState.Sent,
          feedbackMessage: message,
        },
      },
      optimisticResponse,
      onError: onNotificationErrorHandler(),
      refetchQueries: [getArtifactActivitiesQuery, getFeedbackPageQuery],
      onCompleted: () => {
        setInitialFeedbackMessage(feedbackMessage);
      },
    });
  };

  const handleShowConfirmFeedback = () => {
    setShowConfirmFeedback(true);
  };

  const handleCancelConfirmFeedback = () => {
    setShowConfirmFeedback(false);
  };

  const feedbackRecipientIsCurrentUser =
    artifact.__typename === "FeedbackArtifactNode" &&
    recipients[0]?.id === currentUser.id;

  return (
    <>
      <div className="pt-2 px-4 sm:px-6 pb-10 bg-white flex flex-col gap-4 tracking-tight">
        <div className="text-sm flex items-center gap-4">
          <div className="font-medium w-18 text-gray-600">State:</div>
          <div className="flex items-center gap-2">
            <FeedbackStateLabel feedbackState={artifact.feedbackState} />
            {artifact.feedbackState === FeedbackState.Draft &&
              artifact.creator && (
                <div className="text-xs text-gray-400">
                  created by {artifact.creator.name}.
                </div>
              )}
            {artifact.feedbackState === FeedbackState.Requested &&
              artifact.creator && (
                <div className="text-xs text-gray-400">
                  by {artifact.creator.name}.
                </div>
              )}
          </div>
        </div>
        <div className="text-sm flex items-center gap-4">
          <div className="font-medium w-18 text-gray-600">Sender:</div>
          <div className="flex items-center gap-1">
            <Avatar user={artifact.sender} size="5" />
            {artifact.sender.name}
          </div>
        </div>
        <div className="text-sm flex items-center gap-4">
          <div className="font-medium w-18 text-gray-600">
            {pluralize("Subject", recipients.length)}:
          </div>
          <div className="flex items-center gap-2">
            {recipients.map((recipient, i) => (
              <span
                key={recipient.id}
                className="relative group flex items-center gap-1 pr-1"
              >
                {recipients.length > 1 && artifact.canUpdate.permission && (
                  <button
                    className="p-0.5 border rounded-full bg-gray-100 hover:bg-gray-200 absolute -top-1 -right-1 hidden group-hover:block"
                    onClick={handleRemoveRecognitionRecipient(recipient)}
                  >
                    <XIcon className="h-3 w-3 text-gray-500" />
                  </button>
                )}
                <Avatar user={recipient} size={5} />
                {recipient.name}
                {i < recipients.length - 1 ? "," : ""}
              </span>
            ))}
          </div>
          {artifact.feedbackState !== FeedbackState.Requested &&
            artifact.canUpdate.permission && (
              <RecipientDropdown
                recipients={recipients}
                onChangeRecipient={handleAddRecognitionRecipient}
              >
                {({ setReferenceElement, onClickButton }) => (
                  <Popover.Button
                    className="flex items-center gap-1 text-gray-400"
                    onClick={onClickButton}
                    ref={setReferenceElement}
                  >
                    <PlusCircleIcon className="h-4 w-4" />
                  </Popover.Button>
                )}
              </RecipientDropdown>
            )}
        </div>
        <div className="text-sm flex items-start gap-4">
          <div className="font-medium w-18 text-gray-600">Visible to:</div>
          <div className="flex items-center gap-2">
            {artifact.canUpdate.permission && (
              <AppPopover
                content={
                  <div className="p-4 flex flex-col gap-4 text-sm">
                    <div className="flex flex-col gap-1">
                      {artifact.feedbackState === FeedbackState.Requested &&
                        !feedbackRecipientIsCurrentUser && (
                          <label className="flex items-center gap-1">
                            <input
                              name="recipientsCanView"
                              type="checkbox"
                              checked={creatorCanView}
                              onChange={(e) =>
                                setCreatorCanView(e.target.checked)
                              }
                            />
                            Creator{" "}
                            {artifact.creator?.name
                              ? `(${artifact.creator.name})`
                              : ""}
                          </label>
                        )}
                      <label className="flex items-center gap-1">
                        <input
                          name="recipientsCanView"
                          type="checkbox"
                          checked={recipientsCanView}
                          onChange={(e) =>
                            setRecipientsCanView(e.target.checked)
                          }
                        />
                        Subjects
                      </label>
                      <label className="flex items-center gap-1">
                        <input
                          name="recipientsManagersCanView"
                          type="checkbox"
                          checked={recipientsManagersCanView}
                          onChange={(e) =>
                            setRecipientsManagersCanView(e.target.checked)
                          }
                        />
                        Subjects' managers
                      </label>
                      <label className="flex items-center gap-1">
                        <input
                          name="adminsCanView"
                          type="checkbox"
                          checked={adminsCanView}
                          onChange={(e) => setAdminsCanView(e.target.checked)}
                        />
                        Admins
                      </label>
                    </div>
                    <div className="mt-4">
                      <Button
                        text="Save"
                        theme={buttonTheme.primary}
                        disabled={loadingSave}
                        onClick={handleSaveSendToOption}
                      />
                    </div>
                  </div>
                }
              >
                <AppPopover.Button
                  aria-label="Feedback visibility button"
                  className="text-sm text-gray-400 hover:underline flex items-center gap-1"
                >
                  <>
                    <div className="text-gray-600 capitalize">
                      {getFeedbackSentToString(artifact)}
                    </div>
                    <PencilIcon className="h-4 w-4" />
                  </>
                </AppPopover.Button>
              </AppPopover>
            )}
          </div>
        </div>
        <div className="text-sm flex items-start gap-4">
          <div className="font-medium w-18 text-gray-600 mt-0.5">Message:</div>
          <div className="flex-1">
            <FeedbackMessageWYSIWYG
              key={forceRefresh}
              value={feedbackMessage}
              onUpdateContent={handleChangeFeedbackMessage}
              editable={artifact.canUpdate.permission}
              organizationId={artifact.organization!.id}
              artifactId={artifact.id}
              placeholder={
                artifact.canUpdate.permission
                  ? "Type feedback message..."
                  : "No message."
              }
              className="border rounded-lg px-2 py-1 min-h-16 text-sm w-full"
            />

            {artifact.canUpdate.permission && (
              <div>
                {showConfirmFeedback ? (
                  <div className="mt-4 flex flex-col gap-4 text-gray-800">
                    <div className="font-medium">
                      Confirm you want to sent this feedback?
                    </div>
                    <div className="text-sm font-normal">
                      This feedback about{" "}
                      <span className="font-medium">
                        {joinStringWithCommaAnd(recipients.map((r) => r.name))}
                      </span>{" "}
                      will be visible to:{" "}
                      <span className="font-medium">
                        {getFeedbackSentToString({
                          recipientsCanView: recipientsCanView,
                          recipientsManagersCanView: recipientsManagersCanView,
                          adminsCanView: adminsCanView,
                        })}
                      </span>
                    </div>

                    <div className="flex items-center gap-2">
                      <Button
                        text="Confirm"
                        onClick={handleSendFeedback}
                        theme={buttonTheme.primary}
                        type="button"
                      />
                      <Button
                        text="Cancel"
                        onClick={handleCancelConfirmFeedback}
                        type="button"
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className={classNames(
                      "mt-2 flex items-center gap-2 empty:hidden"
                    )}
                  >
                    {(artifact.feedbackState === FeedbackState.Draft ||
                      artifact.feedbackState === FeedbackState.Requested) &&
                      artifact.canUpdate.permission && (
                        <Button
                          theme={buttonTheme.primary}
                          onClick={handleShowConfirmFeedback}
                          text={
                            recipientsCanView
                              ? `Send to ${
                                  recipients.length === 1
                                    ? `${recipients[0].name}`
                                    : `${recipients.length} subjects`
                                }`
                              : "Send feedback"
                          }
                          disabled={
                            loadingSave || isEmptyValue(feedbackMessage)
                          }
                          tooltip={
                            isEmptyValue(feedbackMessage)
                              ? "Add a message"
                              : null
                          }
                        />
                      )}
                    {initialFeedbackMessage !== feedbackMessage && (
                      <>
                        <Button
                          text={
                            artifact.feedbackState === FeedbackState.Sent
                              ? "Save changes"
                              : "Save draft"
                          }
                          disabled={
                            loadingSave ||
                            initialFeedbackMessage === feedbackMessage
                          }
                          onClick={handleSaveFeedbackMessage}
                        />
                        <Button text="Discard" onClick={handleDiscardChanges} />
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ArtifactSidebarFeedback;
