import copy from "copy-to-clipboard";
import { round, sortBy } from "lodash";
import moment from "moment";
import numeral from "numeral";
import { useState } from "react";
import {
  KpiDashboardKpiFragmentFragment,
  KpiSummarizedMeasurementFragmentFragment,
  KpiTargetCondition,
  KpiTargetType,
} from "types/graphql-schema";

import { successNotificationVar } from "@cache/cache";
import Button from "@components/button/button";
import Tooltip from "@components/tooltip/tooltip";
import { classNames } from "@helpers/css";
import { assertNonNull } from "@helpers/helpers";

import KPIAddMeasurementDialog from "./add-measurement-dialog";
import { measurementRowCellClassName } from "./summary-table";

export const KPISummaryTableCell = ({
  kpi,
  isLastCell = false,
  measurement,
  previousMeasurement,
  className = "",
  onAddValue = null,
}: {
  kpi: KpiDashboardKpiFragmentFragment;
  isLastCell: boolean;
  measurement: KpiSummarizedMeasurementFragmentFragment;
  previousMeasurement: KpiSummarizedMeasurementFragmentFragment;
  className?: string;
  onAddValue: null | (() => void);
}) => {
  const [open, setOpen] = useState(false);
  const handleAddValue = () => {
    setOpen(false);
    if (onAddValue) onAddValue();
  };

  if (measurement.aggregateMeasurement === null) {
    return (
      <div
        className={classNames(
          "text-gray-400 flex justify-center items-center relative group",
          className
        )}
        key={measurement.date}
      >
        -
        {open && (
          <KPIAddMeasurementDialog
            kpiId={kpi.id}
            open={open}
            date={isLastCell ? moment().format() : measurement.date}
            onClose={() => setOpen(false)}
            onCreated={handleAddValue}
          />
        )}
        {kpi.canUpdate.permission && (
          <div className="hidden group-hover:flex absolute inset-0 items-center justify-center">
            <Button onClick={() => setOpen(true)} text="Add value" />
          </div>
        )}
      </div>
    );
  }

  const currentValue = measurement.aggregateMeasurement || 0;
  const previousValue = previousMeasurement?.aggregateMeasurement || 0;
  const diff = currentValue - previousValue;
  const progress =
    previousValue === 0 ? null : (currentValue / previousValue - 1) * 100;
  const valueToCompare =
    kpi.targetType === KpiTargetType.Growth ? progress : currentValue;
  const targetValue = kpi.targetValue || 0;
  const hasReachedTarget =
    (kpi.targetCondition === KpiTargetCondition.GreaterThan &&
      valueToCompare !== null &&
      valueToCompare > targetValue) ||
    (kpi.targetCondition === KpiTargetCondition.GreaterThanOrEqualTo &&
      valueToCompare !== null &&
      valueToCompare >= targetValue) ||
    (kpi.targetCondition === KpiTargetCondition.LessThan &&
      valueToCompare !== null &&
      valueToCompare < targetValue) ||
    (kpi.targetCondition === KpiTargetCondition.LessThanOrEqualTo &&
      valueToCompare !== null &&
      valueToCompare <= targetValue);
  const targetClassName = hasReachedTarget ? "bg-green-100" : "bg-red-100";

  const handleCopyCurrentValue = () => {
    copy(String(currentValue));
    successNotificationVar({
      title: `Value copied!`,
      description: String(currentValue),
      timeout: 1000,
    });
  };

  return (
    <div
      className={classNames(targetClassName, className, "group relative")}
      key={measurement.date}
    >
      {open && (
        <KPIAddMeasurementDialog
          kpiId={kpi.id}
          open={open}
          date={moment().format()}
          onClose={() => setOpen(false)}
          onCreated={handleAddValue}
        />
      )}
      {isLastCell && kpi.canUpdate.permission && (
        <div className="hidden group-hover:flex absolute inset-0 items-center justify-center">
          <Button onClick={() => setOpen(true)} text="Add value" />
        </div>
      )}
      <Tooltip text={String(currentValue)}>
        <button
          type="button"
          className="text-gray-800 tracking-tight"
          onClick={handleCopyCurrentValue}
        >
          {numeral(currentValue).format("0[.]00a")}
        </button>
      </Tooltip>
      <div className="mt-px text-[11px] tracking-tighter flex gap-1.5 justify-center fs-mask">
        {previousMeasurement &&
          previousMeasurement.aggregateMeasurement !== null && (
            <>
              <span
                className={classNames(
                  hasReachedTarget ? "text-green-700" : "text-red-700"
                )}
              >
                {diff > 0 ? "+" : diff < 0 ? "" : ""}
                {round(diff, 2)}
              </span>
              {progress !== null && (
                <span
                  className={classNames(
                    hasReachedTarget ? "text-green-700" : "text-red-700"
                  )}
                >
                  ({diff > 0 ? "+" : diff < 0 ? "" : ""}
                  {round(progress, 2)}%)
                </span>
              )}
            </>
          )}
      </div>
    </div>
  );
};

const KPISummaryTableRow = ({
  kpi,
  isLastKpi,
  onAddValue,
}: {
  kpi: KpiDashboardKpiFragmentFragment;
  isLastKpi: boolean;
  onAddValue: null | (() => void);
}) => {
  // RENDER
  const measurements =
    kpi?.summarizedMeasurements?.edges.map((edge) => {
      return assertNonNull(edge!.node);
    }) || [];
  const sortedMeasurements = sortBy(measurements, (node) =>
    moment(node.date).unix()
  );
  return (
    <div className="flex" key={kpi.id}>
      {sortedMeasurements.map((node, i) => (
        <KPISummaryTableCell
          key={i}
          kpi={kpi}
          isLastCell={sortedMeasurements.length - 1 === i}
          measurement={node}
          onAddValue={onAddValue}
          previousMeasurement={sortedMeasurements[i - 1]}
          className={classNames(
            measurementRowCellClassName,
            isLastKpi && sortedMeasurements.length - 1 === i && "rounded-br-md"
          )}
        />
      ))}
    </div>
  );
};

export default KPISummaryTableRow;
