import { useState } from "react";

import { ProgramActionToDoStateFilter } from "@apps/programs/components/compliance-program";
import useLabel from "@apps/use-label/use-label";
import Layout from "@components/layout/layout";
import Tabs from "@components/tabs/tabs";

import AssessmentsHomepageActiveReviews from "./homepage-active-reviews";
import AssessmentHomepageDeliveries from "./homepage-deliveries";

enum AssessmentsHomepageTabs {
  Active = "Active",
  PastReviews = "Past Reviews",
}

const AssessmentsHomepage = () => {
  const label = useLabel();
  const [selectedTab, setSelectedTab] = useState(
    AssessmentsHomepageTabs.Active
  );
  const [activeReviewFilter, setActiveReviewFilter] = useState(
    ProgramActionToDoStateFilter.All
  );
  return (
    <Layout.Main>
      <div className="p-8 pb-4 flex flex-col gap-4">
        <div className="text-2xl font-semibold">
          {label("review", { capitalize: true, pluralize: true })}
        </div>
        <Tabs
          options={[
            {
              value: AssessmentsHomepageTabs.Active,
              label: `Active ${label("review", {
                capitalize: true,
                pluralize: true,
              })}`,
            },
            {
              value: AssessmentsHomepageTabs.PastReviews,
              label: `Delivered ${label("review", {
                capitalize: true,
                pluralize: true,
              })}`,
            },
          ]}
          selectedValue={selectedTab}
          onClickOptionValue={(value) =>
            setSelectedTab(value as AssessmentsHomepageTabs)
          }
        />
      </div>
      {selectedTab === AssessmentsHomepageTabs.Active && (
        <AssessmentsHomepageActiveReviews
          activeReviewFilter={activeReviewFilter}
          onChangeActiveReviewFilter={setActiveReviewFilter}
        />
      )}
      {selectedTab === AssessmentsHomepageTabs.PastReviews && (
        <AssessmentHomepageDeliveries />
      )}
    </Layout.Main>
  );
};

export default AssessmentsHomepage;
