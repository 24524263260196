import { useQuery } from "@apollo/client";
import { ArrowSmLeftIcon, ArrowSmRightIcon } from "@heroicons/react/outline";
import moment from "moment";
import {
  GetRecurringMeetingPaginationQueryQuery,
  GetRecurringMeetingPaginationQueryQueryVariables,
  MeetingViewMeetingNodeNewPageFragmentFragment,
} from "types/graphql-schema";

import getRecurringMeetingPaginationQuery from "@apps/meeting-new/graphql/get-recurring-meeting-pagination-query";
import Button, { buttonTheme } from "@components/button/button";
import Tooltip from "@components/tooltip/tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

const MeetingSidebarRecurringMeetings = ({
  meeting,
  onChangeMeetingId,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
  onChangeMeetingId: (meetingId: number) => void;
}) => {
  const { data, loading } = useQuery<
    GetRecurringMeetingPaginationQueryQuery,
    GetRecurringMeetingPaginationQueryQueryVariables
  >(getRecurringMeetingPaginationQuery, {
    variables: {
      meetingId: meeting.id,
    },
    onError: onNotificationErrorHandler(),
  });

  const beforeMeeting = data?.beforeMeeting;
  const afterMeeting = data?.afterMeeting;

  const handleClickBefore = () => {
    if (beforeMeeting?.id) {
      onChangeMeetingId(beforeMeeting?.id);
    }
  };

  const handleClickAfter = () => {
    if (afterMeeting?.id) {
      onChangeMeetingId(afterMeeting?.id);
    }
  };

  return (
    <div className="flex items-center gap-2 justify-between px-6 py-4 text-sm text-gray-500 border-b">
      <div className="text-sm text-gray-500">Recurring Meeting</div>
      <div className="font-medium flex items-center gap-1">
        <Tooltip
          text={
            beforeMeeting
              ? `Previous meeting: ${moment(beforeMeeting.startDatetime).format(
                  "MMM D, YYYY"
                )}`
              : "No previous meeting"
          }
        >
          <span>
            <Button
              icon={ArrowSmLeftIcon}
              theme={buttonTheme.iconGray}
              disabled={loading || !beforeMeeting}
              onClick={handleClickBefore}
            />
          </span>
        </Tooltip>
        {moment(meeting.startDatetime).format("MMM D, YYYY")}
        <Tooltip
          text={
            afterMeeting
              ? `Next meeting: ${moment(afterMeeting.startDatetime).format(
                  "MMM D, YYYY"
                )}`
              : "No next meeting"
          }
        >
          <span>
            <Button
              icon={ArrowSmRightIcon}
              theme={buttonTheme.iconGray}
              disabled={loading || !afterMeeting}
              onClick={handleClickAfter}
            />
          </span>
        </Tooltip>
      </div>
    </div>
  );
};
export default MeetingSidebarRecurringMeetings;
