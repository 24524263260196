import { SparklesIcon } from "@heroicons/react/outline";
import { Editor } from "@tiptap/react";

import Button, { buttonTheme } from "@components/button/button";
import Loading from "@components/loading/loading";
import { classNames } from "@helpers/css";

import { AIMenuItems } from "./extensions/bubble-menu";

const WYSIWYGAIButton = ({
  editor,
  aiLoading,
}: {
  editor: Editor;
  aiLoading: boolean;
}) => {
  const { from, to } = editor.state.selection;
  const hasSelectedText = from !== to;
  return (
    <div className="mt-0.5">
      {aiLoading ? (
        <div className="py-1 px-3">
          <Loading size={5} mini />
        </div>
      ) : (
        <AIMenuItems
          editor={editor}
          onlyTextPrompt
          menuButton={
            <Button
              disabled={editor.isEmpty || !hasSelectedText}
              tooltip={
                editor.isEmpty
                  ? `Add text in the field`
                  : !hasSelectedText
                  ? "Select the text you want to edit with AI"
                  : null
              }
              theme={buttonTheme.iconGray}
              className={classNames(
                "text-purple-500 hover:text-purple-600",
                (editor.isEmpty || !hasSelectedText) && "opacity-50"
              )}
            >
              <SparklesIcon className="h-5 w-5" />
            </Button>
          }
        />
      )}
    </div>
  );
};

export default WYSIWYGAIButton;
