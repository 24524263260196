import { Node, mergeAttributes } from "@tiptap/core";
import { Editor, ReactNodeViewRenderer } from "@tiptap/react";
import { NodeViewWrapper } from "@tiptap/react";
import a from "indefinite";
import { compact } from "lodash";
import { ChangeEvent, useState } from "react";
import { ArtifactType, GoalScope } from "types/graphql-schema";

import ArtifactCreationDialog from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import useLabel from "@apps/use-label/use-label";
import { currentUserVar } from "@cache/cache";
import Button, { buttonTheme } from "@components/button/button";
import Input from "@components/input/input";
import Select from "@components/select/select";
import { classNames } from "@helpers/css";

const buttonAction = {
  createActionItem: "createActionItem",
  createGoal: "createGoal",
  createRecognition: "createRecognition",
  createDocument: "createDocument",
  createDecision: "createDecision",
};

const ButtonComponent = ({
  node,
  updateAttributes,
  selected,
  deleteNode,
  getPos,
  editor,
}: {
  node: any;
  updateAttributes: (attributes: any) => void;
  selected: boolean;
  deleteNode: () => void;
  getPos: () => number;
  editor: Editor;
}) => {
  const currentUser = currentUserVar();
  const label = useLabel();
  const buttonActions = [
    {
      value: buttonAction.createActionItem,
      label: "Create an action item",
      createArtifactOptions: {
        artifactType: ArtifactType.ActionItem,
      },
    },
    {
      value: buttonAction.createGoal,
      label: `Create ${a(label("goal"))}`,
      createArtifactOptions: {
        artifactType: ArtifactType.Goal,
        scope: GoalScope.Personal,
      },
    },
    {
      value: buttonAction.createRecognition,
      label: `Create ${a(label("recognition"))}`,
      createArtifactOptions: {
        artifactType: ArtifactType.Recognition,
      },
    },
    {
      value: buttonAction.createDocument,
      label: `Create a document`,
      createArtifactOptions: {
        artifactType: ArtifactType.Document,
      },
    },
    {
      value: buttonAction.createDecision,
      label: `Create a decision`,
      createArtifactOptions: {
        artifactType: ArtifactType.Decision,
      },
    },
  ];
  const defaultAction = buttonActions.find(
    ({ value }) => value === buttonAction.createActionItem
  );

  const [showCreateArtifactModal, setShowCreateArtifactModal] = useState(false);
  const [form, setForm] = useState({
    text: node.attrs.text,
    action: defaultAction!.value,
    createArtifactOptions: defaultAction!.createArtifactOptions,
  });

  const savedAction = buttonActions.find(
    ({ value }) => value === node.attrs.action
  );

  const handleClickButton = () => {
    if (savedAction?.createArtifactOptions) {
      setShowCreateArtifactModal(true);
    }
  };

  const handleChangeAction = (option: { value: string }) => {
    const matchingAction = buttonActions.find(
      ({ value }) => value === option.value
    );
    if (matchingAction) {
      setForm({
        ...form,
        action: option.value,
        createArtifactOptions: matchingAction.createArtifactOptions,
      });
    }
  };

  const handleChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, text: e.target.value });
  };

  const handleChangeGoalScope = ({ value }: { value: GoalScope }) => {
    const createArtifactOptions = {
      ...form.createArtifactOptions,
      scope: value,
    };
    setForm({ ...form, createArtifactOptions });
  };

  const handleCloseCreateArtifactDialog = (createdArtifact: any) => {
    setShowCreateArtifactModal(false);
    if (createdArtifact) {
      editor
        .chain()
        .focus(getPos() + 1)
        .insertContent({
          type: createdArtifact.artifactType,
          attrs: { id: createdArtifact.id },
        })
        .run();
    }
  };

  const handleSaveForm = () => {
    updateAttributes(form);
  };

  return (
    <NodeViewWrapper className={classNames("flex mb-3 w-full rounded-lg")}>
      {savedAction ? (
        <div
          className="flex items-center text-sm relative"
          contentEditable={false}
        >
          {showCreateArtifactModal && (
            <ArtifactCreationDialog
              formOptions={{
                ...savedAction?.createArtifactOptions,
                ...node.attrs.createArtifactOptions,
              }}
              onClose={handleCloseCreateArtifactDialog}
            />
          )}
          <Button
            theme={buttonTheme.primary}
            type="button"
            text={node.attrs.text}
            onClick={handleClickButton}
            className={classNames(
              selected && "ring-2 ring-blue-200 ring-offset-2"
            )}
          />
        </div>
      ) : (
        <div
          className={classNames(
            "flex-1 relative p-4 border rounded-lg bg-gray-50",
            selected && "ring-2 ring-blue-200 ring-offset-2"
          )}
          contentEditable={false}
        >
          <div
            className={classNames(
              "flex flex-1 flex-col gap-4 text-sm max-w-96"
            )}
            contentEditable={false}
          >
            <Select
              label="Action"
              options={buttonActions}
              value={form.action}
              onChange={handleChangeAction}
              disabled={!editor.options.editable}
            />
            <Input
              type="text"
              label="Button text"
              placeholder="Click me..."
              value={form.text}
              onChange={handleChangeText}
              disabled={!editor.options.editable}
            />
            {form.action === buttonAction.createGoal && (
              <Select
                label={`${label("goal", { capitalize: true })} type`}
                options={compact([
                  currentUser.paidFeatures.canCreateGroupGoals && {
                    value: GoalScope.Career,
                    label: `Career ${label("goal")}`,
                  },
                  {
                    value: GoalScope.Personal,
                    label: `${label("goal", { capitalize: true })}`,
                  },
                  currentUser.paidFeatures.canCreateGroupGoals && {
                    value: GoalScope.Team,
                    label: `${label("team", { capitalize: true })} ${label(
                      "goal"
                    )}`,
                  },
                  currentUser.paidFeatures.canCreateGroupGoals && {
                    value: GoalScope.Organization,
                    label: `${label("organization", {
                      capitalize: true,
                    })} ${label("goal")}`,
                  },
                ])}
                value={form.createArtifactOptions.scope}
                onChange={handleChangeGoalScope}
                disabled={!editor.options.editable}
              />
            )}
            <div className="flex gap-4 items-center">
              <Button
                disabled={
                  !editor.options.editable || form.text.trim().length === 0
                }
                theme={buttonTheme.primary}
                type="button"
                text="Save button"
                onClick={handleSaveForm}
              />
              <Button
                type="button"
                text="Cancel"
                onClick={() => deleteNode()}
                disabled={!editor.options.editable}
              />
            </div>
          </div>
        </div>
      )}
    </NodeViewWrapper>
  );
};

const ButtonExtension = Node.create({
  name: "button",
  group: "block",

  addAttributes() {
    // Return an object with attribute configuration
    return {
      action: {
        default: null,
      },
      text: {
        default: "Click",
      },
      createdByUser: {
        default: null,
      },
      createArtifactOptions: {
        default: null,
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(ButtonComponent);
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(HTMLAttributes)];
  },

  addCommands() {
    return {
      addButton:
        (attrs) =>
        ({ chain }) => {
          return chain().insertContent({ type: "button", attrs }).run();
        },
    };
  },
});

export default ButtonExtension;
