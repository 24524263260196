import { ChevronRightIcon, PlusCircleIcon } from "@heroicons/react/outline";
import { compact } from "lodash";
import { Fragment, MouseEvent, ReactNode, useCallback, useState } from "react";
import { TbArrowBarLeft, TbArrowBarRight } from "react-icons/tb";

import ArtifactCreationDialog, {
  ArtifactCreationDialogFormType,
} from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import useUiPreferenceCache, {
  UiPreferenceCache,
} from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import { buttonTheme } from "@components/button/button";
import Button from "@components/button/button";
import Dropdown, { DropdownOptionType } from "@components/dropdown/dropdown";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import {
  ToggleButtonGroup,
  ToggleButtonGroupTheme,
  ToggleButtonGroupType,
} from "@components/toggle-button-group/toggle-button-group";
import { classNames } from "@helpers/css";

import ToggleChevronIcon from "./toggle-chevron-icon";

const Layout = ({
  children,
  className,
  absoluteInsetPosition = true,
  ...props
}: {
  children: ReactNode;
  className?: string;
  absoluteInsetPosition?: boolean;
}) => (
  <div
    className={classNames(
      "min-w-0 flex flex-col bg-white",
      absoluteInsetPosition && "absolute inset-0",
      className
    )}
    {...props}
  >
    {children}
  </div>
);

const headerPaddingClassName = "px-1.5";

const Header = ({
  className,
  breadcrumbs,
  title,
  sidebarExpandedUiPreferenceKey,
  sidebarIsOpened,
  onToggleSidebar,
  children,
  ...props
}: {
  sidebarExpandedUiPreferenceKey?: keyof UiPreferenceCache;
  sidebarIsOpened?: boolean;
  onToggleSidebar?: () => void;
  title?: string | ReactNode;
  breadcrumbs?: {
    title: string;
    url: string;
  }[];
  className?: string;
  children?: ReactNode;
}) => {
  if (
    sidebarExpandedUiPreferenceKey !== undefined &&
    sidebarIsOpened !== undefined
  ) {
    throw new Error(
      "sidebarExpandedUiPreferenceKey and sidebarIsOpened cannot be used at the same time"
    );
  }
  if (title !== undefined && breadcrumbs !== undefined) {
    throw new Error("title and breadcrumbs cannot be used at the same time");
  }
  if (title === undefined && breadcrumbs === undefined) {
    throw new Error("You must define title or breadcrumbs");
  }
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const expanded = sidebarExpandedUiPreferenceKey
    ? !!uiPreferenceCache[`${sidebarExpandedUiPreferenceKey}`]
    : !!sidebarIsOpened;

  const handleCollapseSidebar = useCallback(() => {
    if (sidebarExpandedUiPreferenceKey) {
      saveUiPreference({ [`${sidebarExpandedUiPreferenceKey}`]: !expanded });
    } else if (onToggleSidebar) {
      onToggleSidebar();
    }
  }, [
    sidebarExpandedUiPreferenceKey,
    saveUiPreference,
    expanded,
    onToggleSidebar,
  ]);

  return (
    <div
      className={classNames(
        "@container/layout-header",
        "shrink-0 px-6 h-14 border-b flex items-center sticky z-horizontalNavbar bg-white",
        "absolute left-0 right-0 top-0",
        className
      )}
      {...props}
    >
      <header className="w-full fs-mask flex gap-4 justify-between items-center">
        {breadcrumbs && breadcrumbs.length > 0 ? (
          <div className="flex gap-2 items-center text-sm text-gray-600">
            {breadcrumbs.map((breadcrumb, index) => (
              <Fragment key={breadcrumb.url}>
                <AppLink
                  className="font-medium text-gray-800 truncate hover:underline"
                  to={breadcrumb.url}
                >
                  {breadcrumb.title}
                </AppLink>
                {index < breadcrumbs.length - 1 && (
                  <ChevronRightIcon className="h-3 w-3 text-gray-500" />
                )}
              </Fragment>
            ))}
          </div>
        ) : typeof title === "string" ? (
          <span className="font-medium text-gray-800 truncate text-sm">
            {title}
          </span>
        ) : (
          title
        )}

        <div className="flex items-center gap-1.5 shrink-0 empty:hidden">
          {children}
          {(onToggleSidebar || sidebarExpandedUiPreferenceKey) && (
            <Button
              theme={buttonTheme.iconGray}
              className={classNames("hidden lg:inline")}
              tooltip={expanded ? "Collapse sidebar" : "Expand sidebar"}
              onClick={handleCollapseSidebar}
              icon={expanded ? TbArrowBarRight : TbArrowBarLeft}
            />
          )}
        </div>
      </header>
    </div>
  );
};

const Container = ({
  children,
  loading,
  className,
  ...props
}: {
  className?: string;
  children: ReactNode;
  loading?: boolean;
}) => (
  <div
    className={classNames(
      "absolute top-14 left-0 right-0 bottom-0 flex",
      className
    )}
    {...props}
  >
    {loading ? <Loading className="flex-1 py-8" /> : children}
  </div>
);

const Main = ({
  children,
  sidebarExpandedUiPreferenceKey,
  sidebarIsOpened,
  className,
  subClassName,
  fullWidth,
  onScroll,
  ...props
}: {
  children: ReactNode;
  className?: string;
  subClassName?: string;
  fullWidth?: boolean;
  onScroll?: () => void;
  sidebarIsOpened?: boolean;
  sidebarExpandedUiPreferenceKey?: keyof UiPreferenceCache;
}) => {
  if (
    sidebarExpandedUiPreferenceKey !== undefined &&
    sidebarIsOpened !== undefined
  ) {
    throw new Error(
      "sidebarExpandedUiPreferenceKey and sidebarIsOpened cannot be used at the same time"
    );
  }
  const { uiPreferenceCache } = useUiPreferenceCache();
  const expanded =
    sidebarExpandedUiPreferenceKey !== undefined
      ? !!uiPreferenceCache[`${sidebarExpandedUiPreferenceKey}`]
      : !!sidebarIsOpened;
  return (
    <main
      className={classNames(
        "overflow-y-scroll absolute top-0 left-0 right-0 bottom-0",
        expanded && "lg:mr-96 xl:mr-120 2xl:mr-144", // padding and space for sidebar
        className
      )}
      onScroll={onScroll}
      {...props}
    >
      <div
        className={classNames(
          "@container/layout-main mx-auto",
          !fullWidth && "pb-16 max-w-screen-lg",
          subClassName
        )}
      >
        {children}
      </div>
    </main>
  );
};

const MainSection = ({
  children,
  title,
  loading,
  rightSide,
  options,
  expandedUiPreferenceKey,
  className,
  ...props
}: {
  expandedUiPreferenceKey?: keyof UiPreferenceCache;
  loading?: boolean;
  title?: string | ReactNode | null;
  className?: string;
  children: ReactNode;
  options?: DropdownOptionType[];
  rightSide?: ReactNode;
}) => {
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const expanded = expandedUiPreferenceKey
    ? uiPreferenceCache[`${expandedUiPreferenceKey}`]
    : true;

  const handleToggleCollapsible = useCallback(() => {
    saveUiPreference({ [`${expandedUiPreferenceKey}`]: !expanded });
  }, [expandedUiPreferenceKey, saveUiPreference, expanded]);

  return (
    <div
      className={classNames("flex flex-col p-8 gap-4", className)}
      {...props}
    >
      {loading ? (
        <Loading size={6} />
      ) : (
        <>
          {title && (
            <div className="flex justify-between items-center gap-2">
              <div
                className={classNames(
                  "shrink-0 flex items-center justify-between gap-3 px-1.5 -mx-1.5 rounded",
                  expandedUiPreferenceKey && "hover:bg-gray-50 "
                )}
                role={expandedUiPreferenceKey ? "button" : "none"}
                onClick={
                  expandedUiPreferenceKey ? handleToggleCollapsible : undefined
                }
              >
                <div className="text-lg font-semibold text-gray-900">
                  {title}
                </div>
                {expandedUiPreferenceKey && (
                  <ToggleChevronIcon expanded={expanded} />
                )}
              </div>
              <div className="flex items-center gap-4">
                {rightSide}
                {options && <Dropdown options={options} />}
              </div>
            </div>
          )}
          {expanded && children}
        </>
      )}
    </div>
  );
};

const MainSubSectionEmpty = ({
  children,
}: {
  children: string | ReactNode;
}) => (
  <div className="p-4 border rounded-lg text-center bg-gray-50 border-gray-100 text-gray-500 text-sm">
    {children}
  </div>
);

const MainSubSectionLoading = () => (
  <div
    className={classNames(
      "flex justify-between gap-3 bg-white px-3 py-3 border rounded-lg"
    )}
  >
    <div className="skeleton h-5 flex-1 rounded-lg" />
    <div className="skeleton h-5 w-36 rounded-lg" />
  </div>
);

const MainSubSection = ({
  children,
  title,
  collapsible,
  defaultIsExpanded = false,
  expandedUiPreferenceKey,
  emptyPlaceholder,
  className = "",
  loading,
  ...props
}: {
  expandedUiPreferenceKey?: keyof UiPreferenceCache;
  collapsible?: boolean;
  defaultIsExpanded?: boolean;
  title?: string;
  className?: string;
  loading?: boolean;
  emptyPlaceholder?: string | false | null;
  children: ReactNode;
}) => {
  if (collapsible !== undefined && expandedUiPreferenceKey !== undefined) {
    throw new Error(
      "collapsible and expandedUiPreferenceKey cannot be used at the same time"
    );
  }
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const effectivelyCollapsible =
    collapsible || expandedUiPreferenceKey !== undefined;
  const [isExpanded, setIsExpanded] = useState(
    expandedUiPreferenceKey
      ? uiPreferenceCache[`${expandedUiPreferenceKey}`]
      : effectivelyCollapsible
      ? defaultIsExpanded
      : true
  );

  const handleToggleCollapsible = useCallback(() => {
    if (expandedUiPreferenceKey) {
      saveUiPreference({ [`${expandedUiPreferenceKey}`]: !isExpanded });
    }
    setIsExpanded(!isExpanded);
  }, [expandedUiPreferenceKey, isExpanded, saveUiPreference]);

  return (
    <div
      className={classNames("text-gray-700", isExpanded && "mb-2", className)}
      {...props}
    >
      {title && (
        <div className="flex">
          <div
            role={effectivelyCollapsible ? "button" : "none"}
            className={classNames(
              "shrink-0 flex items-center gap-2 py-0.5 px-1.5 -mx-1.5 rounded",
              effectivelyCollapsible && "hover:bg-gray-50",
              isExpanded && "mb-2"
            )}
            onClick={
              effectivelyCollapsible ? handleToggleCollapsible : undefined
            }
          >
            <div
              className={classNames(
                "text-sm font-medium",
                isExpanded
                  ? "text-gray-700"
                  : "text-gray-500 hover:text-gray-900"
              )}
            >
              {title}
            </div>
            {effectivelyCollapsible && (
              <ToggleChevronIcon expanded={isExpanded} />
            )}
          </div>
        </div>
      )}
      {isExpanded && (
        <>
          {loading ? (
            <MainSubSectionLoading />
          ) : emptyPlaceholder ? (
            <MainSubSectionEmpty>{emptyPlaceholder}</MainSubSectionEmpty>
          ) : (
            children
          )}
        </>
      )}
    </div>
  );
};

const MainSubSectionList = ({
  children,
  className,
  ...props
}: {
  className?: string;
  children: ReactNode;
}) => {
  return (
    <div
      className={classNames(
        "divide-y divide-gray-200",
        "border border-gray-200 rounded-lg bg-white",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

const MainSubSectionListItem = ({
  children,
  className,
  ...props
}: {
  className?: string;
  children: ReactNode;
}) => {
  return (
    <div className={classNames("p-4 hover:bg-gray-50", className)} {...props}>
      {children}
    </div>
  );
};

const Sidebar = <T,>({
  children,
  sidebarExpandedUiPreferenceKey,
  isOpened,
  onHideSidebar,
  className,
  fullHeight,
  tabs,
  onChangeTab,
  ...props
}: {
  children: ReactNode;
  className?: string;
  fullHeight?: boolean;
  tabs?: ToggleButtonGroupType<T>[];
  onChangeTab?: (
    button: ToggleButtonGroupType<T>,
    e: MouseEvent<HTMLButtonElement>
  ) => void;
  sidebarExpandedUiPreferenceKey?: keyof UiPreferenceCache;
  isOpened?: boolean;
  onHideSidebar?: () => void;
}) => {
  const { uiPreferenceCache } = useUiPreferenceCache();
  if (isOpened === undefined && sidebarExpandedUiPreferenceKey === undefined) {
    throw new Error(
      "You must define isOpened or sidebarExpandedUiPreferenceKey"
    );
  }
  if (isOpened !== undefined && sidebarExpandedUiPreferenceKey !== undefined) {
    throw new Error(
      "isOpened and sidebarExpandedUiPreferenceKey cannot be used at the same time"
    );
  }
  const expanded = sidebarExpandedUiPreferenceKey
    ? !!uiPreferenceCache[`${sidebarExpandedUiPreferenceKey}`]
    : !!isOpened;
  if (!expanded) return null;
  return (
    <aside
      className={classNames(
        "hidden lg:block",
        "lg:w-96 xl:w-120 2xl:w-144",
        "fixed right-0 top-14 bottom-0",
        "max-h-full overflow-y-scroll",
        "border-l",
        className
      )}
      {...props}
    >
      <div
        className={classNames(
          "flex flex-col divide-y",
          fullHeight && "h-full",
          !fullHeight && "pb-24" // extra padding for intercom
        )}
      >
        {tabs && onChangeTab && (
          <div className="px-5 py-4 flex items-center top-0 sticky bg-white z-sticky border-b -mb-px">
            <ToggleButtonGroup<T>
              theme={ToggleButtonGroupTheme.sidebar}
              buttons={compact(tabs)}
              onClick={onChangeTab}
            />
          </div>
        )}
        {children}
      </div>
    </aside>
  );
};

const SidebarSection = ({
  children,
  className,
  title,
  options,
  expandedUiPreferenceKey,
  createArtifactFormOptions,
  ...props
}: {
  expandedUiPreferenceKey?: keyof UiPreferenceCache;
  title?: string;
  className?: string;
  options?: DropdownOptionType[];
  children: ReactNode;
  createArtifactFormOptions?: Partial<ArtifactCreationDialogFormType>;
}) => {
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const [isShowingCreateDialog, setIsShowingCreateDialog] = useState(false);
  const expanded = expandedUiPreferenceKey
    ? uiPreferenceCache[`${expandedUiPreferenceKey}`]
    : true;

  const handleToggleCollapsible = useCallback(() => {
    saveUiPreference({ [`${expandedUiPreferenceKey}`]: !expanded });
  }, [expandedUiPreferenceKey, saveUiPreference, expanded]);

  const handleShowArtifactCreationDialog = () => setIsShowingCreateDialog(true);
  const handleHideArtifactCreationDialog = () =>
    setIsShowingCreateDialog(false);

  return (
    <div
      className={classNames("px-6 py-4 border-b empty:hidden", className)}
      {...props}
    >
      {isShowingCreateDialog && (
        <ArtifactCreationDialog
          formOptions={createArtifactFormOptions}
          onClose={handleHideArtifactCreationDialog}
        />
      )}
      {title ? (
        <div>
          <div className="flex justify-between items-center gap-x-4 gap-y-1 flex-wrap py-1 group">
            <button
              className="text-sm flex items-center gap-2 py-1 px-2 rounded-md font-semibold hover:bg-gray-100 text-gray-900 -ml-2"
              onClick={
                expandedUiPreferenceKey ? handleToggleCollapsible : undefined
              }
            >
              {title}
              {expandedUiPreferenceKey && (
                <ToggleChevronIcon expanded={expanded} />
              )}
            </button>
            <div className="flex opacity-0 items-center gap-2 group-hover:opacity-100 empty:hidden">
              {createArtifactFormOptions !== undefined && (
                <button
                  className="text-gray-400 hover:bg-gray-50 rounded p-0.5"
                  onClick={handleShowArtifactCreationDialog}
                >
                  <PlusCircleIcon className="h-5 w-5" />
                </button>
              )}
              {options && <Dropdown options={options} align="end" />}
            </div>
          </div>
          {expanded && children}
        </div>
      ) : (
        children
      )}
    </div>
  );
};

const SidebarSubSectionEmpty = ({
  children,
}: {
  children: string | ReactNode;
}) => <div className="-mt-1 text-gray-400 text-xs">{children}</div>;

const SidebarSubSectionLoading = () => (
  <div className={classNames("flex justify-between gap-3 py-2")}>
    <div className="skeleton h-5 flex-1 rounded-lg" />
    <div className="skeleton h-5 w-16 rounded-lg" />
  </div>
);

const SidebarSubSection = ({
  children,
  className,
  title,
  titleRightSide = null,
  expandedUiPreferenceKey,
  isExpanded,
  loading,
  count,
  onToggleIsExpanded,
}: {
  children: ReactNode;
  className?: string;
  title: string;
  titleRightSide?: ReactNode;
  expandedUiPreferenceKey?: keyof UiPreferenceCache;
  isExpanded?: boolean;
  loading?: boolean;
  count?: number;
  onToggleIsExpanded?: (isExpanded: boolean) => void;
}) => {
  if (expandedUiPreferenceKey === undefined && isExpanded === undefined) {
    throw new Error(
      `${title}: you must define expandedUiPreferenceKey or isExpanded/onToggleIsExpanded props.`
    );
  }
  if (expandedUiPreferenceKey !== undefined && isExpanded !== undefined) {
    throw new Error(
      `${title}: expandedUiPreferenceKey and isExpanded cannot be used at the same time.`
    );
  }
  const { uiPreferenceCache, saveUiPreference } = useUiPreferenceCache();
  const expanded = expandedUiPreferenceKey
    ? uiPreferenceCache[`${expandedUiPreferenceKey}`]
    : isExpanded;
  const toggable =
    count !== undefined &&
    count > 0 &&
    (expandedUiPreferenceKey !== undefined || onToggleIsExpanded !== undefined);

  const handleToggleCollapsible = () => {
    if (expandedUiPreferenceKey) {
      saveUiPreference({ [`${expandedUiPreferenceKey}`]: !expanded });
    } else if (onToggleIsExpanded) {
      onToggleIsExpanded(!expanded);
    }
  };

  const showExpandedContent = count !== undefined && count > 0 && expanded;

  return (
    <div
      className={
        classNames(className, showExpandedContent && "mb-2")
        // don't use container queries, or it'll mess up the action items drag and drop
        // https://github.com/Topicflow/topicflow/issues/1608
      }
    >
      <div
        className={classNames(
          "fs-unmask",
          "flex items-center justify-between py-1"
        )}
      >
        <div
          role="button"
          onClick={toggable ? handleToggleCollapsible : undefined}
          className={classNames(
            "font-medium text-sm flex items-center gap-2 text-gray-500",
            "px-2 -ml-2 py-1 rounded-md",
            toggable ? "group hover:bg-gray-100" : ""
          )}
        >
          {title}
          {count !== undefined && (
            <span className="text-xs text-gray-500 bg-gray-100 group-hover:bg-gray-200 rounded-md px-2 py-0.5 font-normal">
              {count}
            </span>
          )}
          {toggable && <ToggleChevronIcon expanded={expanded} />}
        </div>
        <div className="flex items-center gap-2 empty:hidden">
          {titleRightSide}
        </div>
      </div>
      {showExpandedContent && (
        <div>{loading ? <SidebarSubSectionLoading /> : children}</div>
      )}
    </div>
  );
};

const SidebarSubSectionListPaginate = ({
  loading,
  hasNextPage,
  onClickMore,
}: {
  hasNextPage?: boolean;
  loading?: boolean;
  onClickMore?: () => void;
}) =>
  hasNextPage && onClickMore !== undefined && loading !== undefined ? (
    <div className="flex items-center justify-center">
      {loading ? (
        <Loading size="4" mini />
      ) : (
        <button
          className="text-gray-400 hover:text-gray-500 text-center text-xs hover:bg-gray-100 rounded px-2 py-1"
          onClick={onClickMore}
          disabled={loading}
        >
          View more
        </button>
      )}
    </div>
  ) : null;

const SidebarSubSectionList = ({
  children,
  className,
  hasNextPage,
  loading,
  onClickMore,
  ...props
}: {
  className?: string;
  hasNextPage?: boolean;
  loading?: boolean;
  onClickMore?: () => void;
  children: ReactNode;
}) => {
  return (
    <div className={classNames(className)} {...props}>
      {children}

      <SidebarSubSectionListPaginate
        hasNextPage={hasNextPage}
        loading={loading}
        onClickMore={onClickMore}
      />
    </div>
  );
};

const SidebarSubSectionListItem = ({
  children,
  className,
  ...props
}: {
  children: ReactNode;
  className?: string;
}) => (
  <div
    className={classNames(
      "py-2 px-2 -mx-2 hover:bg-gray-100 rounded-md",
      className
    )}
    {...props}
  >
    {children}
  </div>
);

Layout.Sidebar = Sidebar;
Layout.SidebarSection = SidebarSection;
Layout.SidebarSubSection = SidebarSubSection;
Layout.SidebarSubSectionLoading = SidebarSubSectionLoading;
Layout.SidebarSubSectionEmpty = SidebarSubSectionEmpty;
Layout.SidebarSubSectionList = SidebarSubSectionList;
Layout.SidebarSubSectionListItem = SidebarSubSectionListItem;
Layout.SidebarSubSectionListPaginate = SidebarSubSectionListPaginate;
Layout.Container = Container;
Layout.Header = Header;
Layout.headerPaddingClassName = headerPaddingClassName;
Layout.Main = Main;
Layout.MainSection = MainSection;
Layout.MainSubSection = MainSubSection;
Layout.MainSubSectionEmpty = MainSubSectionEmpty;
Layout.MainSubSectionList = MainSubSectionList;
Layout.MainSubSectionListItem = MainSubSectionListItem;

// const test = (
// <Layout>
//   <Layout.Header>
//     Header
//   </Layout.Header>
//   <Layout.Container>
//     <Layout.Main>
//       <Layout.MainSection>
//         <Layout.MainSubSection>
//           <Layout.MainSubSectionList>
//             <Layout.MainSubSectionListItem/>
//             <Layout.MainSubSectionListItem/>
//           </Layout.MainSubSectionList>
//         </Layout.MainSubSection>
//         <Layout.MainSubSection />
//         <Layout.MainSubSection />
//       </Layout.MainSection>
//     </Layout.Main>
//     <Layout.Sidebar>
//       <Layout.SidebarSection>
//         <Layout.SidebarSubSection>
//           <Layout.SidebarSubSectionList>
//           <Layout.SidebarSubSectionListItem />
//           <Layout.SidebarSubSectionListItem />
//           <Layout.SidebarSubSectionListPaginate />
//           </Layout.SidebarSubSectionList>
//         </Layout.SidebarSubSection>
//         <Layout.SidebarSubSection />
//         <Layout.SidebarSubSection />
//       </Layout.SidebarSection>
//     </Layout.Sidebar>
//   </Layout.Container>
// </Layout>
// );

export default Layout;
