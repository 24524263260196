import { CalendarIcon } from "@heroicons/react/outline";
import { withErrorBoundary } from "@sentry/react";
import copy from "copy-to-clipboard";
import moment from "moment";
import { ReactElement } from "react";
import {
  ExplorerMeetingResultFragmentFragment,
  ExplorerResultFragmentFragment,
  ExternalProfileNode,
  GetUrlPreviewQuery,
  GithubIssueNode,
} from "types/graphql-schema";

import Artifact from "@apps/artifact/artifact";
import { getLabel } from "@apps/use-label/use-label";
import { successNotificationVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import AppError from "@components/error/error";
import IntegrationLogo, {
  getIntegrationLogoType,
} from "@components/integration-logo/integration-logo";
import AppLink from "@components/link/link";
import {
  assertEdgesNonNullWithStringId,
  getNodeUrl,
  testIsArtifactTypename,
} from "@helpers/helpers";
import { pluralize } from "@helpers/string";

const PreviewAssignee = ({
  result,
  prefixWithAssignee = "",
  noAssignee = null,
  assignee = null,
}: {
  result: Pick<GetUrlPreviewQuery["urlToInfoCard"], "assignees">;
  prefixWithAssignee?: string;
  noAssignee?: ReactElement | null;
  assignee?: ExternalProfileNode | null;
}) => {
  if (assignee === null) {
    assignee = result?.assignees?.[0] || null;
  }
  if (assignee && assignee.url) {
    return (
      <span>
        {prefixWithAssignee}
        <a
          href={assignee.url}
          className="hover:underline"
          target="_blank"
          rel="noreferrer"
        >
          {assignee.username}
        </a>
      </span>
    );
  }
  return noAssignee;
};

export const GenericPreviewSubtitle = ({
  result,
}: {
  result: GetUrlPreviewQuery["urlToInfoCard"];
}) => {
  const url = getNodeUrl(result);
  return (
    <div className="tracking-tight text-2xs text-gray-500 ml-6">
      {url ? (
        <a
          href={url}
          className="hover:underline font-mono"
          target="_blank"
          rel="noreferrer"
        >
          #{result.id}
        </a>
      ) : (
        <span className="font-mono">#{result.id}</span>
      )}

      <PreviewAssignee
        prefixWithAssignee=" - "
        result={result}
        noAssignee={<span> - unassigned</span>}
      />
      {result.closedAt
        ? ` - Closed on ${moment(result.closedAt).format("LLL")}`
        : ""}
    </div>
  );
};

export const GithubPreviewSubtitle = ({
  result,
}: {
  result: Extract<
    GetUrlPreviewQuery["urlToInfoCard"],
    { __typename: "GithubIssueNode" }
  >;
}) => {
  const githubResult = result as GithubIssueNode;
  const url = getNodeUrl(githubResult);
  return (
    <div className="tracking-tight text-2xs text-gray-500 ml-6">
      {url ? (
        <a
          href={url}
          className="hover:underline font-mono"
          target="_blank"
          rel="noreferrer"
        >
          #{githubResult.id}
        </a>
      ) : (
        <span className="font-mono">#{githubResult.id}</span>
      )}
      <PreviewAssignee
        prefixWithAssignee=" - "
        result={githubResult}
        noAssignee={<span> - unassigned</span>}
      />
      {githubResult.closedAt ? (
        <span>
          {" "}
          -{" "}
          <span
            className={
              githubResult.isMerged ? "text-purple-700" : "text-red-700"
            }
          >
            Closed on {moment(githubResult.closedAt).format("LLL")}
          </span>
        </span>
      ) : githubResult.status === "open" ? (
        <span>
          {" "}
          -{" "}
          <span className="text-green-800">
            Opened on {moment(githubResult.created).format("LLL")}
          </span>
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export const SalesforcePreviewSubtitle = ({
  result,
}: {
  result: Extract<
    GetUrlPreviewQuery["urlToInfoCard"],
    { __typename: "SalesforceOpportunityNode" }
  >;
}) => {
  const assignee =
    result.assignees && result.assignees.length > 0
      ? result.assignees[0]
      : null;
  const assigneeUrl = assignee ? getNodeUrl(assignee) : null;
  return (
    <div className="tracking-tight text-2xs text-gray-500 ml-6">
      {assignee && assigneeUrl ? (
        <span>
          <a
            href={assigneeUrl}
            className="hover:underline"
            target="_blank"
            rel="noreferrer"
          >
            {assignee.username}
          </a>{" "}
          {result.amount && <span>- ${result.amount.toLocaleString()}</span>}
        </span>
      ) : (
        "no owner"
      )}
    </div>
  );
};

export const ClickupPreviewSubtitle = ({
  result,
}: {
  result: Extract<
    GetUrlPreviewQuery["urlToInfoCard"],
    { __typename: "ClickupTaskNode" }
  >;
}) => {
  const assignee =
    result.assignees && result.assignees.length > 0
      ? result.assignees[0]
      : null;
  const assigneeUrl = assignee?.url ? getNodeUrl(assignee) : null;
  return (
    <div className="tracking-tight text-2xs text-gray-500 ml-6">
      {assignee && assigneeUrl ? (
        <span>
          <a
            href={assigneeUrl}
            className="hover:underline"
            target="_blank"
            rel="noreferrer"
          >
            {assignee.username}
          </a>
        </span>
      ) : assignee ? (
        <span>{assignee.username}</span>
      ) : (
        "no assignee"
      )}
    </div>
  );
};

export const HubspotPreviewSubtitle = ({
  result,
}: {
  result: Extract<
    GetUrlPreviewQuery["urlToInfoCard"],
    { __typename: "HubspotDealNode" }
  >;
}) => {
  const assignee =
    result.assignees && result.assignees.length > 0
      ? result.assignees[0]
      : null;
  return (
    <div className="tracking-tight text-2xs text-gray-500 ml-6">
      {assignee ? (
        <span>
          <span>{assignee.username}</span>{" "}
          {result.amount && <span>- ${result.amount.toLocaleString()}</span>}
        </span>
      ) : (
        "no owner"
      )}
      {result.closedAt
        ? ` - Closed ${result.closedWon ? "won" : "lost"} on ${moment(
            result.closedAt
          ).format("LLL")}`
        : " - " + result.stageName}
    </div>
  );
};

export const LinearIssueSubtitle = ({
  result,
}: {
  result: Extract<
    GetUrlPreviewQuery["urlToInfoCard"],
    { __typename: "LinearIssueNode" }
  >;
}) => {
  const url = getNodeUrl(result);
  return (
    <div className="tracking-tight text-2xs text-gray-500 ml-6">
      {url ? (
        <a
          href={url}
          className="hover:underline font-mono"
          target="_blank"
          rel="noreferrer"
        >
          Issue #{result.id}
        </a>
      ) : (
        <span className="font-mono">Issue #{result.id}</span>
      )}

      <PreviewAssignee
        prefixWithAssignee=" - "
        result={result}
        noAssignee={<span> - unassigned</span>}
      />
      {result.closedAt
        ? ` - Closed on ${moment(result.closedAt).format("LLL")}`
        : ""}
    </div>
  );
};

export const LinearProjectSubtitle = ({
  result,
}: {
  result: Extract<
    GetUrlPreviewQuery["urlToInfoCard"],
    { __typename: "LinearProjectNode" }
  >;
}) => {
  const url = getNodeUrl(result);
  return (
    <div className="tracking-tight text-2xs text-gray-500 ml-6">
      {url ? (
        <a
          href={url}
          className="hover:underline font-mono"
          target="_blank"
          rel="noreferrer"
        >
          Project #{result.id}
        </a>
      ) : (
        <span className="font-mono">Project #{result.id}</span>
      )}

      <PreviewAssignee
        prefixWithAssignee=" - "
        result={result}
        noAssignee={<span> - unassigned</span>}
        assignee={result.lead}
      />
      {result.closedAt
        ? ` - Closed on ${moment(result.closedAt).format("LLL")}`
        : ""}
    </div>
  );
};

export const ExplorerResultPreview = ({ result }: { result: any }) => {
  const url = getNodeUrl(result);
  return (
    <div className="flex flex-col gap-px w-full">
      <div className="flex gap-1 sm:gap-2 items-center">
        <IntegrationLogo type={getIntegrationLogoType(result)} size={4} />
        {url ? (
          <a
            className="hover:underline font-medium"
            target="_blank"
            rel="noreferrer"
            href={url}
          >
            {result.title}
          </a>
        ) : (
          <span className="font-medium">{result.title}</span>
        )}
      </div>
      {result.__typename === "SalesforceOpportunityNode" ? (
        <SalesforcePreviewSubtitle result={result} />
      ) : result.__typename === "GithubIssueNode" ? (
        <GithubPreviewSubtitle result={result} />
      ) : result.__typename === "ClickupTaskNode" ? (
        <ClickupPreviewSubtitle result={result} />
      ) : result.__typename === "HubspotDealNode" ? (
        <HubspotPreviewSubtitle result={result} />
      ) : result.__typename === "LinearIssueNode" ? (
        <LinearIssueSubtitle result={result} />
      ) : result.__typename === "LinearProjectNode" ? (
        <LinearProjectSubtitle result={result} />
      ) : (
        <GenericPreviewSubtitle result={result} />
      )}
    </div>
  );
};

export const ExplorerMeetingPreview = ({
  result,
}: {
  result: ExplorerMeetingResultFragmentFragment;
}) => {
  if (!result.meeting) return null;
  const url = getNodeUrl(result);
  const participants = assertEdgesNonNullWithStringId(
    result.meeting.participants
  );
  const label = getLabel();
  const isOneonone = !!result.meeting.meetingGroup?.isFormalOneonone;
  const participantCount = (result.meeting.participants?.totalCount || 0) - 2;
  const participantLabel = [];
  participantLabel.push(
    `${isOneonone ? label("1-on-1", { capitalize: true }) : "Meeting"} between `
  );
  if (participants[0]) {
    participantLabel.push(participants[0].user?.name);
  }
  if (participants[1]) {
    if (isOneonone) {
      participantLabel.push(` and ${participants[1].user?.name}`);
    } else {
      participantLabel.push(`, ${participants[1].user?.name}`);
    }
  }
  if (isOneonone && participantCount > 0) {
    participantLabel.push(
      ` & ${participantCount} ${pluralize("participant", participantCount)}`
    );
  }
  return (
    <div>
      <div className="flex items-center justify-between w-full">
        <div className="flex gap-1 sm:gap-2 items-center">
          <CalendarIcon className="h-4 w-4 text-gray-400" />
          {url ? (
            <a
              className="hover:underline"
              target="_blank"
              rel="noreferrer"
              href={url}
            >
              {result.meeting.title}
            </a>
          ) : (
            <span>{result.meeting.title}</span>
          )}
        </div>
        <div className="text-xs text-gray-500">
          {moment(result.meeting.startDatetime).format("llll")}
        </div>
      </div>
      <div className="mt-1 text-xs text-gray-500 pl-6">
        {participantLabel.join("")}
      </div>
    </div>
  );
};
const integrationTypes = [
  "GithubIssueNode",
  "JiraIssueNode",
  "ClickupTaskNode",
  "HubspotDealNode",
];

const ExplorerResult = ({
  result,
  artifactUrlPrefix = "",
  size = 5,
  hideActionDropdown = false,
}: {
  result: ExplorerResultFragmentFragment;
  size?: number;
  hideActionDropdown: boolean;
  artifactUrlPrefix?: string;
}) => {
  const handleClickCopy = (text: string) => () => {
    successNotificationVar({
      title: `Copied "${text}"`,
      timeout: 2000,
    });
    copy(text);
  };
  return result.__typename === "BaseArtifactNode" &&
    result.specificArtifact &&
    testIsArtifactTypename(result.specificArtifact.__typename) ? (
    <Artifact
      artifact={result.specificArtifact}
      hideActionDropdown={hideActionDropdown}
      urlPrefix={artifactUrlPrefix}
    />
  ) : result.__typename === "MeetingWithRelevantSectionsNode" &&
    result.meeting ? (
    <ExplorerMeetingPreview result={result} />
  ) : integrationTypes.includes(result.__typename) ? (
    <ExplorerResultPreview result={result} />
  ) : result.__typename === "UserNode" ? (
    // means it's a user
    <div className="flex justify-between items-center">
      <AppLink
        className="flex items-center gap-1 sm:gap-2"
        to={`/dashboard/user/${result.id}`}
      >
        <Avatar user={result} size={size} />
        {result.name}
      </AppLink>
      <button
        className="text-gray-500 text-xs tracking-tight hover:underline"
        onClick={handleClickCopy(result.email)}
      >
        {result.email}
      </button>
    </div>
  ) : null;
};

export default withErrorBoundary(ExplorerResult, {
  fallback: (
    <AppError description={"This explorer result could not be displayed."} />
  ),
});
