import { useQuery } from "@apollo/client";
import { withErrorBoundary } from "@sentry/react";
import { sortBy } from "lodash";
import { useMemo } from "react";
import {
  GetDashboardInsightsQuery,
  GetDashboardInsightsQueryVariables,
  InsightType,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import useUiPreferenceCache from "@apps/use-ui-preference-cache/use-ui-preference-cache";
import Error from "@components/error/error";
import Layout from "@components/layout/layout";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull } from "@helpers/helpers";

import getDashboardInsightsQuery from "../graphql/get-dashboard-insights-query";
import Insight from "./insight";

const requestTypes = [InsightType.FeedbackRequest];

const Insights = ({ selectedUser }: { selectedUser: BasicUser }) => {
  const { uiPreferenceCache } = useUiPreferenceCache();
  const { data, loading } = useQuery<
    GetDashboardInsightsQuery,
    GetDashboardInsightsQueryVariables
  >(getDashboardInsightsQuery, {
    notifyOnNetworkStatusChange: true,
    skip: !uiPreferenceCache.dashboardRequirementsContainerExpanded,
    variables: { personId: selectedUser.id },
    onError: onNotificationErrorHandler(),
  });
  const allInsights = useMemo(() => {
    const userInsights = data?.user?.insights
      ? assertEdgesNonNull(data.user.insights)
      : [];
    return sortBy(userInsights, "type");
  }, [data]);

  const requirementInsights = useMemo(() => {
    return allInsights.filter(
      (insight) =>
        !!insight.complianceProgram?.id && !requestTypes.includes(insight.type)
    );
  }, [allInsights]);
  const insights = useMemo(() => {
    return allInsights.filter(
      (insight) =>
        !insight.complianceProgram && !requestTypes.includes(insight.type)
    );
  }, [allInsights]);
  const requests = useMemo(() => {
    return allInsights.filter((insight) => requestTypes.includes(insight.type));
  }, [allInsights]);

  const loadingNoData = !data && loading;

  return (
    <Layout.MainSection
      title={
        <div className="flex items-center gap-4">
          Requirements & Insights
          {loading && !!data && <Loading mini size={5} />}
        </div>
      }
      expandedUiPreferenceKey="dashboardRequirementsContainerExpanded"
    >
      <Layout.MainSubSection
        title={`Requirements${requirementInsights.length === 0 ? " (0)" : ""}`}
        loading={loadingNoData}
      >
        {requirementInsights.length > 0 && (
          <Layout.MainSubSectionList>
            {requirementInsights.map((requirementInsight) => (
              <Layout.MainSubSectionListItem key={requirementInsight.id}>
                <Insight
                  insight={requirementInsight}
                  selectedUser={selectedUser}
                />
              </Layout.MainSubSectionListItem>
            ))}
          </Layout.MainSubSectionList>
        )}
      </Layout.MainSubSection>
      <Layout.MainSubSection
        title={`Requests${requests.length === 0 ? " (0)" : ""}`}
        loading={loadingNoData}
      >
        {requests.length > 0 && (
          <Layout.MainSubSectionList>
            {requests.map((request) => (
              <Layout.MainSubSectionListItem key={request.id}>
                <Insight insight={request} selectedUser={selectedUser} />
              </Layout.MainSubSectionListItem>
            ))}
          </Layout.MainSubSectionList>
        )}
      </Layout.MainSubSection>
      <Layout.MainSubSection
        title={`Insights${insights.length === 0 ? " (0)" : ""}`}
        loading={loadingNoData}
      >
        {insights.length > 0 && (
          <Layout.MainSubSectionList>
            {insights.map((insight) => (
              <Layout.MainSubSectionListItem key={insight.id}>
                <Insight insight={insight} selectedUser={selectedUser} />
              </Layout.MainSubSectionListItem>
            ))}
          </Layout.MainSubSectionList>
        )}
      </Layout.MainSubSection>
    </Layout.MainSection>
  );
};

export default withErrorBoundary(Insights, {
  fallback: <Error description={"The insights could not be rendered."} />,
});
