import { useMutation, useQuery } from "@apollo/client";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { compact, uniqBy } from "lodash";
import numeral from "numeral";
import { ChangeEventHandler, useState } from "react";
import {
  AlignmentGoalFragmentFragment,
  GetKpisToConnectToKrQueryQuery,
  GetKpisToConnectToKrQueryQueryVariables,
  GoalArtifactSidebarFragmentFragment,
  GoalProgressType,
  KeyResultFragmentFragment,
  ManageGoalKeyResultMutation,
  ManageGoalKeyResultMutationVariables,
} from "types/graphql-schema";
import { typeGuardBasicUserHasDefinedEmail } from "types/topicflow";

import { KpiPicker } from "@apps/artifact-creation-dialog/artifact-creation-dialog";
import useLabel from "@apps/use-label/use-label";
import Button, { buttonTheme } from "@components/button/button";
import { ComboboxGenericOption } from "@components/combobox/generic-combobox";
import Input from "@components/input/input";
import Loading from "@components/loading/loading";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import Select, { SelectOption } from "@components/select/select";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { UserComboboxUserOption } from "@components/user-combobox/user-combobox-list";
import {
  getKeyResultProgressTypeOptions,
  graphqlNone,
} from "@helpers/constants";
import {
  assertEdgesNonNull,
  getStartValueForProgressType,
  getTargetValueForProgressType,
} from "@helpers/helpers";

import getArtifactActivitiesQuery from "../graphql/get-artifact-activities-query";
import getKpisToConnectToKrQuery from "../graphql/get-kpis-to-connect-to-kr-query";
import manageGoalKeyResultMutation from "../graphql/manage-goal-key-result-mutation";
import GoalKeyResultAssignee from "./goal-key-result-assignee";

const noKpiOption = { value: graphqlNone, label: "None" };

const GoalKeyResultModal = ({
  keyResult,
  goalArtifact,
  onClose,
}: {
  keyResult?: KeyResultFragmentFragment;
  goalArtifact:
    | GoalArtifactSidebarFragmentFragment
    | ({ __typename: "GoalArtifactNode" } & AlignmentGoalFragmentFragment);
  onClose: (keyResult?: KeyResultFragmentFragment) => void;
}) => {
  const label = useLabel();
  const [progressType, setProgressType] = useState(
    keyResult?.progressType || GoalProgressType.Percentage
  );
  const [startValue, setStartValue] = useState(`${keyResult?.startValue || 0}`);
  const [targetValue, setTargetValue] = useState(
    `${keyResult?.targetValue || 100}`
  );
  const [title, setTitle] = useState(keyResult?.title || "");
  const [assignee, setAssignee] = useState<
    | KeyResultFragmentFragment["assignee"]
    | (UserComboboxUserOption & { email: string })
  >(keyResult?.assignee);
  const [manageKeyResult, { loading: loadingMutation }] = useMutation<
    ManageGoalKeyResultMutation,
    ManageGoalKeyResultMutationVariables
  >(manageGoalKeyResultMutation);

  const [kpi, setKpi] = useState<
    (ComboboxGenericOption<number> & KeyResultFragmentFragment["kpi"]) | null
  >(
    keyResult?.kpi
      ? {
          ...keyResult.kpi,
          label: keyResult.kpi.title,
          value: keyResult.kpi.id,
        }
      : null
  );

  const { data, loading: kpiLoading } = useQuery<
    GetKpisToConnectToKrQueryQuery,
    GetKpisToConnectToKrQueryQueryVariables
  >(getKpisToConnectToKrQuery, {
    onError: onNotificationErrorHandler(),
  });
  const defaultKpiOptions = compact([
    noKpiOption,
    kpi?.value && {
      id: kpi.value,
      value: kpi.value,
      label: kpi.label,
      title: kpi.label,
      currentMeasurement: kpi.currentMeasurement,
    },
  ]);
  const kpis = data?.kpis ? assertEdgesNonNull(data.kpis) : [];
  const kpiOptions = uniqBy(
    defaultKpiOptions.concat(
      kpis.map(({ id, title, currentMeasurement }) => ({
        id,
        value: id,
        label: title,
        title,
        currentMeasurement,
      }))
    ),
    "value"
  );

  const handleSelectKpi = (option: any) => {
    setKpi(option.value === graphqlNone ? null : option);
  };

  const handleSelectProgressType = (option: SelectOption<GoalProgressType>) => {
    setProgressType(option.value);
  };

  const handleChangeStartValue: ChangeEventHandler<HTMLInputElement> = (e) => {
    setStartValue(e.target.value);
  };

  const handleChangeTargetValue: ChangeEventHandler<HTMLInputElement> = (e) => {
    setTargetValue(e.target.value);
  };

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleChangeAssignee = (option: UserComboboxUserOption | null) => {
    if (option && typeGuardBasicUserHasDefinedEmail(option))
      setAssignee(option);
  };

  const handleHideEditing = () => {
    onClose();
  };

  const handleSaveKR = () => {
    const newStartValue = getStartValueForProgressType({
      progressType,
      startValue: Number(startValue),
    });
    const newTargetValue = getTargetValueForProgressType({
      progressType,
      targetValue: Number(targetValue),
    });
    const optimisticKeyResult: KeyResultFragmentFragment = {
      __typename: "KeyResultNode",
      ...keyResult,
      id: keyResult?.id || -1,
      title: title,
      startValue: newStartValue,
      currentValue: newStartValue,
      targetValue: newTargetValue,
      progressType,
      kpi,
      assignee: assignee
        ? {
            ...assignee,
            __typename: "UserNode",
          }
        : null,
    };
    manageKeyResult({
      variables: {
        title,
        startValue: newStartValue,
        targetValue: newTargetValue,
        kpiId: kpi?.value || graphqlNone,
        keyResultId: keyResult?.id,
        goalId: goalArtifact.id,
        assigneeId: assignee?.id,
        progressType,
      },
      optimisticResponse: {
        __typename: "Mutation",
        createOrUpdateKeyResult: {
          __typename: "CreateOrUpdateKeyResultMutation",
          keyResult: optimisticKeyResult,
          goal: {
            ...goalArtifact,
            __typename: "GoalArtifactNode",
            keyResults: {
              ...goalArtifact.keyResults,
              __typename: "KeyResultNodeConnection",
              edges: [
                ...(goalArtifact.keyResults?.edges || []),
                {
                  __typename: "KeyResultNodeEdge",
                  node: optimisticKeyResult,
                },
              ],
            },
          },
        },
      },
      onCompleted: (response) => {
        if (response.createOrUpdateKeyResult?.keyResult)
          onClose(response.createOrUpdateKeyResult.keyResult);
      },
      refetchQueries: [getArtifactActivitiesQuery],
      onError: onNotificationErrorHandler(),
    });
  };

  return (
    <Modal alignedTop open>
      <div className="p-6 flex flex-col gap-4">
        <ModalTitle onClose={handleHideEditing}>
          {keyResult?.id ? "Edit" : "Create"} {label("key result")}
        </ModalTitle>
        <div className="flex items-center gap-2">
          <div className="font-medium text-sm w-28">Title</div>
          <Input
            className="py-1 px-2 w-full text-sm max-w-96"
            value={title}
            placeholder={`New ${label("key result")}`}
            onChange={handleChangeTitle}
            aria-label="Key result title"
            autoFocus
          />
          <GoalKeyResultAssignee
            assignee={assignee}
            onChange={handleChangeAssignee}
          />
        </div>
        {progressType !== GoalProgressType.Boolean && (
          <div className="flex gap-2 items-center">
            <div className="font-medium text-sm w-28">Start value</div>
            <Input
              type="text"
              className="w-24 text-sm px-2 py-0.5"
              value={startValue}
              onChange={handleChangeStartValue}
              aria-label="Key result start value"
            />
          </div>
        )}
        {progressType !== GoalProgressType.Boolean && (
          <div className="flex gap-2 items-center">
            <div className="font-medium text-sm w-28">Target value</div>
            <Input
              type="text"
              className="w-24 text-sm px-2 py-0.5 rounded border"
              value={targetValue}
              onChange={handleChangeTargetValue}
              aria-label="Key result target value"
            />
          </div>
        )}
        <div className="flex gap-2 items-start">
          <div className="font-medium text-sm w-28 mt-0.5 border border-transparent">
            Type
          </div>
          <div>
            <Select<GoalProgressType>
              className="text-sm pl-2 py-0.5"
              onChange={handleSelectProgressType}
              value={progressType}
              options={getKeyResultProgressTypeOptions()}
            />
          </div>
        </div>
        {kpiOptions.length > 1 && (
          <div className="flex gap-2 items-start">
            <div className="font-medium text-sm w-28 mt-0.5 border border-transparent">
              Linked KPI
            </div>
            <div className="">
              <div className="flex items-center gap-2">
                <KpiPicker
                  loading={kpiLoading}
                  options={kpiOptions}
                  value={kpi || noKpiOption}
                  onSelectKpi={handleSelectKpi}
                />
                {kpi && kpi.id !== noKpiOption.value && (
                  <a
                    href={`/kpis/${kpi.id}`}
                    rel="noreferrer"
                    target="_blank"
                    className="p-1 hover:bg-gray-100 rounded text-gray-500 hover:text-gray-800"
                  >
                    <ExternalLinkIcon className="h-4 w-4" />
                  </a>
                )}
              </div>
              {kpi && kpi.id !== noKpiOption.value && kpi.currentMeasurement && (
                <a
                  href={`/kpis/${kpi.id}`}
                  rel="noreferrer"
                  target="_blank"
                  className="mt-1 ml-0.5 text-xs tracking-tight text-gray-500 hover:underline"
                >
                  Latest value recorded:{" "}
                  {numeral(kpi.currentMeasurement.measurement).format()}
                </a>
              )}
            </div>
          </div>
        )}
        <div className="flex justify-between items-center gap-2 ml-30">
          <div className="flex items-center gap-2">
            <Button
              theme={buttonTheme.primary}
              text="Save"
              disabled={loadingMutation || title.trim().length === 0}
              onClick={handleSaveKR}
            />
            <Button text="Cancel" onClick={handleHideEditing} />
            {loadingMutation && <Loading mini size={4} />}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GoalKeyResultModal;
