import { compact, uniqBy } from "lodash";
import { AssessmentQuestionType } from "types/graphql-schema";

import { AssessmentQuestionRole, AssessmentQuestionWithWeight } from "./types";

export const isRoleBasedQuestionType = (
  questionType: AssessmentQuestionType
) => {
  return [
    AssessmentQuestionType.Competency,
    AssessmentQuestionType.CompetencyCriteria,
    AssessmentQuestionType.Responsibility,
  ].includes(questionType);
};

export const isGoalBasedQuestionType = (
  questionType: AssessmentQuestionType
) => {
  return [
    AssessmentQuestionType.OverallGoal,
    AssessmentQuestionType.IndividualGoal,
  ].includes(questionType);
};

export const getQuestionsByRole = (
  questions: AssessmentQuestionWithWeight[],
  roleFilter: (role: AssessmentQuestionRole) => boolean = () => true
) => {
  return questions.reduce(
    (acc, questionWithWeight) => {
      const { question } = questionWithWeight;
      const role =
        question.__typename === "CompetencyAssessmentQuestionNode"
          ? question.role
          : question.__typename === "ResponsibilityAssessmentQuestionNode"
          ? question.responsibility?.role
          : question.__typename === "CompetencyCriteriaAssessmentQuestionNode"
          ? question.role
          : null;
      if (!role) {
        throw new Error("Expected roll to not be null");
      }
      if (!roleFilter(role)) {
        return acc;
      }
      const roleId = role.id;
      const existing = acc.roleBasedQuestionsByRoleId[roleId] ?? [];
      return {
        roleBasedQuestionsByRoleId: {
          ...acc.roleBasedQuestionsByRoleId,
          [roleId]: [...existing, questionWithWeight],
        },
        roles: uniqBy(compact([...acc.roles, role]), "id"),
      };
    },
    { roles: [], roleBasedQuestionsByRoleId: {} } as {
      roles: AssessmentQuestionRole[];
      roleBasedQuestionsByRoleId: {
        [role: number]: AssessmentQuestionWithWeight[];
      };
    }
  );
};
