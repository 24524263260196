import { gql } from "@apollo/client";

import { AssessmentNominationFragment } from "@apps/assessments/graphql/get-assessment-nominations-query";

export const ComplianceProgramActionAssessmentDeliveryFragment = gql`
  fragment ComplianceProgramActionAssessmentDelivery on AssessmentDeliveryNode {
    id
    state
    deliveryDatetime
    lastSubmissionDatetime
    target {
      id
      name
      email
      firstName
      avatar
    }
    complianceProgram {
      id
      title
      dueDate
    }
    respondersCount
    nominees {
      totalCount
    }
    template {
      id
      assessmentType
    }
  }
`;

export default gql`
  ${ComplianceProgramActionAssessmentDeliveryFragment}
  fragment UserActivityComplianceGroupAssessmentTemplate on AssessmentTemplateNode {
    id
    assessmentType
    hasSelfAssessment
    assessments(responderId: $responderId) {
      edges {
        node {
          id
          state
          target {
            id
            name
            avatar
          }
        }
      }
    }
  }
  fragment UserActivityComplianceProgramMeeting on MeetingNode {
    id
    title
    meetingGroupId
    isFinalized
    startDatetime
    meetingGroup {
      id
      topicTemplate {
        id
      }
    }
    participants {
      edges {
        node {
          id
          status
          user {
            id
            name
            avatar
            email
          }
        }
      }
    }
  }
  ${AssessmentNominationFragment}
  query getComplianceProgramActions(
    $complianceProgramId: Int!
    $responderId: Int!
    $deliveryTargetIds: [Int]!
  ) {
    assessmentsOpenForNominations(complianceProgramId: $complianceProgramId) {
      edges {
        node {
          ...AssessmentNomination
        }
      }
    }
    assessmentDeliveries(
      complianceProgramId: $complianceProgramId
      targetIds: $deliveryTargetIds
    ) {
      edges {
        node {
          ...ComplianceProgramActionAssessmentDelivery
        }
      }
    }
    complianceProgram(pk: $complianceProgramId) {
      id
      title
      dueDate
      startDate
      ongoing
      state
      canUpdate {
        permission
        reason
      }
      appliesTo
      appliesToTeams {
        edges {
          node {
            id
            title
          }
        }
      }
      appliesToManagers {
        edges {
          node {
            id
            name
            avatar
          }
        }
      }
      appliesToUsers {
        edges {
          node {
            id
            name
            avatar
          }
        }
      }
      excludedUsers {
        edges {
          node {
            id
            name
            avatar
          }
        }
      }
      excludedHireDateAfter
      requiredTopicTemplates {
        edges {
          node {
            id
            title
          }
        }
      }
      matchingOneonones(participants: [$responderId]) {
        edges {
          node {
            ...UserActivityComplianceProgramMeeting
          }
        }
      }
      usersMissingAssessment {
        edges {
          node {
            responder {
              id
              name
              avatar
            }
            target {
              id
              name
              avatar
            }
            assessmentTemplateId
            assessmentTemplate {
              id
            }
          }
        }
      }
      performanceAssessmentTemplate {
        ...UserActivityComplianceGroupAssessmentTemplate
      }
      managerAssessmentTemplate {
        ...UserActivityComplianceGroupAssessmentTemplate
      }
      peerAssessmentTemplate {
        ...UserActivityComplianceGroupAssessmentTemplate
      }
      assessments(responderId: $responderId) {
        edges {
          node {
            id
            state
            submittedDatetime
            target {
              id
              name
              avatar
            }
            template {
              id
            }
          }
        }
      }
      applicableReports: applicableUsers(managerIds: [$responderId]) {
        edges {
          node {
            id
            name
            avatar
            email
          }
        }
      }
      applicableManagers: applicableUsers(reportIds: [$responderId]) {
        edges {
          node {
            id
            name
            avatar
            email
          }
        }
      }
    }
  }
`;
