import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/outline";
import {
  DetailedHTMLProps,
  HTMLAttributes,
  PropsWithChildren,
  createContext,
  useContext,
} from "react";

import { classNames } from "@helpers/css";

export type TableSortDir = "asc" | "desc";

export enum TableTheme {
  default = "default",
  whiteHeader = "whiteHeader",
}

export const TableThemeContext = createContext(TableTheme.default);

export type TableProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  scroll?: boolean;
  theme?: TableTheme;
};

export const TableContainer = ({
  children,
  scroll,
  className,
  theme = TableTheme.default,
  ...props
}: PropsWithChildren<TableProps>) => (
  <div
    className={classNames(
      "min-w-full border",
      theme === TableTheme.default && "rounded-md",
      theme === TableTheme.whiteHeader && "rounded-lg",
      scroll ? "overflow-scroll" : "overflow-hidden",
      className
    )}
    {...props}
  >
    {children}
  </div>
);

export const Table = ({
  children,
  theme = TableTheme.default,
  className,
  ...props
}: PropsWithChildren<
  { theme?: TableTheme; className?: string } & HTMLAttributes<HTMLTableElement>
>) => {
  return (
    <table
      className={classNames(
        "bg-white min-w-full divide-y divide-gray-200",
        className
      )}
      {...props}
    >
      <TableThemeContext.Provider value={theme}>
        {children}
      </TableThemeContext.Provider>
    </table>
  );
};

export const TableHeadCell = ({
  className = "",
  cellClassName,
  width = "",
  widthPx,
  children = "",
  roundedLeft = false,
  roundedRight = false,
  sorted,
  onClick,
}: PropsWithChildren<{
  className?: string;
  cellClassName?: string;
  width?: string;
  widthPx?: number;
  roundedLeft?: boolean;
  roundedRight?: boolean;
  sorted?: TableSortDir | null;
  onClick?: () => void;
}>) => {
  const theme = useContext(TableThemeContext);
  return (
    <th
      scope="col"
      className={classNames(
        "text-left",
        theme === TableTheme.default &&
          "text-xs font-medium text-gray-500 bg-gray-50 py-3 px-5",
        theme === TableTheme.whiteHeader &&
          "text-xs font-medium text-gray-500 py-4 px-4",
        width && `w-${width}`,
        roundedLeft && "rounded-tl-md",
        roundedRight && "rounded-tr-md",
        onClick && "cursor-pointer hover:underline",
        cellClassName
      )}
      style={{ width: widthPx !== undefined ? `${widthPx}px` : undefined }}
      onClick={onClick}
    >
      <div
        className={classNames(
          className,
          "flex items-center justify-start gap-2"
        )}
      >
        <div className="flex-auto">{children}</div>
        {sorted === "asc" && (
          <div>
            <ArrowUpIcon className="h-3 w-3" />
          </div>
        )}
        {sorted === "desc" && (
          <div>
            <ArrowDownIcon className="h-3 w-3" />
          </div>
        )}
      </div>
    </th>
  );
};

export const TableHeadRow = ({ children }: PropsWithChildren) => (
  <thead className="">
    <tr>{children}</tr>
  </thead>
);

export const TableBody = ({ children }: PropsWithChildren) => (
  <tbody>{children}</tbody>
);

export const TableBodyRow = ({
  children,
  className,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLTableRowElement>>) => (
  <tr className={classNames("border-t", className)} {...props}>
    {children}
  </tr>
);

export const TableBodyCell = ({
  children,
  className = "",
  colSpan,
}: PropsWithChildren<{ className?: string; colSpan?: number }>) => {
  const theme = useContext(TableThemeContext);
  return (
    <td
      className={classNames(
        "text-sm text-gray-500",
        theme === TableTheme.default && "px-5 py-3",
        theme === TableTheme.whiteHeader && "px-4 py-4",
        className
      )}
      colSpan={colSpan}
    >
      {children}
    </td>
  );
};

export const TableFooter = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => (
  <div
    className={classNames("px-5 pt-3 pb-1 text-sm text-gray-700", className)}
  >
    {children}
  </div>
);

export default Table;
