import { gql } from "@apollo/client";

export default gql`
  query getAssessmentQuestion($questionId: Int!) {
    assessmentQuestion(questionId: $questionId) {
      ... on RangeAssessmentQuestionNode {
        id
        title
        description
        created
        answersExist
        canUpdate {
          permission
          reason
        }
        creator {
          id
          name
        }
        categories {
          edges {
            node {
              id
              title
            }
          }
        }
        isCommentMandatory
        responses
        responseVisibility
        questionType
        startValue
        endValue
        labels
        labelDescriptions
      }
      ... on IndividualGoalAssessmentQuestionNode {
        id
        title
        description
        created
        answersExist
        canUpdate {
          permission
          reason
        }
        creator {
          id
          name
        }
        categories {
          edges {
            node {
              id
              title
            }
          }
        }
        isCommentMandatory
        responses
        responseVisibility
        questionType
        startValue
        endValue
        labels
        labelDescriptions
      }
      ... on OverallGoalAssessmentQuestionNode {
        id
        title
        description
        created
        answersExist
        canUpdate {
          permission
          reason
        }
        creator {
          id
          name
        }
        categories {
          edges {
            node {
              id
              title
            }
          }
        }
        isCommentMandatory
        responses
        responseVisibility
        questionType
        startValue
        endValue
        labels
        labelDescriptions
      }
      ... on CompetencyAssessmentQuestionNode {
        id
        title
        description
        created
        answersExist
        canUpdate {
          permission
          reason
        }
        creator {
          id
          name
        }
        categories {
          edges {
            node {
              id
              title
            }
          }
        }
        isCommentMandatory
        responses
        responseVisibility
        questionType
        startValue
        endValue
        labels
        labelDescriptions
      }
      ... on CompetencyCriteriaAssessmentQuestionNode {
        id
        title
        description
        created
        answersExist
        canUpdate {
          permission
          reason
        }
        creator {
          id
          name
        }
        categories {
          edges {
            node {
              id
              title
            }
          }
        }
        isCommentMandatory
        responses
        responseVisibility
        questionType
        startValue
        endValue
        labels
        labelDescriptions
      }
      ... on ResponsibilityAssessmentQuestionNode {
        id
        title
        description
        created
        answersExist
        canUpdate {
          permission
          reason
        }
        creator {
          id
          name
        }
        categories {
          edges {
            node {
              id
              title
            }
          }
        }
        isCommentMandatory
        responses
        responseVisibility
        questionType
        startValue
        endValue
        labels
        labelDescriptions
      }
      ... on MultiChoiceAssessmentQuestionNode {
        id
        title
        description
        created
        answersExist
        canUpdate {
          permission
          reason
        }
        creator {
          id
          name
        }
        categories {
          edges {
            node {
              id
              title
            }
          }
        }
        isCommentMandatory
        responses
        responseVisibility
        questionType
        options
        optionDescriptions
      }
      ... on TextAssessmentQuestionNode {
        id
        title
        description
        created
        answersExist
        canUpdate {
          permission
          reason
        }
        creator {
          id
          name
        }
        categories {
          edges {
            node {
              id
              title
            }
          }
        }
        isCommentMandatory
        responses
        responseVisibility
        questionType
      }
    }
  }
`;
