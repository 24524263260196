import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  GetAssessmentDeliveriesReceivedQuery,
  GetAssessmentDeliveriesReceivedQueryVariables,
} from "types/graphql-schema";

import getAssessmentDeliveriesReceived from "@apps/assessments/graphql/get-assessment-deliveries-received";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import Loading from "@components/loading/loading";
import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableFooter,
  TableHeadCell,
  TableHeadRow,
  TableSortDir,
} from "@components/table/table";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assessmentTypeLabels } from "@helpers/constants";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

enum SortKey {
  targetName = "name",
  lastSubmission = "submitted_datetime",
  delivery = "delivery_datetime",
  program = "compliance_program__title",
  assessmentType = "template__assessment_type",
}

const MyAssessmentsReceived = ({
  userFilter,
}: {
  userFilter: number | null;
}) => {
  const location = useLocation();
  const label = useLabel();
  const organization = currentOrganizationVar();
  const [sortKey, setSortKey] = useState<SortKey>(SortKey.lastSubmission);
  const [sortDir, setSortDir] = useState<TableSortDir>("asc");

  const [fetchAssessmentDeliveries, { data, loading, fetchMore }] =
    useLazyQuery<
      GetAssessmentDeliveriesReceivedQuery,
      GetAssessmentDeliveriesReceivedQueryVariables
    >(getAssessmentDeliveriesReceived);

  useEffect(() => {
    fetchAssessmentDeliveries({
      variables: {
        organizationId: organization.id,
        targetId: userFilter,
        orderBy: `${sortDir === "desc" ? "-" : ""}${sortKey}`,
      },
      onError: onNotificationErrorHandler(),
    });
  }, [userFilter, fetchAssessmentDeliveries, organization, sortDir, sortKey]);

  const toDeliver = useMemo(
    () =>
      data
        ? assertEdgesNonNull(data.assessmentDeliveries).filter(
            (assessmentDelivery) =>
              userFilter === null ||
              assessmentDelivery.target?.id === userFilter
          )
        : [],
    [data, userFilter]
  );

  const handleLoadMore = () => {
    if (data?.assessmentDeliveries.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          merge: true,
          after: data.assessmentDeliveries.pageInfo.endCursor,
        },
      });
    }
  };

  const handleColumnSort = useCallback(
    (newSortKey: SortKey) => {
      if (newSortKey !== sortKey) {
        setSortKey(newSortKey);
      } else {
        setSortDir(sortDir === "asc" ? "desc" : "asc");
      }
    },
    [sortDir, sortKey]
  );

  if (loading) {
    return <Loading className="p-6 w-full mx-auto">Loading</Loading>;
  }

  return (
    <>
      <TableContainer aria-label="Previous assessments">
        <Table>
          <TableHeadRow>
            <TableHeadCell
              sorted={sortKey === SortKey.targetName ? sortDir : undefined}
              onClick={() => handleColumnSort(SortKey.targetName)}
            >
              Subject
            </TableHeadCell>
            <TableHeadCell
              sorted={sortKey === SortKey.program ? sortDir : undefined}
              onClick={() => handleColumnSort(SortKey.program)}
            >
              Program
            </TableHeadCell>
            <TableHeadCell
              sorted={sortKey === SortKey.assessmentType ? sortDir : undefined}
              onClick={() => handleColumnSort(SortKey.assessmentType)}
            >
              {`${label("review", { capitalize: true })} type`}
            </TableHeadCell>
            <TableHeadCell
              sorted={sortKey === SortKey.lastSubmission ? sortDir : undefined}
              onClick={() => handleColumnSort(SortKey.lastSubmission)}
            >
              Last submission
            </TableHeadCell>
            <TableHeadCell
              sorted={sortKey === SortKey.delivery ? sortDir : undefined}
              onClick={() => handleColumnSort(SortKey.delivery)}
            >
              Delivery
            </TableHeadCell>
            <TableHeadCell className="w-52"></TableHeadCell>
          </TableHeadRow>
          <TableBody>
            {!loading && toDeliver.length === 0 && (
              <TableBodyRow>
                <TableBodyCell colSpan={5}>
                  {`No ${label("review", { pluralize: true })} received.`}
                </TableBodyCell>
              </TableBodyRow>
            )}
            {toDeliver.map((assessmentDelivery) => {
              const target = assertNonNull(assessmentDelivery.target);
              return (
                <TableBodyRow key={assessmentDelivery.id}>
                  <TableBodyCell>
                    <div className="flex items-center">
                      <Avatar className="mr-2" size="8" user={target} />{" "}
                      {target.name}
                    </div>
                  </TableBodyCell>
                  <TableBodyCell>
                    {assessmentDelivery.complianceProgram?.title}
                  </TableBodyCell>
                  <TableBodyCell>
                    {
                      assessmentTypeLabels[
                        assessmentDelivery.template.assessmentType
                      ]
                    }
                  </TableBodyCell>
                  <TableBodyCell>
                    {moment(assessmentDelivery.lastSubmissionDatetime).format(
                      "MMM D, YYYY"
                    )}
                  </TableBodyCell>
                  <TableBodyCell>
                    {assessmentDelivery.deliveryDatetime
                      ? `Delivered on ${moment(
                          assessmentDelivery.deliveryDatetime
                        ).format("MMM D, YYYY")}`
                      : "Immediate access"}
                  </TableBodyCell>
                  <TableBodyCell className="text-right">
                    <Button
                      theme={buttonTheme.lightBlue}
                      to={{
                        pathname: `/assessments/assessment/delivery/${assessmentDelivery.id}`,
                        state: {
                          previousPathname: `${location.pathname}${location.search}`,
                        },
                      }}
                    >
                      View
                    </Button>
                  </TableBodyCell>
                </TableBodyRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TableFooter>
        {data?.assessmentDeliveries.pageInfo.hasNextPage && (
          <div className="flex justify-center">
            {loading ? (
              <Loading mini size="5" />
            ) : (
              <button
                onClick={handleLoadMore}
                className="text-gray-500 hover:underline"
              >
                View more
              </button>
            )}
          </div>
        )}
      </TableFooter>
    </>
  );
};

export default MyAssessmentsReceived;
