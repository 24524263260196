import { useMutation, useQuery } from "@apollo/client";
import { compact, flatMap, map } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  AssessmentDeliveryState,
  AssessmentQuestionResponseVisibility,
  AssessmentQuestionType,
  CompetencyAssessmentAnswerNode,
  CompetencyCriteriaAssessmentAnswerNode,
  GetAssessmentDeliveryQuery,
  GetAssessmentDeliveryQueryVariables,
  IndividualGoalAssessmentAnswerNode,
  MultiChoiceAssessmentAnswerNode,
  OverallGoalAssessmentAnswerNode,
  RangeAssessmentAnswerNode,
  ResponsibilityAssessmentAnswerNode,
  SaveAssessmentDeliveryMutation,
  SaveAssessmentDeliveryMutationVariables,
  TextAssessmentAnswerNode,
} from "types/graphql-schema";
import { BasicUser, TFLocationState } from "types/topicflow";

import AskAI from "@apps/ask-ai/ask-ai";
import Feedbacks from "@apps/dashboard-new/components/feedbacks";
import Recognitions from "@apps/dashboard-new/components/recognitions";
import useLabel from "@apps/use-label/use-label";
import WorkHistorySidebar from "@apps/work-history/components/work-history-sidebar";
import { currentOrganizationVar, successNotificationVar } from "@cache/cache";
import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import Layout from "@components/layout/layout";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import useDebounce from "@components/use-debounce/use-debounce";
import {
  onNotificationErrorHandler,
  useNotificationError,
} from "@components/use-error/use-error";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";

import getAssessmentDelivery from "../../graphql/get-assessment-delivery";
import updateAssessmentDeliveryMutation from "../../graphql/update-assessment-delivery-mutation";
import AssessmentQuestionMultiSelectOptions from "../assessment-question-multi-select-options";
import { AssessmentSidebarTab } from "../assessment/assessment";
import AssessmentGoalSidebar from "../assessment/assessment-goal-sidebar";
import RelatedRequirements from "../assessment/related-requirements";
import AssessmentQuestionHeading from "../questions/assessment-question-heading";
import {
  getQuestionsByRole,
  isGoalBasedQuestionType,
  isRoleBasedQuestionType,
} from "../questions/helpers";
import RangeAssessmentQuestionContent from "../questions/range-assessment-question-content";
import RoleBasedAssessmentQuestionGroup from "../questions/role-based-assessment-question-group";
import { AssessmentQuestion, AssessmentSection } from "../questions/types";
import AssessmentDeliveryHeader from "./assessment-delivery-header";

const getAssessmentDeliverySections = (
  assessmentDeliveryData?: GetAssessmentDeliveryQuery
) => {
  if (!assessmentDeliveryData) {
    return [];
  }

  const assessmentTemplate = assertNonNull(
    assessmentDeliveryData.assessmentDelivery?.template
  );
  const additionalQuestionSets = assertEdgesNonNull(
    assessmentDeliveryData.assessmentDelivery?.additionalQuestionSets
  );
  const addtionalSections = flatMap(additionalQuestionSets, (set) => {
    const newSections: AssessmentSection[] = assertEdgesNonNull(
      set.sections
    ).map((section) => {
      const questions = assertEdgesNonNull(section.questions);
      return {
        id: section.id,
        title: section.title,
        description: section.description,
        questions,
      };
    });
    return newSections;
  });
  const questionSets = assertEdgesNonNull(assessmentTemplate.questionSets);
  const regularSections = flatMap(questionSets, (set) => {
    const newSections: AssessmentSection[] = assertEdgesNonNull(
      set.sections
    ).map((section) => {
      const questions = assertEdgesNonNull(section.questions);
      return {
        id: section.id,
        title: section.title,
        description: section.description,
        questions,
      };
    });
    return newSections;
  });
  return [...addtionalSections, ...regularSections];
};

const AssessmentDeliveryQuestion = ({
  canUpdate,
  excludedAnswerIds,
  answers,
  index,
  question,
  isQuestionWeightingEnabled,
  weight,
  totalQuestionWeight,
  target,
  onUpdateExcludedAnswers,
}: {
  canUpdate: boolean;
  excludedAnswerIds: number[];
  isQuestionWeightingEnabled: boolean;
  answers: ((
    | Pick<
        IndividualGoalAssessmentAnswerNode,
        "__typename" | "id" | "integerAnswer" | "comment"
      >
    | Pick<
        OverallGoalAssessmentAnswerNode,
        "__typename" | "id" | "integerAnswer" | "comment"
      >
    | Pick<
        RangeAssessmentAnswerNode,
        "__typename" | "id" | "integerAnswer" | "comment"
      >
    | Pick<
        ResponsibilityAssessmentAnswerNode,
        "__typename" | "id" | "integerAnswer" | "comment"
      >
    | Pick<
        CompetencyAssessmentAnswerNode,
        "__typename" | "id" | "integerAnswer" | "comment"
      >
    | Pick<
        CompetencyCriteriaAssessmentAnswerNode,
        "__typename" | "id" | "integerAnswer" | "comment"
      >
    | Pick<
        MultiChoiceAssessmentAnswerNode,
        "__typename" | "id" | "choices" | "comment"
      >
    | Pick<
        TextAssessmentAnswerNode,
        "__typename" | "id" | "textAnswer" | "comment"
      >
  ) & { question: { id: number }; responder?: BasicUser | null })[];
  index: number;
  question: AssessmentQuestion;
  weight: number;
  totalQuestionWeight: number;
  target?: BasicUser | null;
  onUpdateExcludedAnswers: (excludedAnswerIds: number[]) => void;
}) => {
  if (!canUpdate && answers.length === 0) {
    return null;
  }

  const hiddenFromSubject =
    question.responseVisibility ===
    AssessmentQuestionResponseVisibility.HiddenFromSubject;
  return (
    <div
      key={question.id}
      className={classNames(
        question.__typename === "OverallGoalAssessmentQuestionNode"
          ? "p-4"
          : question.__typename === "IndividualGoalAssessmentQuestionNode"
          ? "p-4"
          : "border-b py-6"
      )}
      aria-label={`Assessment question: ${question.title}`}
    >
      <AssessmentQuestionHeading index={index} question={question} />

      <div
        className={classNames(
          "flex flex-col gap-4",
          question.__typename === "OverallGoalAssessmentQuestionNode"
            ? ""
            : "mt-4"
        )}
      >
        {answers?.map((answer) => {
          const comment = answer?.comment ?? "{}";
          const textAnswer =
            answer.__typename === "TextAssessmentAnswerNode"
              ? answer.textAnswer
              : "{}";
          const isExcluded = excludedAnswerIds.includes(answer.id);

          const weightedScore =
            answer &&
            answer.__typename === "RangeAssessmentAnswerNode" &&
            question.questionType === AssessmentQuestionType.Range ? (
              <span>
                Weighted score:{" "}
                {(
                  (weight * assertNonNull(answer.integerAnswer)) /
                  totalQuestionWeight
                ).toFixed(1)}
              </span>
            ) : null;
          const responder = (
            <div className="flex items-center text-sm mb-2">
              {canUpdate && (
                <input
                  className="mr-2 disabled:cursor-not-allowed"
                  type="checkbox"
                  checked={hiddenFromSubject ? false : !isExcluded}
                  disabled={hiddenFromSubject}
                  onChange={(evt) => {
                    const newAnswers = !evt.target.checked
                      ? excludedAnswerIds.concat([answer.id])
                      : excludedAnswerIds.filter((a) => a !== answer.id);
                    onUpdateExcludedAnswers(newAnswers);
                  }}
                />
              )}
              <Avatar className="mr-1" user={answer.responder} size="4" />{" "}
              {answer.responder?.name ?? "Anonymous"}
              {answer.responder?.id === target?.id ? " (Self)" : ""}
              {isExcluded ? " [excluded]" : ""}
            </div>
          );

          if (isExcluded) {
            return <div key={answer.id}>{responder}</div>;
          }

          if (
            question.__typename === "RangeAssessmentQuestionNode" ||
            question.__typename === "CompetencyAssessmentQuestionNode" ||
            question.__typename === "IndividualGoalAssessmentQuestionNode" ||
            question.__typename === "OverallGoalAssessmentQuestionNode" ||
            question.__typename ===
              "CompetencyCriteriaAssessmentQuestionNode" ||
            question.__typename === "ResponsibilityAssessmentQuestionNode"
          ) {
            return (
              <div key={answer.id}>
                {responder}
                <RangeAssessmentQuestionContent
                  disabled
                  answer={answer}
                  isQuestionWeightingEnabled={isQuestionWeightingEnabled}
                  weightedScore={weightedScore}
                  question={question}
                />
              </div>
            );
          }
          if (question.__typename === "MultiChoiceAssessmentQuestionNode") {
            return (
              <div key={answer.id}>
                {responder}
                <AssessmentQuestionMultiSelectOptions
                  choices={
                    answer.__typename === "MultiChoiceAssessmentAnswerNode"
                      ? answer.choices
                      : null
                  }
                  disabled
                  options={question.options}
                  optionDescriptions={question.optionDescriptions}
                />
                {answer && !isEmptyValue(answer.comment) && (
                  <div className="mt-2 flex flex-col gap-2">
                    <div className="flex items-center justify-between">
                      <div className="text-gray-500 text-xs uppercase font-semibold">
                        Comment
                      </div>
                    </div>
                    <TextareaWysiwyg
                      editable={false}
                      className="mt-1 bg-white"
                      value={comment}
                    />
                  </div>
                )}
              </div>
            );
          }

          if (question.__typename === "TextAssessmentQuestionNode") {
            return (
              <div key={answer.id}>
                {responder}
                <TextareaWysiwyg
                  key={answer.id}
                  editable={false}
                  className="bg-white"
                  value={textAnswer}
                  deps={[answer.id]}
                />
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export const AssessmentDeliveryContent = ({
  assessmentDeliveryData,
  showHiddenQuestions,
  excludedAnswerIds,
  summary,
  canUpdate,
  isDraft,
  onUpdateDeliveryData,
}: {
  showHiddenQuestions: boolean;
  assessmentDeliveryData: GetAssessmentDeliveryQuery;
  summary: JSON | string | null;
  excludedAnswerIds: number[];
  canUpdate: boolean;
  isDraft: boolean;
  onUpdateDeliveryData?: (attrs: any) => void;
}) => {
  const label = useLabel();
  const assessmentTemplate = useMemo(
    () =>
      assessmentDeliveryData?.assessmentDelivery
        ? assessmentDeliveryData.assessmentDelivery?.template
        : null,
    [assessmentDeliveryData]
  );
  const target = useMemo(
    () =>
      assessmentDeliveryData?.assessmentDelivery
        ? assessmentDeliveryData.assessmentDelivery?.target
        : null,
    [assessmentDeliveryData]
  );
  const targetCurrentRoleIds = useMemo(
    () =>
      compact(assessmentDeliveryData.assessmentDelivery?.targetCurrentRoleIds),
    [assessmentDeliveryData]
  );

  const sectionNodes = useMemo(() => {
    return getAssessmentDeliverySections(assessmentDeliveryData);
  }, [assessmentDeliveryData]);

  const questionNodes = useMemo(() => {
    return flatMap(sectionNodes, (section) => section.questions);
  }, [sectionNodes]);

  const allAnswers = useMemo(() => {
    if (!assessmentDeliveryData?.assessmentDelivery) {
      return [];
    }
    const allAnswers = assertEdgesNonNull(
      assessmentDeliveryData?.assessmentDelivery.answers
    );
    return allAnswers;
  }, [assessmentDeliveryData]);

  const totalQuestionWeight = useMemo(
    () => questionNodes.reduce((sum, node) => sum + node.weight, 0),
    [questionNodes]
  );

  const totalWeightedScore = useMemo(() => {
    return questionNodes
      .filter(
        (questionNode) =>
          questionNode.question.__typename === "RangeAssessmentQuestionNode"
      )
      .reduce((sum, node) => {
        const answer = allAnswers.find(
          (answer) => answer.question.id === node.question.id
        );
        if (!answer || answer.__typename !== "RangeAssessmentAnswerNode") {
          return sum;
        }
        return (
          sum +
          (node.weight * assertNonNull(answer.integerAnswer)) /
            totalQuestionWeight
        );
      }, 0);
  }, [allAnswers, questionNodes, totalQuestionWeight]);
  return (
    <>
      {sectionNodes.map((section) => {
        const sectionQuestions = section.questions;
        const hasVisibleQuestions = sectionQuestions.some((question) => {
          const answers = allAnswers.filter(
            (answer) => answer.question.id === question.question.id
          );
          return answers.length > 0;
        });
        if (!hasVisibleQuestions) {
          return null;
        }
        const heading = String(section.id).endsWith(
          "-goals"
        ) ? null : section.title ? (
          <div>
            <div className="font-bold text-xl">{section.title}</div>
            {section.description && !isEmptyValue(section.description) && (
              <TextareaWysiwyg
                editable={false}
                className="mt-1 bg-white"
                value={section.description}
              />
            )}
          </div>
        ) : null;

        const regularQuestions = sectionQuestions.filter(
          ({ question }) =>
            !isRoleBasedQuestionType(question.questionType) &&
            !isGoalBasedQuestionType(question.questionType)
        );
        const roleBasedQuestions = sectionQuestions.filter(({ question }) =>
          isRoleBasedQuestionType(question.questionType)
        );
        const goalBasedQuestions = sectionQuestions.filter(({ question }) =>
          isGoalBasedQuestionType(question.questionType)
        );
        const overallGoalBasedQuestion = goalBasedQuestions.find(
          ({ question }) =>
            question.questionType === AssessmentQuestionType.OverallGoal
        );
        const individualGoalBasedQuestions = goalBasedQuestions.filter(
          ({ question }) =>
            question.questionType === AssessmentQuestionType.IndividualGoal
        );
        const overallGoalBasedQuestionAnswers = allAnswers.filter(
          (answer) =>
            overallGoalBasedQuestion &&
            answer.question.id === overallGoalBasedQuestion.question.id
        );

        const {
          roles: currentRoles,
          roleBasedQuestionsByRoleId: currentRoleQuestionsByRoleId,
        } = getQuestionsByRole(roleBasedQuestions, (role) => {
          return targetCurrentRoleIds.includes(role.id);
        });
        const {
          roles: nextRoles,
          roleBasedQuestionsByRoleId: nextRoleQuestionsByRoleId,
        } = getQuestionsByRole(roleBasedQuestions, (role) => {
          return !targetCurrentRoleIds.includes(role.id);
        });

        return (
          <div key={section.id}>
            {heading}
            {regularQuestions
              .filter(
                (node) =>
                  showHiddenQuestions ||
                  node.question.responseVisibility !==
                    AssessmentQuestionResponseVisibility.HiddenFromSubject
              )
              .map((node, index) => {
                const { question, weight } = node;
                const answers = allAnswers.filter(
                  (answer) => answer.question.id === question.id
                );
                return (
                  <AssessmentDeliveryQuestion
                    key={question.id}
                    canUpdate={!!canUpdate && isDraft}
                    excludedAnswerIds={excludedAnswerIds}
                    onUpdateExcludedAnswers={(newExcludedAnswerIds) => {
                      if (onUpdateDeliveryData) {
                        onUpdateDeliveryData({
                          excludedAnswerIds: newExcludedAnswerIds,
                        });
                      }
                    }}
                    answers={answers}
                    question={question}
                    weight={weight}
                    totalQuestionWeight={totalQuestionWeight}
                    index={index}
                    isQuestionWeightingEnabled={
                      !!assessmentTemplate?.isQuestionWeightingEnabled
                    }
                    target={target}
                  />
                );
              })}
            {map(currentRoleQuestionsByRoleId, (questions, roleIdStr) => {
              const role = assertNonNull(
                currentRoles.find((role) => role.id === parseInt(roleIdStr))
              );

              return (
                <div className="mt-4" key={roleIdStr}>
                  <RoleBasedAssessmentQuestionGroup
                    allAnswers={allAnswers.map((ans) => ({
                      ...ans,
                      questionId: ans.question.id,
                      textAnswer:
                        ans.__typename === "TextAssessmentAnswerNode"
                          ? ans.textAnswer
                          : null,
                      comment: ans.comment ?? null,
                    }))}
                    role={role}
                    questions={questions}
                    formDisabled
                    isCurrentRole
                  />
                </div>
              );
            })}
            {map(nextRoleQuestionsByRoleId, (questions, roleIdStr) => {
              const role = assertNonNull(
                nextRoles.find((role) => role.id === parseInt(roleIdStr))
              );
              const hasAnswers = allAnswers.some((answer) =>
                questions.map((q) => q.id).includes(answer.question.id)
              );
              if (!hasAnswers) {
                return null;
              }

              return (
                <div className="mt-4" key={roleIdStr}>
                  <RoleBasedAssessmentQuestionGroup
                    allAnswers={allAnswers.map((ans) => ({
                      ...ans,
                      questionId: ans.question.id,
                      textAnswer:
                        ans.__typename === "TextAssessmentAnswerNode"
                          ? ans.textAnswer
                          : null,
                      comment: ans.comment ?? null,
                    }))}
                    role={role}
                    questions={questions}
                    formDisabled
                  />
                </div>
              );
            })}

            {String(section.id).endsWith("-goals") && (
              <>
                <div className="font-bold text-xl mb-4">
                  {label("goal", { pluralize: true, capitalize: true })}
                </div>
                <div className="font-medium text-base">
                  Assess the {label("goal", { pluralize: true })} due in this{" "}
                  {label("review")} cycle
                </div>
                <div className="border rounded-md divide-y mt-2">
                  {individualGoalBasedQuestions.length === 0 && (
                    <div className="p-4">
                      <div className="text-sm text-gray-500">
                        No {label("goal")}s to assess.
                      </div>
                    </div>
                  )}
                  {individualGoalBasedQuestions
                    .filter((node) => {
                      if (
                        node.question.responseVisibility ===
                          AssessmentQuestionResponseVisibility.HiddenFromSubject &&
                        !showHiddenQuestions
                      )
                        return false;
                      return true;
                    })
                    .map((node, index) => {
                      const { question, weight } = node;
                      const answers = allAnswers.filter(
                        (answer) => answer.question.id === question.id
                      );
                      return (
                        <AssessmentDeliveryQuestion
                          key={question.id}
                          canUpdate={!!canUpdate && isDraft}
                          excludedAnswerIds={excludedAnswerIds}
                          onUpdateExcludedAnswers={(newExcludedAnswerIds) => {
                            if (onUpdateDeliveryData) {
                              onUpdateDeliveryData({
                                excludedAnswerIds: newExcludedAnswerIds,
                              });
                            }
                          }}
                          answers={answers}
                          question={question}
                          weight={weight}
                          totalQuestionWeight={totalQuestionWeight}
                          index={index}
                          isQuestionWeightingEnabled={
                            !!assessmentTemplate?.isQuestionWeightingEnabled
                          }
                          target={target}
                        />
                      );
                    })}
                </div>

                {individualGoalBasedQuestions.length > 0 &&
                  overallGoalBasedQuestion && (
                    <div className="mt-6">
                      <div className="font-medium text-base">
                        Provide an overall assessment on{" "}
                        {label("goal", { pluralize: true })}
                      </div>
                      <div className="border rounded-md divide-y mt-2">
                        <AssessmentDeliveryQuestion
                          key={overallGoalBasedQuestion.id}
                          canUpdate={!!canUpdate && isDraft}
                          excludedAnswerIds={excludedAnswerIds}
                          onUpdateExcludedAnswers={(newExcludedAnswerIds) => {
                            if (onUpdateDeliveryData) {
                              onUpdateDeliveryData({
                                excludedAnswerIds: newExcludedAnswerIds,
                              });
                            }
                          }}
                          answers={overallGoalBasedQuestionAnswers}
                          question={overallGoalBasedQuestion.question}
                          weight={0}
                          totalQuestionWeight={totalQuestionWeight}
                          index={0}
                          isQuestionWeightingEnabled={
                            !!assessmentTemplate?.isQuestionWeightingEnabled
                          }
                          target={target}
                        />
                      </div>
                    </div>
                  )}
              </>
            )}
          </div>
        );
      })}

      {((canUpdate && isDraft) || !isEmptyValue(summary)) && (
        <>
          <div className="text-gray-500 text-xs uppercase font-semibold">
            Summary
          </div>
          <TextareaWysiwyg
            editable={!!canUpdate && isDraft}
            className="mt-1 bg-white"
            value={summary}
            onChangeValue={(newSummary) => {
              if (onUpdateDeliveryData)
                onUpdateDeliveryData({
                  summary: newSummary,
                });
            }}
          />
        </>
      )}

      <div className="text-sm text-gray-500">
        {assessmentTemplate?.isQuestionWeightingEnabled && (
          <span>Total weighted score: {totalWeightedScore.toFixed(1)}</span>
        )}
      </div>
    </>
  );
};

type DeliveryData = {
  excludedAnswerIds: number[];
  summary: string | null;
};

const AssessmentDelivery = () => {
  const { assessmentDeliveryId: assessmentDeliveryIdParam } = useParams<{
    assessmentDeliveryId: string;
  }>();
  const assessmentDeliveryId = parseInt(assessmentDeliveryIdParam);
  const location = useLocation<TFLocationState>();
  const backUrl = location.state?.previousPathname || "/assessments";
  const { onError } = useNotificationError();
  const link = useLink();
  const label = useLabel();
  const organization = currentOrganizationVar();

  const [activeTab, setActiveTab] = useState(AssessmentSidebarTab.artifacts);
  const [chatSessionId, setChatSessionId] = useState<null | number>(null);
  const [proposedDeliveryData, setProposedDeliveryData] =
    useState<DeliveryData | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isShowingHiddenQuestions, setIsShowingHiddenQuestions] =
    useState(false);
  const debouncedDeliveryData = useDebounce(proposedDeliveryData, 500);

  const { data: assessmentDeliveryData, loading: isLoadingAssessmentDelivery } =
    useQuery<GetAssessmentDeliveryQuery, GetAssessmentDeliveryQueryVariables>(
      getAssessmentDelivery,
      {
        fetchPolicy: "no-cache",
        variables: {
          assessmentDeliveryId,
          organizationId: organization.id,
        },
        onCompleted: (response) => {
          if (response.assessmentDelivery) {
            const excludedAnswerIds = response.assessmentDelivery
              .excludedAnswerIds as number[];
            setProposedDeliveryData({
              summary: response.assessmentDelivery.summary,
              excludedAnswerIds,
            });
          }
        },
        onError: onNotificationErrorHandler(),
      }
    );

  const sectionNodes = useMemo(() => {
    return getAssessmentDeliverySections(assessmentDeliveryData);
  }, [assessmentDeliveryData]);
  const questionNodes = useMemo(() => {
    return flatMap(sectionNodes, (section) => section.questions);
  }, [sectionNodes]);
  const allAnswers = useMemo(() => {
    if (!assessmentDeliveryData?.assessmentDelivery) {
      return [];
    }
    return assertEdgesNonNull(
      assessmentDeliveryData?.assessmentDelivery.answers
    );
  }, [assessmentDeliveryData]);
  const hiddenQuestionCount = useMemo(() => {
    return questionNodes.filter(
      (node) =>
        node.question.responseVisibility ===
          AssessmentQuestionResponseVisibility.HiddenFromSubject &&
        allAnswers.some((answer) => answer.question.id === node.question.id)
    ).length;
  }, [questionNodes, allAnswers]);

  const [saveAssessmentDelivery] = useMutation<
    SaveAssessmentDeliveryMutation,
    SaveAssessmentDeliveryMutationVariables
  >(updateAssessmentDeliveryMutation);

  const canUpdate = useMemo(
    () =>
      assessmentDeliveryData?.assessmentDelivery &&
      assessmentDeliveryData.assessmentDelivery.canUpdate?.permission,
    [assessmentDeliveryData]
  );

  useEffect(() => {
    if (
      !assessmentDeliveryData?.assessmentDelivery ||
      !canUpdate ||
      debouncedDeliveryData === null
    ) {
      return;
    }
    saveAssessmentDelivery({
      variables: {
        assessmentDeliveryId,
        ...debouncedDeliveryData,
      },
      onCompleted: () => setIsSaving(false),
      onError,
    });
    // autosave, only fire to backend when debounced data changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedDeliveryData]);

  const program = useMemo(
    () =>
      assessmentDeliveryData?.assessmentDelivery
        ? assessmentDeliveryData.assessmentDelivery?.complianceProgram
        : null,
    [assessmentDeliveryData]
  );
  const complianceProgramPeriod = useMemo(
    () =>
      program?.periodStartDate && program?.periodEndDate
        ? [program.periodStartDate, program.periodEndDate]
        : undefined,
    [program]
  );
  const target = useMemo(
    () =>
      assessmentDeliveryData?.assessmentDelivery
        ? assessmentDeliveryData.assessmentDelivery?.target
        : null,
    [assessmentDeliveryData]
  );
  const relatedMeetings = assessmentDeliveryData?.assessmentDelivery
    ?.relatedMeetings
    ? assertEdgesNonNull(
        assessmentDeliveryData?.assessmentDelivery?.relatedMeetings
      )
    : [];

  const handleUpdateDeliveryData = useCallback(
    (data: Partial<DeliveryData>) => {
      if (proposedDeliveryData === null) {
        return;
      }
      setProposedDeliveryData({ ...proposedDeliveryData, ...data });
      setIsSaving(true);
    },
    [proposedDeliveryData]
  );

  const {
    ConfirmationDialog: DeliverConfirmationDialog,
    confirm: confirmDeliver,
  } = useConfirm(
    "Are you sure?",
    `This will deliver the responses you have selected to ${
      assessmentDeliveryData?.assessmentDelivery?.target?.name
    }. Any further responses to this ${label(
      "review"
    )} with automatically be added to the delivery package. Do you want to continue?`
  );
  const handleDeliver = useCallback(async () => {
    const confirmation = await confirmDeliver();
    if (confirmation) {
      saveAssessmentDelivery({
        variables: {
          assessmentDeliveryId,
          state: AssessmentDeliveryState.Delivered,
        },
        onCompleted: () => {
          successNotificationVar({
            title: `${label("review", {
              capitalize: true,
            })} package delivered`,
          });
          link.redirect(backUrl);
        },
        onError,
      });
    }
  }, [
    confirmDeliver,
    saveAssessmentDelivery,
    assessmentDeliveryId,
    onError,
    label,
    link,
    backUrl,
  ]);

  const {
    ConfirmationDialog: ReopenConfirmationDialog,
    confirm: confirmReopen,
  } = useConfirm(
    "Are you sure?",
    `Reopening the ${label(
      "review"
    )} delivery package will remove the subject's access until it is redelivered.`
  );
  const handleReopen = useCallback(async () => {
    const confirmation = await confirmReopen();
    if (confirmation) {
      saveAssessmentDelivery({
        variables: {
          assessmentDeliveryId,
          state: AssessmentDeliveryState.Draft,
        },
        onCompleted: () => {
          successNotificationVar({
            title: `${label("review", {
              capitalize: true,
            })} package reverted to in progress`,
          });
        },
        refetchQueries: [getAssessmentDelivery],
        onError,
      });
    }
  }, [
    assessmentDeliveryId,
    confirmReopen,
    onError,
    saveAssessmentDelivery,
    label,
  ]);

  const isDraft =
    assessmentDeliveryData?.assessmentDelivery?.state !==
    AssessmentDeliveryState.Delivered;

  return (
    <Layout className="bg-white">
      <Layout.Header
        title={label("review", { capitalize: true, pluralize: true })}
        sidebarExpandedUiPreferenceKey="assessmentDeliverySidebarExpanded"
        className="bg-white"
      />
      <Layout.Container loading={isLoadingAssessmentDelivery}>
        <Layout.Main sidebarExpandedUiPreferenceKey="assessmentDeliverySidebarExpanded">
          <ReopenConfirmationDialog />
          <DeliverConfirmationDialog />
          <div
            className="mt-8"
            aria-label="Assessments > performance assessment delivery"
          >
            <div className="p-6 flex flex-col gap-4">
              {assessmentDeliveryData && proposedDeliveryData && (
                <>
                  <AssessmentDeliveryHeader
                    backUrl={backUrl}
                    assessmentDeliveryData={assessmentDeliveryData}
                    canUpdate={!!canUpdate}
                    isSaving={isSaving}
                    onReopen={handleReopen}
                    onToggleShowHiddenQuestions={setIsShowingHiddenQuestions}
                    isShowingHiddenQuestions={isShowingHiddenQuestions}
                    hiddenQuestionCount={hiddenQuestionCount}
                  />
                  <AssessmentDeliveryContent
                    assessmentDeliveryData={assessmentDeliveryData}
                    canUpdate={!!canUpdate}
                    isDraft={isDraft}
                    excludedAnswerIds={proposedDeliveryData.excludedAnswerIds}
                    summary={proposedDeliveryData.summary}
                    onUpdateDeliveryData={handleUpdateDeliveryData}
                    showHiddenQuestions={isShowingHiddenQuestions}
                  />
                </>
              )}

              {canUpdate && isDraft && (
                <div className="mt-2 flex items-center justify-end">
                  {isSaving && <Loading className="mr-4" mini size="5" />}
                  <Button
                    theme={buttonTheme.primary}
                    disabled={isSaving}
                    onClick={handleDeliver}
                  >
                    Deliver to{" "}
                    {assessmentDeliveryData?.assessmentDelivery?.target?.name}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Layout.Main>
        <Layout.Sidebar<AssessmentSidebarTab>
          sidebarExpandedUiPreferenceKey="assessmentDeliverySidebarExpanded"
          className="bg-white"
          onChangeTab={(tab) => {
            if (tab.value) {
              setActiveTab(tab.value);
            }
          }}
          tabs={compact([
            {
              title: AssessmentSidebarTab.artifacts,
              value: AssessmentSidebarTab.artifacts,
              active: activeTab === AssessmentSidebarTab.artifacts,
            },
            organization.featureFlags.workHistory && {
              title: AssessmentSidebarTab.workHistory,
              value: AssessmentSidebarTab.workHistory,
              active: activeTab === AssessmentSidebarTab.workHistory,
            },
            {
              title: AssessmentSidebarTab.askAI,
              value: AssessmentSidebarTab.askAI,
              active: activeTab === AssessmentSidebarTab.askAI,
            },
          ])}
          fullHeight={activeTab === AssessmentSidebarTab.askAI}
        >
          {activeTab === AssessmentSidebarTab.askAI ? (
            <AskAI
              chatSessionId={chatSessionId}
              onChangeChatSessionId={setChatSessionId}
            />
          ) : activeTab === AssessmentSidebarTab.workHistory ? (
            <WorkHistorySidebar
              userId={assertNonNull(
                assessmentDeliveryData?.assessmentDelivery?.target?.id
              )}
              startDate={moment().subtract(7, "days").format("YYYY-MM-DD")}
              endDate={moment().format("YYYY-MM-DD")}
            />
          ) : activeTab === AssessmentSidebarTab.artifacts ? (
            <>
              {relatedMeetings.length === 0 &&
                !organization.featureFlags.goals &&
                !organization.featureFlags.recognitions &&
                !organization.featureFlags.feedbacks && (
                  <Layout.SidebarSection>
                    <Layout.SidebarSubSectionEmpty>
                      No related data.
                    </Layout.SidebarSubSectionEmpty>
                  </Layout.SidebarSection>
                )}
              <RelatedRequirements relatedMeetings={relatedMeetings} />

              {organization.featureFlags.goals && target && (
                <AssessmentGoalSidebar
                  selectedUser={target}
                  goalDueBetweenDates={complianceProgramPeriod}
                />
              )}

              {organization.featureFlags.recognitions && target && (
                <Recognitions
                  selectedUser={target}
                  createdBetweenDates={complianceProgramPeriod}
                />
              )}
              {organization.featureFlags.feedbacks && target && (
                <Feedbacks
                  selectedUser={target}
                  createdBetweenDates={complianceProgramPeriod}
                />
              )}
            </>
          ) : null}
        </Layout.Sidebar>
      </Layout.Container>
    </Layout>
  );
};

export default AssessmentDelivery;
