import { gql } from "@apollo/client";

import TopicNodeNewPageFragment from "@apps/meeting-new/graphql/topic-node-fragment";

export default gql`
  ${TopicNodeNewPageFragment}
  mutation addTopicToMeetingMutation(
    $topicId: Int
    $meetingId: Int
    $includesIndividualNotes: Boolean
    $title: String
    $discussionNotes: JSONString
  ) {
    createOrUpdateTopic(
      topicId: $topicId
      includesIndividualNotes: $includesIndividualNotes
      meetingId: $meetingId
      title: $title
      discussionNotes: $discussionNotes
    ) {
      topic {
        ...TopicNodeNewPageFragment
      }
      meeting {
        id
        topics {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;
