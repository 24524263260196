import { Route, Switch, useRouteMatch } from "react-router-dom";

import Button, { buttonTheme } from "@components/button/button";
import AppLink from "@components/link/link";
import useDocumentTitle from "@components/use-document-title/use-document-title";

import KPIEdit from "./components/edit";
import KPIGroupForm from "./components/group-form";
import KPIList from "./components/list";
import KPIView from "./components/view";

const KPIs = () => {
  useDocumentTitle("KPIs");
  const { path } = useRouteMatch();

  // RENDER
  return (
    <div aria-label="KPIs" className="flex flex-col flex-1">
      <div className="flex flex-wrap items-center justify-between h-14 px-4 sm:px-6 border-b bg-gray-50">
        <AppLink
          className="mb-2 sm:mb-0 text-xl font-medium mr-8 flex items-center"
          to="/kpis"
        >
          KPIs
        </AppLink>

        <div className="flex items-center gap-4">
          <Button
            theme={buttonTheme.default}
            to="/kpis/group/new"
            text="Add KPI Group"
          />
          <Button theme={buttonTheme.primary} to="/kpis/new" text="Add KPI" />
        </div>
      </div>
      <div className="p-6">
        <Switch>
          <Route exact path={[`${path}`]}>
            <KPIList />
          </Route>
          <Route
            exact
            path={[`${path}/group/new`, `${path}/group/:kpiGroupId(\\d+)`]}
          >
            <KPIGroupForm />
          </Route>
          <Route exact path={[`${path}/new`, `${path}/:kpiId(\\d+)/edit`]}>
            <KPIEdit />
          </Route>
          <Route path={`${path}/:kpiId(\\d+)`}>
            <KPIView />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default KPIs;
