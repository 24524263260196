import { ReactNode } from "react";
import { RangeAssessmentQuestionNode } from "types/graphql-schema";

import { isEmptyValue } from "@components/wysiwyg/helpers";
import TextareaWysiwyg from "@components/wysiwyg/textarea-wysiwyg";

import AssessmentQuestionRangeOptions from "../assessment-question-range-options";
import { AssessmentAnswer } from "./types";

const IndividualGoalAssessmentQuestionContent = ({
  answer,
  question,
  isQuestionWeightingEnabled,
  weightedScore,
  disabled,
  onChangeAnswer,
}: {
  answer?: { integerAnswer?: number | null; comment?: string | null } | null;
  isQuestionWeightingEnabled: boolean;
  weightedScore: ReactNode | null;
  question: Pick<
    RangeAssessmentQuestionNode,
    "labelDescriptions" | "labels" | "startValue" | "endValue"
  >;
  disabled?: boolean;
  onChangeAnswer?: (newAnswer: Partial<AssessmentAnswer>) => void;
}) => {
  const comment = answer?.comment ?? "{}";
  return (
    <>
      <AssessmentQuestionRangeOptions
        answer={answer?.integerAnswer}
        disabled={disabled}
        labels={question.labels}
        labelDescriptions={question.labelDescriptions}
        startValue={question.startValue}
        endValue={question.endValue}
        showIntegerValues={isQuestionWeightingEnabled}
        onClickAnswer={(answer) => {
          if (!onChangeAnswer) {
            return;
          }
          onChangeAnswer({
            integerAnswer: answer,
          });
        }}
      />
      {answer && (!disabled || !isEmptyValue(answer.comment)) && (
        <div className="mt-2 flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Comment
            </div>
            {isQuestionWeightingEnabled && (
              <div className="text-sm text-gray-500">{weightedScore}</div>
            )}
          </div>
          <TextareaWysiwyg
            editable={!disabled}
            className="mt-1 bg-white"
            value={comment}
            onChangeValue={(comment) => {
              if (!onChangeAnswer) {
                return;
              }
              onChangeAnswer({
                comment,
              });
            }}
          />
        </div>
      )}
      {answer && isEmptyValue(answer.comment) && isQuestionWeightingEnabled && (
        <div className="mt-2 flex justify-end">
          <div className="text-sm text-gray-500">{weightedScore}</div>
        </div>
      )}
    </>
  );
};

export default IndividualGoalAssessmentQuestionContent;
