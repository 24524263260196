import _pluralize from "pluralize";

export const pluralize = (word: string, count: number) => {
  if (
    ["feedback", "alignment", "coaching"].includes(String(word).toLowerCase())
  ) {
    return _pluralize(word, 1);
  }
  if (["need"].includes(String(word).toLowerCase())) {
    return `${word}${count === 1 ? "s" : ""}`;
  }
  return _pluralize(word, count);
};

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const titleCase = (str: string) =>
  str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

export const lowerCase = (str: string, accronyms = false) => {
  if (accronyms) {
    return str.toLowerCase();
  }
  return str
    .split(" ")
    .map((word) => {
      if (/[A-Z]/.test(str.slice(1))) {
        return word;
      }
      return word.toLowerCase();
    })
    .join(" ");
};

export const removeBreaklines = (str = "", replaceBy = " ") => {
  return str.replace(/(\r\n|\n|\r)/gm, replaceBy);
};

export const joinStringWithCommaAnd = (arr: string[]) => {
  if (arr.length === 0) return "";
  if (arr.length === 1) return arr[0];
  const firsts = arr.slice(0, arr.length - 1);
  const last = arr[arr.length - 1];
  return firsts.join(", ") + " and " + last;
};
