import { useQuery } from "@apollo/client";
import { StarIcon } from "@heroicons/react/outline";
import {
  GetFavouriteArtifactsQueryQuery,
  GetFavouriteArtifactsQueryQueryVariables,
} from "types/graphql-schema";

import Artifact from "@apps/artifact/artifact";
import Loading from "@components/loading/loading";
import AppPopover from "@components/popover/app-popover";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

import getFavouriteArtifactsQuery from "./graphql/get-favourite-artifacts-query";

const FavouriteArtifactsPopover = () => {
  const { data, loading } = useQuery<
    GetFavouriteArtifactsQueryQuery,
    GetFavouriteArtifactsQueryQueryVariables
  >(getFavouriteArtifactsQuery, {
    onError: onNotificationErrorHandler(),
  });
  const favouriteArtifacts = data?.me?.favouriteArtifacts
    ? assertEdgesNonNull(data?.me?.favouriteArtifacts)
    : [];

  // RENDER
  return (
    <AppPopover
      options={{
        className: classNames(
          AppPopover.className,
          "min-h-48 max-h-96 overflow-y-scroll"
        ),
      }}
      content={
        <>
          {!data && loading ? (
            <div className="p-3">
              <Loading>Loading favorites.</Loading>
            </div>
          ) : (
            <div
              aria-label="Favourite artifacts container"
              className="flex flex-col gap-2 tracking-tight text-sm"
            >
              {favouriteArtifacts.length > 0 ? (
                <div className="divide-y divide-gray-100">
                  {favouriteArtifacts.map((artifact) => (
                    <div key={artifact.id} className="px-3 py-2">
                      <Artifact artifact={artifact} hideActionDropdown />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-gray-500 text-sm p-3">No favorites.</div>
              )}
            </div>
          )}
        </>
      }
    >
      <AppPopover.Button className="flex items-center text-gray-500 hover:text-gray-800 p-0.5 focus:outline-none focus:ring-0 rounded bg-black/5 hover:bg-black/10">
        <StarIcon className="h-4 w-4" />
      </AppPopover.Button>
    </AppPopover>
  );
};

export default FavouriteArtifactsPopover;
