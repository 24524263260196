import { useQuery } from "@apollo/client";
import {
  ArrowSmRightIcon,
  ChevronDownIcon,
  ExternalLinkIcon,
} from "@heroicons/react/outline";
import { compact, uniqBy } from "lodash";
import { useCallback, useMemo } from "react";
import {
  AssessmentType,
  GetActiveReviewsActionsQuery,
  GetActiveReviewsActionsQueryVariables,
} from "types/graphql-schema";

import { ProgramActionToDoStateFilter } from "@apps/programs/components/compliance-program";
import ComplianceProgramActionItem from "@apps/programs/components/compliance-program-action-item";
import ComplianceProgramActionItemForAssessment from "@apps/programs/components/compliance-program-action-item-for-assessment";
import ComplianceProgramActionItemForNomination from "@apps/programs/components/compliance-program-action-item-for-nomination";
import {
  ComplianceProgramActionAssessmentItemProps,
  ComplianceProgramActionBasicItemProps,
  getAssessmentItemsForTemplate,
  mapAssessmentDeliveryToItem,
  mapAssessmentForNominationToItem,
} from "@apps/programs/helpers";
import useLabel from "@apps/use-label/use-label";
import { currentOrganizationVar, currentUserVar } from "@cache/cache";
import Button from "@components/button/button";
import Layout from "@components/layout/layout";
import AppLink from "@components/link/link";
import AppPopover from "@components/popover/app-popover";
import {
  ToggleButtonGroup,
  ToggleButtonGroupTheme,
} from "@components/toggle-button-group/toggle-button-group";
import Tooltip from "@components/tooltip/tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assessmentTypeLabels } from "@helpers/constants";
import { classNames } from "@helpers/css";
import {
  assertEdgesNonNull,
  assertEdgesNonNullWithStringId,
} from "@helpers/helpers";

import getActiveReviewsActionsQuery from "../graphql/get-active-reviews-actions-query";

const ComplianceProgramItemProgramLink = ({
  program,
}: {
  program?: null | {
    id: number;
    title: string;
  };
}) =>
  program ? (
    <AppLink
      to={`/programs/${program.id}/progress`}
      className="text-xs text-gray-500 hover:underline"
    >
      <Tooltip text="View program progress">
        <span className="flex items-center gap-1">
          {program.title} <ExternalLinkIcon className="w-3 h-3" />
        </span>
      </Tooltip>
    </AppLink>
  ) : null;

const AssessmentsHomepageActiveReviews = ({
  activeReviewFilter,
  onChangeActiveReviewFilter,
}: {
  activeReviewFilter: ProgramActionToDoStateFilter;
  onChangeActiveReviewFilter: (filter: ProgramActionToDoStateFilter) => void;
}) => {
  const label = useLabel();
  const currentOrganization = currentOrganizationVar();
  const currentUser = currentUserVar();
  const reportIds = useMemo(
    () =>
      assertEdgesNonNull(currentUser.directReports).map((report) => report.id),
    [currentUser.directReports]
  );
  const { data, loading } = useQuery<
    GetActiveReviewsActionsQuery,
    GetActiveReviewsActionsQueryVariables
  >(getActiveReviewsActionsQuery, {
    variables: {
      organizationId: currentOrganization.id,
      currentUserId: currentUser.id,
      nominationTargetId: null,
      deliveryTargetIds: [...reportIds, currentUser.id],
    },
    onError: onNotificationErrorHandler(),
  });

  const filterToDoState = useCallback(
    (item: ComplianceProgramActionBasicItemProps) =>
      activeReviewFilter === ProgramActionToDoStateFilter.All ||
      (activeReviewFilter === ProgramActionToDoStateFilter.ToDo &&
        !item.statusCompleted) ||
      (activeReviewFilter === ProgramActionToDoStateFilter.Completed &&
        !!item.statusCompleted),
    [activeReviewFilter]
  );
  const filterForCurrentUser = useCallback(
    (item: ComplianceProgramActionBasicItemProps) =>
      item.user?.id === currentUser.id && filterToDoState(item),
    [currentUser.id, filterToDoState]
  );
  const filterForDirectReports = useCallback(
    (item: ComplianceProgramActionBasicItemProps) =>
      reportIds.length > 0 &&
      item.user?.id &&
      reportIds.includes(item.user.id) &&
      filterToDoState(item),
    [reportIds, filterToDoState]
  );
  const filterForOtherUsers = useCallback(
    (item: ComplianceProgramActionBasicItemProps) =>
      item.user?.id &&
      !reportIds.includes(item.user.id) &&
      item.user.id !== currentUser.id &&
      filterToDoState(item),
    [currentUser.id, reportIds, filterToDoState]
  );

  const unmetPerformanceAssessmentCompliancePrograms =
    data?.unmetPerformanceAssessmentCompliancePrograms
      ? assertEdgesNonNull(data.unmetPerformanceAssessmentCompliancePrograms)
      : [];
  const unmetManagerEffectivenessCompliancePrograms =
    data?.unmetManagerEffectivenessCompliancePrograms
      ? assertEdgesNonNull(data.unmetManagerEffectivenessCompliancePrograms)
      : [];
  const unmetPeerAssessmentCompliancePrograms =
    data?.unmetPeerAssessmentCompliancePrograms
      ? assertEdgesNonNull(data.unmetPeerAssessmentCompliancePrograms)
      : [];
  const performanceAssessmentItems =
    unmetPerformanceAssessmentCompliancePrograms.reduce((memo, program) => {
      if (!program.performanceAssessmentTemplate) {
        return memo;
      }
      const items = getAssessmentItemsForTemplate(
        program,
        program.performanceAssessmentTemplate,
        currentUser.id
      );
      return memo.concat(items);
    }, [] as ComplianceProgramActionAssessmentItemProps[]);
  const myPerformanceAssessmentItems =
    performanceAssessmentItems.filter(filterForCurrentUser);
  const directReportsPerformanceAssessmentItems =
    performanceAssessmentItems.filter(filterForDirectReports);
  const otherPerformanceAssessmentItems =
    performanceAssessmentItems.filter(filterForOtherUsers);

  const managerAssessmentItems =
    unmetManagerEffectivenessCompliancePrograms.reduce((memo, program) => {
      if (!program.managerAssessmentTemplate) {
        return memo;
      }
      const items = getAssessmentItemsForTemplate(
        program,
        program.managerAssessmentTemplate,
        currentUser.id
      );
      return memo.concat(items);
    }, [] as ComplianceProgramActionAssessmentItemProps[]);
  const myManagerAssessmentItems =
    managerAssessmentItems.filter(filterForCurrentUser);
  const directReportsManagerAssessmentItems = managerAssessmentItems.filter(
    filterForDirectReports
  );
  const otherManagerAssessmentItems =
    managerAssessmentItems.filter(filterForOtherUsers);
  const peerAssessmentItems = unmetPeerAssessmentCompliancePrograms.reduce(
    (memo, program) => {
      if (!program.peerAssessmentTemplate) {
        return memo;
      }
      const items = getAssessmentItemsForTemplate(
        program,
        program.peerAssessmentTemplate,
        currentUser.id
      );
      return memo.concat(items);
    },
    [] as ComplianceProgramActionAssessmentItemProps[]
  );
  const myPeerAssessmentItems =
    peerAssessmentItems.filter(filterForCurrentUser);
  const directReportsPeerAssessmentItems = peerAssessmentItems.filter(
    filterForDirectReports
  );
  const otherPeerAssessmentItems =
    peerAssessmentItems.filter(filterForOtherUsers);

  const assessmentDeliveries = (
    data?.assessmentDeliveries
      ? assertEdgesNonNull(data.assessmentDeliveries)
      : []
  ).map((delivery) => mapAssessmentDeliveryToItem(currentUser.id, delivery));
  const myDeliveries = assessmentDeliveries.filter(filterForCurrentUser);
  const directReportsDeliveries = assessmentDeliveries.filter(
    filterForDirectReports
  );
  const otherDeliveries = assessmentDeliveries.filter(filterForOtherUsers);

  const assessmentsOpenForNominations = (
    data?.assessmentsOpenForNominations
      ? assertEdgesNonNullWithStringId(data.assessmentsOpenForNominations)
      : []
  ).map((assessmentsOpenForNomination) =>
    mapAssessmentForNominationToItem(
      currentUser.id,
      assessmentsOpenForNomination
    )
  );
  const myNominations =
    assessmentsOpenForNominations.filter(filterForCurrentUser);
  const directReportsNominations = assessmentsOpenForNominations.filter(
    filterForDirectReports
  );
  const otherNominations =
    assessmentsOpenForNominations.filter(filterForOtherUsers);

  const myItemsCount =
    myNominations.length +
    myDeliveries.length +
    myPerformanceAssessmentItems.length +
    myManagerAssessmentItems.length +
    myPeerAssessmentItems.length;
  const directReportsItemsCount =
    directReportsNominations.length +
    directReportsDeliveries.length +
    directReportsManagerAssessmentItems.length +
    directReportsPerformanceAssessmentItems.length +
    directReportsPeerAssessmentItems.length;
  const otherItemsCount =
    otherPerformanceAssessmentItems.length +
    otherManagerAssessmentItems.length +
    otherPeerAssessmentItems.length +
    otherDeliveries.length +
    otherNominations.length;

  const programs: { id: number; title: string }[] = uniqBy(
    compact([
      ...unmetPerformanceAssessmentCompliancePrograms,
      ...unmetManagerEffectivenessCompliancePrograms,
      ...unmetPeerAssessmentCompliancePrograms,
      ...assessmentsOpenForNominations.map(
        ({ complianceProgram }) => complianceProgram
      ),
      ...assessmentDeliveries.map(({ complianceProgram }) => complianceProgram),
    ]),
    "id"
  );
  return (
    <>
      <div className="p-8 pb-4 flex">
        <div>
          <ToggleButtonGroup<ProgramActionToDoStateFilter>
            theme={ToggleButtonGroupTheme.buttons}
            buttons={[
              {
                title: ProgramActionToDoStateFilter.All,
                value: ProgramActionToDoStateFilter.All,
                active: activeReviewFilter === ProgramActionToDoStateFilter.All,
              },
              {
                title: ProgramActionToDoStateFilter.ToDo,
                value: ProgramActionToDoStateFilter.ToDo,
                active:
                  activeReviewFilter === ProgramActionToDoStateFilter.ToDo,
              },
              {
                title: ProgramActionToDoStateFilter.Completed,
                value: ProgramActionToDoStateFilter.Completed,
                active:
                  activeReviewFilter === ProgramActionToDoStateFilter.Completed,
              },
            ]}
            onClick={(button) =>
              onChangeActiveReviewFilter(
                button?.value || ProgramActionToDoStateFilter.All
              )
            }
          />
        </div>
      </div>

      <Layout.MainSection
        title={`My ${label("review", {
          capitalize: true,
          pluralize: true,
        })}`}
      >
        <Layout.MainSubSection
          title=""
          loading={loading}
          emptyPlaceholder={
            myItemsCount === 0
              ? `There are no active ${label("review", {
                  pluralize: true,
                })} for you`
              : null
          }
        >
          <Layout.MainSubSectionList>
            {myNominations.map((item) => (
              <ComplianceProgramActionItemForNomination
                key={item.key}
                item={item}
              />
            ))}
            {myDeliveries.map(({ key, ...item }) => (
              <ComplianceProgramActionItem key={key} {...item}>
                <ComplianceProgramItemProgramLink
                  program={item.complianceProgram}
                />
              </ComplianceProgramActionItem>
            ))}
            {myPerformanceAssessmentItems.map((item) => (
              <ComplianceProgramActionItemForAssessment
                key={item.key}
                item={item}
              >
                <ComplianceProgramItemProgramLink
                  program={item.complianceProgram}
                />
              </ComplianceProgramActionItemForAssessment>
            ))}
            {myManagerAssessmentItems.map((item) => (
              <ComplianceProgramActionItemForAssessment
                key={item.key}
                item={item}
              >
                <ComplianceProgramItemProgramLink
                  program={item.complianceProgram}
                />
              </ComplianceProgramActionItemForAssessment>
            ))}
            {myPeerAssessmentItems.map((item) => (
              <ComplianceProgramActionItemForAssessment
                key={item.key}
                item={item}
              >
                <ComplianceProgramItemProgramLink
                  program={item.complianceProgram}
                />
              </ComplianceProgramActionItemForAssessment>
            ))}
          </Layout.MainSubSectionList>
        </Layout.MainSubSection>
      </Layout.MainSection>

      <Layout.MainSection
        title={`Actions for Direct Reports`}
        loading={loading}
        rightSide={
          <AppPopover
            options={{
              className: classNames(
                AppPopover.className,
                "w-96 overflow-y-auto max-h-64"
              ),
            }}
            placement="bottom-end"
            content={
              <div className="p-4">
                <div className="text-sm font-medium mb-2">Programs</div>
                <div className="flex flex-col divide-y border-t border-b">
                  {programs.map((program) => (
                    <AppLink
                      key={program.id}
                      to={`/programs/${program.id}/progress`}
                      className="py-2 px-1 text-sm text-gray-500 hover:bg-gray-50 flex items-center justify-between"
                    >
                      {program.title}
                      <ArrowSmRightIcon className="w-4 h-4" />
                    </AppLink>
                  ))}
                </div>
              </div>
            }
          >
            <AppPopover.Button
              as={Button}
              className="flex items-center gap-1"
              disabled={programs.length === 0}
            >
              View program progress{" "}
              <ChevronDownIcon className="w-3 h-3 text-gray-500" />
            </AppPopover.Button>
          </AppPopover>
        }
      >
        {directReportsItemsCount === 0 && (
          <Layout.MainSubSectionEmpty>
            There are no active{""}
            {label("review", {
              pluralize: true,
            })}{" "}
            for your direct reports.
          </Layout.MainSubSectionEmpty>
        )}

        {directReportsPerformanceAssessmentItems.length > 0 && (
          <Layout.MainSubSection
            title={assessmentTypeLabels[AssessmentType.Performance]}
            collapsible
            defaultIsExpanded
          >
            <Layout.MainSubSectionList>
              {directReportsPerformanceAssessmentItems.map((item) => (
                <ComplianceProgramActionItemForAssessment
                  key={item.key}
                  item={item}
                >
                  <ComplianceProgramItemProgramLink
                    program={item.complianceProgram}
                  />
                </ComplianceProgramActionItemForAssessment>
              ))}
            </Layout.MainSubSectionList>
          </Layout.MainSubSection>
        )}
        {directReportsManagerAssessmentItems.length > 0 && (
          <Layout.MainSubSection
            title={assessmentTypeLabels[AssessmentType.Manager]}
            collapsible
            defaultIsExpanded
          >
            <Layout.MainSubSectionList>
              {directReportsManagerAssessmentItems.map((item) => (
                <ComplianceProgramActionItemForAssessment
                  key={item.key}
                  item={item}
                >
                  <ComplianceProgramItemProgramLink
                    program={item.complianceProgram}
                  />
                </ComplianceProgramActionItemForAssessment>
              ))}
            </Layout.MainSubSectionList>
          </Layout.MainSubSection>
        )}
        {directReportsPeerAssessmentItems.length > 0 && (
          <Layout.MainSubSection
            title={assessmentTypeLabels[AssessmentType.Peer]}
            collapsible
            defaultIsExpanded
          >
            <Layout.MainSubSectionList>
              {directReportsPeerAssessmentItems.map((item) => (
                <ComplianceProgramActionItemForAssessment
                  key={item.key}
                  item={item}
                >
                  <ComplianceProgramItemProgramLink
                    program={item.complianceProgram}
                  />
                </ComplianceProgramActionItemForAssessment>
              ))}
            </Layout.MainSubSectionList>
          </Layout.MainSubSection>
        )}
        {directReportsDeliveries.length > 0 && (
          <Layout.MainSubSection
            title={`Peer ${label("review", {
              capitalize: true,
              pluralize: true,
            })} to Deliver to Direct Reports`}
            collapsible
            defaultIsExpanded
          >
            <Layout.MainSubSectionList>
              {directReportsDeliveries.map(({ key, ...item }) => (
                <ComplianceProgramActionItem key={key} {...item}>
                  <ComplianceProgramItemProgramLink
                    program={item.complianceProgram}
                  />
                </ComplianceProgramActionItem>
              ))}
            </Layout.MainSubSectionList>
          </Layout.MainSubSection>
        )}
        {directReportsNominations.length > 0 && (
          <Layout.MainSubSection
            title="Nominations"
            collapsible
            defaultIsExpanded
          >
            <Layout.MainSubSectionList>
              {directReportsNominations.map((item) => (
                <ComplianceProgramActionItemForNomination
                  key={item.key}
                  item={item}
                >
                  <ComplianceProgramItemProgramLink
                    program={item.complianceProgram}
                  />
                </ComplianceProgramActionItemForNomination>
              ))}
            </Layout.MainSubSectionList>
          </Layout.MainSubSection>
        )}
      </Layout.MainSection>

      <Layout.MainSection title={`Actions for Others`} loading={loading}>
        {otherItemsCount === 0 && (
          <Layout.MainSubSectionEmpty>
            There are no active{" "}
            {label("review", {
              pluralize: true,
            })}{" "}
            for your peers or managers.
          </Layout.MainSubSectionEmpty>
        )}

        {otherPerformanceAssessmentItems.length > 0 && (
          <Layout.MainSubSection
            title={assessmentTypeLabels[AssessmentType.Performance]}
            collapsible
            defaultIsExpanded
          >
            <Layout.MainSubSectionList>
              {otherPerformanceAssessmentItems.map((item) => (
                <ComplianceProgramActionItemForAssessment
                  key={item.key}
                  item={item}
                >
                  <ComplianceProgramItemProgramLink
                    program={item.complianceProgram}
                  />
                </ComplianceProgramActionItemForAssessment>
              ))}
            </Layout.MainSubSectionList>
          </Layout.MainSubSection>
        )}
        {otherManagerAssessmentItems.length > 0 && (
          <Layout.MainSubSection
            title={assessmentTypeLabels[AssessmentType.Manager]}
            collapsible
            defaultIsExpanded
          >
            <Layout.MainSubSectionList>
              {otherManagerAssessmentItems.map((item) => (
                <ComplianceProgramActionItemForAssessment
                  key={item.key}
                  item={item}
                >
                  <ComplianceProgramItemProgramLink
                    program={item.complianceProgram}
                  />
                </ComplianceProgramActionItemForAssessment>
              ))}
            </Layout.MainSubSectionList>
          </Layout.MainSubSection>
        )}
        {otherPeerAssessmentItems.length > 0 && (
          <Layout.MainSubSection
            title={assessmentTypeLabels[AssessmentType.Peer]}
            collapsible
            defaultIsExpanded
          >
            <Layout.MainSubSectionList>
              {otherPeerAssessmentItems.map((item) => (
                <ComplianceProgramActionItemForAssessment
                  key={item.key}
                  item={item}
                >
                  <ComplianceProgramItemProgramLink
                    program={item.complianceProgram}
                  />
                </ComplianceProgramActionItemForAssessment>
              ))}
            </Layout.MainSubSectionList>
          </Layout.MainSubSection>
        )}
        {otherDeliveries.length > 0 && (
          <Layout.MainSubSection
            title={`Peer ${label("review", {
              capitalize: true,
              pluralize: true,
            })} to Deliver to Others`}
            collapsible
            defaultIsExpanded
          >
            <Layout.MainSubSectionList>
              {otherDeliveries.map(({ key, ...item }) => (
                <ComplianceProgramActionItem key={key} {...item}>
                  <ComplianceProgramItemProgramLink
                    program={item.complianceProgram}
                  />
                </ComplianceProgramActionItem>
              ))}
            </Layout.MainSubSectionList>
          </Layout.MainSubSection>
        )}
        {otherNominations.length > 0 && (
          <Layout.MainSubSection
            title="Nominations"
            collapsible
            defaultIsExpanded
          >
            <Layout.MainSubSectionList>
              {otherNominations.map((item) => (
                <ComplianceProgramActionItemForNomination
                  key={item.key}
                  item={item}
                >
                  <ComplianceProgramItemProgramLink
                    program={item.complianceProgram}
                  />
                </ComplianceProgramActionItemForNomination>
              ))}
            </Layout.MainSubSectionList>
          </Layout.MainSubSection>
        )}
      </Layout.MainSection>
    </>
  );
};

export default AssessmentsHomepageActiveReviews;
