import { useQuery } from "@apollo/client";
import { compact, flatMap, range } from "lodash";
import { useMemo } from "react";
import {
  AssessmentQuestionType,
  AssessmentType,
  GetComplianceProgramAssessmentAnswersQuery,
  GetComplianceProgramAssessmentAnswersQueryVariables,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import Loading from "@components/loading/loading";
import Table, {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableFooter,
  TableHeadCell,
  TableHeadRow,
} from "@components/table/table";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import getComplianceProgramAssessmentAnswersQuery from "../graphql/get-compliance-program-assessment-answers-query";
import { ProgramTab, ProgramTabType } from "./compliance-program-admin";
import MissingAssessmentTable from "./missing-assessment-table";

const ComplianceProgramAssessmentView = ({
  tab,
  complianceProgramId,
}: {
  tab: ProgramTab;
  complianceProgramId: number;
}) => {
  const label = useLabel();
  const { data, loading, fetchMore } = useQuery<
    GetComplianceProgramAssessmentAnswersQuery,
    GetComplianceProgramAssessmentAnswersQueryVariables
  >(getComplianceProgramAssessmentAnswersQuery, {
    variables: {
      complianceProgramId: complianceProgramId,
      includesUserMissingAssessments: true,
      assessmentType: assertNonNull(tab.assessmentType),
      selfAssessment: assertNonNull(tab.selfAssessment),
    },
    onError: onNotificationErrorHandler(),
  });
  const complianceProgram = data?.complianceProgram;

  const performanceAssessmentTemplate = useMemo(
    () =>
      complianceProgram?.performanceAssessmentTemplate
        ? complianceProgram.performanceAssessmentTemplate
        : null,
    [complianceProgram]
  );
  const managerAssessmentTemplate = useMemo(
    () =>
      complianceProgram?.managerAssessmentTemplate
        ? complianceProgram.managerAssessmentTemplate
        : null,
    [complianceProgram]
  );
  const peerAssessmentTemplate = useMemo(
    () =>
      complianceProgram?.peerAssessmentTemplate
        ? complianceProgram.peerAssessmentTemplate
        : null,
    [complianceProgram]
  );
  const allAssessmentTemplates = useMemo(
    () =>
      compact([
        performanceAssessmentTemplate,
        managerAssessmentTemplate,
        peerAssessmentTemplate,
      ]),
    [
      managerAssessmentTemplate,
      peerAssessmentTemplate,
      performanceAssessmentTemplate,
    ]
  );
  const assessmentTemplate = allAssessmentTemplates.find(
    (group) => group.id === tab.assessmentGroup
  );

  const handleLoadMore = () => {
    if (data?.complianceProgram?.assessments.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          merge: true,
          after: data.complianceProgram.assessments.pageInfo.endCursor,
          includesUserMissingAssessments: false,
        },
      });
    }
  };

  if (!assessmentTemplate) return null;

  const allQuestions = flatMap(
    assertEdgesNonNull(assessmentTemplate.questionSets),
    (questionSet) => {
      const sections = assertEdgesNonNull(questionSet.sections);
      return flatMap(sections, (section) => {
        return assertEdgesNonNull(section.questions);
      });
    }
  );

  const usersMissingAssessment = complianceProgram?.usersMissingAssessment
    ? complianceProgram.usersMissingAssessment.edges
        .map((edge) => assertNonNull(edge?.node))
        .filter(
          (missingAssessment) =>
            missingAssessment.assessmentTemplate.id === assessmentTemplate.id
        )
        .filter(({ responder, target }) => {
          return (
            (tab.type === ProgramTabType.ASSESSMENTS &&
              responder.id !== target.id) ||
            (tab.type === ProgramTabType.SELF_ASSESSMENTS &&
              responder.id === target.id)
          );
        })
    : [];

  return !data && loading ? (
    <Loading>Loading {label("review", { pluralize: true })}</Loading>
  ) : complianceProgram ? (
    <>
      <div className="mt-8">
        <div className="flex items-center mb-2">
          <div className="font-bold mr-4">
            {label("review", { capitalize: true, pluralize: true })}
          </div>
          {assessmentTemplate.assessmentType !== AssessmentType.Peer && (
            <Button
              theme={buttonTheme.lightBlue}
              to={`/reporting/${
                assessmentTemplate.assessmentType === AssessmentType.Performance
                  ? "performance-assessments"
                  : "manager-effectiveness"
              }?programId=${complianceProgramId}`}
            >
              View detailed report
            </Button>
          )}
        </div>
        <TableContainer scroll>
          <Table>
            <TableHeadRow>
              <TableHeadCell>Name</TableHeadCell>
              {tab.type === ProgramTabType.ASSESSMENTS && (
                <TableHeadCell>
                  {assessmentTemplate.assessmentType ===
                  AssessmentType.Performance
                    ? "Manager"
                    : assessmentTemplate.assessmentType === AssessmentType.Peer
                    ? "Peer"
                    : "Report"}
                </TableHeadCell>
              )}
              {allQuestions.map((node) => {
                const q = node.question;
                if (q.questionType === AssessmentQuestionType.Text) {
                  return null;
                }
                return <TableHeadCell key={q.id}>{q.title}</TableHeadCell>;
              })}
            </TableHeadRow>
            <TableBody>
              {complianceProgram.assessments.edges
                .filter(
                  (edge) => edge?.node?.template.id === assessmentTemplate.id
                )
                .map((assessment) => {
                  const response = assertNonNull(assessment?.node);
                  const responder = assertNonNull(response.responder);
                  const target = assertNonNull(response.target);
                  if (
                    (tab.type === ProgramTabType.ASSESSMENTS &&
                      responder.id === target.id) ||
                    (tab.type === ProgramTabType.SELF_ASSESSMENTS &&
                      responder.id !== target.id)
                  ) {
                    return null;
                  }
                  return (
                    <TableBodyRow key={response.id}>
                      <TableBodyCell>
                        <div className="flex items-center">
                          <Avatar className="mr-2" size="8" user={target} />{" "}
                          {target.name}
                        </div>
                      </TableBodyCell>
                      {tab.type === ProgramTabType.ASSESSMENTS && (
                        <TableBodyCell>
                          <div className="flex items-center">
                            <Avatar
                              className="mr-2"
                              size="8"
                              user={responder}
                            />{" "}
                            {responder.name}
                          </div>
                        </TableBodyCell>
                      )}

                      {allQuestions.map((node) => {
                        const q = node.question;
                        if (q.__typename === "TextAssessmentQuestionNode") {
                          return null;
                        }
                        if (q.__typename === "RangeAssessmentQuestionNode") {
                          const qLabels = range(
                            q.startValue,
                            q.endValue + 1
                          ).reduce(
                            (labelObj, value, index) => ({
                              ...labelObj,
                              [value]: q.labels[index] || value,
                            }),
                            {} as { [key: number]: string | number }
                          );
                          const answer = response.answers.edges.find(
                            (edge) =>
                              assertNonNull(edge?.node).question.id === q.id
                          );
                          if (
                            !answer ||
                            answer.node?.__typename !==
                              "RangeAssessmentAnswerNode"
                          ) {
                            return (
                              <TableBodyCell key={q.id}>N/A</TableBodyCell>
                            );
                          }
                          const integerAnswer = assertNonNull(
                            answer.node?.integerAnswer
                          );
                          return (
                            <TableBodyCell key={q.id}>
                              {qLabels[integerAnswer]}
                            </TableBodyCell>
                          );
                        }

                        if (
                          q.__typename === "MultiChoiceAssessmentQuestionNode"
                        ) {
                          const answer = response.answers.edges.find(
                            (edge) =>
                              assertNonNull(edge?.node).question.id === q.id
                          );
                          if (
                            !answer ||
                            answer.node?.__typename !==
                              "MultiChoiceAssessmentAnswerNode"
                          ) {
                            return (
                              <TableBodyCell key={q.id}>N/A</TableBodyCell>
                            );
                          }
                          return (
                            <TableBodyCell key={q.id}>
                              {answer.node.choices
                                .map(
                                  (choice) =>
                                    choice !== null && q.options[choice]
                                )
                                .join(", ")}
                            </TableBodyCell>
                          );
                        }

                        return null;
                      })}
                    </TableBodyRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TableFooter>
          {data?.complianceProgram?.assessments.pageInfo.hasNextPage && (
            <div className="flex justify-center">
              {loading ? (
                <Loading mini size="5" />
              ) : (
                <button
                  onClick={handleLoadMore}
                  className="text-gray-500 hover:underline"
                >
                  View more
                </button>
              )}
            </div>
          )}
        </TableFooter>
      </div>
      {usersMissingAssessment.length > 0 && (
        <MissingAssessmentTable
          assessmentType={assessmentTemplate.assessmentType}
          complianceProgramId={complianceProgram.id}
          usersMissingAssessment={usersMissingAssessment}
          tab={tab}
        />
      )}
    </>
  ) : null;
};

export default ComplianceProgramAssessmentView;
