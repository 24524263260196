import { useMutation } from "@apollo/client";
import { ChatAltIcon } from "@heroicons/react/solid";
import copy from "copy-to-clipboard";
import uniqueId from "lodash/uniqueId";
import {
  FormEvent,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import TimeAgo from "react-timeago";
import { BaseArtifactNode, TopicNode } from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import { successNotificationVar } from "@cache/cache";
import removeCommentFromCache from "@cache/remove-comment";
import Avatar from "@components/avatar/avatar";
import Button from "@components/button/button";
import Dropdown from "@components/dropdown/dropdown";
import Link from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import CommentWYSIWYG from "@components/wysiwyg/comment-wysiwyg";
import { isEmptyValue } from "@components/wysiwyg/helpers";
import { classNames } from "@helpers/css";
import {
  getCommentUrl,
  justNowFormatter,
  parseStringToJSON,
} from "@helpers/helpers";
import useConfirm from "@helpers/hooks/use-confirm";

import deleteCommentMutation from "../graphql/delete-comment-mutation";
import updateCommentMutation from "../graphql/update-comment-mutation";

type CommentType = {
  id: number;
  creator: BasicUser;
  comment: string;
  created: string;
};

const Comment = ({
  comment,
  meetingGroupId,
  meetingId,
  currentUserId,
  topic,
  artifact,
  selected = false,
  isLastComment = false,
}: {
  comment: CommentType;
  meetingGroupId?: number;
  meetingId?: number;
  currentUserId?: number;
  topic?: TopicNode;
  artifact?: BaseArtifactNode;
  selected?: boolean;
  isLastComment?: boolean;
}) => {
  const url = getCommentUrl({
    topicId: topic?.id,
    artifactId: artifact?.id,
    artifactType: artifact?.artifactType,
    meetingId: meetingId,
    meetingGroupId: meetingGroupId,
    commentId: comment.id,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [formComment, setFormComment] = useState(comment.comment);
  const [updateComment] = useMutation(updateCommentMutation, {
    update: (cache, { data }) => {
      const commentId = cache.identify(comment);
      if (commentId) {
        cache.modify({
          id: commentId,
          fields: {
            comment() {
              return data.createOrUpdateComment.comment.comment;
            },
            id() {
              return data.createOrUpdateComment.comment.id;
            },
          },
        });
      }
    },
  });
  const [deleteComment] = useMutation(deleteCommentMutation, {
    update: () => {
      removeCommentFromCache({
        commentId: comment.id,
        topicId: topic?.id,
        artifactId: artifact?.id,
      });
    },
  });
  const { ConfirmationDialog, confirm } = useConfirm(
    "Are you sure?",
    "Are you sure you want to delete this comment?"
  );

  // Handlers
  const handleSubmitForm = (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // Handlers
  const handleSaveForm = (e: KeyboardEvent | MouseEvent) => {
    e.preventDefault();
    if (isEmptyValue(formComment)) {
      return;
    }
    updateComment({
      variables: {
        comment: JSON.stringify(formComment),
        commentId: comment.id,
      },
      optimisticResponse: {
        createOrUpdateComment: {
          comment: {
            id: uniqueId("temp-comment"),
            comment: formComment,
          },
        },
      },
      onError: onNotificationErrorHandler(),
    });
    setIsEditing(false);
  };

  const handleKeyDownForm = (e: KeyboardEvent) => {
    if (e.keyCode === 13 && e.metaKey) {
      handleSaveForm(e);
    }
  };

  const handleChangeComment = (value: any) => {
    setFormComment(value);
  };

  const handleDeleteComment = useCallback(
    async (comment: any) => {
      const confirmation = await confirm();
      if (confirmation) {
        deleteComment({
          variables: { commentId: comment.id },
          optimisticResponse: {
            deleteComment: {
              topic: {
                id: "topoc",
                __typename: "TopicNode",
              },
              __typename: "DeleteCommentMutation",
            },
          },
          onError: onNotificationErrorHandler(),
        });
      }
    },
    [confirm, deleteComment]
  );

  const handleEditComment = (comment: CommentType) => {
    setFormComment(comment.comment);
    setIsEditing(true);
  };

  const handleCopyLink = () => {
    copy(`${document.location.origin}${url}`);
    successNotificationVar({
      title: "Comment link copied!",
      timeout: 2000,
    });
  };

  const handleCancel = () => {
    setFormComment(comment.comment);
    setIsEditing(false);
  };

  const ref = useRef<HTMLLIElement | null>(null);
  useEffect(() => {
    if (ref.current && selected) {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [ref, selected]);

  // Render
  return (
    <li key={comment.id} data-testid={`comment-${comment.id}`} ref={ref}>
      <ConfirmationDialog />
      <div className="relative pb-6">
        {!isLastComment && (
          <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" />
        )}
        <div
          className={classNames(
            "relative flex items-start space-x-3",
            selected
              ? "bg-yellow-50 -m-2 p-2 rounded-md border border-yellow-100"
              : ""
          )}
        >
          <div className="relative">
            <Avatar user={comment.creator} size="8" />
            <span className="absolute -bottom-0.5 -right-1 bg-gray-50 rounded-tl px-0.5 py-px">
              <ChatAltIcon className="h-4 w-4 text-gray-400" />
            </span>
          </div>
          <div className="min-w-0 flex-1">
            <div className="flex items-center">
              <div className="flex-1">
                <div className="text-sm font-medium text-gray-900">
                  {comment.creator.name}
                </div>
                <div className="mt-0.5 text-xs text-gray-500">
                  {String(comment.id).includes("temp-comment") ? (
                    <div className="flex items-center">
                      <Loading size="4" mini />{" "}
                      <span className="ml-2">Saving...</span>
                    </div>
                  ) : (
                    <div>
                      Commented{" "}
                      <Link to={url}>
                        <TimeAgo
                          date={comment.created}
                          formatter={justNowFormatter}
                        />
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              {comment.creator.id === currentUserId && (
                <div>
                  <Dropdown
                    aria-label="Comment action dropdown"
                    options={[
                      {
                        label: "Copy link",
                        onClick: () => handleCopyLink(),
                      },
                      {
                        label: "Edit",
                        onClick: () => handleEditComment(comment),
                      },
                      {
                        label: "Delete",
                        onClick: () => handleDeleteComment(comment),
                      },
                    ]}
                  />
                </div>
              )}
            </div>
            <div className="mt-2 text-sm text-gray-900">
              {isEditing ? (
                <form
                  aria-label="Edit comment form"
                  onSubmit={handleSubmitForm}
                  onKeyDown={handleKeyDownForm}
                >
                  <div className="shadow-sm block w-full ">
                    <CommentWYSIWYG
                      value={parseStringToJSON(formComment)}
                      editable
                      meetingGroupId={meetingGroupId}
                      onChange={handleChangeComment}
                      className="py-1 px-2 my-2 bg-white border rounded-md text-sm"
                      topicId={topic?.id}
                      uploadVariable={{
                        topicId: topic?.id,
                        artifactId: artifact?.id,
                      }}
                      mentionsConfig={{
                        meetingGroupId,
                        meetingId,
                        artifactId: artifact?.id,
                      }}
                      placeholder="Leave a comment"
                    />
                  </div>
                  <div className="mt-2">
                    <Button
                      type="button"
                      aria-label="Comment form submit button"
                      text={"Save"}
                      theme="primary"
                      disabled={isEmptyValue(formComment)}
                      className="mr-2"
                      onClick={handleSaveForm}
                    />
                    <Button
                      type="button"
                      text="Cancel"
                      onClick={handleCancel}
                    />
                  </div>
                </form>
              ) : (
                <div>
                  <CommentWYSIWYG
                    editable
                    uploadVariable={{
                      topicId: topic?.id,
                      artifactId: artifact?.id,
                    }}
                    mentionsConfig={{
                      meetingGroupId,
                      meetingId,
                      artifactId: artifact?.id,
                    }}
                    topicId={topic?.id}
                    value={parseStringToJSON(comment.comment)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default Comment;
