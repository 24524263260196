import { compact, sortBy, uniqBy } from "lodash";
import moment from "moment";
import {
  AssessmentDeliveryState,
  AssessmentNominationFragment,
  AssessmentState,
  AssessmentType,
  ComplianceProgramActionAssessmentDeliveryFragment,
  GetComplianceProgramActionsQuery,
  UnmetComplainceProgramAssessmentTemplateFragment,
  UnmetComplianceProgramFragment,
  UserActivityComplianceGroupAssessmentTemplateFragment,
} from "types/graphql-schema";
import { BasicUser } from "types/topicflow";

import { getLabel } from "@apps/use-label/use-label";
import { currentUserVar } from "@cache/cache";
import { ButtonProps, buttonTheme } from "@components/button/button";
import { assessmentTypeLabels } from "@helpers/constants";
import {
  assertEdgesNonNull,
  assertEdgesNonNullWithStringId,
  assertNonNull,
  removeCurrentYear,
} from "@helpers/helpers";
import { pluralize } from "@helpers/string";

export enum UserRelationEnum {
  manager,
  report,
}

export const getReportsAndManagersWithMatchingOneonones = (
  complianceProgram: NonNullable<
    GetComplianceProgramActionsQuery["complianceProgram"]
  >
) => {
  const currentUser = currentUserVar();

  const matchingOneonones = assertEdgesNonNull(
    complianceProgram.matchingOneonones
  );
  const reports = assertEdgesNonNull(complianceProgram.applicableReports).map(
    (user) => ({
      ...user,
      type: UserRelationEnum.report,
    })
  );
  const managers = assertEdgesNonNull(complianceProgram.applicableManagers).map(
    (user) => ({
      ...user,
      type: UserRelationEnum.manager,
    })
  );
  const reportsAndManagers = [...reports, ...managers];
  const items = reportsAndManagers.map((user) => {
    const userIds = sortBy([currentUser.id, user.id]);
    const matchingOneonone = matchingOneonones.find((oneonone) => {
      const participantIds = sortBy(
        assertEdgesNonNullWithStringId(oneonone.participants).map(
          (participant) => participant.user?.id
        )
      );
      return userIds.every((userId) => participantIds.includes(userId));
    });
    return { user, matchingOneonone };
  });
  return sortBy(items, (item) =>
    !item.matchingOneonone ? 0 : item.matchingOneonone.isFinalized ? 2 : 1
  );
};

export type ComplianceProgramActionBasicItemProps = {
  key: number | string;
  id?: null | number | string;
  user?: BasicUser | null;
  label?: string | null;
  button?: ButtonProps | null;
  statusText?: string | null;
  statusCompleted?: boolean | null;
};

export type ComplianceProgramActionNominationItemProps =
  ComplianceProgramActionBasicItemProps & {
    assessmentOpenForNomination: AssessmentNominationFragment;
    complianceProgram?: AssessmentNominationFragment["complianceProgram"];
  };

export const mapAssessmentForNominationToItem = (
  currentUserId: number,
  assessmentOpenForNomination: AssessmentNominationFragment
): ComplianceProgramActionNominationItemProps => ({
  key: assessmentOpenForNomination.id,
  id: assessmentOpenForNomination.id,
  complianceProgram: assessmentOpenForNomination.complianceProgram,
  user: assessmentOpenForNomination.targetUser,
  label:
    currentUserId === assessmentOpenForNomination.targetUser?.id
      ? "Peer Nominations"
      : null,
  button: {
    text: "Nominate peers",
    theme: buttonTheme.lightBlue,
  },
  statusText: `${
    assessmentOpenForNomination.nominations.totalCount
  } ${pluralize(
    "nomination",
    assessmentOpenForNomination.nominations.totalCount
  )}`,
  statusCompleted: assessmentOpenForNomination.nominations.totalCount > 0,
  assessmentOpenForNomination: assessmentOpenForNomination,
});

export const mapAssessmentDeliveryToItem = (
  currentUserId: number,
  delivery: ComplianceProgramActionAssessmentDeliveryFragment
) => {
  const label = getLabel();
  return {
    key: delivery.id,
    id: delivery.id,
    user: delivery.target,
    complianceProgram: delivery.complianceProgram,
    label:
      currentUserId === delivery.target?.id
        ? `${assessmentTypeLabels[delivery.template.assessmentType]} ${label(
            "review"
          )} package`
        : null,
    button: {
      text:
        delivery.state === AssessmentDeliveryState.Draft ? "Deliver" : `View`,
      to: `/assessments/assessment/delivery/${delivery.id}`,
      theme:
        delivery.state === AssessmentDeliveryState.Draft
          ? buttonTheme.lightBlue
          : undefined,
    },
    statusText:
      delivery.state === AssessmentDeliveryState.Draft
        ? `${delivery.respondersCount}/${
            delivery.nominees.totalCount
          } ${pluralize("peer", delivery.nominees.totalCount)} submitted`
        : `Delivered${
            delivery.deliveryDatetime
              ? removeCurrentYear(
                  moment(delivery.deliveryDatetime).format("MMM DD, YYYY")
                )
              : ""
          }`,
    statusCompleted: delivery.state === AssessmentDeliveryState.Delivered,
  };
};

const getAssessmentStateList = (
  complianceProgram:
    | NonNullable<GetComplianceProgramActionsQuery["complianceProgram"]>
    | UnmetComplianceProgramFragment,
  assessmentTemplate:
    | UserActivityComplianceGroupAssessmentTemplateFragment
    | UnmetComplainceProgramAssessmentTemplateFragment,
  currentUserId: number
) => {
  const usersMissingAssessments = complianceProgram?.usersMissingAssessment
    ? complianceProgram.usersMissingAssessment.edges.map((edge) =>
        assertNonNull(edge?.node)
      )
    : [];
  const complianceGroupAssessments = complianceProgram?.assessments
    ? assertEdgesNonNull(complianceProgram.assessments)
    : [];
  const matchingUserMissingAssessments = usersMissingAssessments
    .filter((matchingUserMissingPeerAssessment) => {
      return (
        matchingUserMissingPeerAssessment.responder.id === currentUserId &&
        matchingUserMissingPeerAssessment.assessmentTemplateId ===
          assessmentTemplate.id
      );
    })
    .map((matchingUserMissingPeerAssessment) => ({
      id: null,
      target: matchingUserMissingPeerAssessment.target,
      state: null,
      submittedDatetime: null,
    }));
  const createdAssessments = complianceGroupAssessments
    .filter(
      (createdAssessment) =>
        createdAssessment.template.id === assessmentTemplate.id
    )
    .map((createdAssessment) => ({
      id: createdAssessment.id,
      target: createdAssessment.target,
      state: createdAssessment.state,
      submittedDatetime: createdAssessment.submittedDatetime,
    }));
  const allAssessments = uniqBy(
    [...createdAssessments, ...matchingUserMissingAssessments],
    (assessment) => assessment.target?.id
  );
  return sortBy(allAssessments, (assessment) =>
    assessment.state === null
      ? 1
      : assessment.state === AssessmentState.Draft
      ? 2
      : 3
  );
};

export type ComplianceProgramActionAssessmentItemProps =
  ComplianceProgramActionBasicItemProps & {
    assessmentTemplateId: number;
    complianceProgram:
      | NonNullable<GetComplianceProgramActionsQuery["complianceProgram"]>
      | UnmetComplianceProgramFragment;
  };

export const getAssessmentItemsForTemplate = (
  complianceProgram:
    | NonNullable<GetComplianceProgramActionsQuery["complianceProgram"]>
    | UnmetComplianceProgramFragment,
  assessmentTemplate:
    | UserActivityComplianceGroupAssessmentTemplateFragment
    | UnmetComplainceProgramAssessmentTemplateFragment,
  currentUserId: number
): ComplianceProgramActionAssessmentItemProps[] => {
  const label = getLabel();
  return compact(
    getAssessmentStateList(
      complianceProgram,
      assessmentTemplate,
      currentUserId
    ).map((assessmentState) => {
      if (!assessmentState.target) {
        return null;
      }
      const isCurrentUser = assessmentState.target.id === currentUserId;
      return {
        id: assessmentState.id,
        key: `${assessmentState.id}-${assessmentState.target.id}-${assessmentTemplate.id}`,
        assessmentTemplateId: assessmentTemplate.id,
        complianceProgram: complianceProgram,
        user: assessmentState.target,
        label: isCurrentUser
          ? `Self ${label("review")}${
              assessmentTemplate.assessmentType === AssessmentType.Performance
                ? ` for performance`
                : assessmentTemplate.assessmentType === AssessmentType.Manager
                ? ` for upward feedback`
                : ""
            }`
          : null,
        button:
          assessmentState.state === AssessmentState.Draft ||
          assessmentState.state === null
            ? {
                text: `${
                  assessmentState.state === AssessmentState.Draft
                    ? "Continue"
                    : `Start`
                } ${label("review")}`,
                to: `/assessments/assessments/${assessmentState.id}`,
                theme: buttonTheme.lightBlue,
              }
            : {
                // assumed it is submitted
                text: `View`,
                to: `/assessments/assessments/${assessmentState.id}`,
              },
        statusText:
          assessmentState.state === AssessmentState.Submitted &&
          assessmentState.submittedDatetime
            ? `Submitted ${removeCurrentYear(
                moment(assessmentState.submittedDatetime).format("MMM DD, YYYY")
              )}`
            : complianceProgram?.dueDate
            ? `Due ${removeCurrentYear(
                moment(complianceProgram?.dueDate).format("MMM DD, YYYY")
              )}`
            : null,
        statusCompleted:
          assessmentState.state === AssessmentState.Submitted &&
          !!assessmentState.submittedDatetime,
      };
    })
  );
};

export type ComplianceProgramActionOneononeItemProps =
  ComplianceProgramActionBasicItemProps & {
    userRelation: number;
    requiredTopicTemplateId: number;
  };

export const getOneononeItemsFromComplianceProgram = (
  complianceProgram: NonNullable<
    GetComplianceProgramActionsQuery["complianceProgram"]
  >,
  currentUserId: number
): ComplianceProgramActionOneononeItemProps[] => {
  const label = getLabel();
  const requiredTopicTemplates = complianceProgram?.requiredTopicTemplates
    ? assertEdgesNonNull(complianceProgram.requiredTopicTemplates)
    : [];
  const requiredTopicTemplate = requiredTopicTemplates[0];
  const reportsAndManagersWithMatchingOneonone = requiredTopicTemplate
    ? getReportsAndManagersWithMatchingOneonones(complianceProgram)
    : [];
  return reportsAndManagersWithMatchingOneonone.map(
    (reportAndManagerWithMatchingOneonone) => {
      return {
        key: reportAndManagerWithMatchingOneonone.user.id,
        id: reportAndManagerWithMatchingOneonone.user.id,
        user: reportAndManagerWithMatchingOneonone.user,
        requiredTopicTemplateId: requiredTopicTemplate.id,
        label:
          currentUserId === reportAndManagerWithMatchingOneonone.user.id &&
          reportAndManagerWithMatchingOneonone.user.type ===
            UserRelationEnum.report
            ? `${label("1-on-1", { pluralize: true })} Meetings`
            : null,
        button: {
          text: "Create meeting",
          theme: buttonTheme.lightBlue,
        },
        statusText: reportAndManagerWithMatchingOneonone.matchingOneonone
          ? "Scheduled"
          : null,
        userRelation: reportAndManagerWithMatchingOneonone.user.type,
      };
    }
  );
};
