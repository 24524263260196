import { useMutation } from "@apollo/client";
import { MinusCircleIcon, PauseIcon } from "@heroicons/react/outline";
import moment from "moment";
import { ChangeEvent } from "react";
import {
  AddBotToMeetingNewPageMutationMutation,
  AddBotToMeetingNewPageMutationMutationVariables,
  ChangeBotRecordingStatusNewPageMutationMutation,
  ChangeBotRecordingStatusNewPageMutationMutationVariables,
  MeetingViewMeetingNodeNewPageFragmentFragment,
  RemoveBotFromMeetingNewPageMutationMutation,
  RemoveBotFromMeetingNewPageMutationMutationVariables,
  UpdateMeetingGroupNewPageMutationMutation,
  UpdateMeetingGroupNewPageMutationMutationVariables,
} from "types/graphql-schema";

import addBotToMeetingMutation from "@apps/meeting-new/graphql/add-bot-to-meeting-mutation";
import changeBotRecordingStatusMutation from "@apps/meeting-new/graphql/change-bot-recording-status-mutation";
import removeBotFromMeetingMutation from "@apps/meeting-new/graphql/remove-bot-from-meeting-mutation";
import updateMeetingGroupMutation from "@apps/meeting-new/graphql/update-meeting-group-mutation";
import Button, { ButtonSize, buttonTheme } from "@components/button/button";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

export const AIAssistMeetingBot = ({
  meeting,
}: {
  meeting: MeetingViewMeetingNodeNewPageFragmentFragment;
}) => {
  const [addBotToMeeting] = useMutation<
    AddBotToMeetingNewPageMutationMutation,
    AddBotToMeetingNewPageMutationMutationVariables
  >(addBotToMeetingMutation);
  const [removeBotFromMeeting] = useMutation<
    RemoveBotFromMeetingNewPageMutationMutation,
    RemoveBotFromMeetingNewPageMutationMutationVariables
  >(removeBotFromMeetingMutation);
  const [changeBotRecordingStatus] = useMutation<
    ChangeBotRecordingStatusNewPageMutationMutation,
    ChangeBotRecordingStatusNewPageMutationMutationVariables
  >(changeBotRecordingStatusMutation);
  const [updateMeetingGroup] = useMutation<
    UpdateMeetingGroupNewPageMutationMutation,
    UpdateMeetingGroupNewPageMutationMutationVariables
  >(updateMeetingGroupMutation);

  const meetingGroup = meeting.meetingGroup;
  if (!meetingGroup) return null;

  const handleToggleAddTranscriptionBotToMeetingsByDefault = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    updateMeetingGroup({
      variables: {
        meetingGroupId: meetingGroup.id,
        addTranscriptionBotToMeetingsByDefault: e.target.checked,
      },
      onError: onNotificationErrorHandler(),
      optimisticResponse: {
        __typename: "Mutation",
        updateMeetingGroup: {
          __typename: "UpdateMeetingGroupMutation",
          meetingGroup: {
            ...meetingGroup,
            addTranscriptionBotToMeetingsByDefault: e.target.checked,
          },
        },
      },
    });
  };

  const handleClickAddBotInMeeting = () => {
    addBotToMeeting({
      variables: {
        meetingId: meeting.id,
      },
      onError: onNotificationErrorHandler(),
      optimisticResponse: {
        __typename: "Mutation",
        addBotToMeeting: {
          __typename: "AddBotToMeetingMutation",
          meeting: {
            ...meeting,
            hasBotInMeeting: true,
            botIsWaitingToJoinMeeting: true,
          },
        },
      },
    });
  };

  const handleClickRemoveBotInMeeting = () => {
    removeBotFromMeeting({
      variables: {
        meetingId: meeting.id,
      },
      onError: onNotificationErrorHandler(),
      optimisticResponse: {
        __typename: "Mutation",
        removeBotFromMeeting: {
          __typename: "RemoveBotFromMeetingMutation",
          meeting: {
            ...meeting,
            hasBotInMeeting: true,
            botIsWaitingToJoinMeeting: false,
          },
        },
      },
    });
  };

  const handleClickPauseBotRecording = () => {
    const newValue = !meeting.botIsRecording;
    changeBotRecordingStatus({
      onError: onNotificationErrorHandler(),
      variables: {
        meetingId: meeting.id,
        isRecording: newValue,
      },
    });
  };

  const meetingHasStarted = moment(meeting.startDatetime).isSameOrBefore(
    moment(),
    "seconds"
  );
  const meetingHasEnded = moment(meeting.endDatetime)
    .add(1, "hour")
    .isBefore(moment(), "seconds");

  return (
    <div className="flex flex-col gap-4 text-sm tracking-tight text-gray-800 p-4">
      {!meetingHasStarted ? (
        <div className="flex items-center gap-2">
          <div className="h-3 w-3 shrink-0 rounded-full bg-gray-200" />
          Meeting has not started
        </div>
      ) : meetingHasEnded ? (
        <div className="flex items-center gap-2">
          <div className="h-3 w-3 shrink-0 rounded-full bg-gray-200" />
          Meeting ended.
        </div>
      ) : (
        <div className="flex items-center gap-2 flex-wrap">
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-1.5">
              {meeting.hasBotInMeeting && !meeting.botIsRecording ? (
                <PauseIcon className="h-5 w-5 text-gray-400" />
              ) : meeting.hasBotInMeeting && meeting.botIsRecording ? (
                <div className="h-2 w-2 shrink-0 rounded-full bg-red-600 outline outline-offset-1 outline-1 outline-red-600 animate-pulse" />
              ) : meeting.botIsWaitingToJoinMeeting ? (
                <Loading mini size={4} />
              ) : (
                <div className="h-3 w-3 shrink-0 rounded-full bg-gray-200" />
              )}
              {meeting.hasBotInMeeting && meeting.botIsRecording
                ? "Notetaker in meeting and recording"
                : meeting.hasBotInMeeting && !meeting.botIsRecording
                ? "Notetaker in meeting, not recording"
                : meeting.botIsWaitingToJoinMeeting
                ? "Notetaker is waiting to join the meeting"
                : "Notetaker not in the meeting"}
            </div>
            {!meeting.hasBotInMeeting && !meeting.botIsWaitingToJoinMeeting && (
              <Button
                size={ButtonSize.small}
                theme={buttonTheme.primary}
                text="Add to meeting"
                tooltip="Add notetaker to meeting"
                onClick={handleClickAddBotInMeeting}
              />
            )}
          </div>
          {meeting.hasBotInMeeting && (
            <div className="flex flex-1 items-center gap-2 @container/aiassistbotbuttons">
              <Button
                size={ButtonSize.small}
                className="flex items-center gap-1"
                tooltip="Pause transcription"
                onClick={handleClickPauseBotRecording}
              >
                <PauseIcon className="h-4 w-4 text-gray-500" />
                <span className="hidden @[14rem]/aiassistbotbuttons:block">
                  {meeting.botIsRecording
                    ? "Pause transcription"
                    : "Resume transcription"}
                </span>
              </Button>
              <Button
                size={ButtonSize.small}
                className="flex items-center gap-1"
                tooltip="Remove notetaker"
                onClick={handleClickRemoveBotInMeeting}
              >
                <MinusCircleIcon className="h-4 w-4 text-gray-500" />
                <span className="hidden @[14rem]/aiassistbotbuttons:block">
                  Remove notetaker
                </span>
              </Button>
            </div>
          )}
        </div>
      )}

      <div className="bg-gray-100 rounded-lg px-2 py-1">
        <label className="flex items-center gap-1 cursor-pointer">
          <input
            type="checkbox"
            className="cursor-pointer"
            checked={meetingGroup.addTranscriptionBotToMeetingsByDefault}
            onChange={handleToggleAddTranscriptionBotToMeetingsByDefault}
          />
          Automatically add notetaker to this meeting.
        </label>
      </div>
    </div>
  );
};

export default AIAssistMeetingBot;
