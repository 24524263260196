import { gql } from "@apollo/client";

import { AssessmentQuestionFragment } from "./get-questions-query";

export default gql`
  ${AssessmentQuestionFragment}
  mutation saveAssessmentQuestion(
    $title: String
    $description: String
    $organizationId: Int
    $questionId: Int
    $startValue: Int
    $endValue: Int
    $isCommentMandatory: Boolean
    $labels: [String]
    $labelDescriptions: [String]
    $options: [String]
    $optionDescriptions: [String]
    $responses: AssessmentQuestionResponses
    $responseVisibility: AssessmentQuestionResponseVisibility
    $questionType: AssessmentQuestionType
    $archived: Boolean
    $categories: [String]
  ) {
    createOrUpdateAssessmentQuestion(
      organizationId: $organizationId
      questionId: $questionId
      title: $title
      description: $description
      startValue: $startValue
      endValue: $endValue
      isCommentMandatory: $isCommentMandatory
      labels: $labels
      labelDescriptions: $labelDescriptions
      options: $options
      optionDescriptions: $optionDescriptions
      responses: $responses
      responseVisibility: $responseVisibility
      questionType: $questionType
      archived: $archived
      categories: $categories
    ) {
      question {
        ...AssessmentQuestionFragment
      }
    }
  }
`;
