import moment from "moment";
import { useMemo } from "react";
import { MdGridView } from "react-icons/md";
import {
  AssessmentDeliveryState,
  GetAssessmentDeliveryQuery,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import Avatar from "@components/avatar/avatar";
import Button, { buttonTheme } from "@components/button/button";
import Heading from "@components/heading/heading";
import Layout from "@components/layout/layout";
import Loading from "@components/loading/loading";
import ToggleSwitch from "@components/toggle-switch/toggle-switch";
import { assessmentTypeLabels } from "@helpers/constants";
import { assertNonNull } from "@helpers/helpers";

const AssessmentDeliveryHeader = ({
  assessmentDeliveryData,
  onReopen,
  isSaving,
  canUpdate,
  backUrl,
  onToggleShowHiddenQuestions,
  isShowingHiddenQuestions,
  hiddenQuestionCount,
}: {
  assessmentDeliveryData?: GetAssessmentDeliveryQuery;
  onReopen?: () => void;
  isSaving?: boolean;
  canUpdate: boolean;
  backUrl?: string;
  onToggleShowHiddenQuestions: (isShowingHiddenQuestions: boolean) => void;
  isShowingHiddenQuestions: boolean;
  hiddenQuestionCount: number;
}) => {
  const label = useLabel();

  const assessmentDelivery = useMemo(
    () =>
      assessmentDeliveryData?.assessmentDelivery
        ? assessmentDeliveryData.assessmentDelivery
        : null,
    [assessmentDeliveryData]
  );
  const program = useMemo(
    () => (assessmentDelivery ? assessmentDelivery.complianceProgram : null),
    [assessmentDelivery]
  );
  const template = useMemo(
    () => (assessmentDelivery ? assessmentDelivery.template : null),
    [assessmentDelivery]
  );

  const target = useMemo(
    () =>
      assessmentDelivery ? assertNonNull(assessmentDelivery.target) : null,
    [assessmentDelivery]
  );

  if (!assessmentDeliveryData) {
    return null;
  }

  const leftCellClassName = "py-2 font-medium";
  const rightCellClassName = "py-2";
  const isDraft =
    assessmentDelivery?.state !== AssessmentDeliveryState.Delivered;

  return (
    <div className="flex flex-col text-sm gap-6 pb-6 border-b">
      <Heading
        small
        title={`${
          assessmentTypeLabels[assertNonNull(template?.assessmentType)]
        } ${label("review")}: ${program?.title || "Untitled"}`}
        backUrl={backUrl}
      />

      <div>
        <Layout.MainSubSection
          title={`${label("review", { capitalize: true })} Details`}
          collapsible
        >
          <table className="text-gray-600 w-full">
            <tr>
              <td className={leftCellClassName}>Program</td>
              <td className={rightCellClassName}>
                <Button
                  to={`/programs/${program?.id}`}
                  theme={buttonTheme.text}
                  leftNegativeMargin
                >
                  <MdGridView className="shrink-0 w-4 h-4" />
                  {program?.title}
                </Button>
              </td>
            </tr>
            <tr>
              <td className={leftCellClassName}>{`${label("review", {
                capitalize: true,
              })} type`}</td>
              <td className={rightCellClassName}>
                {assessmentTypeLabels[assertNonNull(template?.assessmentType)]}
              </td>
            </tr>
            <tr>
              <td className={leftCellClassName}>Subject</td>
              <td className={rightCellClassName}>
                <div className="flex items-center">
                  <Avatar
                    className="mr-1"
                    user={assertNonNull(target)}
                    size="5"
                  />{" "}
                  {target?.name}
                </div>
              </td>
            </tr>
            {target?.organizationSpecificInfo?.position && (
              <tr>
                <td className={leftCellClassName}>Position</td>
                <td className={rightCellClassName}>
                  {target?.organizationSpecificInfo?.position}
                </td>
              </tr>
            )}
            {target?.organizationSpecificInfo?.jobLevel && (
              <tr>
                <td className={leftCellClassName}>Job level</td>
                <td className={rightCellClassName}>
                  {target?.organizationSpecificInfo?.jobLevel}
                </td>
              </tr>
            )}

            <tr>
              <td className={leftCellClassName}>Program period</td>
              <td className={rightCellClassName}>
                {program?.periodStartDate && program?.periodEndDate
                  ? `${moment(program.periodStartDate).format(
                      "MMM D, YYYY"
                    )} - ${moment(program.periodEndDate).format("MMM D, YYYY")}`
                  : "Not set"}
              </td>
            </tr>
            <tr>
              <td className={leftCellClassName}>Due date</td>
              <td className={rightCellClassName}>
                {moment(assessmentDelivery?.dueDate).format("MMM D, YYYY")}
              </td>
            </tr>

            {!isDraft && (
              <tr>
                <td className={leftCellClassName}>Delivery</td>
                <td className={rightCellClassName}>
                  <div>
                    {assessmentDelivery.deliveryDatetime
                      ? `Delivered by ${
                          assessmentDelivery.creator?.name
                        } on ${moment(
                          assessmentDelivery.deliveryDatetime
                        ).format("MMM D, YYYY @ h:mma")}`
                      : "Immediate access"}
                  </div>
                </td>
              </tr>
            )}
          </table>
        </Layout.MainSubSection>
      </div>

      {canUpdate && assessmentDelivery?.deliveryDatetime && !!onReopen && (
        <div className="flex items-center w-full gap-2">
          <Button className="mr-2" disabled={isSaving} onClick={onReopen}>
            Reopen delivery package
          </Button>
          {isSaving && <Loading mini size="5" />}
        </div>
      )}

      {hiddenQuestionCount > 0 && (
        <div className="flex items-center w-full gap-4">
          <ToggleSwitch
            checked={isShowingHiddenQuestions}
            onChange={onToggleShowHiddenQuestions}
          />
          <div className="text-sm font-medium h-6">
            Show questions not visible to {target?.firstName}
          </div>
        </div>
      )}
    </div>
  );
};

export default AssessmentDeliveryHeader;
