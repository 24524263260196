import { ReactNode } from "react";

import Avatar from "@components/avatar/avatar";
import Button from "@components/button/button";
import Layout from "@components/layout/layout";
import { classNames } from "@helpers/css";

import { ComplianceProgramActionBasicItemProps } from "../helpers";

const ComplianceProgramActionItem = ({
  user,
  label,
  statusText,
  statusCompleted = false,
  button,
  children,
}: Omit<ComplianceProgramActionBasicItemProps, "key" | "id"> & {
  children?: ReactNode | null;
}) => {
  return (
    <Layout.MainSubSectionListItem>
      <div className="flex flex-col sm:flex-row sm:justify-between gap-4 text-sm">
        <div className="flex items-center gap-3">
          <Avatar user={user} size="5" />
          <div className="flex flex-col gap-0.5">
            <span className="font-medium">
              {label || user?.name || "Unknown"}
            </span>
            {children}
          </div>
        </div>
        <div className="flex items-center gap-4">
          {statusText && (
            <div
              className={classNames(
                "text-sm",
                statusCompleted ? "text-emerald-600" : "text-gray-500"
              )}
            >
              {statusText}
            </div>
          )}
          {button && <Button {...button} />}
        </div>
      </div>
    </Layout.MainSubSectionListItem>
  );
};

export default ComplianceProgramActionItem;
