import { gql } from "@apollo/client";

export default gql`
  mutation sendProgramReminderEmails(
    $complianceProgramId: Int!
    $message: String!
    $nominationsUserIds: [Int]
    $oneononeStatusUserIds: [Int]
    $performanceAssessmentStatusUserIds: [Int]
    $performanceSelfAssessmentStatusUserIds: [Int]
    $managerAssessmentStatusUserIds: [Int]
    $managerSelfAssessmentStatusUserIds: [Int]
    $peerAssessmentStatusUserIds: [Int]
    $peerSelfAssessmentStatusUserIds: [Int]
  ) {
    sendProgramReminder(
      complianceProgramId: $complianceProgramId
      nominationsUserIds: $nominationsUserIds
      oneononeStatusUserIds: $oneononeStatusUserIds
      performanceAssessmentStatusUserIds: $performanceAssessmentStatusUserIds
      performanceSelfAssessmentStatusUserIds: $performanceSelfAssessmentStatusUserIds
      managerAssessmentStatusUserIds: $managerAssessmentStatusUserIds
      managerSelfAssessmentStatusUserIds: $managerSelfAssessmentStatusUserIds
      peerAssessmentStatusUserIds: $peerAssessmentStatusUserIds
      peerSelfAssessmentStatusUserIds: $peerSelfAssessmentStatusUserIds
      message: $message
    ) {
      complianceProgram {
        id
      }
    }
  }
`;
