import numeral from "numeral";
import { PropsWithChildren } from "react";

import {
  TargetValueProgressData,
  getProgressFromStartCurrentTargetValues,
} from "@helpers/helpers";

import Tooltip from "./tooltip";

const StartCurrentTargetTooltip: React.FC<
  PropsWithChildren<{ goalOrKeyresult: TargetValueProgressData }>
> = ({ children, goalOrKeyresult }) => (
  <Tooltip
    text={`
      <div>
        <div>Start value: ${numeral(goalOrKeyresult.startValue).format()}</div>
        <div>Current value: ${numeral(
          goalOrKeyresult.currentValue
        ).format()}</div>
        <div>Target value: ${numeral(
          goalOrKeyresult.targetValue
        ).format()}</div>
        <div>Progress: ${getProgressFromStartCurrentTargetValues(
          goalOrKeyresult
        )}%</div>
      </div>`}
  >
    <div>{children}</div>
  </Tooltip>
);

export default StartCurrentTargetTooltip;
