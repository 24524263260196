import { ChevronRightIcon } from "@heroicons/react/outline";
import { GetMeetingNewPageQueryQuery } from "types/graphql-schema";

import AppLink from "@components/link/link";
import useDocumentTitle from "@components/use-document-title/use-document-title";
import useUrlQueryParams from "@helpers/hooks/use-url-query-params";

type Props = {
  meetingGroup: NonNullable<
    NonNullable<GetMeetingNewPageQueryQuery["meeting"]>["meetingGroup"]
  >;
};
const MeetingSettingsHeader: React.FC<Props> = ({ meetingGroup }) => {
  const title = meetingGroup?.title || "";
  useDocumentTitle(title);
  const defaultMeetingUrl = `/meeting/${meetingGroup.id}`;
  const queryParams = useUrlQueryParams({ from: "" });

  return (
    <>
      <div className="px-3 sm:px-6 h-14 border-b flex items-center bg-white sticky top-14 md:top-0 z-horizontalNavbar">
        <header className="w-full fs-mask flex gap-4 justify-between items-center">
          <div className="flex gap-2 items-center text-sm text-gray-600">
            <AppLink className="truncate hover:underline" to="/calendar">
              Meetings
            </AppLink>
            <ChevronRightIcon className="h-3 w-3 text-gray-500" />
            <AppLink
              className="truncate hover:underline"
              to={
                queryParams.from && queryParams.from.includes(defaultMeetingUrl)
                  ? queryParams.from
                  : defaultMeetingUrl
              }
            >
              {title}
            </AppLink>
            <ChevronRightIcon className="h-3 w-3 text-gray-500" />
            <AppLink
              className="truncate hover:underline"
              to={`${defaultMeetingUrl}/settings`}
            >
              Settings
            </AppLink>
          </div>
        </header>
      </div>
    </>
  );
};

export default MeetingSettingsHeader;
