import { gql } from "@apollo/client";

const MeetingViewMeetingGroupNewPageFragment = gql`
  fragment MeetingViewMeetingGroupNewPageFragment on MeetingGroupNode {
    id
    title
    hasTemplate
    allowAdminVisibility
    allowOrgVisibility
    allowManagementTreeVisibility
    isFormalOneonone
    transcribeMeetingsByDefault
    addTranscriptionBotToMeetingsByDefault
    canUpdate {
      permission
    }
    facilitator {
      id
      name
      avatar
    }
    organization {
      id
      name
    }
    syncCredentials {
      id
      hasCalendarWriteCredentials
      provider
    }
    topicTemplate {
      id
      title
      organization {
        id
      }
      globalTemplate
      publicTemplate
      meetingTemplate
      finalizePermissions
    }
  }
`;

export default MeetingViewMeetingGroupNewPageFragment;
