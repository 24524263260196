import { useMutation, useQuery } from "@apollo/client";
import { TemplateIcon } from "@heroicons/react/outline";
import { TemplateIcon as TemplateIconSolid } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import {
  CreateOrUpdateTemplateSeriesForMeetingMutationMutation,
  CreateOrUpdateTemplateSeriesForMeetingMutationMutationVariables,
  RemoveTemplateSeriesFromMeetingMutationMutation,
  RemoveTemplateSeriesFromMeetingMutationMutationVariables,
} from "types/graphql-schema";

import TemplateEditTopics from "@apps/templates/components/edit-topics";
import Templates from "@apps/templates/dialog";
import { getNewTopic } from "@apps/templates/helpers";
import cache, { currentUserVar } from "@cache/cache";
import Button from "@components/button/button";
import Dropdown from "@components/dropdown/dropdown";
import { useLink } from "@components/link/link";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";

import createOrUpdateTemplateSeriesMutation from "../graphql/create-or-update-template-series-mutation";
import getTemplateSeriesQuery from "../graphql/get-template-series-query";
import removeTemplateSeriesFromMeetingMutation from "../graphql/remove-template-series-from-meeting-mutation";
import CreatePublicTemplateDialog from "./create-public-template-dialog";
import {
  filterTopics,
  getTopicArrayFromMeetingGroupTemplateSeries,
  getTopicArrayFromTemplate,
  transformTopicsObjectToArray,
} from "./helpers";

const TemplateForm = ({ meetingGroup }: { meetingGroup: any }) => {
  const currentUser = currentUserVar();
  const link = useLink();

  const [openTemplateLibraryDialog, setOpenTemplateLibraryDialog] =
    useState(false);
  const [openPublicTemplateNameDialog, setOpenPublicTemplateNameDialog] =
    useState(false);
  const { data, loading } = useQuery(getTemplateSeriesQuery, {
    variables: { meetingGroupId: meetingGroup.id },
    onError: onNotificationErrorHandler(),
  });
  const [formTopics, _setFormTopics] = useState([]);
  const [formTemplate, setFormTemplate] = useState<null | {
    id: number;
    publicTemplate: any;
    title: string;
    oneononeTemplate: any;
  }>(null);
  const [createOrUpdateTemplateSeries, { loading: loadingSave }] = useMutation<
    CreateOrUpdateTemplateSeriesForMeetingMutationMutation,
    CreateOrUpdateTemplateSeriesForMeetingMutationMutationVariables
  >(createOrUpdateTemplateSeriesMutation);
  const [removeTemplateSeriesFromMeeting, { loading: loadingDelete }] =
    useMutation<
      RemoveTemplateSeriesFromMeetingMutationMutation,
      RemoveTemplateSeriesFromMeetingMutationMutationVariables
    >(removeTemplateSeriesFromMeetingMutation);

  useEffect(() => {
    setFormTopics(getTopicArrayFromMeetingGroupTemplateSeries(data));
    setFormTemplate(
      data?.meetingGroup.templateSeries.edges[0]?.node.topicTemplates.edges[0]
        ?.node.topicTemplate
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  // we ensure there is an empty topic at the end
  const setFormTopics = (updatedFormTopics: any) => {
    if (
      !loading &&
      (updatedFormTopics.length === 0 ||
        updatedFormTopics[updatedFormTopics.length - 1].title)
    ) {
      _setFormTopics(updatedFormTopics.concat(getNewTopic()));
    } else {
      _setFormTopics(updatedFormTopics);
    }
  };

  // Show loading state
  if (loading) {
    return (
      <div className="p-6">
        <Loading>loading template</Loading>
      </div>
    );
  }

  const formTopicsHaveChanged =
    JSON.stringify(filterTopics(transformTopicsObjectToArray(formTopics))) !==
    JSON.stringify(
      filterTopics(
        transformTopicsObjectToArray(
          getTopicArrayFromMeetingGroupTemplateSeries(data)
        )
      )
    );
  const templateTopicsHaveChanged =
    JSON.stringify(filterTopics(transformTopicsObjectToArray(formTopics))) !==
    JSON.stringify(
      filterTopics(
        transformTopicsObjectToArray(getTopicArrayFromTemplate(formTemplate))
      )
    );

  const handleDiscardTemplate = () => {
    const topicsById = getTopicArrayFromMeetingGroupTemplateSeries(data);
    setFormTopics(topicsById || []);
    setFormTemplate(
      data?.meetingGroup.templateSeries.edges[0]?.node.topicTemplates.edges[0]
        ?.node.topicTemplate
    );
  };

  const handleSaveTemplate = () => {
    const templateSeriesId =
      data?.meetingGroup.templateSeries.edges[0]?.node.id;
    const publicTemplate = formTemplate?.publicTemplate;
    // if template is public and it has been changed by user
    // we reset the id, so it creates a new non public template
    // and do not update the public template.
    const templateId =
      publicTemplate && templateTopicsHaveChanged ? null : formTemplate?.id;
    // if template id is not set, then we can ignore topic id so it creates new topics
    const filteredTopics = filterTopics(formTopics, { clearId: !templateId });
    const variables = {
      templateSeriesId,
      meetingGroupId: meetingGroup.id,
      topics:
        publicTemplate && !templateTopicsHaveChanged ? [] : filteredTopics,
      templateId,
    };
    if (templateId && !publicTemplate && filteredTopics.length === 0) {
      removeTemplateSeriesFromMeeting({
        variables: { meetingGroupId: meetingGroup.id },
        refetchQueries: [getTemplateSeriesQuery],
        onCompleted: () => {
          // force cache clearing for meeting group related data
          //  because we change the topic list for future meetings
          cache.evict({ id: cache.identify(meetingGroup) });
        },
        onError: onNotificationErrorHandler(),
      });
    } else {
      createOrUpdateTemplateSeries({
        variables,
        refetchQueries: [getTemplateSeriesQuery],
        onCompleted: () => {
          // force cache clearing for meeting group related data
          //  because we change the topic list for future meetings
          cache.evict({ id: cache.identify(meetingGroup) });
        },
        onError: onNotificationErrorHandler(),
      });
    }
  };

  const handleSavePublicTemplate = () => {
    if (currentUser.paidFeatures.canCreateOrgTemplates) {
      setOpenPublicTemplateNameDialog(true);
    } else {
      link.redirect("/billing");
    }
  };

  const handleClickBrowseTemplate = () => {
    setOpenTemplateLibraryDialog(true);
  };

  const saveTemplateDropdownOptions = [
    {
      label: "Save as public template",
      description: currentUser.paidFeatures.canCreateOrgTemplates ? (
        ""
      ) : (
        <span className="text-red-600">Upgrade to save as public template</span>
      ),
      onClick: handleSavePublicTemplate,
    },
  ];

  const topicsWithTitle = formTopics.filter(({ title }) => title);

  return (
    <div aria-label="Meeting settings template form">
      <Prompt
        when={formTopicsHaveChanged}
        message="Your changes have not been saved. Are you sure you want to leave?"
      />
      {openTemplateLibraryDialog && (
        <Templates
          meeting={null}
          meetingGroup={meetingGroup}
          onClose={() => setOpenTemplateLibraryDialog(false)}
        />
      )}
      {openPublicTemplateNameDialog && (
        <CreatePublicTemplateDialog
          meetingGroup={meetingGroup}
          topics={formTopics}
          onClose={() => setOpenPublicTemplateNameDialog(false)}
        />
      )}
      <div className="mb-4">
        <Button
          onClick={handleClickBrowseTemplate}
          aria-label="Open templates dialog"
        >
          Browse Template Library
        </Button>
      </div>
      <div className="bg-white rounded-lg border p-5">
        <div className="flex justify-between text-gray-800 items-center mb-4 h-8">
          <div
            className={classNames(
              "flex items-center gap-2",
              formTemplate?.publicTemplate && "text-pink-500"
            )}
          >
            {formTemplate?.publicTemplate ? (
              <TemplateIconSolid className="w-5 h-5" />
            ) : (
              <TemplateIcon className="w-5 h-5" />
            )}
            <span className="text-lg font-medium">
              {formTemplate?.publicTemplate
                ? formTemplate.title
                : "Custom template"}
            </span>
            {topicsWithTitle.length > 0 && (
              <Dropdown options={saveTemplateDropdownOptions} />
            )}
          </div>
          <div className="flex items-center gap-4">
            {(loadingSave || loadingDelete) && <Loading mini size={4} />}
            {formTopicsHaveChanged && (
              <>
                <Button
                  disabled={
                    loadingSave || loadingDelete || !formTopicsHaveChanged
                  }
                  onClick={handleDiscardTemplate}
                >
                  Discard changes
                </Button>
                <Button
                  disabled={
                    loadingSave || loadingDelete || !formTopicsHaveChanged
                  }
                  theme="primary"
                  onClick={handleSaveTemplate}
                >
                  {loadingSave || loadingDelete ? "Saving" : "Save"}
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-2">
            <div className="text-gray-500 text-xs uppercase font-semibold">
              Agenda
            </div>
            <div className="border border-gray-300 bg-white rounded-lg">
              <TemplateEditTopics
                oneononeTemplate={!!formTemplate?.oneononeTemplate}
                formTopics={formTopics}
                onChangeFormTopics={setFormTopics}
                autoFocus={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateForm;
