const ProgressBar = ({
  progress,
  label,
}: {
  progress: number;
  label: string;
}) => {
  return (
    <div className="shrink-0 text-xs tracking-tight flex rounded-full border bg-white px-2 justify-center w-26 relative overflow-hidden text-gray-600">
      <div
        className="bg-sky-100 absolute top-0 left-0 bottom-0 z-0"
        style={{
          width: `${Math.min(Math.max(progress, 5), 100)}%`,
        }}
      />
      <span className="z-10">{label}</span>
    </div>
  );
};

export default ProgressBar;
