import { useMutation } from "@apollo/client";
import { ChangeEventHandler, FormEventHandler, useRef, useState } from "react";

import { successNotificationVar } from "@cache/cache";
import { currentOrganizationVar } from "@cache/cache";
import Button from "@components/button/button";
import Input from "@components/input/input";
import Modal from "@components/modal/modal";
import ModalTitle from "@components/modal/modal-title";
import { onNotificationErrorHandler } from "@components/use-error/use-error";

import createPublicTemplateMutation from "../graphql/create-or-update-topic-template-mutation";

const CreatePublicTemplateDialog = ({
  meetingGroup,
  topics,
  onClose,
}: {
  meetingGroup: any;
  topics: any;
  onClose: any;
}) => {
  const focusRef = useRef(null);
  const currentOrganization = currentOrganizationVar();
  const [templateTitle, setTemplateTitle] = useState(meetingGroup.title);
  const [createPublicTemplate] = useMutation(createPublicTemplateMutation, {
    onCompleted: () => {
      successNotificationVar({ title: "Template created!" });
      onClose();
    },
  });
  const handleChangeTitle: ChangeEventHandler<HTMLInputElement> = (e) => {
    setTemplateTitle(e.target.value);
  };
  const handleSubmitForm: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const variables = {
      title: templateTitle,
      organization: currentOrganization.id,
      topics: topics
        .filter((topic: any) => topic.title)
        .map(
          ({
            title,
            description,
            discussionNotes,
          }: {
            title: any;
            description: any;
            discussionNotes: any;
          }) => ({
            title,
            description,
            discussionNotes,
          })
        ),
    };
    createPublicTemplate({
      variables,
      onError: onNotificationErrorHandler(),
    });
  };

  return (
    <Modal onClose={onClose} initialFocus={focusRef}>
      <div className="px-4 py-5 sm:p-6" aria-label="Create template dialog">
        <ModalTitle onClose={onClose}>Create public template</ModalTitle>
        <form className="mt-5" onSubmit={handleSubmitForm}>
          <div className="w-full sm:max-w-xs">
            <label htmlFor="email" className="sr-only">
              Template title
            </label>
            <Input
              name="title"
              id="title"
              ref={focusRef}
              placeholder="Template title..."
              aria-label="Create template name input"
              value={templateTitle}
              onChange={handleChangeTitle}
            />
          </div>
          <div className="mt-4 flex items-center gap-4">
            <Button
              theme="primary"
              type="submit"
              aria-label="Create template submit button"
            >
              Create template
            </Button>
            <Button type="button" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CreatePublicTemplateDialog;
