import { range } from "lodash";

import Tooltip from "@components/tooltip/tooltip";

const RoleBasedAssessmentQuestionScale = ({
  startValue,
  endValue,
  labels,
}: {
  startValue: number;
  endValue: number;
  labels: ReadonlyArray<string | null>;
}) => {
  return (
    <div className="flex-1 flex items-center justify-between">
      {range(startValue, endValue + 1).map((value, index) => (
        <Tooltip text={labels[index] ? labels[index] : undefined} key={value}>
          <div className="h-4 w-10 text-center">{value}</div>
        </Tooltip>
      ))}
    </div>
  );
};

export default RoleBasedAssessmentQuestionScale;
