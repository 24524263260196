import { useQuery } from "@apollo/client";
import { XIcon } from "@heroicons/react/outline";
import { Editor } from "@tiptap/react";
import { FormEvent, useState } from "react";
import {
  GetKpIsQueryQuery,
  GetKpIsQueryQueryVariables,
} from "types/graphql-schema";

import Button, { buttonTheme } from "@components/button/button";
import AppLink from "@components/link/link";
import Loading from "@components/loading/loading";
import Select from "@components/select/select";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull } from "@helpers/helpers";

import getKPIsQuery from "../graphql/get-kpis-query";

const SelectKpiForm = ({
  updateAttributes,
  deleteNode,
  editor,
}: {
  updateAttributes: (attributes: any) => void;
  deleteNode: () => void;
  editor: Editor;
}) => {
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const { data, loading } = useQuery<
    GetKpIsQueryQuery,
    GetKpIsQueryQueryVariables
  >(getKPIsQuery, {
    onCompleted: (response) => {
      setSelectedOption(response?.kpis?.edges?.[0]?.node?.id || null);
    },
    onError: onNotificationErrorHandler(),
  });
  const kpis = data?.kpis ? assertEdgesNonNull(data.kpis) : [];
  const options = kpis.map(({ id, title }) => ({ value: id, label: title }));

  const handleSubmitForm = (e: FormEvent) => {
    e.preventDefault();
    updateAttributes({ id: selectedOption });
  };

  return (
    <form
      className="flex flex-col gap-4 border p-4 rounded bg-gray-50 not-prose"
      contentEditable={false}
      onSubmit={handleSubmitForm}
      aria-label="Artifact kpi form"
    >
      <div className="flex justify-between items-center gap-6">
        {loading && (
          <div className="flex-1">
            <Loading mini size="5" />
          </div>
        )}
        {selectedOption ? (
          <div className="flex-1">
            <Select
              disabled={!editor.options.editable}
              options={options}
              value={selectedOption}
              onChange={(newOption) => setSelectedOption(newOption.value)}
            />
          </div>
        ) : (
          !loading && (
            <div className="text-gray-500 text-sm flex-1">
              There are no KPIs available to be inserted in your meeting notes.
            </div>
          )
        )}
        <button className="p-0.5 rounded hover:bg-black/5" onClick={deleteNode}>
          <XIcon className="h-5 w-5 text-gray-600" />
        </button>
      </div>
      {selectedOption && (
        <div className="flex gap-2 items-center">
          <Button
            type="button"
            disabled={!editor.options.editable || !selectedOption}
            onClick={handleSubmitForm}
            text="Insert selected KPI"
            theme={buttonTheme.primary}
          />
          <Button
            disabled={!editor.options.editable}
            text="Cancel"
            onClick={deleteNode}
          />
        </div>
      )}
      <div
        className={classNames(
          "text-gray-500 text-sm flex gap-2 items-center",
          !!selectedOption && "pt-6 border-t"
        )}
      >
        {!!selectedOption && "or"}
        <AppLink
          to="/kpis/new"
          className="text-blue-link hover:underline font-normal"
        >
          Create a new KPI
        </AppLink>
      </div>
    </form>
  );
};
export default SelectKpiForm;
