import {
  ComplianceProgramParticipantStatus,
  GetComplianceProgramProgressQuery,
} from "types/graphql-schema";

import AppPopover from "@components/popover/app-popover";
import { classNames } from "@helpers/css";

import {
  ComplianceProgramReportingEntityTableCol,
  ComplianceProgramReportingEntityTableParticipantStatusWithNominations,
} from "./compliance-program-progress-entity-table";
import ProgramProgressPillPopoverPanel from "./program-progress-pill-popover-panel";

const ProgramProgressPill = ({
  className,
  status,
  col,
  complianceProgram,
  participantStatus,
}: {
  col: ComplianceProgramReportingEntityTableCol;
  participantStatus: ComplianceProgramReportingEntityTableParticipantStatusWithNominations;
  className?: string;
  status: ComplianceProgramParticipantStatus;
  complianceProgram: NonNullable<
    GetComplianceProgramProgressQuery["complianceProgram"]
  >;
}) => {
  const iconClassName =
    status === ComplianceProgramParticipantStatus.NotApplicable
      ? "border-gray-300"
      : status === ComplianceProgramParticipantStatus.NotStarted
      ? "border-amber-400"
      : status === ComplianceProgramParticipantStatus.InProgress
      ? "border-blue-400 "
      : "border-emerald-400 bg-emerald-400";
  const statusClassName =
    status === ComplianceProgramParticipantStatus.NotApplicable
      ? "bg-gray-100 text-gray-600"
      : status === ComplianceProgramParticipantStatus.NotStarted
      ? "bg-amber-100 text-gray-600"
      : status === ComplianceProgramParticipantStatus.InProgress
      ? "bg-[#DFEDFF] text-gray-600"
      : "bg-emerald-100 text-gray-600";
  const statusLabel =
    status === ComplianceProgramParticipantStatus.NotStarted
      ? col.keyName === "oneononeStatus"
        ? "Not Scheduled"
        : "Not Started"
      : status === ComplianceProgramParticipantStatus.InProgress
      ? "In Progress"
      : status === ComplianceProgramParticipantStatus.Complete
      ? "Complete"
      : "N/A";

  const pillClassName = classNames(
    "flex items-center gap-2 px-2 py-1 text-xs break-keep rounded-full text-gray-600 outline-none",
    statusClassName,
    className,
    "hover:brightness-95"
  );
  const pillContent =
    status === ComplianceProgramParticipantStatus.NotApplicable ? (
      <span className="text-gray-500">N/A</span>
    ) : (
      <>
        <div
          className={classNames(
            "w-2 h-2 border-2 rounded-full relative",
            iconClassName
          )}
        >
          {status === ComplianceProgramParticipantStatus.InProgress && (
            <div className="absolute top-1/2 left-0 w-full h-1/2 bg-blue-400"></div>
          )}
        </div>
        {statusLabel}
      </>
    );
  return (
    <div className="flex gap-2 items-center">
      <AppPopover
        content={({ open }) =>
          open && (
            <ProgramProgressPillPopoverPanel
              participantStatus={participantStatus}
              status={status}
              col={col}
              complianceProgram={complianceProgram}
            />
          )
        }
      >
        <AppPopover.Button className={pillClassName}>
          {pillContent}
        </AppPopover.Button>
      </AppPopover>
    </div>
  );
};

export default ProgramProgressPill;
