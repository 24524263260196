import { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { TFLocationState } from "types/topicflow";

import useCreateEmptyAssessment from "@apps/assessments/hooks/use-create-empty-assessment";

import { ComplianceProgramActionAssessmentItemProps } from "../helpers";
import ComplianceProgramActionItem from "./compliance-program-action-item";

const ComplianceProgramActionItemForAssessment = ({
  item,
  children,
}: {
  item: ComplianceProgramActionAssessmentItemProps;
  children?: ReactNode | null;
}) => {
  const location = useLocation<TFLocationState>();
  const { handleCreateEmptyAssessment, isLoading } = useCreateEmptyAssessment(
    location.pathname
  );

  const handleClickCreateAssessment = () => {
    if (item.user) {
      handleCreateEmptyAssessment(
        item.complianceProgram.id,
        item.assessmentTemplateId,
        item.user.id
      );
    }
  };

  const { key, ...extendedItem } = {
    ...item,
    button: {
      ...item.button,
      to: item.id ? `/assessments/assessment/${item.id}` : undefined,
      onClick: item.id ? undefined : handleClickCreateAssessment,
      text: isLoading ? "Creating..." : item.button?.text,
      disabled: isLoading,
    },
  };

  return (
    <>
      <ComplianceProgramActionItem {...extendedItem}>
        {children}
      </ComplianceProgramActionItem>
    </>
  );
};

export default ComplianceProgramActionItemForAssessment;
