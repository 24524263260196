import { gql } from "@apollo/client";

export const AssessmentQuestionFragment = gql`
  fragment AssessmentQuestionFragment on AssessmentQuestionInterface {
    ... on OverallGoalAssessmentQuestionNode {
      id
      title
      description
      archived
      answersExist
      isCommentMandatory
      responses
      responseVisibility
      questionType
      created
      canUpdate {
        permission
        reason
      }
      canDelete {
        permission
        reason
      }
      categories {
        edges {
          node {
            id
            title
          }
        }
      }
      creator {
        id
        name
      }
      startValue
      endValue
      labels
      labelDescriptions
    }
    ... on IndividualGoalAssessmentQuestionNode {
      id
      title
      description
      archived
      answersExist
      isCommentMandatory
      responses
      responseVisibility
      questionType
      created
      canUpdate {
        permission
        reason
      }
      canDelete {
        permission
        reason
      }
      categories {
        edges {
          node {
            id
            title
          }
        }
      }
      creator {
        id
        name
      }
      startValue
      endValue
      labels
      labelDescriptions
    }
    ... on RangeAssessmentQuestionNode {
      id
      title
      description
      archived
      answersExist
      isCommentMandatory
      responses
      responseVisibility
      questionType
      created
      canUpdate {
        permission
        reason
      }
      canDelete {
        permission
        reason
      }
      categories {
        edges {
          node {
            id
            title
          }
        }
      }
      creator {
        id
        name
      }
      startValue
      endValue
      labels
      labelDescriptions
    }
    ... on CompetencyAssessmentQuestionNode {
      id
      title
      description
      archived
      answersExist
      isCommentMandatory
      responses
      responseVisibility
      questionType
      created
      canUpdate {
        permission
        reason
      }
      canDelete {
        permission
        reason
      }
      categories {
        edges {
          node {
            id
            title
          }
        }
      }
      creator {
        id
        name
      }
      startValue
      endValue
      labels
      labelDescriptions
    }
    ... on CompetencyCriteriaAssessmentQuestionNode {
      id
      title
      description
      archived
      answersExist
      isCommentMandatory
      responses
      responseVisibility
      questionType
      created
      canUpdate {
        permission
        reason
      }
      canDelete {
        permission
        reason
      }
      categories {
        edges {
          node {
            id
            title
          }
        }
      }
      creator {
        id
        name
      }
      startValue
      endValue
      labels
      labelDescriptions
    }
    ... on ResponsibilityAssessmentQuestionNode {
      id
      title
      description
      archived
      answersExist
      isCommentMandatory
      responses
      responseVisibility
      questionType
      created
      canUpdate {
        permission
        reason
      }
      canDelete {
        permission
        reason
      }
      categories {
        edges {
          node {
            id
            title
          }
        }
      }
      creator {
        id
        name
      }
      startValue
      endValue
      labels
      labelDescriptions
    }
    ... on TextAssessmentQuestionNode {
      id
      title
      description
      archived
      answersExist
      isCommentMandatory
      responses
      responseVisibility
      questionType
      created
      canUpdate {
        permission
        reason
      }
      canDelete {
        permission
        reason
      }
      categories {
        edges {
          node {
            id
            title
          }
        }
      }
      creator {
        id
        name
      }
    }
    ... on MultiChoiceAssessmentQuestionNode {
      id
      title
      description
      archived
      answersExist
      isCommentMandatory
      responses
      responseVisibility
      questionType
      created
      canUpdate {
        permission
        reason
      }
      canDelete {
        permission
        reason
      }
      categories {
        edges {
          node {
            id
            title
          }
        }
      }
      creator {
        id
        name
      }
      options
      optionDescriptions
    }
  }
`;

export default gql`
  ${AssessmentQuestionFragment}
  query getAssessmentQuestions($organizationId: Int!, $archived: Boolean) {
    assessmentQuestions(organizationId: $organizationId, archived: $archived) {
      edges {
        node {
          ...AssessmentQuestionFragment
        }
      }
    }
  }
`;
